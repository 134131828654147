import { PlusIcon, SearchIcon } from '@assets/icons';
import { Button, TableView, TableViewButton } from '@components/button';
import { TextInput } from '@components/form';
import { useModal } from '@components/modal';
import { Typography } from '@components/typography';
import { useGetOrganizationSettingsQuery, useGetWorkspacesQuery } from '@generated/UseGraphqlHooks';
import { Box, SelectChangeEvent, Stack, useTheme } from '@mui/material';
import {
  NewWorkspacesModal,
  NewWorkspacesModalHelpContent,
} from '@subsets/workspaces/workspaces-modals';
import { find } from 'lodash';
import posthog from 'posthog-js';

const CreateWorkspaceButton = ({
  currentListSize,
  organizationId,
  organizationName,
}: {
  currentListSize?: number;
  organizationId: string;
  organizationName?: string;
}) => {
  const { data: organizationSettingsData, loading: orgSettingsLoading } =
    useGetOrganizationSettingsQuery({
      variables: { organizationId },
    });

  const { usage, limit } =
    (!orgSettingsLoading &&
      find(organizationSettingsData?.getOrganizationSettings, {
        setting: 'Workspaces',
      })) ||
    {};
  const { refetch: organizationWorkspacesRefetch } = useGetWorkspacesQuery({
    variables: { organizationId, limit: (currentListSize || 30) + 1 },
  });
  const { refetch: organizationSettingsRefetch } = useGetOrganizationSettingsQuery({
    variables: { organizationId },
  });
  const workspacesRefetch = async () => {
    await organizationWorkspacesRefetch();
    await organizationSettingsRefetch();
  };
  const { showModal } = useModal({
    component: NewWorkspacesModal,
    componentProps: {
      organizationId,
      refetchWorkspaces: workspacesRefetch,
      organizationName,
    },
    modalProps: { title: 'New workspace', help: NewWorkspacesModalHelpContent },
  });

  const isOrganizationWorkspacesReachedLimit = Number(usage) === Number(limit);

  return (
    <Button
      variant="accent"
      disabled={isOrganizationWorkspacesReachedLimit}
      startIcon={PlusIcon}
      onClick={() => {
        posthog.capture('user_create-workspace_intent', { location: 'organization-landing' });
        showModal();
      }}
    >
      New workspace
    </Button>
  );
};

interface LandingPageWorkspacesHeaderProps {
  organizationId: string;
  organizationName?: string;
  label: string;
  tableView: TableView;
  currentListSize?: number;
  setTableView: (newView: TableView) => void;
  handleSearchBarChange?: ({
    target: { value },
  }: {
    target: {
      value: string;
    };
  }) => void;
  graphConfigSortKey: string;
  handleSortByChange: ({ target: { value } }: SelectChangeEvent<string>) => void;
}

export const LandingPageWorkspacesHeader = ({
  organizationId,
  organizationName,
  label,
  tableView,
  currentListSize,
  setTableView,
  handleSearchBarChange,
}: LandingPageWorkspacesHeaderProps) => {
  const { palette } = useTheme();

  if (organizationId) {
    return (
      <Stack gap={8} mb={5}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography maxWidth="400px" variant="h1" noWrap handleOverFlow>
            {label}
          </Typography>
          {organizationId && (
            <CreateWorkspaceButton
              organizationId={organizationId}
              organizationName={organizationName}
              currentListSize={currentListSize}
            />
          )}
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Box sx={{ maxWidth: '60%', flex: 1 }}>
            <TextInput
              name="search"
              data-cy="Landing-Page-Workspaces-Header-Search-Bar"
              startIcon={SearchIcon}
              onChange={handleSearchBarChange}
            />
          </Box>
          <TableViewButton mode={tableView} onChange={(newView) => setTableView(newView)} />
        </Stack>
      </Stack>
    );
  }

  return (
    <Stack
      direction="row"
      gap={8}
      mb={5}
      justifyContent="space-between"
      data-cy="Landing-Page-Workspaces"
    >
      <Typography maxWidth="400px" variant="h1" noWrap handleOverFlow>
        {label} workspaces
      </Typography>
      <Box width="560px">
        <TextInput startIcon={SearchIcon} onChange={handleSearchBarChange} />
      </Box>
    </Stack>
  );
};
