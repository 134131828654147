import { Typography } from '@components/typography';
import { GanDataset } from '@generated/UseGraphqlHooks';
import { Box, Grid, useTheme } from '@mui/material';
import { DatasetsDetailsGanDatasetsListItemPreview } from '@subsets/workspaces';

interface DatasetsDetailsGanDatasetsListProps {
  workspaceId: string;
  ganDatasets: Partial<GanDataset>[];
  refetch: VoidFunction;
}

export const DatasetsDetailsGanDatasetsList = ({
  ganDatasets,
  workspaceId,
  refetch,
}: DatasetsDetailsGanDatasetsListProps) => {
  const { palette } = useTheme();
  if (ganDatasets.length === 0) {
    return (
      <Typography variant="caption2" noWrap>
        This dataset has no GAN datasets.
      </Typography>
    );
  }

  return (
    <>
      <Grid container alignItems="center">
        <Grid item xs={1.5}>
          <Typography color={palette.table.header} variant="caption2">
            Status
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography color={palette.table.header} variant="caption2">
            Name
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography color={palette.table.header} variant="caption2">
            Model
          </Typography>
        </Grid>
      </Grid>
      {ganDatasets.map((ganDataset) => (
        <DatasetsDetailsGanDatasetsListItemPreview
          ganDataset={ganDataset}
          workspaceId={workspaceId}
          refetch={refetch}
          key={ganDataset.datasetId}
        />
      ))}
    </>
  );
};
