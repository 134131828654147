import { AsyncButton, Button } from '@components/button';
import { Form, FormTextInput } from '@components/form';
import { Typography } from '@components/typography';
import {
  useCreateManagedVolumeMutation,
  useGetOrganizationSettingsQuery,
  useGetVolumesQuery,
} from '@generated/UseGraphqlHooks';
import { Stack } from '@mui/material';
import { useNotifications } from '@notifications/Notifications';
import * as yup from 'yup';

interface NewVolumeModalProps {
  onClose: () => void;
  organizationId: string;
}
interface SubmitType {
  volumeName: string;
  description: string;
}

const validationSchema = yup.object({
  volumeName: yup
    .string()
    .trim()
    .max(128, 'Must be less than 128 characters')
    .required('Name is required'),
  description: yup.string(),
});

export const NewVolumeModal = ({ onClose, organizationId }: NewVolumeModalProps) => {
  const { useAsyncNotification } = useNotifications();
  const [createManagedVolumeMutation] = useCreateManagedVolumeMutation();
  const { refetch: refetchVolumes } = useGetVolumesQuery({
    variables: {
      organizationId,
    },
  });
  const { refetch: volumesSettingsRefetch } = useGetOrganizationSettingsQuery({
    variables: { organizationId },
  });

  const handleFormSubmit = useAsyncNotification(
    'Successfully created the new volume',
    async ({ volumeName, description }: SubmitType) => {
      const { data } = await createManagedVolumeMutation({
        variables: { organizationId, name: volumeName, description },
      });
      if (data) {
        await refetchVolumes();
        await volumesSettingsRefetch();
        onClose();
      }
    },
  );

  return (
    <Form
      initialValues={{ volumeName: '', description: '' }}
      validationSchema={validationSchema}
      validateOnBlur={false}
      onSubmit={handleFormSubmit}
    >
      {({ isValid, handleSubmit, dirty, isSubmitting }) => (
        <Stack>
          <FormTextInput name="volumeName" label="Name" />
          <FormTextInput name="description" label="Description" multiline rows={3} />
          <Stack gap={4}>
            <AsyncButton
              fullWidth
              loading={isSubmitting}
              disabled={!(isValid && dirty && !isSubmitting)}
              onClick={handleSubmit}
              data-cy="New-Volume-Modal-Create-Button"
            >
              Create
            </AsyncButton>
            <Button
              fullWidth
              variant="secondary"
              data-cy="New-Volume-Modal-Cancel-Button"
              onClick={onClose}
            >
              Cancel
            </Button>
          </Stack>
        </Stack>
      )}
    </Form>
  );
};

export const NewVolumeModalHelpContent = (
  <Typography variant="body2">
    Volumes store static assets such as 3D models and backgrounds for use in synthetic data
    generation. Volumes created via the GUI are referred to as Workspace volumes. Workspace volumes
    are associated with user-managed workspaces and are created and maintained by users. These
    volumes are dynamically updated for increased fidelity of generated datasets.
  </Typography>
);
