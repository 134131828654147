import { Button } from '@components/button';
import { Typography } from '@components/typography';
import { navigate } from 'gatsby';
import { ExpiredModalWrapper } from './ExpiredModalWrapper';
import { ProgressCircle } from './ProgressCircle';

type ExpiredModalProps = {
  isAdmin: boolean;
  workspacesPage?: boolean;
  organizationId: string;
};

export const ExpiredModal = ({ isAdmin, workspacesPage, organizationId }: ExpiredModalProps) => {
  const handleResubscribeButton = async () => {
    await navigate(`/organizations/${organizationId}/billing`);
  };
  const handleGoToHomeButton = async () => {
    await navigate('/');
  };
  return (
    <ExpiredModalWrapper>
      <Typography variant="subtitle1">Your subscription has expired</Typography>
      <ProgressCircle content="Expired" value={100} error />
      <Typography variant="body2" textAlign="center">
        {isAdmin
          ? 'Please upgrade your plan to continue using our platform.'
          : 'Please contact your administrator.'}
      </Typography>
      {isAdmin && (
        <Button fullWidth onClick={handleResubscribeButton}>
          Re-subscribe
        </Button>
      )}
      {workspacesPage && !isAdmin && (
        <Button variant="secondary" fullWidth onClick={handleGoToHomeButton}>
          Go back to home page
        </Button>
      )}
    </ExpiredModalWrapper>
  );
};
