import { Box, BoxProps } from '@mui/material';
import { PropsWithChildren } from 'react';

type TabPanelProps = BoxProps & {
  index: string;
  value: string;
};

export const TabPanel = ({ value, index, sx, ...rest }: PropsWithChildren<TabPanelProps>) => {
  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      sx={sx}
      {...rest}
    />
  );
};
