import { CopyIconButton } from '@components/button';
import { Group } from '@components/layout';
import { InternalLink } from '@components/link';
import { Typography } from '@components/typography';
import { useTheme } from '@mui/material';
import { ReactNode } from 'react';

const InfoPreview = ({
  content,
  id,
  label,
}: {
  content?: ReactNode;
  id: string;
  label: string;
}) => {
  const { palette } = useTheme();
  return (
    <Group justifyContent="space-between" alignItems="center" gap={2}>
      <Typography variant="caption2" color={palette.text.paper}>
        {label}
      </Typography>
      <Group alignItems="center" gap={2}>
        {content || (
          <Typography handleOverFlow variant="caption2">
            {id}
          </Typography>
        )}
        <CopyIconButton value={id} />
      </Group>
    </Group>
  );
};

type JobConfigurationsPreviewExpandedViewInfoProps = {
  channel: string;
  channelId: string;
  workspaceId: string;
  graphId: string;
};

export const JobsConfigurationsPreviewExpandedViewInfo = ({
  channel,
  channelId,
  workspaceId,
  graphId,
}: JobConfigurationsPreviewExpandedViewInfoProps) => {
  return (
    <>
      <InfoPreview id={workspaceId} label="Workspace Id" />
      <InfoPreview
        id={channelId}
        label="Channel"
        content={
          <Typography handleOverFlow variant="caption2">
            {channel}
          </Typography>
        }
      />
      <InfoPreview
        id={graphId}
        content={
          <InternalLink to={`/workspaces/${workspaceId}/graphs/${graphId}`} unstyled>
            <Typography
              variant="caption2"
              sx={{ textDecoration: 'underline', cursor: 'pointer' }}
              tooltip="View graph"
            >
              {graphId}
            </Typography>
          </InternalLink>
        }
        label="Graph Id"
      />
    </>
  );
};
