import { ChartIcon, DownloadIcon, TrashIcon, UploadIcon } from '@assets/icons';
import { ActionButton } from '@components/button';
import { useConfirmation, useModal } from '@components/modal';
import { useDeleteDatasetMutation, useDownloadDatasetMutation } from '@generated/UseGraphqlHooks';
import { saveFile } from '@helper-functions/save-file';
import { Stack } from '@mui/material';
import { useNotifications } from '@notifications/Notifications';
import { DatasetsActionUploadModal, DatasetsCompareModal, useDatasets } from '@subsets/workspaces';
import { first, map, size } from 'lodash';
import posthog from 'posthog-js';
import { useState } from 'react';

interface DatasetsActionsProps {
  workspaceId: string;
}

export const DatasetsActions = ({ workspaceId }: DatasetsActionsProps) => {
  const { selectedDatasets, setSelectedDataset, setSelectedDatasets, datasets, pagination } =
    useDatasets();
  const { showConfirmation } = useConfirmation();
  const { useAsyncNotification, addNotification, removeNotification } = useNotifications();
  const [compareModalOpen, setCompareModalOpen] = useState(false);
  const handleCompareOnClick = () => {
    if (size(selectedDatasets) >= 2) {
      posthog.capture('user_create-umap_intent');
      setCompareModalOpen(true);
    }
  };
  const selected = size(selectedDatasets) > 0;
  const oneFileSelected = size(selectedDatasets) === 1;

  const [downloadDataset] = useDownloadDatasetMutation();
  const [deleteDataset] = useDeleteDatasetMutation();

  const { showModal: showUploadDatasetModal } = useModal({
    component: DatasetsActionUploadModal,
    componentProps: { workspaceId },
    modalProps: { title: 'Upload Dataset' },
  });

  const handleDownloadDataset = async () => {
    const key = `${Date.now() * 1000}.${Math.random() * 10000}`;
    try {
      const { data } = await downloadDataset({
        variables: {
          workspaceId,
          datasetId: first(selectedDatasets),
        },
      });
      const datasetName = datasets.filter((d) => d.datasetId === first(selectedDatasets))[0].name;

      addNotification({ text: 'Loading...', type: 'loading', key, duration: 100000 });
      saveFile({ url: data.downloadDataset, name: `${datasetName}.zip` });
      removeNotification(key);
      addNotification({
        text: 'Starting download...',
        type: 'success',
        duration: 6000,
      });
    } catch (e) {
      removeNotification(key);
    }
  };

  const handleDeleteDatasets = async () => {
    await showConfirmation({
      onAffirm: useAsyncNotification('Datasets deleted', async () => {
        await Promise.all(
          map(selectedDatasets, async (id: string) => {
            const res = await deleteDataset({
              variables: {
                workspaceId,
                datasetId: id,
              },
            });
            return res;
          }),
        );
        setSelectedDatasets([]);
        setSelectedDataset(null);
        pagination.refetch();
      }),
      message: `Are you sure you want to delete the selected dataset${selectedDatasets.length > 1 ? 's' : ''}?`,
    });
  };

  return (
    <Stack alignItems="center">
      <ActionButton
        Icon={UploadIcon}
        label="Upload"
        onClick={() => {
          posthog.capture('user_upload-dataset_intent');
          showUploadDatasetModal();
        }}
      />
      <ActionButton
        Icon={DownloadIcon}
        label="Download"
        onClick={oneFileSelected ? handleDownloadDataset : undefined}
        disabled={!oneFileSelected}
      />
      <ActionButton
        Icon={TrashIcon}
        label="Delete"
        onClick={handleDeleteDatasets}
        disabled={!selected}
      />
      <ActionButton
        Icon={ChartIcon}
        label="Compare"
        onClick={handleCompareOnClick}
        disabled={selectedDatasets.length < 2}
      />
      <DatasetsCompareModal
        open={compareModalOpen}
        workspaceId={workspaceId}
        onClose={() => setCompareModalOpen(false)}
      />
    </Stack>
  );
};
