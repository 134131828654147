import { gql, useMutation } from '@apollo/client';
import { MoreVerticalIcon } from '@assets/icons';
import { IconButton } from '@components/button';
import { useModal } from '@components/modal';
import { Typography } from '@components/typography';
import { saveFile } from '@helper-functions/save-file';
import { Box, Menu, MenuItem, useTheme } from '@mui/material';
import { useNotifications } from '@notifications/Notifications';
import { MouseEvent, useState } from 'react';
import { DeleteAnnotationMapModal } from './DeleteAnnotationMapModal';
import { EditAnnotationMapModal } from './EditAnnotationMapModal';

const DownloadMapMutation = gql`
  mutation downloadManagedMap($mapId: String!) {
    downloadManagedMap(mapId: $mapId)
  }
`;

type AnnotationMapTableRowDropdownProps = {
  name?: string;
  mapId?: string;
  organizationId?: string;
  description?: string;
  selectedOrganizationId?: string;
};

export const AnnotationMapTableRowDropdown = ({
  name,
  mapId,
  organizationId,
  description,
  selectedOrganizationId,
}: AnnotationMapTableRowDropdownProps) => {
  const { palette } = useTheme();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { addNotification, removeNotification } = useNotifications();
  const [downloadManagedAnnotationMap] = useMutation<{ downloadManagedMap: string }>(
    DownloadMapMutation,
  );
  const isAnnotationMapOwnedByOrganization = selectedOrganizationId === organizationId;

  const { showModal: showDeleteMapModal } = useModal({
    component: DeleteAnnotationMapModal,
    componentProps: {
      name,
      mapId,
      organizationId: selectedOrganizationId,
      isAnnotationMapOwnedByOrganization,
    },
    modalProps: {
      title: `${isAnnotationMapOwnedByOrganization ? 'Delete' : 'Remove'} annotation map`,
    },
  });
  const { showModal: showAnnotationMapModal } = useModal({
    component: EditAnnotationMapModal,
    componentProps: { name, description, mapId, organizationId: selectedOrganizationId },
    modalProps: { title: 'Edit annotation map' },
  });
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const handleEditAnnotationMapClick = () => {
    showAnnotationMapModal();
    handleClose();
  };
  const handleDeleteAnnotationMapClick = () => {
    showDeleteMapModal();
    handleClose();
  };

  const handleDownloadClick = async () => {
    const key = `${Date.now() * 1000}.${Math.random() * 10000}`;
    try {
      addNotification({ text: 'Loading...', type: 'loading', key, duration: 100000 });
      const response = await downloadManagedAnnotationMap({
        variables: { mapId },
      });
      if (response?.data?.downloadManagedMap?.includes('https://')) {
        saveFile({
          url: response?.data?.downloadManagedMap,
          name: `${mapId || ''}.yml`,
        });
        addNotification({
          text: 'Starting download...',
          type: 'success',
          duration: 6000,
        });
      } else {
        throw new Error('Unable to download annotation.');
      }
      removeNotification(key);
      handleClose();
    } catch (e) {
      removeNotification(key);
    }
  };

  return (
    <Box>
      <IconButton
        onClick={handleClick}
        Icon={MoreVerticalIcon}
        size={20}
        data-cy="Annotation-Map-Table-Row-Dropdown-Icon"
      />
      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {isAnnotationMapOwnedByOrganization ? (
          <Box>
            <MenuItem onClick={handleEditAnnotationMapClick}>
              <Typography variant="caption2" data-cy="Annotation-Map-Table-Row-Dropdown-Edit">
                Edit
              </Typography>
            </MenuItem>
            <MenuItem onClick={handleDownloadClick}>
              <Typography variant="caption2" data-cy="Annotation-Map-Table-Row-Dropdown-Download">
                Download
              </Typography>
            </MenuItem>
            <MenuItem onClick={handleDeleteAnnotationMapClick}>
              <Typography variant="caption2" data-cy="Annotation-Map-Table-Row-Dropdown-Delete">
                Delete
              </Typography>
            </MenuItem>
          </Box>
        ) : (
          <Box>
            <MenuItem onClick={handleDeleteAnnotationMapClick}>
              <Typography variant="caption2" data-cy="Annotation-Map-Table-Row-Dropdown-Remove">
                Remove
              </Typography>
            </MenuItem>
          </Box>
        )}
      </Menu>
    </Box>
  );
};
