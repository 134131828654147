import { LoadingIcon } from '@assets/icons';
import { Image } from '@components/image';
import { Typography } from '@components/typography';
import { Box, Stack, useTheme } from '@mui/material';
import { ReactNode, useEffect, useRef, useState } from 'react';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

const Volume3DPreviewContent = ({ url }: { url: string }) => {
  const { palette } = useTheme();
  const mountRef = useRef<HTMLDivElement | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!url || !mountRef.current) return;

    // Set up the scene
    const scene = new THREE.Scene();
    scene.background = new THREE.Color(palette.background.default);

    // Set up the camera
    const camera = new THREE.PerspectiveCamera(
      75,
      mountRef.current.clientWidth / mountRef.current.clientHeight,
      0.1,
      1000,
    );
    camera.position.set(0, 2, 5);

    // Set up the renderer
    const renderer = new THREE.WebGLRenderer({ antialias: true });
    renderer.setSize(mountRef.current.clientWidth, mountRef.current.clientHeight);
    mountRef.current.appendChild(renderer.domElement);

    // Set up lighting
    const ambientLight = new THREE.AmbientLight(0xffffff, 0.6);
    scene.add(ambientLight);

    const directionalLight = new THREE.DirectionalLight(0xffffff, 1);
    directionalLight.position.set(0, 10, 5).normalize();
    scene.add(directionalLight);

    // Set up OrbitControls for model interaction
    const controls = new OrbitControls(camera, renderer.domElement);
    controls.enableDamping = true;
    controls.dampingFactor = 0.05;
    controls.screenSpacePanning = false;

    // Function to calculate bounding box and camera properties
    const calculateCameraProperties = (gltfScene) => {
      const box = new THREE.Box3().setFromObject(gltfScene);
      const size = box.getSize(new THREE.Vector3());
      const center = box.getCenter(new THREE.Vector3());

      // Update camera position and distance
      const maxDimension = Math.max(size.x, size.y, size.z);
      const fov = THREE.MathUtils.degToRad(camera.fov); // Convert FOV to radians
      const distance = (maxDimension / 2) / Math.tan(fov / 2); // Camera distance

      camera.position.set(center.x, center.y, center.z + distance * 2);
      camera.lookAt(center);

      // Set camera clipping planes
      camera.near = distance / 10;
      camera.far = distance * 10;
      camera.updateProjectionMatrix();
    };

    // Load the GLB model
    const loader = new GLTFLoader();
    loader.load(
      url,
      (gltf) => {
        scene.add(gltf.scene);
        calculateCameraProperties(gltf.scene); 
        setLoading(false);
      },
      undefined,
      (error) => {
        console.error('Error loading GLB model:', error);
        setLoading(false);
      },
    );

    // Resize handler
    const onWindowResize = () => {
      camera.aspect = mountRef.current!.clientWidth / mountRef.current!.clientHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(mountRef.current!.clientWidth, mountRef.current!.clientHeight);
    };

    window.addEventListener('resize', onWindowResize, false);

    // Animation loop
    const animate = () => {
      requestAnimationFrame(animate);
      controls.update();
      renderer.render(scene, camera);
    };

    animate();

    // Clean up on component unmount
    return () => {
      window.removeEventListener('resize', onWindowResize);

      if (mountRef.current && renderer.domElement) {
        mountRef.current.removeChild(renderer.domElement);
      }

      renderer.dispose();
    };
  }, [url, palette.background.default]);

  return (
    <Box
      ref={mountRef}
      sx={{
        height: '70vh',
        width: '70vw',
        backgroundColor: palette.background.default,
      }}
    >
      {loading && (
        <Box
          position={'absolute'}
          sx={{
            height: '70vh',
            width: '70vw',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <LoadingIcon size={16} style={{ marginBottom: 4 }} />
        </Box>
      )}
    </Box>
  );
};

const VolumeThubmailContent = ({ url }: { url: string }) => {
  const { palette } = useTheme();

  return (
    <Box
      sx={{
        height: '70vh',
        width: '70vw',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: palette.background.default,
      }}
    >
      <Image alt="volume thubmnail" url={url} />
    </Box>
  );
};

export const Volume3DPreviewModal = ({
  name,
  previewUrl,
  imageUrl,
  options,
}: {
  name: string;
  previewUrl: string;
  imageUrl?: string;
  options?: ReactNode;
}) => {
  return (
    <Stack minWidth="516px" height="auto" gap={4}>
      <Typography variant="h3" sx={{ textAlign: 'center' }}>
        Preview {name}
      </Typography>
      <Box position="relative" pb={8} px={8}>
        {options && (
          <Box position="absolute" top={8} right={32}>
            {options}
          </Box>
        )}
        {previewUrl ? (
          <Volume3DPreviewContent url={previewUrl} />
        ) : imageUrl ? (
          <VolumeThubmailContent url={imageUrl} />
        ) : null}
      </Box>
    </Stack>
  );
};
