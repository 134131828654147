import { Typography } from '@components/typography';
import { LinearProgress, LinearProgressProps, Stack, useTheme } from '@mui/material';

type ProgressBarProps = LinearProgressProps & {
  error?: boolean;
  label?: string;
  percent?: number;
  warning?: boolean;
};

export const ProgressBar = ({ error, label, percent, sx, warning, ...rest }: ProgressBarProps) => {
  const { palette } = useTheme();

  let value = 0;
  if (error) {
    value = 100;
  } else if (!percent) {
    value = 0;
  } else if (percent > 100) {
    value = 100;
  } else if (percent < 0) {
    value = 0;
  } else {
    value = percent;
  }

  return (
    <Stack direction="row" gap={2} alignItems="center" sx={sx}>
      <LinearProgress
        sx={{
          '& .MuiLinearProgress-barColorPrimary': {
            backgroundColor: error
              ? palette.progress.error
              : warning
                ? palette.progress.warning
                : palette.progress.default,
          },
        }}
        variant="determinate"
        value={value}
        {...rest}
      />
      {label && (
        <Typography
          handleOverFlow
          flex={1}
          variant="caption2"
          color={error ? palette.error.main : palette.grey[500]}
        >
          {label}
        </Typography>
      )}
    </Stack>
  );
};
