import { ArrowLeftIcon } from '@assets/icons';
import { RenderedAiIcon } from '@assets/logos';
import { Group } from '@components/layout';
import { Typography } from '@components/typography';
import { Stack, useTheme } from '@mui/material';

export const DatasetsDetailsPreview = () => {
  const { palette } = useTheme();

  return (
    <Stack justifyContent="center" alignItems="center" height="100%" gap={4}>
      <RenderedAiIcon color={palette.text.light} size="54px" />
      <Group alignItems="center" justifyContent="center">
        <ArrowLeftIcon color={palette.grey[500]} />

        <Typography color={palette.grey[600]} ml={3} mb={1} variant="subtitle1">
          Select a row to view details
        </Typography>
      </Group>
    </Stack>
  );
};
