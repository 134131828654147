import { FileTextIcon, HelpCircleIcon, WaveIcon } from '@assets/icons';
import { ExternalLink } from '@components/link';
import { useContactUsModal, useModal } from '@components/modal';
import { Typography } from '@components/typography';
import { MenuItem, Stack } from '@mui/material';
import { GettingStartedModal } from '@subsets/landing-page';
import posthog from 'posthog-js';
import { useState } from 'react';
import { HeaderMenu } from './HeaderMenu';

export const Help = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClose = () => setAnchorEl(null);

  const { showModal } = useContactUsModal({ onModalOpen: handleClose });
  const handleContactUsClick = () => {
    posthog.capture('user_contact-us_intent', { location: 'help-menu' });
    showModal();
  };

  const handleDocumentationClick = () => {
    posthog.capture('user_clicked_help-documentation');
    handleClose();
  };

  const { showModal: showIntroModal } = useModal({
    component: GettingStartedModal,
    onModalOpen: handleClose,
    modalProps: { sx: { py: 12, transition: 'all .5s' } },
  });
  const handleIntroModalClick = () => {
    posthog.capture('user_clicked_help-intro_modal');
    showIntroModal();
  };

  return (
    <HeaderMenu icon={HelpCircleIcon} anchorEl={anchorEl} setAnchorEl={setAnchorEl}>
      <MenuItem onClick={handleContactUsClick} sx={{ display: 'flex', gap: 2 }}>
        <HelpCircleIcon size={20} />
        <Typography variant="body2" color="text.primary">
          Contact Us
        </Typography>
      </MenuItem>
      <ExternalLink unstyled href="http://support.rendered.ai">
        <MenuItem onClick={handleDocumentationClick}>
          <Stack direction="row" gap={2}>
            <FileTextIcon size={20} />
            <Typography variant="body2" color="text.primary">
              Documentation
            </Typography>
          </Stack>
        </MenuItem>
      </ExternalLink>
      <MenuItem onClick={handleIntroModalClick} sx={{ display: 'flex', gap: 2 }}>
        <WaveIcon size={20} />
        <Typography variant="body2" color="text.primary">
          Intro to Rendered.ai
        </Typography>
      </MenuItem>
    </HeaderMenu>
  );
};
