import { LoadingIcon } from '@assets/icons';
import { TableView } from '@components/button';
import { InfiniteScrollTableComponents, useGraphqlPagination } from '@components/infiniteScroll';
import { Typography } from '@components/typography';
import {
  GetWorkspacesQuery,
  Organization,
  Workspace,
  useGetWorkspacesQuery,
} from '@generated/UseGraphqlHooks';
import { calculateDateDifference } from '@helper-functions/calculateDateDifference';
import { useSearch } from '@hooks/UseSearch';
import { Box, Grid, SelectChangeEvent, useTheme } from '@mui/material';
import { LandingPageExpirationWrapper } from '@subsets/expiration';
import {
  LandingPageOrganizationDetails,
  LandingPageWorkspacesHeader,
  LandingPageWorkspacesWorkspacePreview,
  WorkspaceTableHeader,
  WorkspaceTableRow,
} from '@subsets/landing-page';
import { forwardRef, useCallback, useState } from 'react';
import { TableVirtuoso, VirtuosoGrid } from 'react-virtuoso';

const getOrganizationExpirationData = (organizations: Organization[], organizationId: string) => {
  const workspaceOrganization = organizations.find(
    (organization) => organization.organizationId === organizationId,
  );
  const { days, hours } = calculateDateDifference(workspaceOrganization?.expirationDate);
  return {
    expirationDate: { days, hours },
    expired: workspaceOrganization?.expired || hours < 1,
  };
};

const ItemContainer = (props) => (
  <Box
    data-test-id="virtuoso-item-list"
    sx={{
      display: 'flex',
      flex: 'none',
      alignContent: 'stretch',
      boxSizing: 'border-box',
    }}
    {...props}
  />
);

const ListContainer = forwardRef<HTMLDivElement>((props, ref) => (
  <Box
    sx={{
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
      gap: 6,
      paddingRight: 2,
    }}
    {...props}
    ref={ref}
  />
));

type LandingPageWorkspacesProps = {
  label: string;
  organization?: Organization;
  organizations: Organization[];
  recent?: boolean;
};

export const LandingPageWorkspaces = ({
  label,
  organization,
  organizations,
  recent = false,
}: LandingPageWorkspacesProps) => {
  const { palette } = useTheme();
  const [tableView, setTableView] = useState<TableView>('grid');
  const {
    data: workspaces,
    loading: workspacesLoading,
    fetchMore: fetchMoreWorkspace,
  } = useGraphqlPagination<GetWorkspacesQuery, Workspace>(
    useGetWorkspacesQuery({
      variables: { organizationId: recent ? null : organization?.organizationId, limit: 30 },
      fetchPolicy: recent ? 'no-cache' : 'cache-first',
    }),
    'getWorkspaces',
    'workspaceId',
  );
  const [sortKey, setSortKey] = useState('Recently edited');
  const { data: filteredWorkspaces, onSearch } = useSearch(workspaces, [
    'name',
    'organziation',
    'workspaceId',
  ]);
  const handleSearchBarChange = ({
    target: { value: searchBarValue },
  }: {
    target: { value: string };
  }) => {
    onSearch(searchBarValue);
  };

  const handleSortByChange = ({ target: { value: dropdownValue } }: SelectChangeEvent<string>) => {
    setSortKey(dropdownValue);
  };

  const tableRow = (_index, workspace) => <WorkspaceTableRow {...workspace} />;

  const item = useCallback(
    (index: number, workspace: any) => {
      const { expired } =
        getOrganizationExpirationData(organizations, workspace.organizationId) || {};
      return (
        <LandingPageWorkspacesWorkspacePreview
          recent={recent}
          key={workspace?.workspaceId}
          expired={recent && expired}
          {...workspace}
        />
      );
    },
    [recent],
  );

  return (
    <>
      <Grid container height="100%">
        <Grid
          item
          xs={recent ? 12 : 8.5}
          pl={10}
          pt={10}
          pr={recent ? 0 : 10}
          sx={{ height: '100%', overflowY: 'hidden', display: 'flex', flexDirection: 'column' }}
        >
          <LandingPageWorkspacesHeader
            label={label}
            graphConfigSortKey={sortKey}
            tableView={tableView}
            currentListSize={workspaces?.length}
            setTableView={setTableView}
            handleSortByChange={handleSortByChange}
            organizationId={organization?.organizationId}
            organizationName={organization?.name}
            handleSearchBarChange={handleSearchBarChange}
          />
          {filteredWorkspaces?.length > 0 ? (
            tableView === 'grid' ? (
              <VirtuosoGrid
                totalCount={filteredWorkspaces.length}
                endReached={() => {
                  fetchMoreWorkspace();
                }}
                components={{
                  Item: ItemContainer,
                  List: ListContainer as any,
                }}
                data={filteredWorkspaces}
                itemContent={item}
              />
            ) : (
              <Box
                flex={1}
                data-test-id="virtuoso-item-list"
                sx={{ backgroundColor: palette.background.paper, p: 6 }}
              >
                <TableVirtuoso
                  data={filteredWorkspaces}
                  endReached={fetchMoreWorkspace}
                  components={InfiniteScrollTableComponents as any}
                  fixedHeaderContent={WorkspaceTableHeader}
                  itemContent={tableRow}
                  increaseViewportBy={400}
                />
              </Box>
            )
          ) : (
            <Box display="flex" height="90%" alignItems="center" justifyContent="center">
              {workspacesLoading ? (
                <LoadingIcon size={16} />
              ) : workspaces.length === 0 ? (
                <Typography>Your organization has no workspaces.</Typography>
              ) : (
                <Typography>No workspaces found that match your search.</Typography>
              )}
            </Box>
          )}
        </Grid>
        {!recent && (
          <LandingPageOrganizationDetails organizationId={organization?.organizationId} />
        )}
      </Grid>
      {!recent && <LandingPageExpirationWrapper {...organization} />}
    </>
  );
};
