import { InfiniteScrollTableComponents } from '@components/infiniteScroll';
import { Group } from '@components/layout';
import { ProgressBar } from '@components/progress';
import { Typography } from '@components/typography';
import { useGetWorkspaceSettingsQuery, WorkspaceSetting } from '@generated/UseGraphqlHooks';
import { Box, TableCell, TableRow, useTheme } from '@mui/material';
import { size } from 'lodash';
import { memo } from 'react';
import { TableVirtuoso } from 'react-virtuoso';

const TableHeader = () => {
  return (
    <TableRow>
      <TableCell width="25%">
        <Typography variant="caption1">Setting</Typography>
      </TableCell>
      <TableCell width="25%">
        <Typography variant="caption1">Usage</Typography>
      </TableCell>
      <TableCell width="25%">
        <Typography variant="caption1">Limit</Typography>
      </TableCell>
      <TableCell width="25%"></TableCell>
    </TableRow>
  );
};

type OrganizationsSettingsWorkspaceUsageTableProps = {
  selectedWorkspaceId?: string;
};

export const OrganizationsSettingsWorkspaceUsageTable = memo(
  ({ selectedWorkspaceId }: OrganizationsSettingsWorkspaceUsageTableProps) => {
    const { data: workspaceSettingsData, loading } = useGetWorkspaceSettingsQuery({
      variables: { workspaceId: selectedWorkspaceId },
    });
    const workspaceSettings = workspaceSettingsData?.getWorkspaceSettings || [];

    const { palette } = useTheme();

    const noWorkspaceSettings = !loading && size(workspaceSettingsData?.getWorkspaceSettings) === 0;

    const tableRow = (_index, setting: WorkspaceSetting) => (
      <>
        <TableCell>
          <Typography handleOverFlow>{setting.setting}</Typography>
        </TableCell>
        <TableCell>
          <Typography handleOverFlow>
            {String(setting.usage).includes('.') ? Number(setting.usage).toFixed(3) : setting.usage}{' '}
            {setting.units}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography handleOverFlow>
            {setting.limit === '∞' ? '-' : `${setting.limit}  ${setting.units}`}
          </Typography>
        </TableCell>
        <TableCell>
          <ProgressBar percent={(Number(setting.usage) / Number(setting.limit)) * 100} />
        </TableCell>
      </>
    );

    return (
      <Box sx={{ backgroundColor: palette.background.paper, padding: 4, flex: 1 }}>
        {noWorkspaceSettings ? (
          <Group alignItems="center" justifyContent="center" height="100%">
            <Typography>Unable to load workspace usage data</Typography>
          </Group>
        ) : (
          <TableVirtuoso
            data={workspaceSettings}
            totalCount={workspaceSettings.length}
            components={InfiniteScrollTableComponents as any}
            fixedHeaderContent={TableHeader}
            itemContent={tableRow}
          />
        )}
      </Box>
    );
  },
);
