import { PlusIcon } from '@assets/icons';
import { Button } from '@components/button';
import { useModal } from '@components/modal';
import { Typography } from '@components/typography';
import { Stack } from '@mui/material';
import posthog from 'posthog-js';
import { NewGanModelModal, NewGanModelModalHelpContent } from './NewGanModelModal';

type GanModelHeaderProps = {
  name: string;
  organizationId: string;
};

export const GanModelHeader = ({ name, organizationId }: GanModelHeaderProps) => {
  const { showModal: showNewGanModel } = useModal({
    component: NewGanModelModal,
    componentProps: { organizationId },
    modalProps: { title: 'New GAN model', help: NewGanModelModalHelpContent },
  });

  return (
    <Stack spacing="2px">
      <Typography variant="caption2">{name}</Typography>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h2">GAN Models</Typography>
        <Button
          startIcon={PlusIcon}
          onClick={() => {
            posthog.capture('user_create-gan-model_intent', { location: 'organization-landing' });
            showNewGanModel();
          }}
        >
          New GAN model
        </Button>
      </Stack>
    </Stack>
  );
};
