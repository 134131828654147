import { Button } from '@components/button';
import { FormSelect, FormTextInput } from '@components/form';
import { Group } from '@components/layout';
import { MenuItem, Stack } from '@mui/material';

type JobsConfigurationsPreviewExpandedViewFormProps = {
  isValid: boolean;
  isSubmitting: boolean;
};

const JOB_PRIORITIES = [
  { jobPriority: 'Low', num: 1 },
  { jobPriority: 'Medium', num: 2 },
  { jobPriority: 'High', num: 3 },
];

export const JobsConfigurationsPreviewExpandedViewForm = ({
  isValid,
  isSubmitting,
}: JobsConfigurationsPreviewExpandedViewFormProps) => {
  return (
    <Stack mt={3}>
      <FormTextInput label="Dataset name" name="name" />
      <FormTextInput rows={3} multiline label="Description" name="description" />
      <Group gap={6}>
        <FormSelect fullWidth name="priority" label="Priority" defaultValue="Low">
          {JOB_PRIORITIES.map((job) => (
            <MenuItem value={job.jobPriority}>{job.jobPriority}</MenuItem>
          ))}
        </FormSelect>
        <FormTextInput
          label="Runs"
          name="numberOfRuns"
          type="number"
          inputProps={{ min: 1, max: 1000000 }}
        />
      </Group>
      <FormTextInput label="Seed" type="number" name="seed" />
      <Group justifyContent="flex-end">
        <Button
          type="submit"
          disabled={!(isValid && !isSubmitting)}
          variant="primary"
          style={{ width: '100px' }}
        >
          Run
        </Button>
      </Group>
    </Stack>
  );
};
