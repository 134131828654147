import { LoadingIcon } from '@assets/icons';
import { useModal } from '@components/modal';
import { useGetDatasetDetailsGanDatasetsQuery } from '@generated/UseGraphqlHooks';
import {
  DatasetsDetailsGanDatasetsCreateModal,
  DatasetsDetailsGanDatasetsCreateModalHelpContent,
  DatasetsDetailsGanDatasetsList,
  useDatasets,
} from '@subsets/workspaces';
import posthog from 'posthog-js';
import { useEffect } from 'react';
import { DatasetDetailsAccordion } from './DatasetDetailsAccordion';
import { RUNNING_STATUS } from './types';

interface DatasetsDetailsGanDatasetsProps {
  workspaceId: string;
  datasetId: string;
}

export const DatasetsDetailsGanDatasets = ({
  workspaceId,
  datasetId,
}: DatasetsDetailsGanDatasetsProps) => {
  const {
    data: getGANDatasetsData,
    loading: ganDatasetsLoading,
    refetch: refetchGANDatasets,
    startPolling,
    stopPolling,
  } = useGetDatasetDetailsGanDatasetsQuery({ variables: { datasetId, workspaceId } });

  const ganDatasets = getGANDatasetsData?.getGANDatasets || [];

  const { refetchDatasets } = useDatasets();

  useEffect(() => {
    if (ganDatasets.length === 0) {
      return;
    }
    if (ganDatasets.some((ganDataset) => RUNNING_STATUS.includes(ganDataset.status))) {
      startPolling(5000);
    } else {
      stopPolling();
    }
  }, [ganDatasets]);

  const { showModal } = useModal({
    component: DatasetsDetailsGanDatasetsCreateModal,
    componentProps: { datasetId, workspaceId, refetch: refetchGANDatasets, refetchDatasets },
    modalProps: {
      title: 'Create GAN Dataset',
      help: DatasetsDetailsGanDatasetsCreateModalHelpContent,
    },
  });

  return (
    <DatasetDetailsAccordion
      title="GAN Datasets"
      details={
        ganDatasetsLoading ? (
          <LoadingIcon size={8} />
        ) : (
          <DatasetsDetailsGanDatasetsList
            workspaceId={workspaceId}
            ganDatasets={ganDatasets}
            refetch={refetchGANDatasets}
          />
        )
      }
      count={!ganDatasetsLoading ? ganDatasets.length : null}
      onCreate={() => {
        posthog.capture('user_create-gan-dataset_intent', { source: 'dataset' });
        showModal();
      }}
    />
  );
};
