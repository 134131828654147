import { CSSProperties } from 'react';

type ButtonPalette = {
  primary: string;
  secondary: string;
  accent: string;
};

declare module '@mui/material/styles' {
  interface Palette {
    header: {
      primary: string;
      secondary: string;
    };
    shadow: {
      main: CSSProperties['boxShadow'];
      dropdown: CSSProperties['boxShadow'];
      modal: CSSProperties['boxShadow'];
    };
    button: {
      background: ButtonPalette;
      border: ButtonPalette;
      hover: ButtonPalette;
      icon: ButtonPalette;
      text: ButtonPalette;
      outline: string;
    };
    icon: {
      default: string;
      error: string;
      info: string;
      success: string;
      warning: string;
      disabled: string;
    };
    backgroundHover: {
      default: string;
      paper: string;
    };
    input: {
      background: {
        default: string;
        hover: string;
        active: string;
        disabled: string;
      };
      border: {
        default: string;
        hover: string;
        active: string;
        disabled: string;
        error: string;
      };
      text: {
        default: string;
        disabled: string;
        error: string;
        placeholder: string;
      };
      icon: {
        default: string;
        disabled: string;
      };
      label: {
        default: string;
        error: string;
        disabled: string;
      };
    };
    toggle: {
      thumb: string;
      track: {
        unchecked: {
          default: string;
          hover: string;
          border: string;
        };
        checked: {
          default: string;
          hover: string;
          border: string;
        };
      };
    };
    radio: {
      fill: {
        checked: string;
        unchecked: string;
      };
      default: string;
      hover: string;
      focus: string;
      icon: {
        default: string;
        hover: string;
        checked: string;
      };
    };
    checkbox: {
      checked: {
        background: string;
        border: string;
        hover: string;
      };
      unchecked: {
        background: string;
        border: string;
        hover: string;
      };
    };
    menu: {
      background: string;
      item: {
        hover: string;
        selected: string;
      };
    };
    progress: {
      track: string;
      default: string;
      error: string;
      warning: string;
    };
    tab: {
      unselected: {
        default: string;
        hover: string;
      };
      selected: {
        default: string;
        hover: string;
      };
      disabled: string;
      indicator: string;
      divider: string;
    };
    link: {
      default: string;
      hover: string;
    };
    graph: {
      link: {
        default: string;
        inactive: string;
      };
      port: {
        linked: string;
        unlinked: string;
        background: {
          input: string;
          inputHover: string;
          output: string;
        };
      };
      node: {
        background: string;
        border: {
          default: string;
          hover: string;
          active: string;
          error: string;
        };
      };
    };
    table: {
      header: string;
      cell: {
        border: string;
        text: string;
      };
      row: {
        hover: string;
        selected: string;
      };
      modeButton: {
        hover: string;
        border: {
          default: string;
          selected: string;
        };
        icon: {
          default: string;
          selected: string;
        };
      };
    };
    slider: {
      thumb: {
        border: string;
        background: {
          default: string;
          hover: string;
        };
        active: string;
      };
      track: string;
      rail: string;
    };
    billingSelector: {
      default: {
        border: string;
        text: string;
        background: string;
        label: string;
        hover: string;
      };
      selected: {
        border: string;
        text: string;
        background: string;
        label: string;
      };
      credits: {
        background: string;
        border: string;
      };
    };
  }

  interface TypeText {
    light: string;
    paper: string;
    contrast: string;
  }

  interface PaletteOptions {
    header?: {
      primary: string;
      secondary: string;
    };
    shadow?: {
      main: CSSProperties['boxShadow'];
      dropdown: CSSProperties['boxShadow'];
      modal: CSSProperties['boxShadow'];
    };
    button?: {
      background: ButtonPalette;
      border: ButtonPalette;
      hover: ButtonPalette;
      icon: ButtonPalette;
      text: ButtonPalette;
      outline: String;
    };
    icon?: {
      default: string;
      error: string;
      info: string;
      success: string;
      warning: string;
      disabled: string;
    };
    backgroundHover: {
      default: string;
      paper: string;
    };
    input?: {
      background: {
        default: string;
        hover: string;
        active: string;
        disabled: string;
      };
      border: {
        default: string;
        hover: string;
        active: string;
        disabled: string;
        error: string;
      };
      text: {
        default: string;
        disabled: string;
        error: string;
        placeholder: string;
      };
      icon: {
        default: string;
        disabled: string;
      };
      label: {
        default: string;
        error: string;
        disabled: string;
      };
    };
    toggle?: {
      thumb: string;
      track: {
        unchecked: {
          default: string;
          hover: string;
          border: string;
        };
        checked: {
          default: string;
          hover: string;
          border: string;
        };
      };
    };
    radio?: {
      fill: {
        checked: string;
        unchecked: string;
      };
      default: string;
      hover: string;
      focus: string;
      icon: {
        default: string;
        hover: string;
        checked: string;
      };
    };
    checkbox?: {
      checked: {
        background: string;
        border: string;
        hover: string;
      };
      unchecked: {
        background: string;
        border: string;
        hover: string;
      };
    };
    menu?: {
      background: string;
      item: {
        hover: string;
        selected: string;
      };
    };
    progress?: {
      track: string;
      default: string;
      error: string;
      warning: string;
    };
    tab?: {
      unselected: {
        default: string;
        hover: string;
      };
      selected: {
        default: string;
        hover: string;
      };
      disabled: string;
      indicator: string;
      divider: string;
    };
    link?: {
      default: string;
      hover: string;
    };
    graph?: {
      link: {
        default: string;
        inactive: string;
      };
      port?: {
        linked: string;
        unlinked: string;
        background: {
          input: string;
          inputHover: string;
          output: string;
        };
      };
      node: {
        background: string;
        border: {
          default: string;
          hover: string;
          active: string;
          error: string;
        };
      };
    };
    table?: {
      cell: {
        border: string;
        text: string;
      };
      row: {
        hover: string;
        selected: string;
      };
      header: string;
      modeButton: {
        hover: string;
        border: {
          default: string;
          selected: string;
        };
        icon: {
          default: string;
          selected: string;
        };
      };
    };
    slider?: {
      thumb: {
        border: string;
        background: {
          default: string;
          hover: string;
        };
        active: string;
      };
      track: string;
      rail: string;
    };
    billingSelector?: {
      default: {
        border: string;
        text: string;
        background: string;
        label: string;
        hover: string;
      };
      selected: {
        border: string;
        text: string;
        background: string;
        label: string;
      };
      credits: {
        background: string;
        border: string;
      };
    };
  }
}

const sharedPalette = {
  common: {
    black: '#000000',
    white: '#FFFFFF',
  },
  grey: {
    50: '#F8F8F8',
    100: '#EFEFEF',
    200: '#DEDEDE',
    300: '#B2B2B2',
    400: '#989898',
    500: '#7E7E7E',
    600: '#656565',
    700: '#4C4C4C',
    800: '#323232',
    900: '#1B1B1B',
  },
  primary: {
    main: '#91E600',
    dark: '#76B900',
    light: '#E9FACC',
    contrastText: '#1B1B1B',
    100: '#E9FACC',
    500: '#91E600',
    600: '#76B900',
    700: '#3A5C00',
  },
  error: {
    main: '#FF6C52',
    dark: '#CC5642',
    light: '#FFC4BA',
    contrastText: '#ffffff',
    100: '#FFE2DC',
    200: '#FFC4BA',
    500: '#FF6C52',
    600: '#CC5642',
    700: '#994131',
  },
  warning: {
    main: '#F3BE01',
    dark: '#C29801',
    light: '#FDF2CC',
    contrastText: '#1B1B1B',
    100: '#FDF2CC',
    500: '#F3BE01',
    600: '#C29801',
    700: '#927201',
  },
};

export const lightPalette = {
  ...sharedPalette,
  shadow: {
    main: '0px 0px 100px rgba(37,37,37,0.20)',
    dropdown: '0px 0px 20px 0px rgba(0, 0, 0, 0.1)',
    modal: '0px 0px 20px 0px rgba(0, 0, 0, 0.25)',
  },
  text: {
    primary: sharedPalette.grey[900],
    secondary: sharedPalette.common.white,
    light: sharedPalette.grey[700],
    paper: sharedPalette.grey[900],
    contrast: sharedPalette.grey[900],
  },
  icon: {
    default: sharedPalette.grey[500],
    error: sharedPalette.error.main,
    info: '#7F92D4',
    success: sharedPalette.primary.main,
    warning: sharedPalette.warning.main,
    disabled: sharedPalette.grey[300],
  },
  header: {
    primary: sharedPalette.common.black,
    secondary: sharedPalette.grey[800],
  },
  background: {
    default: sharedPalette.grey[100],
    paper: sharedPalette.common.white,
  },
  backgroundHover: {
    default: sharedPalette.grey[50],
    paper: sharedPalette.grey[100],
  },
  input: {
    background: {
      default: sharedPalette.grey[100],
      hover: sharedPalette.grey[50],
      active: sharedPalette.common.white,
      disabled: sharedPalette.grey[100],
    },
    border: {
      default: sharedPalette.grey[500],
      hover: sharedPalette.grey[500],
      active: sharedPalette.grey[500],
      disabled: sharedPalette.grey[200],
      error: sharedPalette.error.dark,
    },
    text: {
      default: sharedPalette.grey[900],
      disabled: sharedPalette.grey[500],
      error: sharedPalette.error.dark,
      placeholder: sharedPalette.grey[500],
    },
    icon: {
      default: sharedPalette.grey[500],
      disabled: sharedPalette.grey[300],
    },
    label: {
      default: sharedPalette.grey[900],
      error: sharedPalette.error.dark,
      disabled: sharedPalette.grey[500],
    },
  },
  button: {
    background: {
      primary: sharedPalette.grey[900],
      secondary: sharedPalette.grey[100],
      accent: sharedPalette.primary.main,
    },
    border: {
      primary: sharedPalette.common.black,
      secondary: sharedPalette.grey[500],
      accent: sharedPalette.primary.dark,
    },
    hover: {
      primary: sharedPalette.grey[700],
      secondary: sharedPalette.grey[50],
      accent: sharedPalette.common.white,
    },
    icon: {
      primary: sharedPalette.grey[200],
      secondary: sharedPalette.grey[500],
      accent: sharedPalette.grey[900],
    },
    text: {
      primary: sharedPalette.common.white,
      secondary: sharedPalette.grey[900],
      accent: sharedPalette.grey[900],
    },
    outline: sharedPalette.grey[500],
  },
  toggle: {
    thumb: sharedPalette.common.white,
    track: {
      unchecked: {
        default: sharedPalette.grey[900],
        hover: sharedPalette.grey[500],
        border: sharedPalette.grey[500],
      },
      checked: {
        default: sharedPalette.primary.dark,
        hover: sharedPalette.primary.main,
        border: sharedPalette.primary[700],
      },
    },
  },
  radio: {
    fill: {
      checked: sharedPalette.primary.main,
      unchecked: sharedPalette.common.white,
    },
    default: sharedPalette.grey[500],
    hover: sharedPalette.grey[900],
    focus: sharedPalette.grey[500],
    icon: {
      default: sharedPalette.grey[500],
      hover: sharedPalette.grey[300],
      checked: sharedPalette.grey[700],
    },
  },
  checkbox: {
    checked: {
      background: sharedPalette.primary.dark,
      border: sharedPalette.primary[700],
      hover: sharedPalette.primary.main,
    },
    unchecked: {
      background: sharedPalette.grey[100],
      border: sharedPalette.grey[500],
      hover: sharedPalette.common.white,
    },
  },
  menu: {
    background: sharedPalette.common.white,
    item: {
      hover: sharedPalette.grey[100],
      selected: sharedPalette.grey[200],
    },
  },
  progress: {
    track: sharedPalette.grey[200],
    default: sharedPalette.grey[700],
    error: sharedPalette.error.main,
    warning: sharedPalette.warning.main,
  },
  tab: {
    unselected: {
      default: sharedPalette.grey[500],
      hover: sharedPalette.grey[700],
    },
    selected: {
      default: sharedPalette.grey[700],
      hover: sharedPalette.grey[900],
    },
    disabled: sharedPalette.grey[200],
    indicator: sharedPalette.grey[500],
    divider: sharedPalette.grey[400],
  },
  link: {
    default: sharedPalette.primary.dark,
    hover: sharedPalette.primary[700],
  },
  graph: {
    link: {
      default: sharedPalette.grey[900],
      inactive: sharedPalette.grey[400],
    },
    port: {
      linked: sharedPalette.grey[900],
      unlinked: sharedPalette.grey[500],
      background: {
        input: sharedPalette.grey[100],
        inputHover: sharedPalette.grey[200],
        output: sharedPalette.grey[200],
      },
    },
    node: {
      background: `${sharedPalette.common.white}BF`,
      border: {
        default: sharedPalette.grey[400],
        hover: sharedPalette.grey[700],
        active: sharedPalette.grey[900],
        error: sharedPalette.error.main,
      },
    },
  },
  table: {
    header: sharedPalette.grey[700],
    cell: {
      border: sharedPalette.grey[200],
      text: sharedPalette.grey[900],
    },
    row: {
      hover: sharedPalette.grey[100],
      selected: sharedPalette.grey[200],
    },
    modeButton: {
      hover: sharedPalette.grey[100],
      border: {
        default: sharedPalette.grey[200],
        selected: sharedPalette.grey[300],
      },
      icon: {
        default: sharedPalette.grey[300],
        selected: sharedPalette.grey[900],
      },
    },
  },
  slider: {
    thumb: {
      border: sharedPalette.grey[900],
      background: {
        default: sharedPalette.common.white,
        hover: sharedPalette.grey[200],
      },
      active: '0 0 0 7px rgba(27, 27, 27, 0.1)',
    },
    rail: sharedPalette.grey[400],
    track: sharedPalette.grey[900],
  },
  billingSelector: {
    default: {
      border: sharedPalette.grey[300],
      text: sharedPalette.grey[900],
      background: 'transparent',
      label: sharedPalette.grey[700],
      hover: sharedPalette.grey[100],
    },
    selected: {
      border: sharedPalette.primary.dark,
      text: sharedPalette.grey[900],
      background: sharedPalette.primary.light,
      label: sharedPalette.primary.dark,
      hover: sharedPalette.common.white,
    },
    credits: {
      background: sharedPalette.grey[200],
      border: sharedPalette.grey[400],
    },
  },
};

export const darkPalette = {
  ...sharedPalette,
  shadow: {
    main: '0px 0px 100px 0px rgba(37,37,37,0.20)',
    dropdown: '0px 0px 20px 0px rgba(0, 0, 0, 0.1)',
    modal: '0px 0px 20px 0px rgba(0, 0, 0, 0.255)',
  },
  text: {
    primary: sharedPalette.grey[300],
    secondary: sharedPalette.grey[900],
    light: sharedPalette.grey[500],
    paper: sharedPalette.grey[200],
    contrast: sharedPalette.common.white,
  },
  icon: {
    default: sharedPalette.grey[500],
    error: sharedPalette.error.main,
    info: '#7F92D4',
    success: sharedPalette.primary.main,
    warning: sharedPalette.warning.main,
    disabled: sharedPalette.grey[700],
  },
  header: {
    primary: sharedPalette.common.black,
    secondary: sharedPalette.grey[800],
  },
  background: {
    default: sharedPalette.grey[900],
    paper: sharedPalette.grey[800],
  },
  backgroundHover: {
    default: sharedPalette.grey[700],
    paper: sharedPalette.grey[800],
  },
  input: {
    background: {
      default: sharedPalette.common.black,
      hover: sharedPalette.grey[900],
      active: sharedPalette.common.black,
      disabled: sharedPalette.grey[800],
    },
    border: {
      default: sharedPalette.grey[700],
      hover: sharedPalette.grey[700],
      active: sharedPalette.grey[700],
      disabled: sharedPalette.grey[900],
      error: sharedPalette.error.main,
    },
    text: {
      default: sharedPalette.grey[200],
      disabled: sharedPalette.grey[600],
      error: sharedPalette.error.main,
      placeholder: sharedPalette.grey[500],
    },
    icon: {
      default: sharedPalette.grey[500],
      disabled: sharedPalette.grey[700],
    },
    label: {
      default: sharedPalette.grey[200],
      error: sharedPalette.error.main,
      disabled: sharedPalette.grey[500],
    },
  },
  button: {
    background: {
      primary: sharedPalette.grey[200],
      secondary: sharedPalette.grey[900],
      accent: sharedPalette.primary.main,
    },
    border: {
      primary: sharedPalette.grey[200],
      secondary: sharedPalette.common.black,
      accent: sharedPalette.primary.dark,
    },
    hover: {
      primary: sharedPalette.grey[400],
      secondary: sharedPalette.grey[700],
      accent: sharedPalette.common.white,
    },
    icon: {
      primary: sharedPalette.grey[500],
      secondary: sharedPalette.grey[200],
      accent: sharedPalette.grey[900],
    },
    text: {
      primary: sharedPalette.grey[900],
      secondary: sharedPalette.common.white,
      accent: sharedPalette.grey[900],
    },
    outline: sharedPalette.grey[500],
  },
  toggle: {
    thumb: sharedPalette.common.black,
    track: {
      unchecked: {
        default: sharedPalette.grey[400],
        hover: sharedPalette.grey[500],
        border: sharedPalette.grey[200],
      },
      checked: {
        default: sharedPalette.primary.main,
        hover: sharedPalette.primary.dark,
        border: sharedPalette.primary[100],
      },
    },
  },
  radio: {
    fill: {
      checked: sharedPalette.primary.main,
      unchecked: sharedPalette.common.black,
    },
    default: sharedPalette.grey[500],
    hover: sharedPalette.grey[200],
    focus: sharedPalette.grey[500],
    icon: {
      default: sharedPalette.grey[500],
      hover: sharedPalette.grey[400],
      checked: sharedPalette.grey[700],
    },
  },
  checkbox: {
    checked: {
      background: sharedPalette.primary.main,
      border: sharedPalette.primary[700],
      hover: sharedPalette.primary.dark,
    },
    unchecked: {
      background: sharedPalette.common.black,
      border: sharedPalette.grey[500],
      hover: sharedPalette.grey[900],
    },
  },
  menu: {
    background: sharedPalette.common.black,
    item: {
      hover: sharedPalette.grey[800],
      selected: sharedPalette.grey[700],
    },
  },
  progress: {
    track: sharedPalette.grey[900],
    default: sharedPalette.grey[200],
    error: sharedPalette.error.main,
    warning: sharedPalette.warning.main,
  },
  tab: {
    unselected: {
      default: sharedPalette.grey[200],
      hover: sharedPalette.primary.dark,
    },
    selected: {
      default: sharedPalette.primary.main,
      hover: sharedPalette.primary.dark,
    },
    disabled: sharedPalette.grey[600],
    indicator: sharedPalette.grey[500],
    divider: sharedPalette.grey[600],
  },
  link: {
    default: sharedPalette.primary.main,
    hover: sharedPalette.primary.dark,
  },
  graph: {
    link: {
      default: sharedPalette.grey[500],
      inactive: sharedPalette.grey[700],
    },
    port: {
      linked: sharedPalette.common.white,
      unlinked: sharedPalette.grey[500],
      background: {
        input: sharedPalette.grey[800],
        inputHover: sharedPalette.grey[700],
        output: sharedPalette.grey[800],
      },
    },
    node: {
      background: `${sharedPalette.grey[900]}BF`,
      border: {
        default: sharedPalette.grey[700],
        hover: sharedPalette.grey[500],
        active: sharedPalette.grey[200],
        error: sharedPalette.error.main,
      },
    },
  },
  table: {
    header: sharedPalette.grey[500],
    cell: {
      border: sharedPalette.grey[700],
      text: sharedPalette.grey[200],
    },
    row: {
      hover: sharedPalette.grey[700],
      selected: sharedPalette.grey[600],
    },
    modeButton: {
      hover: sharedPalette.grey[900],
      border: {
        default: sharedPalette.grey[800],
        selected: sharedPalette.grey[700],
      },
      icon: {
        default: sharedPalette.grey[700],
        selected: sharedPalette.grey[500],
      },
    },
  },
  slider: {
    thumb: {
      border: sharedPalette.grey[200],
      background: {
        default: sharedPalette.grey[900],
        hover: sharedPalette.grey[700],
      },
      active: '0 0 0 7px rgba(255, 255, 255, 0.15)',
    },
    rail: sharedPalette.grey[900],
    track: sharedPalette.grey[200],
  },
  billingSelector: {
    default: {
      border: sharedPalette.grey[700],
      text: sharedPalette.grey[300],
      background: 'transparent',
      label: sharedPalette.grey[400],
      hover: sharedPalette.grey[900],
    },
    selected: {
      border: sharedPalette.primary[700],
      text: sharedPalette.grey[900],
      background: sharedPalette.primary.dark,
      label: sharedPalette.primary.dark,
    },
    credits: {
      background: sharedPalette.grey[900],
      border: sharedPalette.grey[700],
    },
  },
};
