import { delay } from '@helper-functions/delay';
import { useNotifications } from '@notifications/Notifications';
import { useObservableState } from 'observable-hooks';
import { PropsWithChildren, createContext, useContext, useMemo } from 'react';
import { ChannelDetailContext } from './graph/ChannelDetailProvider';
import { GraphDataContext } from './graph/GraphDataProvider';
import { ChannelNode, Node } from './graph/types';

interface GraphsOutdatedNodesProviderType {
  updateAllNodes: (data) => Promise<void>;
}

export const GraphsOutdatedNodesContext = createContext<GraphsOutdatedNodesProviderType>(
  {} as GraphsOutdatedNodesProviderType,
);

export const GraphsOutdatedNodesProvider = ({ children }: PropsWithChildren) => {
  const { nodes, removeNode, updateNode } = useContext(GraphDataContext);
  const { addNotification, action$ } = useNotifications();
  const { data$ } = useContext(ChannelDetailContext);
  const channelNodes: ChannelNode[] = useObservableState(data$);

  const removeUndefinedNode = (node: Node) => {
    if (!node?.name) {
      return;
    }
    removeNode(node?.name);
    addNotification({
      text: `Deleted ${node?.name || ''} node, ${node?.name || ''} does not exist`,
      type: 'error',
      duration: 10000,
    });
  };

  const updateSingleNode = (nodeId: string, last = false) => {
    const node = nodes.find((checkNode) => checkNode.name === nodeId);
    const latestNode = channelNodes.find((channelNode) => channelNode.name === node?.nodeClass);

    if (!latestNode) {
      removeUndefinedNode(node);
      return;
    }
    updateNode(nodeId, latestNode, last);
  };

  const updateAllNodes = async (outdatedNodeIds: string[]) => {
    if (outdatedNodeIds.length === 0) return;

    const key = `${Date.now() * 1000}.${Math.random() * 10000000}`;
    action$.next({
      type: 'add',
      payload: {
        type: 'loading',
        text: 'Processing...',
      },
      key,
    });
    outdatedNodeIds.forEach((id, index) =>
      updateSingleNode(id, index === outdatedNodeIds.length - 1),
    );
    action$.next({
      type: 'update',
      key,
      payload: {
        type: 'success',
        text: 'All Nodes successfully updated.',
      },
    });
    await delay(3000);
    action$.next({ type: 'remove', key });
  };

  const providerValue = useMemo(
    () => ({
      updateAllNodes,
    }),
    [updateAllNodes],
  );

  return (
    <GraphsOutdatedNodesContext.Provider value={providerValue}>
      {children}
    </GraphsOutdatedNodesContext.Provider>
  );
};
