export const getCookies = () => {
  if (typeof window !== `undefined`) {
    return document.cookie
      .split(';')
      .map((cookie) => cookie.split('='))
      .reduce(
        (accumulator, [key, value]) => ({
          ...accumulator,
          [key.trim()]: decodeURIComponent(value),
        }),
        {},
      );
  }
  return undefined;
};
