import { LoadingIcon } from '@assets/icons';
import { ProgressBar } from '@components/progress';
import { Typography } from '@components/typography';
import { useGetWorkspaceSettingsQuery } from '@generated/UseGraphqlHooks';
import { Box, Stack, useTheme } from '@mui/material';

interface WorkspaceRecentUsageProps {
  workspaceId: string;
}

export const WorkspaceRecentUsage = ({ workspaceId }: WorkspaceRecentUsageProps) => {
  const { palette } = useTheme();
  const { data: workspaceSettingsData, loading: workspaceSettingsLoading } =
    useGetWorkspaceSettingsQuery({
      variables: { workspaceId },
    });

  const settings = workspaceSettingsData?.getWorkspaceSettings || [];

  if (workspaceSettingsLoading || settings.length === 0) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
        width="100%"
        sx={{ backgroundColor: palette.background.paper }}
      >
        {!workspaceSettingsLoading && settings.length === 0 ? (
          <Typography>Unable to retrieve workspace limits</Typography>
        ) : (
          <LoadingIcon size={16} />
        )}
      </Box>
    );
  }
  return (
    <Stack
      p={4}
      gap={4}
      sx={{
        height: '100%',
        backgroundColor: palette.background.paper,
        overflowX: 'hidden',
        overflowY: 'auto',
      }}
    >
      {settings.map((setting) => (
        <Stack key={setting.setting}>
          <Typography variant="body2" handleOverFlow>
            {setting.setting || '-'}
          </Typography>
          <ProgressBar
            percent={
              setting?.limit === '∞' ? 100 : (Number(setting?.usage) / Number(setting?.limit)) * 100
            }
            label={setting?.limit === '∞' ? 'Unlimited' : ` ${setting?.usage} / ${setting?.limit}`}
          />
        </Stack>
      ))}
    </Stack>
  );
};
