import { PlayIcon } from '@assets/icons';
import { Box, useTheme } from '@mui/material';
import { TreeView as MUITreeView, TreeViewProps } from '@mui/x-tree-view';

const TreeIcon = ({ expand }: { expand?: boolean }) => {
  const { palette } = useTheme();
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      style={expand ? {} : { transform: 'rotate(90deg)' }}
    >
      <PlayIcon color={palette.grey['300']} size={12} />
    </Box>
  );
};

export const TreeView = ({ ...props }: TreeViewProps<true>) => {
  return (
    <MUITreeView
      {...props}
      defaultCollapseIcon={<TreeIcon />}
      defaultExpandIcon={<TreeIcon expand />}
    />
  );
};
