import { ArrowLeftIcon, HelpCircleIcon } from '@assets/icons';
import { AsyncButton, Button, IconButton } from '@components/button';
import { Form, FormSelect } from '@components/form';
import { Group } from '@components/layout';
import { Modal, useModal } from '@components/modal';
import { Typography } from '@components/typography';
import {
  useCreateWorkspaceMutation,
  useGetOrganizationsLazyQuery,
} from '@generated/UseGraphqlHooks';
import { getCookies } from '@helper-functions/cookies';
import { Box, MenuItem, Stack, useTheme } from '@mui/material';
import { useNotifications } from '@notifications/Notifications';
import { navigate } from 'gatsby';
import Cookies from 'js-cookie';
import posthog from 'posthog-js';
import { useEffect, useState } from 'react';
import * as yup from 'yup';

export const GettingStartedProvider = () => {
  const cookies = getCookies() as { stripe: 'Agreed'; shownWelcome: 'true' };

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!cookies.shownWelcome) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [cookies]);

  const onClose = () => {
    setOpen(false);
    Cookies.set('shownWelcome', 'true', {
      path: '/',
      domain: window.location.hostname,
      expires: new Date('2200:01:01'),
    });
  };

  return (
    <Modal open={open} onClose={onClose} sx={{ py: 12 }}>
      <GettingStartedModal onClose={onClose} />
    </Modal>
  );
};

const validationSchema = yup.object({
  organizationId: yup.string().trim().required('Organization is required'),
});
const OrgSelectionModal = ({ onClose, orgs }) => {
  const { useAsyncNotification } = useNotifications();

  const [createWorkspaceMutation] = useCreateWorkspaceMutation();

  const organizations = orgs?.getOrganizations.filter((org) => !org.expired) || [];

  const createWorkspace = useAsyncNotification(
    'New workspace was created successfully',
    async ({ organizationId }: { organizationId: string }) => {
      const { data: createData } = await createWorkspaceMutation({
        variables: {
          organizationId,
          name: 'Example Workspace',
          code: 'SATRGBV2',
          channelIds: [],
          volumeIds: [],
        },
      });
      if (createData?.createWorkspace) {
        await navigate(`/workspaces/${createData?.createWorkspace}`);
      }
      onClose();
    },
  );

  return (
    <Form
      initialValues={{ organizationId: '' }}
      validateOnBlur={false}
      validationSchema={validationSchema}
      onSubmit={createWorkspace}
    >
      {({ handleSubmit, isSubmitting }) => (
        <Stack>
          <FormSelect name="organizationId" label="Organization">
            {organizations.map((org) => (
              <MenuItem key={org.organizationId} value={org.organizationId}>
                {org.name}
              </MenuItem>
            ))}
          </FormSelect>
          <Stack gap={4}>
            <AsyncButton
              fullWidth
              loading={isSubmitting}
              disabled={isSubmitting}
              onClick={handleSubmit}
              data-cy="New-Workspace-Modal-Create-Button"
            >
              Create
            </AsyncButton>
            <Button
              fullWidth
              variant="secondary"
              data-cy="New-Workspace-Modal-Cancel-Button"
              onClick={onClose}
            >
              Cancel
            </Button>
          </Stack>
        </Stack>
      )}
    </Form>
  );
};

const GettingStartedAction = ({
  imageUrl,
  title,
  description,
  action,
  actionLabel,
}: {
  imageUrl: string;
  title: string;
  description: string;
  action: () => void;
  actionLabel: string;
}) => {
  const { palette } = useTheme();
  return (
    <Stack
      width="292px"
      p={4}
      gap={4}
      alignItems="center"
      borderRadius={1}
      sx={{
        border: `1px solid transparent`,
        cursor: 'pointer',
        '&:hover': {
          border: `1px solid ${palette.grey[700]}`,
          backgroundColor: palette.mode === 'dark' ? palette.grey[700] : palette.grey[50],
        },
      }}
      onClick={action}
    >
      <img
        src={imageUrl}
        alt={title}
        style={{ width: '260px', borderRadius: '8px', border: `1px solid ${palette.grey[900]}` }}
      />
      <Typography variant="subtitle1" maxWidth="150px" textAlign="center">
        {title}
      </Typography>
      <Typography variant="body2" textAlign="center" color={palette.text.primary}>
        {description}
      </Typography>
      <Box flex={1} />
      <Button variant="accent" sx={{ justifySelf: 'flex-end' }}>
        {actionLabel}
      </Button>
    </Stack>
  );
};

export const GettingStartedModal = ({ onClose }: { onClose: VoidFunction }) => {
  const { palette } = useTheme();
  const { useAsyncNotification } = useNotifications();
  const [getOrganizations] = useGetOrganizationsLazyQuery();
  const [createWorkspaceMutation] = useCreateWorkspaceMutation();
  const [state, setState] = useState<'default' | 'video'>('default');
  const { showModal: showOrgSelectionModal } = useModal({
    component: OrgSelectionModal,
  });

  return (
    <>
      <Stack gap={10} color={palette.text.paper}>
        {state === 'video' ? (
          <iframe
            style={{ width: '80vw', height: '45vw' }}
            src="https://www.youtube.com/embed/f7_1YnUvJwQ?si=-dS61QgiSiF-NfyU"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          />
        ) : (
          <>
            <Stack alignItems="center" gap={1}>
              <Typography variant="h1">Getting started with Rendered.ai</Typography>
              <Typography
                variant="body2"
                color={palette.text.primary}
                sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
              >
                Return to this window any time from the Help{' '}
                <HelpCircleIcon size={16} color={palette.text.primary} /> menu
              </Typography>
            </Stack>
            <Group gap={12} px={9}>
              <GettingStartedAction
                imageUrl="/welcome-video-thumbnail.png"
                title="Tutorial video"
                description="Get a quick, guided tour of how to use the Rendered.ai platform."
                action={() => {
                  posthog.capture('user_clicked_welcome-video');
                  setState('video');
                }}
                actionLabel="Watch now"
              />
              <GettingStartedAction
                imageUrl="/welcome-example-thumbnail.png"
                title="Try a sample workspace"
                description="Test drive configurable synthetic data generation in the Satellite RGB Demo workspace."
                action={useAsyncNotification('New workspace was created successfully', async () => {
                  posthog.capture('user_clicked_welcome-create-workspace');
                  const { data: orgData } = await getOrganizations();
                  if (!orgData?.getOrganizations || orgData?.getOrganizations?.length === 0) {
                    return Promise.reject(new Error('Unable to create workspace.'));
                  } else if (orgData?.getOrganizations?.length === 1) {
                    const organizationId =
                      orgData?.getOrganizations?.[orgData.getOrganizations.length - 1]
                        ?.organizationId;
                    if (!organizationId) {
                      return Promise.reject(new Error('Unable to create workspace.'));
                    }
                    const { data: createData } = await createWorkspaceMutation({
                      variables: {
                        organizationId,
                        name: 'Example Workspace',
                        code: 'SATRGBV2',
                        channelIds: [],
                        volumeIds: [],
                      },
                    });
                    if (createData?.createWorkspace) {
                      await navigate(`/workspaces/${createData?.createWorkspace}`);
                    }
                    onClose();
                  } else {
                    onClose();
                    showOrgSelectionModal({ componentProps: { orgs: orgData } });
                  }
                })}
                actionLabel="Access this workspace"
              />
              <GettingStartedAction
                imageUrl="/welcome-sample-gallery-thumbnail.png"
                title="Browse our sample gallery"
                description="Explore other sample workspaces to generate synthetic data for different types of sensors and scenarios."
                action={() => {
                  posthog.capture('user_clicked_welcome-sample-gallery');
                  onClose();
                  navigate('/sample-gallery');
                }}
                actionLabel="Start browsing"
              />
            </Group>
          </>
        )}
      </Stack>
      {state === 'video' && (
        <IconButton
          style={{ position: 'absolute', top: 8, left: 8 }}
          onClick={() => setState('default')}
          size={18}
          Icon={ArrowLeftIcon}
          aria-label="Close"
        />
      )}
    </>
  );
};
