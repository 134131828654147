import {
  AlertTriangleIcon,
  ChevronDownIcon,
  DownloadIcon,
  SlashIcon,
  TrashIcon,
} from '@assets/icons';
import { IconButton } from '@components/button';
import { Group } from '@components/layout';
import { useConfirmation } from '@components/modal';
import { ProgressBar } from '@components/progress';
import { Typography } from '@components/typography';
import {
  DatasetJob,
  useDeleteDatasetMutation,
  useDownloadDatasetMutation,
  useHideDatasetMutation,
} from '@generated/UseGraphqlHooks';
import { saveFile } from '@helper-functions/save-file';
import { Grid, Stack, useTheme } from '@mui/material';
import { useNotifications } from '@notifications/Notifications';
import { capitalize } from 'lodash';
import moment from 'moment';
import { useMemo } from 'react';

const getTimeDifferenceInSeconds = (startTime: Date, endTime: Date) =>
  moment(endTime).diff(moment(startTime), 'seconds');

type JobsManagerJobPreviewInfoProps = DatasetJob & {
  expand: () => void;
  expanded: boolean;
  refetch: VoidFunction;
};

export const JobsManagerJobPreviewInfo = ({
  channel,
  createdAt,
  datasetId,
  estimatedEndAt,
  expand,
  expanded,
  name,
  runs,
  runsCancelled,
  runsFailed,
  runsSuccess,
  runsTimeout,
  status,
  workspaceId,
  refetch,
}: JobsManagerJobPreviewInfoProps) => {
  const { palette } = useTheme();
  const { addNotification, useAsyncNotification, removeNotification } = useNotifications();
  const { showConfirmation } = useConfirmation();
  const [downloadDatasetMutation] = useDownloadDatasetMutation();
  const [deleteDataset] = useDeleteDatasetMutation();
  const [hideDataset] = useHideDatasetMutation();

  const handleFileDownloadClick = async () => {
    const key = `${Date.now() * 1000}.${Math.random() * 10000}`;
    try {
      const response = await downloadDatasetMutation({
        variables: {
          datasetId,
          workspaceId,
        },
      });
      const file = await fetch(response?.data?.downloadDataset);
      addNotification({ text: 'Loading...', type: 'loading', key, duration: 100000 });
      const blob = await file.blob();
      const url = window.URL.createObjectURL(blob);
      saveFile({
        url,
        name: `${name || 'Untitled'}.zip`,
      });
      removeNotification(key);
      addNotification({
        text: 'Starting download...',
        type: 'success',
        duration: 6000,
      });
    } catch (e) {
      removeNotification(key);
    }
  };

  const handleDatasetDeleteClick = async () => {
    await showConfirmation({
      message: 'Are you sure to clear the job?',
      affirmText: 'Yes, clear',
      onAffirm: useAsyncNotification('Successfully cleared job.', async () => {
        if (['success', 'failed', 'timeout', 'cancelled', 'partially_success'].includes(status)) {
          await hideDataset({ variables: { workspaceId, datasetId, showJob: false } });
        } else {
          await deleteDataset({
            variables: {
              datasetId,
              workspaceId,
            },
          });
        }
        refetch();
      }),
    });
  };

  const handleJobStopClick = async () => {
    await showConfirmation({
      message: 'Are you sure to stop the job?',
      affirmText: 'Yes, stop',
      onAffirm: useAsyncNotification('Successfully stopped the job.', async () => {
        await deleteDataset({
          variables: {
            datasetId,
            workspaceId,
          },
        });
        refetch();
      }),
    });
  };

  const percent = useMemo(() => {
    if (
      status === 'success' ||
      status === 'partially_success' ||
      status === 'failed' ||
      status === 'cancelled' ||
      status === 'timeout'
    ) {
      return 100;
    }
    if (!estimatedEndAt) {
      return 0;
    }
    const elapsedTime = getTimeDifferenceInSeconds(new Date(createdAt), new Date());
    const totalTime = getTimeDifferenceInSeconds(new Date(createdAt), new Date(estimatedEndAt));
    return Math.min(99, Math.round((elapsedTime / totalTime) * 100));
  }, [status, createdAt, estimatedEndAt]);

  const jobsDoneCount = runsSuccess + runsCancelled + runsFailed + runsTimeout;

  return (
    <Grid container>
      <Grid item md={3} xs={6}>
        <Stack>
          <Typography handleOverFlow variant="body2" color={palette.text.contrast}>
            {name || '-'}
          </Typography>
          <Typography variant="body2" style={{ color: palette.text.light }}>
            {channel || 'unknown'}
          </Typography>
        </Stack>
      </Grid>
      <Grid item md={3} xs={6} sx={{ paddingLeft: '10px' }}>
        <ProgressBar
          // statusLabel={capitalize(lowerCase(status)) || '-'}
          // statusInfo={status === 'running' ? `est ${100 - percent}% remaining` : ''}
          percent={status === 'queued' ? 0 : percent || 0}
          error={status === 'failed'}
          warning={status === 'timeout'}
          sx={{ marginTop: '3px', marginBottom: '2px' }}
        />
      </Grid>
      <Grid
        item
        xs={6}
        md={3}
        sx={{ paddingLeft: { xs: 0, md: '30px' }, alignSelf: { xs: 'center', md: 'flex-start' } }}
      >
        <Stack>
          <Typography variant="body2">{capitalize(status) || '-'}</Typography>
          <Typography variant="body2">
            {jobsDoneCount || '0'} of {runs || '0'} runs
          </Typography>
        </Stack>
      </Grid>
      <Grid item xs={6} md={3} sx={{ paddingLeft: '10px', minWidth: '128px' }}>
        <Group
          sx={{
            paddingLeft: '10px',
            justifyContent: 'flex-end',
            alignItems: 'center',
            height: '100%',
            alignSelf: 'center',
            gap: 4,
          }}
        >
          {percent === 100 && status === 'failed' && (
            <IconButton
              Icon={AlertTriangleIcon}
              variant="error"
              size={20}
              onClick={() => {
                if (typeof window !== 'undefined') {
                  window.open(`/workspaces/${workspaceId}/jobs/${datasetId}`, '_blank');
                }
              }}
            />
          )}
          {percent === 100 &&
            (status === 'success' ||
              status === 'partially_success' ||
              status === 'complete' ||
              status === 'partially_complete') && (
              <IconButton
                onClick={handleFileDownloadClick}
                tooltip="Download job"
                Icon={DownloadIcon}
                size={20}
              />
            )}
          {status === 'running' && jobsDoneCount >= 1 ? (
            <IconButton
              onClick={handleJobStopClick}
              data-cy="Jobs-Manager-Job-Preview-Info-Stop-Icon"
              tooltip="Stop job"
              Icon={SlashIcon}
              size={20}
            />
          ) : (
            <IconButton
              onClick={handleDatasetDeleteClick}
              tooltip="Clear job"
              Icon={TrashIcon}
              size={20}
              data-cy="Jobs-Manager-Job-Preview-Info-Delete-Icon"
            />
          )}
          <IconButton
            onClick={expand}
            Icon={ChevronDownIcon}
            size={20}
            data-cy={
              !expanded
                ? 'Jobs-Manager-Job-Preview-Info-Expand-View-Down-Icon'
                : 'Jobs-Manager-Job-Preview-Info-Expand-View-Up-Icon'
            }
            style={{
              transition: 'all 0.2s',
              transform: !expanded ? 'rotate(0deg)' : 'rotate(180deg)',
            }}
          />
        </Group>
      </Grid>
    </Grid>
  );
};
