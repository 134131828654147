import { DeckardIconProps } from '@assets/types';
import { useTheme } from '@mui/material';
import { forwardRef } from 'react';

export const HourglassIcon = forwardRef<SVGSVGElement, DeckardIconProps>((props, ref) => {
  const { palette } = useTheme();
  const { color, size } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
      width={size || 24}
      height={size || 24}
      viewBox="0 0 16 24"
      fill="none"
      {...props}
    >
      <path
        d="M13.4844 6.83906V4.73438H14.6188C14.9281 4.73438 15.1719 4.48594 15.1719 4.17656V1.18125C15.1719 0.871875 14.9281 0.609375 14.6188 0.609375H1.38125C1.07188 0.609375 0.828125 0.871875 0.828125 1.18125V4.17656C0.828125 4.48594 1.07188 4.73438 1.38125 4.73438H2.51562V6.83906C2.51562 8.2125 3.19063 9.48281 4.33438 10.2375L6.9875 12L4.33438 13.7625C3.19063 14.5219 2.51562 15.7922 2.51562 17.1609V19.2656H1.38125C1.07188 19.2656 0.828125 19.5141 0.828125 19.8234V22.8187C0.828125 23.1281 1.07188 23.3906 1.38125 23.3906H14.6188C14.9281 23.3906 15.1719 23.1281 15.1719 22.8187V19.8234C15.1719 19.5141 14.9281 19.2656 14.6188 19.2656H13.4844V17.1609C13.4844 15.7875 12.8094 14.5172 11.6656 13.7625L9.0125 12L11.6656 10.2375C12.8094 9.47812 13.4844 8.20781 13.4844 6.83906ZM1.95312 1.73438H14.0469V3.60938H1.95312V1.73438ZM14.0469 22.2656H1.95312V20.3906H14.0469V22.2656ZM11.0469 14.7C11.8766 15.2484 12.3594 16.1719 12.3594 17.1609V19.2656H3.64062V17.1609C3.64062 16.1672 4.12812 15.2484 4.95312 14.7L8 12.675L11.0469 14.7ZM11.0469 9.3L7.99531 11.325L4.95312 9.3C4.12344 8.75156 3.64062 7.82812 3.64062 6.83906V4.73438H12.3594V6.83906C12.3594 7.82812 11.8719 8.75156 11.0469 9.3Z"
        fill={color || palette.icon.default}
        stroke={color || palette.icon.default}
        strokeWidth="0.5"
        className="stroke-override fill-override"
      />
    </svg>
  );
});
