import { Typography } from '@components/typography';
import { Grid, Stack } from '@mui/material';
import { CreditsDetails } from './CreditsDetails';
import { CreditsTopics } from './CreditsTopics';

export const EnhancedSupportCreditsTable = () => {
  return (
    <Stack spacing="33px" position="relative">
      <Typography variant="h1">Enhanced Support Credits</Typography>
      <Grid container style={{ position: 'relative' }} wrap="nowrap">
        <Grid item xs={12}>
          <CreditsTopics />
        </Grid>
      </Grid>
      <CreditsDetails />
    </Stack>
  );
};
