import { Button } from '@components/button';
import { ExternalLink } from '@components/link';
import { Typography } from '@components/typography';
import { Box, Stack, useTheme } from '@mui/material';
import { map } from 'lodash';

const CONTENT = [
  { credits: 1, monthly: 'No' },
  { credits: 1, monthly: 'No' },
  { credits: 2, monthly: 'Yes' },
  { credits: 2, monthly: 'Yes' },
  { credits: 2, monthly: 'Yes' },
  { credits: 1, monthly: 'Yes' },
  { credits: 1, monthly: 'No' },
  { credits: 4, monthly: 'No' },
  { credits: 4, monthly: 'No' },
  { credits: 1, monthly: 'No' },
  { credits: 10, monthly: 'No' },
];

export const CreditsDetails = () => {
  const { palette } = useTheme();
  return (
    <Box
      width="40%"
      position="absolute"
      right="20%"
      top="3px"
      zIndex={0}
      boxShadow="0px 4px 12px rgba(37, 37, 49, 0.1)"
    >
      <Box
        borderRadius="3px 3px 0px 0px"
        bgcolor={palette.billingSelector.credits.background}
        border={`2px solid ${palette.billingSelector.credits.border}`}
        borderBottom="none"
      >
        <Stack direction="row" py="16.5px" px="80px" justifyContent="space-between">
          <Typography variant="h3">Credits</Typography>
          <Typography variant="h3">Recurring monthly</Typography>
        </Stack>
      </Box>
      {map(CONTENT, (line, index) => (
        <Box
          key={index}
          py="8px"
          px="100px"
          pr="150px"
          borderTop={index === 0 && '1px solid transparent'}
          borderBottom="1px solid transparent"
          borderLeft={`2px solid ${palette.billingSelector.credits.border}`}
          borderRight={`2px solid ${palette.billingSelector.credits.border}`}
        >
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="body2">{line.credits}</Typography>
            <Typography variant="body2">{line.monthly}</Typography>
          </Stack>
        </Box>
      ))}
      <Box
        borderRadius="0px 0px 3px 3px"
        bgcolor={palette.billingSelector.credits.background}
        border={`2px solid ${palette.billingSelector.credits.border}`}
        borderTop="none"
      >
        <Stack direction="row" py="16.5px" px="80px" justifyContent="center">
          <ExternalLink href="mailto:sales@rendered.ai" unstyled>
            <Button variant="primary">Purchase credits</Button>
          </ExternalLink>
        </Stack>
      </Box>
    </Box>
  );
};
