import {
  AtomicBurstIcon,
  DatabaseIcon,
  HardDriveIcon,
  HomeIcon,
  PenToolIcon,
  VolumeIcon,
} from '@assets/icons';
import { Stack } from '@mui/material';
import { HeaderItem } from './HeaderItem';

export const HeaderFlow = ({ workspaceId }: { workspaceId: string }) => {
  return (
    <Stack
      position="absolute"
      width="100%"
      height="100%"
      justifyContent="center"
      direction="row"
      display="flex"
      alignItems="center"
    >
      <HeaderItem
        icon={HomeIcon}
        label="Workspace"
        path={`/workspaces/${workspaceId}`}
        width="4.375rem"
      />
      <HeaderItem
        icon={VolumeIcon}
        label="Assets"
        path={`/workspaces/${workspaceId}/assets`}
        partiallyActive
        width="4rem"
      />
      <HeaderItem
        icon={PenToolIcon}
        label="Graphs"
        path={`/workspaces/${workspaceId}/graphs`}
        partiallyActive
        width="3.5rem"
      />
      <HeaderItem
        icon={HardDriveIcon}
        label="Jobs"
        path={`/workspaces/${workspaceId}/jobs`}
        partiallyActive
        width="3.5rem"
      />
      <HeaderItem
        icon={DatabaseIcon}
        label="Datasets"
        path={`/workspaces/${workspaceId}/datasets`}
        partiallyActive
        width="4rem"
      />
      <HeaderItem
        icon={AtomicBurstIcon}
        label="Experiments"
        path={`/workspaces/${workspaceId}/experiments`}
        partiallyActive
        width="4.375rem"
      />
    </Stack>
  );
};
