import { Checkbox } from '@components/form';
import { Typography } from '@components/typography';
import { TableCell, TableRow, useTheme } from '@mui/material';
import { useDatasets } from './DatasetsProvider';

export const DatasetsTableHeader = () => {
  const { palette } = useTheme();
  const { datasets, selectedDatasets, setSelectedDatasets } = useDatasets();
  const isAllDatasetsSelected = datasets?.length > 0 && datasets.length === selectedDatasets.length;

  const selectAllDatasets = () =>
    isAllDatasetsSelected
      ? setSelectedDatasets([])
      : setSelectedDatasets(datasets.map(({ datasetId }) => datasetId));
  return (
    <TableRow sx={{ backgroundColor: palette.common.white }}>
      <TableCell width="30px">
        <Checkbox checked={isAllDatasetsSelected} onClick={selectAllDatasets} />
      </TableCell>
      <TableCell width="30px">
        <Typography variant="caption1">Id</Typography>
      </TableCell>
      <TableCell width="250px">
        <Typography variant="caption1">Name</Typography>
      </TableCell>
      <TableCell width="250px">
        <Typography variant="caption1">Channel</Typography>
      </TableCell>
      <TableCell width="30px">
        <Typography variant="caption1">Runs</Typography>
      </TableCell>
      <TableCell width="250px">
        <Typography variant="caption1">Created by</Typography>
      </TableCell>
      <TableCell width="250px">
        <Typography variant="caption1">Created at</Typography>
      </TableCell>
    </TableRow>
  );
};
