import { EditIcon, LoadingIcon } from '@assets/icons';
import { Button, IconButton } from '@components/button';
import { InfiniteScrollTableComponents, useGraphqlPagination } from '@components/infiniteScroll';
import { Group } from '@components/layout';
import { useModal } from '@components/modal';
import { Typography } from '@components/typography';
import {
  GetMembersQuery,
  Member,
  useGetCurrentUserQuery,
  useGetMembersQuery,
} from '@generated/UseGraphqlHooks';
import { Box, TableCell, TableRow, useTheme } from '@mui/material';
import { upperFirst } from 'lodash';
import { TableVirtuoso } from 'react-virtuoso';
import { OrganizationsMemberEditMemberRoleModal } from './OrganizationsMembersEditMemberRoleModal';
import { OrganizationsMembersRemoveMemberModal } from './OrganizationsMembersRemoveMemberModal';

const TableHeader = () => {
  return (
    <TableRow>
      <TableCell width="30%">
        <Typography variant="caption1">Name</Typography>
      </TableCell>
      <TableCell width="30%">
        <Typography variant="caption1">Email</Typography>
      </TableCell>
      <TableCell width="30%">
        <Typography variant="caption1">Role</Typography>
      </TableCell>
      <TableCell width="10%"></TableCell>
    </TableRow>
  );
};

type OrganizationsMembersTableProps = {
  organizationId: string;
  organizationRole: string;
};

export const OrganizationsMembersTable = ({
  organizationId,
  organizationRole,
}: OrganizationsMembersTableProps) => {
  const { palette } = useTheme();
  const { data: organizationMembers, loading: organizationMembersLoading } = useGraphqlPagination<
    GetMembersQuery,
    Member
  >(useGetMembersQuery({ variables: { organizationId } }), 'getMembers', 'userId');

  const { data: currentUser } = useGetCurrentUserQuery();
  const { userId } = currentUser?.getCurrentUser || { userId: '' };

  const { showModal: showEditModal } = useModal({
    component: OrganizationsMemberEditMemberRoleModal,
    componentProps: { organizationId },
    modalProps: { title: 'Edit member role' },
  });

  const { showModal: showRemoveModal } = useModal({
    component: OrganizationsMembersRemoveMemberModal,
    componentProps: { organizationId },
  });

  const tableRow = (_index, member: Member) => {
    const isUserEditable =
      organizationRole === 'admin' && (member?.role === 'member' || member?.userId === userId);

    const isUserRemovable =
      member?.userId === userId || (organizationRole === 'admin' && member?.role === 'member');

    return (
      <>
        <TableCell>
          <Typography handleOverFlow>{member?.name || '-'}</Typography>
        </TableCell>
        <TableCell>
          <Typography handleOverFlow>{member?.email || '-'}</Typography>
        </TableCell>
        <TableCell>
          <Group alignItems="center" gap={2}>
            <Typography handleOverFlow fitContent>
              {upperFirst(member?.role)}
            </Typography>
            {isUserEditable && (
              <IconButton
                Icon={EditIcon}
                onClick={() => showEditModal({ componentProps: member })}
              />
            )}
          </Group>
        </TableCell>
        <TableCell sx={{ textAlign: 'right' }}>
          {isUserRemovable && (
            <Button
              variant="secondary"
              size="small"
              onClick={() => showRemoveModal({ componentProps: member })}
            >
              Remove
            </Button>
          )}
        </TableCell>
      </>
    );
  };

  return (
    <Box sx={{ backgroundColor: palette.background.paper, padding: 4, flex: 1 }}>
      {organizationMembersLoading ? (
        <Group justifyContent="center" alignItems="center" sx={{ height: '100%' }}>
          <LoadingIcon size={16} />
        </Group>
      ) : (
        <TableVirtuoso
          data={organizationMembers}
          totalCount={organizationMembers.length}
          components={InfiniteScrollTableComponents as any}
          fixedHeaderContent={TableHeader}
          itemContent={tableRow}
        />
      )}
    </Box>
  );
};
