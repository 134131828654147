import { CheckIcon } from '@assets/icons';
import { Group } from '@components/layout';
import { Typography } from '@components/typography';
import { Umap } from '@generated/UseGraphqlHooks';
import { Stack, useTheme } from '@mui/material';

type DatasetRowProps = {
  datasetName: string;
  isFitDataset: boolean;
  sampleSize: number;
};

const DatasetRow = ({ datasetName, sampleSize, isFitDataset }: DatasetRowProps) => {
  const { palette } = useTheme();

  return (
    <Group alignItems="center" ml={3} gap={3}>
      <Typography variant="body2" maxWidth="450px" color={palette.text.paper} handleOverFlow>
        {datasetName}
      </Typography>
      <Typography variant="body2" color={palette.text.paper}>
        {sampleSize} images
      </Typography>
      {isFitDataset && (
        <Group alignItems="center" gap={1}>
          <CheckIcon color={palette.text.paper} size={20} />
          <Typography variant="caption2" color={palette.text.paper}>
            Fit
          </Typography>
        </Group>
      )}
    </Group>
  );
};

type UmapLoadingDatasetsRowProps = {
  datasets: Umap['datasets'];
  samples: number[];
  fitDatasetId: string;
};

export const UmapLoadingDatasetsRow = ({
  datasets,
  samples,
  fitDatasetId,
}: UmapLoadingDatasetsRowProps) => {
  const isFitDataset = (datasetId: string) => {
    return datasetId === fitDatasetId;
  };

  return (
    <Stack gap={2} mt={6}>
      <Typography variant="subtitle1">Comparing:</Typography>
      {datasets?.map((dataset, index) => (
        <DatasetRow
          key={dataset.datasetId}
          datasetName={dataset.dataset ?? ''}
          isFitDataset={isFitDataset(dataset.datasetId)}
          sampleSize={samples[index]}
        />
      ))}
    </Stack>
  );
};
