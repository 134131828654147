import { DeckardIconProps } from '@assets/types';
import { Box, keyframes } from '@mui/material';
import { forwardRef } from 'react';

const pulse = keyframes`0% {
    opacity: 0
  }
  100% {
    opacity: 1
  }`;

export const RenderedAiIcon = forwardRef<SVGSVGElement, DeckardIconProps & { animate?: boolean }>(
  ({ animate = false, color, size, ...props }, ref) => (
    <Box
      sx={{
        animation: animate ? `${pulse} 1s infinite ease-in-out alternate` : 'none',
        height: size || '82px',
        width: size || '82px',
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        ref={ref}
        width={size || 82}
        height={size || 82}
        viewBox="0 0 82 82"
        fill="none"
        {...props}
      >
        <path
          d="M76.393 50.8708L71.6222 55.7841C71.3008 56.1127 70.8663 56.2972 70.4134 56.2972C69.9605 56.2972 69.526 56.1127 69.2047 55.7841L64.4173 50.8537C64.3116 50.7415 64.2214 50.6149 64.1492 50.4775C64.0769 50.6155 63.9858 50.7422 63.8787 50.8537L59.0937 55.7841C58.7723 56.1127 58.3378 56.2972 57.8849 56.2972C57.432 56.2972 56.9975 56.1127 56.6762 55.7841L51.8888 50.8537C51.7979 50.756 51.7182 50.6478 51.6515 50.5312C51.5855 50.6483 51.5058 50.7566 51.4143 50.8537L46.6174 55.7841C46.296 56.1127 45.8615 56.2972 45.4086 56.2972C44.9557 56.2972 44.5212 56.1127 44.1999 55.7841L39.4125 50.8537C39.0281 50.4579 37.8586 49.1435 37.4458 48.7282L34.0889 45.2784C33.5835 44.758 32.191 43.4216 31.7165 42.9427L26.9361 38.005C26.6171 37.6745 26.4381 37.2275 26.4381 36.7614C26.4381 36.2954 26.6171 35.8484 26.9361 35.5179L31.7236 30.5875C31.8368 30.4747 31.9645 30.3785 32.1032 30.3017C31.964 30.2265 31.8362 30.1311 31.7236 30.0183L26.9361 25.0879C26.6171 24.7574 26.4381 24.3104 26.4381 23.8443C26.4381 23.3783 26.6171 22.9313 26.9361 22.6008L31.7236 17.6704C31.8368 17.5576 31.9645 17.4614 32.1032 17.3846C31.964 17.3094 31.8362 17.214 31.7236 17.1012L26.9361 12.1708C26.6171 11.8403 26.4381 11.3933 26.4381 10.9272C26.4381 10.4612 26.6171 10.0142 26.9361 9.68367L31.7236 4.75333C31.8051 4.669 31.895 4.59367 31.9917 4.52856L29.5149 1.97787C23.2299 4.20351 17.5958 8.03464 13.1397 13.1128C8.68366 18.191 5.55122 24.3503 4.03547 31.0145C2.51972 37.6787 2.67021 44.6299 4.47285 51.2179C6.27549 57.8059 9.67137 63.8153 14.3428 68.6839C19.0142 73.5525 24.8085 77.1213 31.1836 79.0562C37.5586 80.9912 44.3061 81.2291 50.7948 79.7478C57.2834 78.2665 63.3012 75.1143 68.2851 70.5862C73.2689 66.0581 77.056 60.3019 79.292 53.8564L76.393 50.8708Z"
          fill={color || '#91E600'}
        />
        <path
          d="M38.1368 25.59L34.6157 29.2162C33.9486 29.9032 33.9486 31.017 34.6157 31.704L38.1368 35.3302C38.8038 36.0172 39.8853 36.0172 40.5524 35.3302L44.0735 31.704C44.7406 31.017 44.7406 29.9032 44.0735 29.2162L40.5524 25.59C39.8853 24.9031 38.8038 24.9031 38.1368 25.59Z"
          fill={color || '#76B900'}
        />
        <path
          d="M38.1368 11.9819L34.6157 15.6081C33.9486 16.2951 33.9486 17.4089 34.6157 18.0958L38.1368 21.722C38.8038 22.409 39.8853 22.409 40.5524 21.722L44.0735 18.0958C44.7406 17.4089 44.7406 16.2951 44.0735 15.6081L40.5524 11.9819C39.8853 11.2949 38.8038 11.2949 38.1368 11.9819Z"
          fill={color || '#91E600'}
        />
        <path
          d="M50.4577 39.2996L46.9366 42.9258C46.2695 43.6128 46.2695 44.7266 46.9366 45.4135L50.4577 49.0397C51.1247 49.7267 52.2063 49.7267 52.8733 49.0397L56.3944 45.4135C57.0615 44.7266 57.0615 43.6128 56.3944 42.9258L52.8733 39.2996C52.2063 38.6126 51.1247 38.6126 50.4577 39.2996Z"
          fill={color || '#76B900'}
        />
        <path
          d="M50.4568 25.59L46.9357 29.2162C46.2687 29.9032 46.2687 31.017 46.9357 31.704L50.4568 35.3302C51.1239 36.0172 52.2054 36.0172 52.8725 35.3302L56.3936 31.704C57.0606 31.017 57.0606 29.9032 56.3936 29.2162L52.8725 25.59C52.2054 24.9031 51.1239 24.9031 50.4568 25.59Z"
          fill={color || '#91E600'}
        />
        <path
          d="M62.9866 37.299L59.4655 40.9252C58.7984 41.6122 58.7984 42.726 59.4655 43.413L62.9866 47.0392C63.6537 47.7261 64.7352 47.7261 65.4022 47.0392L68.9234 43.413C69.5904 42.726 69.5904 41.6122 68.9234 40.9252L65.4023 37.299C64.7352 36.6121 63.6537 36.6121 62.9866 37.299Z"
          fill={color || '#91E600'}
        />
        <path
          d="M62.9846 17.3167L59.4634 20.9429C58.7964 21.6299 58.7964 22.7437 59.4634 23.4307L62.9846 27.0569C63.6516 27.7438 64.7331 27.7438 65.4002 27.0569L68.9213 23.4307C69.5884 22.7437 69.5884 21.6299 68.9213 20.9429L65.4002 17.3167C64.7331 16.6297 63.6516 16.6298 62.9846 17.3167Z"
          fill={color || '#91E600'}
        />
        <path
          d="M50.4571 1.24388L46.936 4.87009C46.2689 5.55706 46.2689 6.67085 46.936 7.35782L50.4571 10.984C51.1241 11.671 52.2057 11.671 52.8727 10.984L56.3938 7.35782C57.0609 6.67085 57.0609 5.55706 56.3938 4.87009L52.8727 1.24388C52.2057 0.556908 51.1241 0.556908 50.4571 1.24388Z"
          fill={color || '#76B900'}
        />
      </svg>
    </Box>
  ),
);
