import { DeckardIconProps } from '@assets/types';
import { useTheme } from '@mui/material';
import { forwardRef } from 'react';

export const SearchIcon = forwardRef<SVGSVGElement, DeckardIconProps>((props, ref) => {
  const { palette } = useTheme();
  const { color, size } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
      width={size || 24}
      height={size || 24}
      viewBox="0 0 24 24"
      fill="none"
      stroke={color || palette.icon.default}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="stroke-override"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5 18C14.6421 18 18 14.6421 18 10.5C18 6.35786 14.6421 3 10.5 3C6.35786 3 3 6.35786 3 10.5C3 14.6421 6.35786 18 10.5 18Z"
      />
      <path d="M20.9998 21L15.7998 15.8" />
    </svg>
  );
});
