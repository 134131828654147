import moment from 'moment';

export const calculateDateDifference = (date: string) => {
  const expirationDate = new Date(moment(date).format('YYYY-MM-DD LT'));
  const diffTime = expirationDate.getTime() - new Date().getTime();

  const days = Math.round(moment.duration(diffTime).asDays());
  const hours = Math.round(moment.duration(diffTime).asHours());
  const seconds = moment.duration(diffTime).asSeconds();

  return {
    days,
    hours,
    seconds,
  };
};
