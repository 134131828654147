import { Button } from '@components/button';
import { Typography } from '@components/typography';
import {
  useDeleteManagedChannelMutation,
  useGetChannelsQuery,
  useGetOrganizationSettingsQuery,
  useRemoveChannelOrganizationMutation,
} from '@generated/UseGraphqlHooks';
import { delay } from '@helper-functions/delay';
import { Stack } from '@mui/material';
import { useNotifications } from '@notifications/Notifications';
import { useState } from 'react';

interface DeleteChannelModalProps {
  name: string;
  channelId: string;
  organizationId: string;
  onClose: () => void;
  isChannelOwnedByOrganization: boolean;
}

export const DeleteChannelModal = ({
  name,
  channelId,
  organizationId,
  onClose,
  isChannelOwnedByOrganization,
}: DeleteChannelModalProps) => {
  const { useAsyncNotification } = useNotifications();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [deleteChannel] = useDeleteManagedChannelMutation();
  const { refetch: refetchChannels } = useGetChannelsQuery({
    variables: {
      organizationId,
    },
  });

  const { refetch: channelsLimitsRefetch } = useGetOrganizationSettingsQuery({
    variables: { organizationId },
  });

  const [removeOrganizationChannel] = useRemoveChannelOrganizationMutation();
  const handleDeleteChannelClick = useAsyncNotification(
    `Successfully ${isChannelOwnedByOrganization ? 'deleted' : 'removed'} the channel`,
    async () => {
      setIsSubmitting(true);
      isChannelOwnedByOrganization
        ? await deleteChannel({ variables: { channelId } })
        : await removeOrganizationChannel({
            variables: { channelId, organizationId },
          });
      await delay(3000);
      await refetchChannels();
      await channelsLimitsRefetch();
      setIsSubmitting(false);
      onClose();
    },
  );

  return (
    <Stack pt={4} gap={10} justifyContent="center">
      <Typography variant="body2" align="center">
        Are you sure you want to {isChannelOwnedByOrganization ? 'delete' : 'remove'} {name}?
      </Typography>
      <Stack gap={4}>
        <Button fullWidth disabled={isSubmitting} onClick={handleDeleteChannelClick}>
          Yes, {isChannelOwnedByOrganization ? 'delete' : 'remove'}
        </Button>
        <Button fullWidth onClick={onClose} variant="secondary">
          Cancel
        </Button>
      </Stack>
    </Stack>
  );
};
