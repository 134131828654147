import { DownloadIcon, ExternalLinkIcon, SearchIcon, TrashIcon } from '@assets/icons';
import { CopyIconButton, IconButton } from '@components/button';
import { Group } from '@components/layout';
import { InternalLink } from '@components/link';
import { useConfirmation, useModal } from '@components/modal';
import { Typography } from '@components/typography';
import {
  MlInference,
  useDeleteMlInferenceMutation,
  useDownloadMlInferenceMutation,
} from '@generated/UseGraphqlHooks';
import { saveFile } from '@helper-functions/save-file';
import { Grid, useTheme } from '@mui/material';
import { useNotifications } from '@notifications/Notifications';
import { useSelectedDataset } from '@subsets/workspaces';
import { DatasetsDetailsImagesSlider } from '../../DatasetsDetailsImagesSlider';
import { DatasetsDetailsMicroserviceStatus } from '../../DatasetsDetailsMicroserviceStatus';

export const DatasetDetailsInferencesListHeader = () => {
  const { palette } = useTheme();
  return (
    <Grid container alignItems="center">
      <Grid item xs={1.5}>
        <Typography color={palette.table.header} variant="caption2">
          Status
        </Typography>
      </Grid>
      <Grid item xs={1.5} sx={{ display: 'flex', justifyContent: 'center' }}>
        <Typography color={palette.table.header} variant="caption2">
          Id
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography color={palette.table.header} variant="caption2">
          Model
        </Typography>
      </Grid>
      <Grid item xs={6} />
    </Grid>
  );
};

export const DatasetDetailsInferencesListItem = ({
  dataset,
  datasetId,
  inferenceId,
  model,
  status,
  workspaceId,
  refetch,
}: Partial<MlInference> & { refetch: () => void }) => {
  const { runs } = useSelectedDataset();
  const { useAsyncNotification, addNotification, removeNotification } = useNotifications();
  const { showConfirmation } = useConfirmation();
  const [deleteInference] = useDeleteMlInferenceMutation();
  const [downloadInference] = useDownloadMlInferenceMutation();
  const handleDelete = async () => {
    await showConfirmation({
      onAffirm: useAsyncNotification('Inference deleted', async () => {
        await deleteInference({
          variables: {
            workspaceId,
            inferenceId,
          },
        });
        if (refetch) {
          refetch();
        }
      }),
      message: `Are you sure you want to delete the inference?`,
    });
  };
  const handleDownload = async () => {
    const key = `${Date.now() * 1000}.${Math.random() * 10000}`;
    try {
      const { data } = await downloadInference({
        variables: {
          workspaceId,
          inferenceId,
        },
      });

      addNotification({ text: 'Loading...', type: 'loading', key, duration: 100000 });
      saveFile({ url: data?.downloadMLInference, name: `${dataset}-${model}.zip` });
      removeNotification(key);
      addNotification({
        text: 'Starting download...',
        type: 'success',
        duration: 6000,
      });
    } catch (e) {
      removeNotification(key);
    }
  };
  const { showModal } = useModal({
    component: DatasetsDetailsImagesSlider,
    componentProps: {
      workspaceId,
      datasetId,
      datasetName: dataset,
      defaultInferenceId: inferenceId,
      loading: false,
      runs,
    },
    modalProps: {
      sx: {
        '&.deckard-modal-container': {
          width: '80vw',
          height: '80vh',
        },
      },
    },
  });
  return (
    <Grid container alignItems="center" justifyContent="flex-start">
      <Grid item xs={1.5}>
        <Group marginLeft={1}>
          <DatasetsDetailsMicroserviceStatus
            status={status}
            microServiceName="inferences"
            workspaceId={workspaceId}
            datasetId={datasetId}
            microServiceId={inferenceId}
          />
        </Group>
      </Grid>
      <Grid item xs={1.5} sx={{ display: 'flex', justifyContent: 'center' }}>
        <CopyIconButton value={inferenceId} showValue tooltipProps={{ variant: 'secondary' }} />
      </Grid>
      <Grid item xs={5}>
        <Typography handleOverFlow variant="body2">
          {model}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Group justifyContent="flex-end" gap={2}>
          {status === 'success' && <IconButton onClick={showModal} Icon={SearchIcon} />}
          {status === 'success' && (
            <>
              <IconButton onClick={handleDownload} Icon={DownloadIcon} />
            </>
          )}
          <InternalLink
            unstyled
            to={`/workspaces/${workspaceId}/experiments/inferences/${inferenceId}`}
          >
            <IconButton Icon={ExternalLinkIcon} />
          </InternalLink>
          <IconButton onClick={handleDelete} Icon={TrashIcon} />
        </Group>
      </Grid>
    </Grid>
  );
};
