import { FormSelect } from '@components/form';
import { Tag } from '@components/tag/Tag';
import { useGetChannelsQuery } from '@generated/UseGraphqlHooks';
import { MenuItem, SelectChangeEvent, Stack } from '@mui/material';
import { useField } from 'formik';
import { filter, find, orderBy } from 'lodash';
import { MouseEvent } from 'react';

interface NewWorkspacesModalAvailableChannelsDropdownProps {
  organizationId: string;
  name: string;
}

export const NewWorkspacesModalAvailableChannelsDropdown = (
  props: NewWorkspacesModalAvailableChannelsDropdownProps,
) => {
  const { organizationId, name } = props;
  const [field, meta, helpers] = useField(props);
  const { value } = field as { value: string[] };
  const { setValue } = helpers;

  const { data: organizationChannels } = useGetChannelsQuery({
    variables: { organizationId },
  });

  const channels =
    orderBy(
      organizationChannels?.getChannels,
      [(channel) => channel.name.toLowerCase()],
      ['asc'],
    ) || [];

  const handleChange = ({ target: { value: targetValue } }: SelectChangeEvent<unknown>) => {
    setValue(targetValue);
  };

  const handleDelete = (_: MouseEvent, channelId: string) => {
    const removedValue = filter(value, (id) => id !== channelId);
    setValue(removedValue);
  };

  const getChannelName = (channelId: string) => {
    const channel = find(channels, { channelId });
    return channel?.name || '';
  };

  return (
    <FormSelect
      multiple
      name={name}
      value={value}
      label="Channels"
      onChange={handleChange}
      renderValue={(selected: string[]) => (
        <Stack direction="row" gap={1} flexWrap="wrap">
          {selected.map((id) => (
            <Tag
              key={id}
              label={getChannelName(id)}
              onDelete={(e) => handleDelete(e, id)}
              sx={{ height: '21px', '& .MuiChip-label': { fontSize: '12px', lineHeight: '21px' } }}
            />
          ))}
        </Stack>
      )}
    >
      {channels.map((channel) => (
        <MenuItem key={channel.channelId} value={channel.channelId}>
          {channel.name}
        </MenuItem>
      ))}
    </FormSelect>
  );
};
