import { ArrowLeftIcon, ArrowRightIcon } from '@assets/icons';
import { Button } from '@components/button';
import { useGetChannelsQuery } from '@generated/UseGraphqlHooks';
import { Grid, Stack } from '@mui/material';
import { filter, map } from 'lodash';
import { Dispatch, useEffect, useState } from 'react';
import { ResourcesType, TransferList, intersection, not } from './TransferList';

interface ChannelsTransferListProps {
  organizationId: string;
  workspaceId: string;
  selectedChannels: ResourcesType;
  setSelectedChannels: Dispatch<ResourcesType>;
}

const getChannels = (
  channels: { channelId: string; name: string }[],
  selected: string[],
  available?: boolean,
) => {
  return map(
    filter(channels, ({ channelId }) =>
      available ? !selected.includes(channelId) : selected.includes(channelId),
    ),
    ({ channelId, name }) => ({ id: channelId, name }),
  );
};

export const ChannelsTransferList = ({
  organizationId,
  workspaceId,
  selectedChannels,
  setSelectedChannels,
}: ChannelsTransferListProps) => {
  const [checked, setChecked] = useState<ResourcesType>([]);
  const [right, setRight] = useState<ResourcesType>([]);
  const { data: availableChannels, loading: availableChannelsLoading } = useGetChannelsQuery({
    variables: {
      organizationId,
    },
  });

  const { data: includedChannels, loading: includedChannelsLoading } = useGetChannelsQuery({
    variables: {
      workspaceId,
    },
  });
  const selectedChannelsIds = map(includedChannels?.getChannels, 'channelId');

  useEffect(() => {
    if (!availableChannelsLoading && !includedChannelsLoading) {
      setRight(getChannels(availableChannels?.getChannels, selectedChannelsIds, true));
      setSelectedChannels(getChannels(availableChannels?.getChannels, selectedChannelsIds, false));
    }

    return () => {
      setRight([]);
      setSelectedChannels([]);
    };
  }, [availableChannelsLoading, includedChannelsLoading]);

  const leftChecked = intersection(checked, selectedChannels);
  const rightChecked = intersection(checked, right);
  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setSelectedChannels(not(selectedChannels, leftChecked));
    setChecked(not(checked, leftChecked));
  };
  const handleCheckedLeft = () => {
    setSelectedChannels(selectedChannels.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  return (
    <Grid container spacing={2} justifyContent="center" alignItems="center">
      <Grid item xs={5}>
        <TransferList
          title="Included"
          items={selectedChannels}
          checked={checked}
          setChecked={setChecked}
        />
      </Grid>
      <Grid item xs={2}>
        <Grid container direction="column" alignItems="center">
          <Stack gap={4}>
            <Button
              fullWidth
              variant="secondary"
              onClick={handleCheckedLeft}
              data-cy="Channel-Transfer-List-Add-Button"
              startIcon={ArrowLeftIcon}
              disabled={rightChecked.length === 0}
            >
              Add
            </Button>
            <Button
              fullWidth
              variant="secondary"
              data-cy="Channel-Transfer-List-Remove-Button"
              onClick={handleCheckedRight}
              disabled={leftChecked.length === 0}
              endIcon={ArrowRightIcon}
            >
              Remove
            </Button>
          </Stack>
        </Grid>
      </Grid>
      <Grid item xs={5}>
        <TransferList title="Excluded" items={right} checked={checked} setChecked={setChecked} />
      </Grid>
    </Grid>
  );
};
