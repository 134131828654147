import { Typography } from '@components/typography';
import { Box, CircularProgress, circularProgressClasses, useTheme } from '@mui/material';

type ProgressBarCircularProps = {
  error?: boolean;
  content?: string;
  value?: number;
  warning?: boolean;
};

export const ProgressCircle = ({
  content,
  error,
  value,
  warning,
  ...props
}: ProgressBarCircularProps) => {
  const { palette } = useTheme();
  return (
    <Box position="relative" display="flex" height="90px" width="90px">
      <CircularProgress
        variant="determinate"
        sx={{
          color: palette.grey[300],
          position: 'absolute',
          left: 0,
          top: 0,
        }}
        size={90}
        thickness={2}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="determinate"
        sx={{
          color: error
            ? palette.progress.error
            : warning
              ? palette.progress.warning
              : palette.progress.default,
          animationDuration: '550ms',
          position: 'absolute',
          left: 0,
          top: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: 'round',
          },
        }}
        size={90}
        value={value}
        thickness={2}
        {...props}
      />
      <Typography variant="subtitle1" component="div" sx={{ alignSelf: 'center', margin: 'auto' }}>
        {content}
      </Typography>
    </Box>
  );
};
