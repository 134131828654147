import { Slider as MuiSlider, styled } from '@mui/material';

export const Slider = styled(MuiSlider)(({ theme: { palette } }) => ({
  '& .MuiSlider-thumb': {
    height: 20,
    width: 20,
    backgroundColor: palette.slider.thumb.background.default,
    border: `1px solid ${palette.slider.thumb.border}`,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: palette.slider.thumb.background.hover,
      boxShadow: 'none',
    },
    '&:active, &:focus-within': {
      boxShadow: palette.slider.thumb.active,
    },
  },
  '& .MuiSlider-track': {
    height: 5,
    border: 'none',
    borderRadius: '2px',
    backgroundColor: palette.slider.track,
  },
  '& .MuiSlider-rail': {
    height: 5,
    borderRadius: '2px',
    color: palette.slider.rail,
  },
  '&.Mui-disabled': {
    '& .MuiSlider-thumb': {
      cursor: 'default',
    },
    '& .MuiSlider-track': {
      backgroundColor: 'rgb(207, 209, 222)',
    },
  },
}));
