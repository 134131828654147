import { AsyncButton, Button } from '@components/button';
import { Form, FormTextInput } from '@components/form';
import { useEditDatasetMutation } from '@generated/UseGraphqlHooks';
import { Stack } from '@mui/material';
import { useNotifications } from '@notifications/Notifications';
import * as yup from 'yup';

interface DatasetsDetailsEditModalProps {
  onClose: () => void;
  detailName: string;
  detailDescription: string;
  datasetId: string;
  workspaceId: string;
  refetchDatasets: VoidFunction;
}

const validationSchema = yup.object({
  name: yup
    .string()
    .trim()
    .max(128, 'Must be less than 128 characters')
    .required('Name is required'),
});

export const DatasetsDetailsEditModal = ({
  onClose,
  detailName,
  detailDescription,
  datasetId,
  workspaceId,
  refetchDatasets,
}: DatasetsDetailsEditModalProps) => {
  const { useAsyncNotification } = useNotifications();
  const [editDataset] = useEditDatasetMutation();
  const handleEditDatasetDetail = useAsyncNotification(
    'Successfully updated description.',
    async ({ description, name }: { description: string; name: string }) => {
      await editDataset({ variables: { datasetId, description, name, workspaceId } });
      if (refetchDatasets) {
        void refetchDatasets();
      }
      onClose();
    },
  );
  return (
    <Form
      enableReinitialize
      onSubmit={handleEditDatasetDetail}
      initialValues={{ name: detailName, description: detailDescription || '' }}
      validationSchema={validationSchema}
    >
      {({ handleSubmit }) => (
        <Stack>
          <FormTextInput label="Name" name="name" />
          <FormTextInput label="Description" name="description" multiline rows={3} />
          <Stack gap={4}>
            <AsyncButton
              fullWidth
              onClick={handleSubmit}
              data-cy="Datasets-Details-Edit-Modal-Save-Button"
            >
              Save
            </AsyncButton>
            <Button
              fullWidth
              variant="secondary"
              onClick={onClose}
              data-cy="Datasets-Details-Edit-Modal-Cancel-Button"
            >
              Cancel
            </Button>
          </Stack>
        </Stack>
      )}
    </Form>
  );
};
