import { FileUpload } from '@components/form';
import { Box } from '@mui/material';
import { useNotifications } from '@notifications/Notifications';
import yaml from 'js-yaml';

interface UploadFileProps {
  onChange: (graphData: string) => void;
}

export const WorkspaceGraphsAddGraphModalUploadFile = ({ onChange }: UploadFileProps) => {
  const { addNotification } = useNotifications();

  const handleFileLoad = (graphFile: File) => {
    const reader = new FileReader();
    try {
      reader.addEventListener('loadend', () => {
        const text = reader.result as string;
        const graphData = yaml.load(text) as { nodes: any; version: any };
        if (!graphData?.nodes) {
          throw new Error('Unable to parse graph file');
        } else {
          onChange(JSON.stringify(graphData ?? '{}'));
        }
      });
      reader.readAsText(graphFile, 'UTF-8');
    } catch (error: unknown) {
      addNotification({
        text: (error as { message: string })?.message || 'Error',
        type: 'error',
      });
    }
  };

  return (
    <Box mb={5}>
      <FileUpload
        name="graph-upload"
        label="Upload graph"
        accept=".json,.yaml,.yml"
        onFileLoad={handleFileLoad}
      />
    </Box>
  );
};
