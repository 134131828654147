import { UploadIcon } from '@assets/icons';
import { ChangeEvent, useRef, useState } from 'react';
import { TextInput } from './TextInput';

export const FileUpload = ({
  accept,
  name,
  label,
  onFileLoad,
}: {
  accept?: string;
  name: string;
  label?: string;
  onFileLoad?: (file: File) => void;
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState<File | null>(null);
  return (
    <>
      <TextInput
        sx={{ cursor: 'pointer', '& .MuiInputBase-input': { cursor: 'pointer' } }}
        onClick={() => inputRef.current.click()}
        value={file?.name || ''}
        readOnly
        label={label}
        name={name}
        endIcon={UploadIcon}
      />
      <input
        accept={accept}
        ref={inputRef}
        type="file"
        hidden
        name={name}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          setFile(e.currentTarget.files[0]);
          onFileLoad && onFileLoad(e.currentTarget.files[0]);
        }}
      />
    </>
  );
};
