import { Typography } from '@components/typography';
import { Box, Stack } from '@mui/material';
import { GraphDataContext, SelectionContext } from '@subsets/workspaces';
import { Port } from '@subsets/workspaces/graphs/graph/types';
import { find, isMatch } from 'lodash';
import { useCallback, useContext, useRef } from 'react';
import { useObservable } from 'react-use';
import { map } from 'rxjs/operators';
import { BasePort } from './BasePort';
import { PortIcon } from './PortIcon';
import { NodeState } from './types';

export const OutputPort = ({
  description,
  state,
  name,
  nodeId,
  portId,
  onSelect,
}: Port & {
  state: NodeState;
  nodeId: string;
  portId: string;
  onSelect?: (portId: string) => void;
}) => {
  const select = useCallback(() => {
    onSelect && onSelect(portId);
  }, [onSelect, portId]);
  const { selectedOutputPort } = useContext(SelectionContext);
  const iconRef = useRef();
  const { links } = useContext(GraphDataContext);
  const thing = useObservable(
    selectedOutputPort.pipe(map((value) => isMatch(value, { portId, nodeId }))),
    false,
  );
  const linked =
    Boolean(find(links, { from: { portId, nodeId } }) || find(links, { to: { portId, nodeId } })) ||
    thing;

  return (
    <BasePort portId={portId} nodeId={nodeId} type="output">
      <Stack direction="row" justifyContent="flex-end" alignItems="center" height="100%">
        <Typography variant="caption2" tooltip={description}>
          {name}
        </Typography>
        <Box sx={{ position: 'absolute', right: -12, height: '32px' }} onClick={select}>
          <PortIcon state={state} linked={linked} ref={iconRef} type="output" />
        </Box>
      </Stack>
    </BasePort>
  );
};
