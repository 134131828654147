import { Group } from '@components/layout';
import { Typography } from '@components/typography';
import { Box, Container, SxProps, useTheme } from '@mui/material';
import { PropsWithChildren } from 'react';

type BannerProps = {
  title?: string;
  sx?: SxProps;
};

export const Banner = ({ sx, title, children }: PropsWithChildren<BannerProps>) => {
  const { palette } = useTheme();
  return (
    <Box
      width="100%"
      sx={{
        height: '48px',
        maxHeight: '48px',
        minHeight: '48px',
        backgroundColor: palette.header.secondary,
        px: 4,
      }}
    >
      <Container maxWidth="lg" sx={{ height: '100%', ...sx }} disableGutters>
        {children || (
          <Group alignItems="center" height="100%">
            <Typography variant="subtitle1" color={palette.common.white}>
              {title}
            </Typography>
          </Group>
        )}
      </Container>
    </Box>
  );
};
