import { EyeIcon, EyeOffIcon } from '@assets/icons';
import { ProgressBar } from '@components/progress';
import { Stack } from '@mui/material';
import { useField } from 'formik';
import { ChangeEvent, useState } from 'react';
import zxcvbn from 'zxcvbn';
import { FormTextInput, FormTextInputProps } from './FormTextInput';

const PasswordWithScore = ({
  name,
  onScoreChange,
  ...props
}: FormTextInputProps & { onScoreChange?: (newScore: number) => void }) => {
  const [field] = useField(name);
  const [score, setScore] = useState(0);
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newScore = (zxcvbn as (string) => { score: number })(e.target.value).score;
    if (newScore !== score) {
      setScore(newScore || 0);
      if (onScoreChange) {
        onScoreChange(newScore);
      }
    }
    field.onChange(e);
  };

  return (
    <Stack width="100%" mb={5}>
      <PasswordField onChange={handleChange} name={name} maintainGutters={false} {...props} />
      <ProgressBar percent={score * 25} />
    </Stack>
  );
};

const PasswordField = ({ name, ...props }: FormTextInputProps) => {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <FormTextInput
      autoComplete="off"
      type={showPassword ? 'text' : 'password'}
      endIcon={showPassword ? EyeIcon : EyeOffIcon}
      onClickEndIcon={() => setShowPassword(!showPassword)}
      name={name}
      {...props}
    />
  );
};

type FormPasswordProps = FormTextInputProps & {
  showScore?: boolean;
  onScoreChange?: (newScore: number) => void;
};

export const FormPassword = ({ showScore, ...props }: FormPasswordProps) => {
  if (showScore) {
    return <PasswordWithScore {...props} />;
  }
  return <PasswordField {...props} />;
};
