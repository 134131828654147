import { Group } from '@components/layout';
import { Tag } from '@components/tag';
import { Box, useTheme } from '@mui/material';
import { DatasetDetailsAccordion } from '@subsets/workspaces/libraries/datasets/DatasetDetailsAccordion';

type DatasetDetailsClassesProps = {
  classes: string[];
};

export const DatasetDetailsClasses = ({ classes }: DatasetDetailsClassesProps) => {
  const { palette } = useTheme();
  return (
    <Box mt={4}>
      <DatasetDetailsAccordion
        title="Classes"
        count={classes?.length || 0}
        details={
          <Group sx={{ flexWrap: 'wrap', gap: 1 }}>
            {classes?.map((className) => (
              <Tag
                label={className}
                sx={{ backgroundColor: palette.background.paper, userSelect: 'none' }}
              />
            ))}
          </Group>
        }
      />
    </Box>
  );
};
