import { MoreVerticalIcon } from '@assets/icons';
import { IconButton } from '@components/button';
import { useModal } from '@components/modal';
import { Typography } from '@components/typography';
import { useGetGraphsQuery } from '@generated/UseGraphqlHooks';
import { Menu, MenuItem } from '@mui/material';
import { useCurrentWorkspaceId } from '@navigation/WorkspaceContext';
import { navigate } from 'gatsby';
import posthog from 'posthog-js';
import { MouseEvent, useContext, useState } from 'react';
import { GraphsListViewPreviewMenuDownloadConfiguration } from '../GraphsListViewPreviewMenuDownloadConfiguration';
import { GraphsListViewPreviewMenuDuplicateConfiguration } from '../GraphsListViewPreviewMenuDuplicateConfiguration';
import { GraphsListViewPreviewMenuEditConfiguration } from '../GraphsListViewPreviewMenuEditConfiguration';
import { GraphDataContext } from './GraphDataProvider';

export const GraphHeaderDropdown = () => {
  const workspaceId = useCurrentWorkspaceId();
  const [anchorEl, setAnchorEl] = useState<HTMLElement>();
  const open = Boolean(anchorEl);
  const { graphMetadata, graphMetadataLoading, refetchGraphMetadata } =
    useContext(GraphDataContext);
  const { refetch } = useGetGraphsQuery({ variables: { workspaceId, staged: false } });

  const handleClick = (event: MouseEvent) => setAnchorEl(event.currentTarget as HTMLElement);
  const handleClose = () => setAnchorEl(null);

  const { showModal: showEditModal } = useModal({
    component: GraphsListViewPreviewMenuEditConfiguration,
    componentProps: { ...graphMetadata, refetch: refetchGraphMetadata },
    modalProps: { title: `Edit ${graphMetadata?.name}` },
  });

  const onDuplicate = (newGraphId?: string) => {
    refetchGraphMetadata();
    refetch();
    if (newGraphId) {
      navigate(`/workspaces/${workspaceId}/graphs/`);
    }
  };

  const { showModal: showDuplicateModal } = useModal({
    component: GraphsListViewPreviewMenuDuplicateConfiguration,
    componentProps: { ...graphMetadata, refetch: onDuplicate },
    modalProps: { title: `Save ${graphMetadata?.name || 'graph'} copy as` },
  });

  const { showModal: showDownloadModal } = useModal({
    component: GraphsListViewPreviewMenuDownloadConfiguration,
    componentProps: { ...graphMetadata },
    modalProps: { title: `Download ${graphMetadata?.name}` },
  });

  const handleEditClick = () => {
    showEditModal();
    handleClose();
  };
  const handleDuplicateClick = () => {
    posthog.capture('user_copy-graph_intent');
    showDuplicateModal();
    handleClose();
  };
  const handleDownloadClick = () => {
    showDownloadModal();
    handleClose();
  };

  if (graphMetadataLoading) {
    return null;
  }

  return (
    <>
      <IconButton
        data-cy="Graph-Header-Dropdown-More-Icon"
        onClick={handleClick}
        Icon={MoreVerticalIcon}
        size={20}
      />
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        <MenuItem data-cy="Graph-Header-Dropdown-Edit" onClick={handleEditClick}>
          <Typography variant="body2">Edit</Typography>
        </MenuItem>
        <MenuItem data-cy="Graph-Header-Dropdown-Save-As" onClick={handleDuplicateClick}>
          <Typography variant="body2">Save As</Typography>
        </MenuItem>
        <MenuItem onClick={handleDownloadClick}>
          <Typography variant="body2">Download</Typography>
        </MenuItem>
      </Menu>
    </>
  );
};
