import { Typography } from '@components/typography';
import { Stack, useTheme } from '@mui/material';
import {
  CardCvcElement as StripeCardCvcElement,
  CardExpiryElement as StripeCardExpiryElement,
  CardNumberElement as StripeCardNumberElement,
} from '@stripe/react-stripe-js';
import { useField } from 'formik';
import { FormFieldError } from './FormInputs/FormFieldError';

interface CardElementType {
  label?: string;
  name: string;
  width?: string;
}

export const CardNumberElement = ({ label, name, width }: CardElementType) => {
  const { palette } = useTheme();

  const [field, meta, helpers] = useField(name);
  const error = meta?.error;

  const handleChange = async ({ error, empty }) => {
    if (!empty) {
      await helpers.setValue('Not empty');
    }
    if (error) {
      helpers.setError(error.message);
    } else {
      helpers.setError('');
    }
  };

  return (
    <Stack
      sx={{
        [`& #${name}`]: {
          borderRadius: 1,
          paddingLeft: 2,
          paddingRight: 2,
          paddingTop: '9px',
          paddingBottom: '9px',
          height: '34px',
          backgroundColor: palette.input.background.default,
          border: `1px solid ${error ? palette.input.border.error : palette.input.border.default}`,
          '&.StripeElement--focus': {
            backgroundColor: palette.input.background.active,
            borderColor: palette.input.border.active,
            borderWidth: '2px',
            paddingTop: '8px',
            paddingBottom: '8px',
          },
        },
      }}
    >
      {label && (
        <Typography
          className="deckard-text-input-label"
          component="label"
          htmlFor={name}
          mb={1}
          variant="caption2"
          color={error ? palette.input.label.error : palette.input.label.default}
        >
          {label}
        </Typography>
      )}
      <StripeCardNumberElement
        data-cy="card-number"
        id={name}
        name={name}
        {...field}
        onChange={handleChange}
        options={{
          style: {
            base: {
              fontSize: '14px',
              fontFamily: 'Lato, sans-serif',
              fontWeight: 400,
              backgroundColor: palette.input.background.default,
              color: palette.input.text.default,
              ':focus': {
                backgroundColor: palette.input.background.active,
                color: palette.input.text.default,
              },
              '::placeholder': {
                color: 'transparent',
              },
            },
            invalid: {
              color: palette.input.text.error,
            },
          },
        }}
      />
      <FormFieldError error={error} maintainGutters />
    </Stack>
  );
};

export const CardExpiryElement = ({ label, name, width }: CardElementType) => {
  const { palette } = useTheme();

  const [field, meta, helpers] = useField(name);
  const error = meta?.error;

  const handleChange = async ({ error, empty }) => {
    if (!empty) {
      await helpers.setValue('Not empty');
    }
    if (error) {
      helpers.setError(error.message);
    } else {
      helpers.setError('');
    }
  };

  return (
    <Stack
      position="relative"
      width={width || '100%'}
      sx={{
        [`& #${name}`]: {
          borderRadius: 1,
          paddingLeft: 2,
          paddingRight: 2,
          paddingTop: '9px',
          paddingBottom: '9px',
          backgroundColor: palette.input.background.default,
          height: '34px',
          border: `1px solid ${error ? palette.input.border.error : palette.input.border.default}`,
          '&.StripeElement--focus': {
            backgroundColor: palette.input.background.active,
            borderColor: palette.input.border.active,
            borderWidth: '2px',
            paddingTop: '8px',
            paddingBottom: '8px',
          },
        },
      }}
    >
      {label && (
        <Typography
          className="deckard-text-input-label"
          component="label"
          htmlFor={name}
          mb={1}
          variant="caption2"
          color={error ? palette.input.label.error : palette.input.label.default}
        >
          {label}
        </Typography>
      )}
      <StripeCardExpiryElement
        id={name}
        name={name}
        {...field}
        onChange={handleChange}
        options={{
          style: {
            base: {
              fontSize: '14px',
              fontFamily: 'Lato, sans-serif',
              fontWeight: 400,
              backgroundColor: palette.input.background.default,
              color: palette.input.text.default,
              ':focus': {
                backgroundColor: palette.input.background.active,
                color: palette.input.text.default,
              },
              '::placeholder': {
                color: 'transparent',
              },
            },
            invalid: {
              color: palette.input.text.error,
            },
          },
        }}
      />
      <FormFieldError error={error} maintainGutters />
    </Stack>
  );
};

export const CardCvcElement = ({ label, name, width }: CardElementType) => {
  const { palette } = useTheme();

  const [field, meta, helpers] = useField(name);
  const error = meta?.error;

  const handleChange = async ({ error, empty }) => {
    if (!empty) {
      await helpers.setValue('Not empty');
    }
    if (error) {
      helpers.setError(error.message);
    } else {
      helpers.setError('');
    }
  };

  return (
    <Stack
      position="relative"
      width={width || '100%'}
      sx={{
        [`& #${name}`]: {
          borderRadius: 1,
          paddingLeft: 2,
          paddingRight: 2,
          paddingTop: '9px',
          paddingBottom: '9px',
          backgroundColor: palette.input.background.default,
          height: '34px',
          border: `1px solid ${error ? palette.input.border.error : palette.input.border.default}`,
          '&.StripeElement--focus': {
            backgroundColor: palette.input.background.active,
            borderColor: palette.input.border.active,
            borderWidth: '2px',
            paddingTop: '8px',
            paddingBottom: '8px',
          },
        },
      }}
    >
      {label && (
        <Typography
          className="deckard-text-input-label"
          component="label"
          htmlFor={name}
          mb={1}
          variant="caption2"
          color={error ? palette.input.label.error : palette.input.label.default}
        >
          {label}
        </Typography>
      )}
      <StripeCardCvcElement
        id={name}
        name={name}
        {...field}
        onChange={handleChange}
        options={{
          style: {
            base: {
              fontSize: '14px',
              fontFamily: 'Lato, sans-serif',
              fontWeight: 400,
              backgroundColor: palette.input.background.default,
              color: palette.input.text.default,
              ':focus': {
                backgroundColor: palette.input.background.active,
                color: palette.input.text.default,
              },
              '::placeholder': {
                color: 'transparent',
              },
            },
            invalid: {
              color: palette.input.text.error,
            },
          },
        }}
      />
      <FormFieldError error={error} maintainGutters />
    </Stack>
  );
};
