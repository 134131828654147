import { Banner } from '@components/banner';
import { Typography } from '@components/typography';
import { Box, useTheme } from '@mui/material';
import { useCurrentWorkspace } from '@navigation/WorkspaceContext';
import { WorkspacesMoreDropdown } from './WorkspacesMoreDropdown';

export const WorkspaceHeadline = () => {
  const { palette } = useTheme();

  const { workspace, refetch } = useCurrentWorkspace();

  return (
    <Banner>
      <Box
        color={palette.common.white}
        display="flex"
        alignItems="center"
        justifyContent="flex-start"
        position="relative"
        gap={3}
        height="100%"
      >
        <Typography
          variant="subtitle1"
          color={palette.common.white}
          handleOverFlow
          data-cy="Workspace-Headline-Workspace-Name"
          sx={{ width: 'auto', maxWidth: '500px' }}
        >
          Dashboard
        </Typography>
        <WorkspacesMoreDropdown
          isLandingPageWorkspace={false}
          color={palette.text.paper}
          workspacesRefetch={refetch}
          workspace={workspace}
        />
      </Box>
    </Banner>
  );
};
