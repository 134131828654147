import { CSSProperties } from 'react';

export const componentOverrides = {
  MuiCssBaseline: {
    styleOverrides: {
      body: {
        margin: 0,
        height: '100vh',
        width: '100%',
      },
    },
  },
  MuiChip: {
    styleOverrides: {
      root: ({ theme: { palette, spacing } }) => ({
        height: '1.75rem',
        width: 'fit-content',
        backgroundColor: palette.mode === 'dark' ? palette.grey[900] : palette.common.white,
        borderColor: palette.mode === 'dark' ? palette.grey[900] : palette.grey[500],
        '&.MuiChip-clickable:hover': {
          backgroundColor: palette.mode === 'dark' ? palette.common.black : palette.common.white,
          borderColor: palette.grey[200],
        },
      }),
      label: ({ theme: { palette } }) => ({
        color: palette.mode === 'dark' ? palette.common.white : palette.grey[900],
        fontFamily: 'Lato, sans-serif',
        fontWeight: 400,
        fontSize: '0.875rem',
        lineHeight: '1.75rem',
      }),
      deleteIcon: ({ theme: { palette } }) => ({
        zIndex: 1000,
        '&:hover': {
          stroke: palette.mode === 'dark' ? palette.common.white : palette.grey[900],
        },
      }),
    },
  },
  MuiIconButton: {
    styleOverrides: {
      root: {
        borderRadius: '0',
      },
    },
  },
  MuiFormLabel: {
    styleOverrides: {
      root: {
        color: '#6A6D7C',
        '&$focused': {
          color: '#6A6D7C',
          fontWeight: '700',
        },
      },
    },
  },
  MuiTouchRipple: {
    styleOverrides: {
      child: {
        borderRadius: '0',
      },
    },
  },
  MuiTooltip: {
    styleOverrides: {
      tooltip: ({ theme: { palette } }) => ({
        padding: '4px 12px',
        backgroundColor: palette.grey[900],
        fontSize: '0.75rem',
        color: palette.common.white,
        minWidth: '50px',
        maxWidth: 'auto',
        lineHeight: '1rem',
        fontFamily: 'Lato',
      }),
      arrow: ({ theme: { palette } }) => ({
        color: palette.grey[900],
      }),
      tooltipPlacementBottom: ({ theme: { spacing } }) => ({
        marginTop: `${spacing(2)} !important`,
      }),
      tooltipPlacementTop: ({ theme: { spacing } }) => ({
        marginBottom: `${spacing(2)} !important`,
      }),
      tooltipPlacementLeft: ({ theme: { spacing } }) => ({
        marginRight: `${spacing(2)} !important`,
      }),
      tooltipPlacementRight: ({ theme: { spacing } }) => ({
        marginLeft: `${spacing(2)} !important`,
      }),
    },
  },
  MuiTableContainer: {
    styleOverrides: {
      root: {
        borderRadius: 0,
      },
    },
  },
  MuiTableCell: {
    styleOverrides: {
      root: ({ theme: { palette, spacing } }) => ({
        maxWidth: '150px',
        padding: spacing(1),
        cursor: 'default',
        height: '36px',
        borderColor: palette.table.cell.border,
        color: palette.table.cell.text,
        '& *': {
          color: palette.table.cell.text,
        },
      }),
      head: ({ theme: { palette, spacing } }) => ({
        backgroundColor: palette.background.paper,
        cursor: 'default',
        padding: spacing(1),
        borderBottom: `1px solid ${palette.table.header}`,
        '& *': {
          color: palette.table.header,
        },
      }),
    },
  },
  MuiLink: {
    styleOverrides: {
      root: ({ theme }) => ({
        textDecorationColor: theme.palette.grey[500],
        '&:hover': {
          textDecorationColor: theme.palette.grey[900],
        },
      }),
    },
  },
  MuiFormControlLabel: {
    styleOverrides: {
      root: ({ theme: { palette, spacing } }) => ({
        gap: spacing(2),
        marginLeft: 0,
        height: '34px',
        '&:hover': {
          '.radio-icon-unchecked,.radio-icon-checked': {
            stroke: palette.radio.hover,
          },
        },
        '&.Mui-disabled': {
          '& .MuiFormControlLabel-label.Mui-disabled': {
            color: 'inherit',
          },
          opacity: 0.4,
        },
      }),
    },
  },
  MuiModal: {
    styleOverrides: {
      root: ({ theme: { palette } }) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '&>.MuiBox-root': {
          backgroundColor: palette.background.paper,
          color: palette.text.paper,
          boxShadow: palette.shadow.modal,
        },
        '& .deckard-modal-container:focus-visible': {
          outline: 'none',
        },
      }),
    },
  },
  MuiInputBase: {
    styleOverrides: {
      root: ({ theme: { palette, spacing } }) => ({
        borderRadius: spacing(1),
        paddingLeft: spacing(2),
        paddingRight: spacing(2),
        minHeight: '34px',
        lineHeight: '1.5em',
        fontSize: '.875rem',
        backgroundColor: palette.input.background.default,
        border: `1px solid ${palette.input.border.default}`,
        color: palette.input.text.default,
        '&.Mui-focused': {
          backgroundColor: palette.input.background.active,
          borderColor: palette.input.border.active,
          borderWidth: '2px',
        },
        '&:hover:not(.Mui-disabled):not(.Mui-focused)': {
          backgroundColor: palette.input.background.hover,
          borderColor: palette.input.border.hover,
        },
        '&.Mui-disabled': {
          backgroundColor: palette.input.background.disabled,
          borderColor: palette.input.border.disabled,
          color: palette.input.text.disabled,
        },
        '&.Mui-error': {
          borderColor: palette.input.border.error,
          color: palette.input.text.error,
        },
      }),
      inputAdornedStart: ({ theme: { spacing } }) => ({
        paddingLeft: spacing(1),
      }),
      inputAdornedEnd: ({ theme: { spacing } }) => ({
        paddingRight: spacing(1),
      }),
      input: ({ theme: { palette, typography } }) => ({
        ...typography.body2,
        boxSizing: 'border-box' as CSSProperties['boxSizing'],
        '& #placeholder': {
          opecity: 1,
          color: palette.input.text.placeholder,
        },
      }),
    },
  },
  MuiSelect: {
    styleOverrides: {
      root: {
        paddingLeft: 0,
        paddingRight: 0,
        cursor: 'pointer',
        '::before,::after': { display: 'none' },
        '& .MuiInputBase-input': {
          backgroundColor: 'transparent',
          '&:focus': {
            backgroundColor: 'transparent',
          },
        },
      },
      select: ({ theme: { spacing } }) => ({
        paddingLeft: spacing(2),
        backgroundColor: 'transparent',
        '&.MuiInputBase-input': {
          paddingRight: spacing(8),
        },
      }),
      icon: ({ theme: { spacing } }) => ({
        top: 'auto',
        height: '16px',
        width: '16px',
        right: spacing(2),
      }),
    },
  },
  MuiMenu: {
    styleOverrides: {
      paper: ({ theme: { palette } }) => ({
        boxShadow: palette.shadow.dropdown,
        borderRadius: '0.25rem',
        border: `1px solid ${palette.grey[500]}`,
        minWidth: '160px',
        backgroundColor: palette.menu.background,
        backgroundImage: 'none',
      }),
      list: ({ theme: { palette, spacing } }) => ({
        '& .MuiMenuItem-root': {
          maxHeight: '75vh',
          marginLeft: spacing(2),
          marginRight: spacing(2),
          paddingLeft: spacing(2),
          paddingRight: spacing(2),
          borderRadius: '0.125rem',
          minHeight: 'auto',
          '&:hover': {
            backgroundColor: palette.backgroundHover.paper,
          },
          '&.Mui-selected': {
            backgroundColor: palette.menu.item.selected,
            '&:hover': {
              backgroundColor: palette.menu.item.selected,
            },
          },
        },
      }),
    },
  },
  MuiSwitch: {
    styleOverrides: {
      root: ({ theme: { palette } }) => ({
        width: '36px',
        height: '20px',
        padding: 0,
        overflow: 'visible',
      }),
      switchBase: ({ theme: { palette } }) => ({
        padding: 0,
        left: 3,
        top: 3,
        '&:hover': {
          '& + .MuiSwitch-track': {
            backgroundColor: palette.toggle.track.unchecked.hover,
            opacity: 1,
          },
        },
        '&.Mui-checked': {
          transform: 'translateX(16px)',
          '& + .MuiSwitch-track': {
            backgroundColor: palette.toggle.track.checked.default,
            opacity: 1,
          },
          '&:hover': {
            '& + .MuiSwitch-track': {
              backgroundColor: palette.toggle.track.checked.hover,
              opacity: 1,
            },
          },
          '&.Mui-focusVisible': {
            '& .MuiSwitch-thumb': {
              backgroundColor: palette.toggle.thumb,
            },
            '& + .MuiSwitch-track': {
              outline: `2px solid ${palette.toggle.track.checked.border}`,
            },
          },
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 1,
        },
        '&.Mui-focusVisible': {
          '& .MuiSwitch-thumb': {
            backgroundColor: palette.toggle.thumb,
          },
          '& + .MuiSwitch-track': {
            outline: `2px solid ${palette.toggle.track.unchecked.border}`,
          },
        },
      }),
      thumb: ({ theme: { palette } }) => ({
        height: '14px',
        width: '14px',
        backgroundColor: palette.toggle.thumb,
        boxShadow: 'none',
      }),
      track: ({ theme: { palette } }) => ({
        backgroundColor: palette.toggle.track.unchecked.default,
        height: '100%',
        width: '100%',
        opacity: 1,
        borderRadius: '10px',
        '&::before, &::after': {
          content: '""',
          position: 'absolute',
          top: '50%',
          transform: 'translateY(-50%)',
          width: '12px',
          height: '12px',
        },
        '&::before': {
          backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="12" width="12" viewBox="0 0 24 24" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path stroke="${encodeURIComponent(
            palette.toggle.thumb,
          )}" d="M20 7L9 18L4 13"/></svg>')`,
          left: 4,
        },
        '&::after': {
          backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="12" width="12" viewBox="0 0 24 24" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path stroke="${encodeURIComponent(
            palette.toggle.thumb,
          )}" d="M18 6L6 18" /><path stroke="${encodeURIComponent(
            palette.toggle.thumb,
          )}" d="M6 6L18 18" /></svg>')`,
          right: 4,
        },
      }),
    },
  },
  MuiRadio: {
    styleOverrides: {
      root: ({ theme: { palette } }) => ({
        width: 'fit-content',
        overflow: 'visible',
        padding: 0,
        '& .radio-icon-unchecked,.radio-icon-checked': {
          stroke: palette.radio.default,
        },
        '&:hover': {
          '& .radio-icon-unchecked,.radio-icon-checked': {
            stroke: palette.radio.hover,
          },
        },
        '&.Mui-focusVisible': {
          '&::before': {
            content: '""',
            position: 'absolute',
            height: '22px',
            width: '22px',
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none" stroke-width="2" stroke="${encodeURIComponent(
              palette.radio.focus,
            )}"><circle cx="11" cy="11" r="10" /></svg>')`,
          },
          '& .radio-icon-unchecked,.radio-icon-checked': {},
        },
        '&.Mui-disabled': {
          '& .radio-icon-unchecked,.radio-icon-checked': {
            stroke: palette.radio.default,
          },
        },
        ml: '3px',
      }),
    },
  },
  MuiCheckbox: {
    styleOverrides: {
      root: ({ theme: { palette, spacing } }) => ({
        marginLeft: '1px',
        padding: 0,
        width: '16px',
        height: '16px',
        borderRadius: '2px',
        backgroundColor: palette.checkbox.unchecked.background,
        border: `1px solid ${palette.checkbox.unchecked.border}`,
        '& svg,.MuiSvgIcon-root': {
          position: 'absolute',
          color: palette.checkbox.unchecked.background,
          display: 'none',
        },
        '&:hover': {
          backgroundColor: palette.checkbox.unchecked.hover,
          '& .MuiSvgIcon-root': {
            color: palette.checkbox.unchecked.background,
          },
        },
        '&.Mui-focusVisible': {
          borderWidth: '2px',
        },
        '&.Mui-checked': {
          backgroundColor: palette.checkbox.checked.background,
          borderColor: palette.checkbox.checked.border,
          '& svg,.MuiSvgIcon-root': {
            display: 'inline-block',
          },
          '&:hover': {
            backgroundColor: palette.checkbox.checked.hover,
          },
        },
      }),
    },
  },
  MuiLinearProgress: {
    styleOverrides: {
      root: ({ theme: { palette, spacing } }) => ({
        flex: 4,
        paddingTop: spacing(1),
        paddingBottom: spacing(1),
        borderRadius: spacing(1),
        marginTop: spacing(1),
        marginBottom: spacing(1),
        backgroundColor: palette.progress.track,
      }),
      bar: ({ theme: { spacing } }) => ({
        borderRadius: spacing(1),
      }),
    },
  },
  MuiTabs: {
    styleOverrides: {
      root: ({ theme: { palette, spacing, typography } }) => ({
        minHeight: 0,
        '& .MuiTab-root': {
          minHeight: 0,
        },
        '&:not(.MuiTabs-vertical)': {
          '& .MuiTabs-fixed': {
            borderBottom: `1px solid ${palette.tab.divider}`,
          },
          '& .MuiTab-root': {
            textTransform: 'none',
            ...typography.body1,
            color: palette.tab.unselected.default,
            padding: `${spacing(1)} ${spacing(2)} ${spacing(3)}`,
            minHeight: 0,
            '&:hover': {
              color: palette.tab.unselected.hover,
            },
            '&.Mui-selected': {
              color: palette.tab.selected.default,
              '&:hover': {
                color: palette.tab.selected.hover,
              },
            },
          },
        },
      }),
      indicator: ({ theme: { palette } }) => ({
        height: '4px',
        backgroundColor: palette.tab.indicator,
      }),
    },
  },
  MuiCard: {
    styleOverrides: {
      root: ({ theme: { palette, spacing } }) => ({
        minWidth: '150px',
        maxWidth: '300px',
        width: 'fit-content',
        padding: spacing(4),
        border: `1px solid ${palette.background.paper}`,
        backgroundColor: palette.background.paper,
        backgroundImage: 'none',
        boxShadow: 'none',
        borderRadius: 0,
      }),
    },
  },
  MuiCardHeader: {
    styleOverrides: {
      root: ({ theme: { palette, spacing } }) => ({
        paddingTop: 0,
        paddingLeft: 0,
        paddingRight: 0,
      }),
      header: ({ theme: { palette, typography } }) => ({
        ...typography.body2,
        color: palette.text.primary,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      }),
      subheader: ({ theme: { palette, typography } }) => ({
        ...typography.caption2,
        color: palette.text.primary,
      }),
    },
  },
  MuiCardContent: {
    styleOverrides: {
      root: ({ theme: { spacing } }) => ({
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 0,
        paddingRight: 0,
        aspectRatio: 1,
        '&:last-child': {
          paddingBottom: 0,
        },
      }),
    },
  },
  MuiTableRow: {
    styleOverrides: {
      root: ({ theme: { palette } }) => ({
        '&.Mui-selected': {
          backgroundColor: palette.table.row.selected,
        },
        '&:hover, &:hover.Mui-selected': {
          backgroundColor: palette.table.row.hover,
        },
      }),
    },
  },
  MuiTreeItem: {
    styleOverrides: {
      content: ({ theme: { palette } }) => ({
        '&:hover': {
          backgroundColor: palette.table.row.hover,
        },
        '&.Mui-selected,&.Mui-focused,&.Mui-focused.Mui-selected': {
          backgroundColor: 'transparent',
          '&:hover': {
            backgroundColor: palette.table.row.hover,
          },
        },
      }),
    },
  },
};
