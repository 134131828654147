export interface Dataset {
  channel: string;
  createdAt: string;
  createdBy: string;
  datasetId: string;
  description: string;
  files: string;
  graphId: string;
  name: string;
  runs: string;
  seed: string;
  size: string;
  status: string;
}

export interface Analytic {
  analyticsId: string;
  datasetId: string;
  status: string;
  type: string;
  workspaceId: string;
}

export interface Annotation {
  annotationId: string;
  datasetId: string;
  format: string;
  map: string;
  status: string;
  updatedAt: string;
  workspaceId: string;
}

export interface GANDataset {
  datasetId: string;
  description: string;
  model: string;
  name: string;
  status: string;
  updatedAt: string;
  workspaceId: string;
}

export interface Umap {
  name: string;
  status: string;
  umapId: string;
  updatedAt: string;
}

export const RUNNING_STATUS = ['queued', 'starting', 'running', 'postprocess'];
