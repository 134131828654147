export const KeyFieldPolicies = {
  Annotation: {
    keyFields: ['annotationId'],
  },
  AnnotationMap: {
    keyFields: ['mapId'],
  },
  Channel: {
    keyFields: ['channelId'],
  },
  Dataset: {
    keyFields: ['datasetId'],
  },
  DatasetJob: {
    keyFields: ['datasetId'],
  },
  FoundationModel: {
    keyFields: ['modelId'],
  },
  GANModel: {
    keyFields: ['modelId'],
  },
  Graph: {
    keyFields: ['graphId'],
  },
  Member: {
    keyFields: ['userId', 'email'],
  },
  MLArchitecture: {
    keyFields: ['architectureId'],
  },
  MLInference: {
    keyFields: ['inferenceId'],
  },
  MLModel: {
    keyFields: ['modelId'],
  },
  Organization: {
    keyFields: ['organizationId'],
  },
  OrganizationSetting: {
    keyFields: ['organizationId', 'setting'],
  },
  Preview: {
    keyFields: ['previewId'],
  },
  Umap: {
    keyFields: ['umapId'],
  },
  Volume: {
    keyFields: ['volumeId'],
  },
  Workspace: {
    keyFields: ['workspaceId'],
  },
};
