import { CopyIconButton } from '@components/button';
import { Checkbox } from '@components/form';
import { Typography } from '@components/typography';
import { Dataset } from '@generated/UseGraphqlHooks';
import { formatTime } from '@helper-functions/time';
import { TableCell } from '@mui/material';
import { memo } from 'react';
import { DatasetsProviderType } from './DatasetsProvider';

type DatasetTableRowProps = {
  dataset: Pick<Dataset, 'datasetId' | 'name' | 'channel' | 'runs' | 'createdAt' | 'createdBy'>;
  context?: Partial<DatasetsProviderType>;
  selected?: boolean;
  onSelect?: (datasetId: string) => void;
};

export const DatasetsTableRow = memo(
  ({
    dataset: { datasetId, name, channel, runs, createdBy, createdAt },
    selected,
    onSelect,
  }: DatasetTableRowProps) => {
    return (
      <>
        <TableCell width="30px">
          <Checkbox
            checked={selected}
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              if (onSelect) {
                onSelect(datasetId);
              }
            }}
          />
        </TableCell>
        <TableCell width="30px">
          <CopyIconButton value={datasetId} showValue />
        </TableCell>
        <TableCell width="250px">
          <Typography handleOverFlow variant="body2">
            {name}
          </Typography>
        </TableCell>
        <TableCell width="250px">
          <Typography handleOverFlow variant="body2">
            {channel}
          </Typography>
        </TableCell>
        <TableCell width="30px">
          <Typography handleOverFlow variant="body2">
            {runs}
          </Typography>
        </TableCell>
        <TableCell width="250px">
          <Typography handleOverFlow variant="body2">
            {createdBy}
          </Typography>
        </TableCell>
        <TableCell width="125px">
          <Typography variant="body2">{formatTime(createdAt, 'date') || '...'}</Typography>
        </TableCell>
      </>
    );
  },
);
