import { LoadingIcon } from '@assets/icons';
import { Group } from '@components/layout';
import { Tab, TabPanel, Tabs } from '@components/tabs';
import { Typography } from '@components/typography';
import { Organization } from '@generated/UseGraphqlHooks';
import { Stack } from '@mui/material';
import {
  OrganizationsBilling,
  OrganizationsMembers,
  OrganizationsSettings,
} from '@subsets/organizations';
import { navigate } from 'gatsby';
import { useEffect } from 'react';

interface OrganizationsTabsProps {
  selectedOrganization: Organization | undefined;
  tab: string | undefined;
  setTab: (newTab: string) => void;
}

export const OrganizationsTabs = ({
  selectedOrganization,
  tab,
  setTab,
}: OrganizationsTabsProps) => {
  const currentUserRole = selectedOrganization?.role || '';
  const isCurrentUserAdmin = currentUserRole.toLowerCase() === 'admin';
  const isCurrentUserMember = currentUserRole.toLowerCase() === 'member';
  const hasNoAccess = selectedOrganization && !isCurrentUserAdmin && !isCurrentUserMember;

  useEffect(() => {
    if ((tab === 'billing' || tab === 'applications') && isCurrentUserMember) {
      setTab('');
    }
  }, [tab, isCurrentUserMember]);

  useEffect(() => {
    if (hasNoAccess && selectedOrganization) {
      void navigate('/');
    }
  }, [hasNoAccess, selectedOrganization]);

  if (hasNoAccess) {
    return (
      <Group justifyContent="center" alignItems="center" sx={{ height: '100%' }}>
        <Typography>You do not have access.</Typography>
      </Group>
    );
  }

  if (!selectedOrganization) {
    return (
      <Group justifyContent="center" alignItems="center" sx={{ height: '100%' }}>
        <LoadingIcon size={16} />
      </Group>
    );
  }

  return (
    <Stack sx={{ flex: 1, overflow: 'auto', minHeight: '200px' }}>
      <Tabs value={tab} onChange={(_, tabValue: string) => setTab(tabValue)}>
        <Tab value="settings" label="Settings" disableRipple disableTouchRipple />
        {isCurrentUserAdmin && (
          <Tab value="billing" label="Billing" disableRipple disableTouchRipple />
        )}
        <Tab value="members" label="Members" disableRipple disableTouchRipple />
        {isCurrentUserAdmin && process.env.GATSBY_SAGEMAKER_ENABLED === 'true' && (
          <Tab value="applications" label="Applications" disableRipple disableTouchRipple />
        )}
      </Tabs>
      <Stack sx={{ flex: 1, overflow: 'auto' }}>
        {tab === 'settings' && (
          <TabPanel value={tab} index="settings" sx={{ overflow: 'hidden', height: '100%' }}>
            <OrganizationsSettings organization={selectedOrganization} />
          </TabPanel>
        )}
        {isCurrentUserAdmin && tab === 'billing' && (
          <TabPanel value={tab} index="billing" sx={{ overflow: 'hidden', height: '100%' }}>
            <OrganizationsBilling organization={selectedOrganization} />
          </TabPanel>
        )}
        {tab === 'members' && (
          <TabPanel value={tab} index="members" sx={{ overflow: 'hidden', height: '100%' }}>
            <OrganizationsMembers
              organizationId={selectedOrganization?.organizationId}
              organizationRole={selectedOrganization?.role}
            />
          </TabPanel>
        )}
      </Stack>
    </Stack>
  );
};
