import { DeckardIconProps } from '@assets/types';
import { useTheme } from '@mui/material';
import { forwardRef } from 'react';

export const GreaterThanOrEqualIcon = forwardRef<SVGSVGElement, DeckardIconProps>((props, ref) => {
  const { palette } = useTheme();
  const { color, size } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
      width={size || 24}
      height={size || 24}
      viewBox="0 0 24 24"
      fill={color || palette.icon.default}
      className="fill-override"
      {...props}
    >
      <path d="M 6.293 5.842 C 5.748 5.592 5.483 4.886 5.702 4.264 C 5.921 3.641 6.539 3.338 7.083 3.588 L 17.708 8.445 C 18.11 8.627 18.376 9.075 18.376 9.572 C 18.376 10.069 18.11 10.517 17.708 10.699 L 7.083 15.556 C 6.539 15.806 5.921 15.503 5.702 14.881 C 5.483 14.258 5.748 13.552 6.293 13.302 L 14.451 9.572 L 6.293 5.842 Z M 18.376 18.072 C 18.963 18.072 19.438 18.614 19.438 19.286 C 19.438 19.958 18.963 20.5 18.376 20.5 L 5.626 20.5 C 5.038 20.5 4.563 19.958 4.563 19.286 C 4.563 18.614 5.038 18.072 5.626 18.072 L 18.376 18.072 Z" />
    </svg>
  );
});
