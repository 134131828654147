import { TransitionPanel } from '@components/transition';
import { Typography } from '@components/typography';
import { useGetOrganizationsQuery } from '@generated/UseGraphqlHooks';
import { Container, Stack } from '@mui/material';
import { useSeo } from '@seo/SEO';
import { useEffect, useMemo, useState } from 'react';
import { OrganizationDropdown } from './OrganizationsDropdown';
import { OrganizationsTabs } from './OrganizationsTabs';

type OrganizationsPageProps = {
  organizationId?: string;
  tab?: string;
};

export const OrganizationsPage = ({ organizationId, tab }: OrganizationsPageProps) => {
  const { setTitle } = useSeo();

  const [value, setValue] = useState<string>(tab || 'settings');
  const { data: organizations, loading: organizationsLoading } = useGetOrganizationsQuery();
  const selectedOrganization = useMemo(
    () =>
      organizationId
        ? organizations?.getOrganizations?.find((org) => org.organizationId === organizationId)
        : organizations?.getOrganizations[0],
    [organizations?.getOrganizations, organizationId],
  );

  useEffect(() => {
    if (window && selectedOrganization) {
      let url = `${window.location.origin}/organizations/${selectedOrganization?.organizationId}/`;
      if (value) {
        url += `${value}/`;
      }
      if (window.location?.pathname === '/organizations/') {
        window.history.replaceState(undefined, undefined, url);
      }
    }
  }, [value, selectedOrganization]);

  useEffect(() => {
    setTitle('Organizations');
  }, [setTitle]);

  if (organizationsLoading) {
    return <TransitionPanel />;
  }

  if (!organizationsLoading && organizations?.getOrganizations?.length < 1) {
    return (
      <Stack alignItems="center" justifyContent="center" sx={{ flex: 1 }}>
        <Typography variant="h3">You have no organizations.</Typography>
      </Stack>
    );
  }

  return (
    <Container
      maxWidth="lg"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        overflow: 'auto',
      }}
    >
      <OrganizationDropdown
        organizations={organizations?.getOrganizations || []}
        selectedOrganizationId={organizationId}
      />
      <OrganizationsTabs
        selectedOrganization={selectedOrganization}
        tab={value}
        setTab={(newTab) => setValue(newTab)}
      />
    </Container>
  );
};
