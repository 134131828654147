import { PlusCircleIcon, XCircleIcon } from '@assets/icons';
import { IconButton } from '@components/button';
import { Drawer } from '@components/drawer';
import { Tab, TabPanel, Tabs } from '@components/tabs';
import { Box, Stack, useTheme } from '@mui/material';
import { useState } from 'react';
import { useToggle } from 'react-use';
import { ChannelNodeTreeView } from './ChannelNodeTreeView';
import { ChannelVolumeTreeView } from './ChannelVolumeTreeView';

type AddNodeButtonProps = {
  workspaceId: string;
};

export const AddNodeButton = (props: AddNodeButtonProps) => {
  const { palette } = useTheme();
  const [value, setValue] = useState<string>('');
  const [open, toggleOpen] = useToggle(false);

  return (
    <Drawer
      open={open}
      anchor="left"
      onClose={toggleOpen}
      sx={{
        '& .MuiDrawer-paper': {
          width: '80%',
          maxWidth: '370px',
          borderTop: `1px solid ${palette.background.default} !important`,
          paddingX: 4,
          overflow: 'visible',
          paddingTop: 6,
        },
      }}
      style={{}}
    >
      <Box
        position="absolute"
        top={16}
        left="100%"
        justifyContent="center"
        bgcolor={palette.background.paper}
        sx={{
          padding: 2,
          borderRadius: '0 4px 4px 0',
          border: `1px solid ${palette.grey[500]}`,
          borderLeft: 'none',
        }}
      >
        <IconButton
          size={28}
          data-testid="addNodeButton"
          data-cy="Add-Node-Button"
          onClick={toggleOpen}
          Icon={open ? XCircleIcon : PlusCircleIcon}
        />
      </Box>
      <Stack
        sx={{
          height: '100%',
        }}
      >
        <Tabs
          value={value}
          variant="fullWidth"
          onChange={(_, tabValue: string) => setValue(tabValue)}
        >
          <Tab value="" label="Channel" />
          <Tab value="volumes" label="Volumes" />
        </Tabs>

        <TabPanel value={value} index="" width="100%" sx={{ flex: 1, overflow: 'auto' }}>
          <ChannelNodeTreeView />
        </TabPanel>
        <TabPanel value={value} index="volumes" width="100%" sx={{ flex: 1, overflow: 'auto' }}>
          <ChannelVolumeTreeView {...props} />
        </TabPanel>
      </Stack>
    </Drawer>
  );
};
