import { Group } from '@components/layout';
import { Typography } from '@components/typography';
import { useTheme } from '@mui/material';
import { ReactNode } from 'react';

export const OrganizationsBillingPaymentInformationDataBlock = ({
  label,
  data,
}: {
  label: string;
  data: ReactNode;
}) => {
  const { palette } = useTheme();
  return (
    <Group borderBottom={`1px solid ${palette.text.primary}`} p={1}>
      <Typography sx={{ width: '50%', flex: 1 }} variant="body1">
        {label}
      </Typography>
      {data}
    </Group>
  );
};
