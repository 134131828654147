import { Skeleton, Stack } from '@mui/material';

export const EditChannelModelLoading = () => {
  return (
    <Stack gap={5}>
      <Skeleton height="54px" width="100%" />
      <Skeleton height="94px" width="100%" />
      <Skeleton height="54px" width="100%" />
      <Skeleton height="54px" width="100%" />
      <Stack gap={4}>
        <Skeleton height="35px" width="100%" />
        <Skeleton height="35px" width="100%" />
      </Stack>
    </Stack>
  );
};
