import { Button } from '@components/button';
import { useDownloadGraphMutation } from '@generated/UseGraphqlHooks';
import { saveFile } from '@helper-functions/save-file';
import { Stack } from '@mui/material';
import { useNotifications } from '@notifications/Notifications';
import yaml from 'js-yaml';
import { DateTime } from 'luxon';

interface DownloadConfigurationModalProps {
  graphId: string;
  name: string;
  workspaceId: string;
  onClose: () => void;
}

export const JobsConfigurationsPreviewOptionsDownloadModal = ({
  graphId,
  name,
  workspaceId,
  onClose,
}: DownloadConfigurationModalProps) => {
  const { addNotification } = useNotifications();
  const [downloadGraph] = useDownloadGraphMutation();

  const fileName = name || graphId || DateTime.now().toISODate();

  const handleDownloadConfigAsJSONClick = async () => {
    onClose();
    const { data: downloadGraphData } = await downloadGraph({
      variables: { graphId, workspaceId },
    });
    const data = downloadGraphData?.downloadGraph || '';
    const name = `${fileName}.json`;
    const url = window.URL.createObjectURL(new Blob([data]));
    saveFile({ url, name });
    addNotification({
      text: 'Starting download as JSON...',
      type: 'success',
      duration: 6000,
    });
  };

  const handleDownloadConfigAsYAMLClick = async () => {
    onClose();
    const { data: downloadGraphData } = await downloadGraph({
      variables: { graphId, workspaceId },
    });
    const parsedData = downloadGraphData?.downloadGraph || '';
    const data = yaml.dump(JSON.parse(parsedData ?? '{}'), { skipInvalid: true, sortKeys: true });
    const name = `${fileName}.yaml`;
    const url = window.URL.createObjectURL(new Blob([data], { type: 'text/plain' }));
    saveFile({ url, name });
    addNotification({
      text: 'Starting download as YAML...',
      type: 'success',
      duration: 6000,
    });
  };

  return (
    <Stack gap={4}>
      <Button variant="secondary" fullWidth onClick={handleDownloadConfigAsJSONClick}>
        JSON
      </Button>
      <Button variant="secondary" fullWidth onClick={handleDownloadConfigAsYAMLClick}>
        YAML
      </Button>
    </Stack>
  );
};
