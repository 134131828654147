import { CopyIconButton } from '@components/button';
import { InternalLink } from '@components/link';
import { Typography } from '@components/typography';
import { DatasetJob } from '@generated/UseGraphqlHooks';
import { formatTime } from '@helper-functions/time';
import { Grid, useTheme } from '@mui/material';

const JOB_PRIORITIES = {
  1: 'Low',
  2: 'Medium',
  3: 'High',
};

const StatRow = ({
  label1,
  value1,
  label2,
  value2,
  link2,
}: {
  label1: string;
  value1: string;
  label2?: string;
  value2?: string;
  link2?: string;
}) => {
  const { palette } = useTheme();
  return (
    <>
      <Grid
        item
        xs={3}
        sx={{ height: '28px', display: 'flex', alignItems: 'center', color: palette.text.paper }}
      >
        <Typography variant="caption2" noWrap>
          {label1}
        </Typography>
      </Grid>
      <Grid
        item
        xs={3}
        sx={{ height: '28px', display: 'flex', alignItems: 'center', color: palette.text.contrast }}
      >
        <Typography variant="caption2" noWrap>
          {value1}
        </Typography>
      </Grid>
      {label2 && (
        <Grid
          item
          xs={2}
          sx={{ height: '28px', display: 'flex', alignItems: 'center', color: palette.text.paper }}
        >
          <Typography variant="caption2" noWrap>
            {label2}
          </Typography>
        </Grid>
      )}
      {value2 && (
        <Grid
          item
          xs={4}
          sx={{ height: '28px', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}
        >
          {link2 ? (
            <InternalLink
              to={link2}
              unstyled
              sx={{
                width: '100%',
                display: 'flex',
              }}
            >
              <Typography
                handleOverFlow
                variant="caption2"
                noWrap
                pr={1}
                sx={{
                  color: palette.text.contrast,
                  textAlign: 'right',
                  textDecoration: 'underline',
                  cursor: 'pointer',
                }}
                tooltip="View graph"
              >
                {value2 || '-'}
              </Typography>
            </InternalLink>
          ) : (
            <Typography
              handleOverFlow
              variant="caption2"
              noWrap
              pr={1}
              sx={{ color: palette.text.contrast, textAlign: 'right' }}
            >
              {value2}
            </Typography>
          )}
          <CopyIconButton value={value2} />
        </Grid>
      )}
    </>
  );
};

export const JobsManagerJobPreviewStatistics = ({
  priority,
  createdAt,
  updatedAt,
  estimatedEndAt,
  channel,
  createdBy,
  channelId,
  datasetId,
  graphId,
  workspaceId,
  seed,
}: DatasetJob) => {
  return (
    <>
      <StatRow
        label1="Start time"
        value1={formatTime(createdAt, 'date') || '...'}
        label2="Workspace Id"
        value2={workspaceId || '-'}
      />
      <StatRow
        label1="Last updated"
        value1={formatTime(updatedAt, 'date') || '...'}
        label2="Dataset Id"
        value2={datasetId || '-'}
      />

      <StatRow
        label1="End time"
        value1={estimatedEndAt ? formatTime(estimatedEndAt, 'date') : '...'}
        label2="Graph Id"
        value2={graphId || '-'}
        link2={`/workspaces/${workspaceId}/graphs/${graphId}`}
      />

      <StatRow
        label1="Channel"
        value1={channel || 'unknown'}
        label2="Channel Id"
        value2={channelId || '-'}
      />

      <StatRow
        label1="Started by"
        value1={createdBy || 'unknown'}
        label2="Seed"
        value2={seed.toString() || '-'}
      />

      <StatRow label1="Priority" value1={JOB_PRIORITIES[priority] || 'unknown'} />
    </>
  );
};
