import { RefObject, useEffect, useState } from 'react';

export const useRefDimensions = (ref: RefObject<HTMLDivElement>) => {
  const [dimensions, setDimensions] = useState({ width: 1, height: 1 });

  const updateDimensions = () => {
    const { current } = ref;
    if (current?.clientHeight || current?.clientWidth)
      setDimensions({
        width: Math.round(current?.clientWidth),
        height: Math.round(current?.clientHeight),
      });
  };

  useEffect(() => {
    if (ref.current) {
      window.addEventListener('resize', updateDimensions);
      updateDimensions();
      return () => window.removeEventListener('resize', updateDimensions);
    }
    return () => {};
  }, []);

  return dimensions;
};
