import { useNotifications } from '@notifications/Notifications';
import { useState } from 'react';
import { Button, ButtonProps } from './Button';

type AsyncButtonProps = Omit<ButtonProps, 'onClick'> & {
  onClick?: () => Promise<unknown> | void;
};

export const AsyncButton = ({ onClick, ...props }: AsyncButtonProps) => {
  const [loading, setLoading] = useState(false);
  const { addNotification } = useNotifications();

  const click = async () => {
    setLoading(true);
    try {
      if (onClick) {
        await onClick();
      }
    } catch (error) {
      if (error instanceof Error) {
        addNotification({
          type: 'failure',
          text: error.message,
        });
      }
    }
    setLoading(false);
  };

  return <Button {...props} onClick={click} loading={loading} />;
};
