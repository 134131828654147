import { Typography } from '@components/typography';
import { Container, Stack, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';

export const SystemNotificationBanner = ({ temp }: { temp?: string }) => {
  const { palette } = useTheme();
  const [message, setMessage] = useState<string | null>(temp);

  useEffect(() => {
    void fetch(process.env.GATSBY_SYSTEM_API_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query: `query getSystemNotifications {
      getSystemNotifications {
          message
          notificationId
      }
    }`,
      }),
    }).then((response) => {
      void response
        .json()
        .then((data: { data: { getSystemNotifications: { message: string } } }) => {
          const newMessage = data?.data?.getSystemNotifications?.message;
          if (newMessage) {
            setMessage(newMessage);
          }
        });
    });
  }, []);

  if (message) {
    return (
      <Stack bgcolor={palette.info.main} width="100%">
        <Container maxWidth="md">
          <Stack display="flex" py={4} height="100%" alignItems="center" justifyContent="center">
            <Typography color={palette.common.white} variant="subtitle1" textAlign="center">
              {message}
            </Typography>
          </Stack>
        </Container>
      </Stack>
    );
  }

  return <div />;
};
