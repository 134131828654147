import { CameraIcon, DownloadIcon } from '@assets/icons';
import { IconButton } from '@components/button';
import { InternalLink } from '@components/link';
import { Tooltip } from '@components/tooltip';
import { Typography } from '@components/typography';
import { Dataset } from '@generated/UseGraphqlHooks';
import { formatTime } from '@helper-functions/time';
import { Box, TableCell } from '@mui/material';
import { memo } from 'react';

type WorkspacesRecentDatasetsRowProps = {
  dataset: Dataset;
  workspaceId: string;
  onDownload: (dataset: Dataset) => void;
  onPreview: (datasetId: string) => void;
};

export const WorkspacesRecentDatasetsRow = memo(
  ({ dataset, workspaceId, onDownload, onPreview }: WorkspacesRecentDatasetsRowProps) => {
    return (
      <>
        <TableCell>
          <InternalLink
            to={`/workspaces/${workspaceId}/datasets/${dataset.datasetId}`}
            unstyled
            sx={{ '&:hover': { textDecoration: 'underline' } }}
          >
            <Typography handleOverFlow>{dataset?.name}</Typography>
          </InternalLink>
        </TableCell>
        <TableCell>{formatTime(dataset?.createdAt || null, 'strToDate')}</TableCell>
        <TableCell>
          <Typography handleOverFlow>{dataset?.runs}</Typography>
        </TableCell>
        <TableCell>
          <Box display="flex">
            <Tooltip title="Download">
              <IconButton onClick={() => onDownload(dataset)} Icon={DownloadIcon} />
            </Tooltip>
            <Tooltip title="Preview">
              <IconButton onClick={() => onPreview(dataset.datasetId)} Icon={CameraIcon} />
            </Tooltip>
          </Box>
        </TableCell>
      </>
    );
  },
);
