export const formatSizeFromBytes = (size: number): string => {
  if (size < 1000) {
    return `${size} B`;
  } else if (size < 1000000) {
    return `${(size / 1000).toPrecision(3)} kB`;
  } else if (size < 1000000000) {
    return `${(size / 1000000).toPrecision(3)} MB`;
  } else if (size < 1000000000000) {
    return `${(size / 1000000000).toPrecision(3)} GB`;
  } else if (size < 1000000000000000) {
    return `${(size / 1000000000000).toPrecision(3)} TB`;
  } else return `${size.toPrecision(3)} B`;
};
