import { DownloadIcon, ExternalLinkIcon, TrashIcon } from '@assets/icons';
import { CopyIconButton, IconButton } from '@components/button';
import { Group } from '@components/layout';
import { InternalLink } from '@components/link';
import { useConfirmation } from '@components/modal';
import { Typography } from '@components/typography';
import {
  MlModel,
  useDeleteMlModelMutation,
  useDownloadMlModelMutation,
} from '@generated/UseGraphqlHooks';
import { saveFile } from '@helper-functions/save-file';
import { Grid, useTheme } from '@mui/material';
import { useNotifications } from '@notifications/Notifications';
import { DatasetsDetailsMicroserviceStatus } from '../../DatasetsDetailsMicroserviceStatus';

export const DatasetDetailsModelsListHeader = () => {
  const { palette } = useTheme();
  return (
    <Grid container alignItems="center">
      <Grid item xs={1.5}>
        <Typography color={palette.table.header} variant="caption2">
          Status
        </Typography>
      </Grid>
      <Grid item xs={1.5} sx={{ display: 'flex', justifyContent: 'center' }}>
        <Typography color={palette.table.header} variant="caption2">
          Id
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography color={palette.table.header} variant="caption2">
          Model
        </Typography>
      </Grid>
      <Grid item xs={6} />
    </Grid>
  );
};

export const DatasetDetailsModelsListItem = ({
  datasetId,
  workspaceId,
  status,
  name,
  modelId,
  refetch,
}: Partial<MlModel> & { datasetId: string; refetch: () => void }) => {
  const { useAsyncNotification, addNotification, removeNotification } = useNotifications();
  const { showConfirmation } = useConfirmation();
  const [deleteModel] = useDeleteMlModelMutation();
  const [downloadModel] = useDownloadMlModelMutation();
  const handleDelete = async () => {
    await showConfirmation({
      onAffirm: useAsyncNotification('Inference deleted', async () => {
        await deleteModel({
          variables: {
            workspaceId,
            modelId,
          },
        });
        if (refetch) {
          refetch();
        }
      }),
      message: `Are you sure you want to delete the model: ${name}?`,
    });
  };
  const handleDownload = async () => {
    const key = `${Date.now() * 1000}.${Math.random() * 10000}`;
    try {
      const { data } = await downloadModel({
        variables: {
          workspaceId,
          modelId,
        },
      });

      if (!data?.downloadMLModel) {
        throw new Error('No model found');
      }

      addNotification({ text: 'Loading...', type: 'loading', key, duration: 100000 });
      saveFile({ url: data?.downloadMLModel, name: `${name}.hdf5` });
      removeNotification(key);
      addNotification({
        text: 'Starting download...',
        type: 'success',
        duration: 6000,
      });
    } catch (e) {
      removeNotification(key);
      addNotification({
        text: 'Error downloading model',
        type: 'error',
        key: `${Date.now() * 1000}.${Math.random() * 10000}`,
        duration: 5000,
      });
    }
  };
  return (
    <Grid container alignItems="center" justifyContent="flex-start">
      <Grid item xs={1.5}>
        <Group marginLeft={1}>
          <DatasetsDetailsMicroserviceStatus
            status={status}
            microServiceName="models"
            workspaceId={workspaceId}
            datasetId={datasetId}
            microServiceId={modelId}
          />
        </Group>
      </Grid>
      <Grid item xs={1.5} sx={{ display: 'flex', justifyContent: 'center' }}>
        <CopyIconButton value={modelId} showValue tooltipProps={{ variant: 'secondary' }} />
      </Grid>
      <Grid item xs={5}>
        <Typography handleOverFlow variant="body2">
          {name}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Group justifyContent="flex-end" gap={2}>
          {status === 'success' && (
            <>
              <IconButton onClick={handleDownload} Icon={DownloadIcon} />
            </>
          )}
          <InternalLink unstyled to={`/workspaces/${workspaceId}/experiments/models/${modelId}`}>
            <IconButton Icon={ExternalLinkIcon} />
          </InternalLink>
          <IconButton onClick={handleDelete} Icon={TrashIcon} />
        </Group>
      </Grid>
    </Grid>
  );
};
