import { ArrowLeftIcon, ArrowRightIcon } from '@assets/icons';
import { Button } from '@components/button';
import { useGetAnnotationMapsQuery } from '@generated/UseGraphqlHooks';
import { Grid, Stack } from '@mui/material';
import { filter, map } from 'lodash';
import { Dispatch, useEffect, useState } from 'react';
import { ResourcesType, TransferList, intersection, not } from './TransferList';

interface AnnotationTransferListProps {
  organizationId: string;
  workspaceId: string;
  selectedAnnotations: ResourcesType;
  setSelectedAnnotations: Dispatch<ResourcesType>;
}

const getAnnotations = (
  annotations: { mapId: string; name: string }[],
  selected: string[],
  available?: boolean,
) => {
  return map(
    filter(annotations, ({ mapId }) =>
      available ? !selected.includes(mapId) : selected.includes(mapId),
    ),
    ({ mapId, name }) => ({ id: mapId, name }),
  );
};

export const AnnotationTransferList = ({
  organizationId,
  workspaceId,
  selectedAnnotations,
  setSelectedAnnotations,
}: AnnotationTransferListProps) => {
  const [checked, setChecked] = useState<ResourcesType>([]);
  const [right, setRight] = useState<ResourcesType>([]);

  const { data: availableAnnotations, loading: availableAnnotationsLoading } =
    useGetAnnotationMapsQuery({
      variables: { organizationId },
    });

  const { data: includedAnnotations, loading: includedAnnotationsLoading } =
    useGetAnnotationMapsQuery({
      variables: {
        workspaceId,
      },
    });
  const selectedAnnotationsIds = map(includedAnnotations?.getAnnotationMaps, 'mapId');

  useEffect(() => {
    if (!availableAnnotationsLoading && !includedAnnotationsLoading) {
      setRight(
        getAnnotations(availableAnnotations?.getAnnotationMaps, selectedAnnotationsIds, true),
      );
      setSelectedAnnotations(
        getAnnotations(availableAnnotations?.getAnnotationMaps, selectedAnnotationsIds, false),
      );
    }

    return () => {
      setRight([]);
      setSelectedAnnotations([]);
    };
  }, [availableAnnotationsLoading, includedAnnotationsLoading]);

  const leftChecked = intersection(checked, selectedAnnotations);
  const rightChecked = intersection(checked, right);

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setSelectedAnnotations(not(selectedAnnotations, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setSelectedAnnotations(selectedAnnotations.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };
  return (
    <Grid container spacing={2} justifyContent="center" alignItems="center">
      <Grid item xs={5}>
        <TransferList
          title="Included"
          items={selectedAnnotations}
          checked={checked}
          setChecked={setChecked}
        />
      </Grid>
      <Grid item xs={2}>
        <Grid container direction="column" alignItems="center">
          <Stack spacing="16px">
            <Button
              variant="secondary"
              onClick={handleCheckedLeft}
              data-cy="Annotations-Transfer-List-Add-Button"
              startIcon={ArrowLeftIcon}
              disabled={rightChecked.length === 0}
            >
              Add
            </Button>
            <Button
              variant="secondary"
              onClick={handleCheckedRight}
              data-cy="Annotations-Transfer-List-Remove-Button"
              disabled={leftChecked.length === 0}
              endIcon={ArrowRightIcon}
            >
              Remove
            </Button>
          </Stack>
        </Grid>
      </Grid>
      <Grid item xs={5}>
        <TransferList title="Excluded" items={right} checked={checked} setChecked={setChecked} />
      </Grid>
    </Grid>
  );
};
