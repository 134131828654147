import { AsyncButton, Button } from '@components/button';
import { Form, FormTextInput } from '@components/form';
import { Typography } from '@components/typography';
import {
  useEditManagedChannelMutation,
  useGetChannelsQuery,
  useGetManagedChannelsQuery,
} from '@generated/UseGraphqlHooks';
import { delay } from '@helper-functions/delay';
import { Stack } from '@mui/material';
import { useNotifications } from '@notifications/Notifications';
import { get } from 'lodash';
import * as yup from 'yup';
import { EditChannelModelLoading } from './EditChannelModalLoading';
import { VolumesWithAccessDropdown } from './VolumesWithAccessDropdown';

interface EditChannelModalProps {
  onClose: () => void;
  organizationId: string;
  channelId: string;
  name: string;
  description: string;
}

interface SubmitType {
  name: string;
  description: string;
  timeout: number;
  volumes: string[];
}

const validationSchema = yup.object({
  name: yup
    .string()
    .trim()
    .max(128, 'Must be less than 128 characters')
    .required('Name is required'),
  description: yup.string(),
  timeout: yup
    .number()
    .positive('Timeout must be a positive number')
    .required('Timeout is required'),
});

export const EditChannelModal = ({
  onClose,
  name: oldName,
  description: oldDescription,
  channelId,
  organizationId,
}: EditChannelModalProps) => {
  const { useAsyncNotification } = useNotifications();
  const {
    data: channelVolumesAndTimeoutData,
    loading: channelVolumesAndTimeoutDataLoading,
    refetch: channelVolumesAndTimeoutDataRefetch,
  } = useGetManagedChannelsQuery({
    variables: { channelId, organizationId },
  });

  const [editManagedChannel] = useEditManagedChannelMutation();
  const { refetch: refetchChannels } = useGetChannelsQuery({
    variables: {
      organizationId,
    },
  });

  const handleFormSubmit = useAsyncNotification(
    'Successfully updated the channel',
    async ({ name, description, volumes, timeout }: SubmitType) => {
      await editManagedChannel({
        variables: { name, channelId, description, volumes, timeout: Number(timeout) },
      });
      await delay(3000);
      await refetchChannels();
      await channelVolumesAndTimeoutDataRefetch();
      onClose();
    },
  );

  if (!channelVolumesAndTimeoutDataLoading) {
    return (
      <Form
        enableReinitialize
        initialValues={{
          name: oldName || '',
          description: oldDescription || '',
          volumes: get(channelVolumesAndTimeoutData, 'getManagedChannels.0.volumes'),
          timeout: get(channelVolumesAndTimeoutData, 'getManagedChannels.0.timeout'),
        }}
        validationSchema={validationSchema}
        validateOnBlur={false}
        onSubmit={handleFormSubmit}
      >
        {({ isValid, handleSubmit, dirty, isSubmitting }) => (
          <Stack pt={0}>
            <FormTextInput name="name" label="Name" />
            <FormTextInput name="description" label="Description" multiline rows={3} />
            <VolumesWithAccessDropdown
              name="volumes"
              label="Volumes with access"
              channelVolumesAndTimeoutDataLoading={channelVolumesAndTimeoutDataLoading}
              organizationId={organizationId}
            />
            <FormTextInput name="timeout" type="number" label="Timeout (secs)" />
            <Stack spacing={4}>
              <AsyncButton
                fullWidth
                loading={isSubmitting}
                disabled={!(isValid && dirty && !isSubmitting)}
                data-cy="Edit-Channel-Modal-Save-Button"
                onClick={handleSubmit}
              >
                Save
              </AsyncButton>
              <Button
                fullWidth
                variant="secondary"
                data-cy="Edit-Channel-Modal-Cancel-Button"
                onClick={onClose}
              >
                Cancel
              </Button>
            </Stack>
          </Stack>
        )}
      </Form>
    );
  }
  return <EditChannelModelLoading />;
};
