import { DownloadIcon, TrashIcon } from '@assets/icons';
import { CopyIconButton, IconButton } from '@components/button';
import { useConfirmation } from '@components/modal';
import { Typography } from '@components/typography';
import {
  Annotation,
  useDeleteAnnotationMutation,
  useDownloadAnnotationMutation,
} from '@generated/UseGraphqlHooks';
import { saveFile } from '@helper-functions/save-file';
import { Box, Grid } from '@mui/material';
import { useNotifications } from '@notifications/Notifications';
import { DatasetsDetailsMicroserviceStatus } from '@subsets/workspaces';

type DatasetsDetailsAnnotationsTablePreviewProps = Pick<
  Partial<Annotation>,
  'format' | 'map' | 'annotationId' | 'status'
> & {
  workspaceId: string;
  datasetId: string;
  refetch: VoidFunction;
};

export const DatasetsDetailsAnnotationsTablePreview = ({
  format,
  map,
  annotationId,
  workspaceId,
  datasetId,
  status,
  refetch,
}: DatasetsDetailsAnnotationsTablePreviewProps) => {
  const [deleteAnnotation] = useDeleteAnnotationMutation();
  const [downloadAnnotation] = useDownloadAnnotationMutation();
  const { useAsyncNotification, addNotification, removeNotification } = useNotifications();
  const { showConfirmation } = useConfirmation();

  const handleDownloadAnnotation = async () => {
    const key = `${Date.now() * 1000}.${Math.random() * 10000}`;
    try {
      addNotification({ text: 'Loading...', type: 'loading', key, duration: 100000 });
      const response = await downloadAnnotation({
        variables: { workspaceId, annotationId },
      });

      if (response?.data?.downloadAnnotation?.includes('https://')) {
        saveFile({
          url: response?.data?.downloadAnnotation,
          name: `${map || ''}.zip`,
        });
        addNotification({
          text: 'Starting download...',
          type: 'success',
          duration: 6000,
        });
      } else {
        throw new Error('Unable to download annotation.');
      }
      removeNotification(key);
    } catch (e) {
      removeNotification(key);
    }
  };

  const handleDeleteAnnotation = () =>
    showConfirmation({
      onAffirm: useAsyncNotification('Successfully deleted annotation.', async () => {
        await deleteAnnotation({
          variables: {
            annotationId,
            workspaceId,
          },
        });
        void refetch();
      }),
      message: `Are you sure you want to delete this annotation?`,
    });

  return (
    <Grid container alignItems="center" justifyContent="flex-start">
      <Grid item xs={1.5}>
        <Box display="flex" marginLeft={1}>
          <DatasetsDetailsMicroserviceStatus
            status={status}
            workspaceId={workspaceId}
            microServiceName="annotations"
            datasetId={datasetId}
            microServiceId={annotationId}
          />
        </Box>
      </Grid>
      <Grid item xs={1.5} sx={{ display: 'flex', justifyContent: 'center' }}>
        <CopyIconButton value={annotationId} showValue tooltipProps={{ variant: 'secondary' }} />
      </Grid>
      <Grid item xs={2} paddingRight={3}>
        <Typography noWrap handleOverFlow variant="body2">
          {format}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography noWrap handleOverFlow variant="body2">
          {map}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Box display="flex" justifyContent="flex-end">
          {status === 'success' && (
            <IconButton
              onClick={handleDownloadAnnotation}
              data-cy="Annotations-Download-Button"
              Icon={DownloadIcon}
            />
          )}
          <Box ml={5}>
            <IconButton
              onClick={handleDeleteAnnotation}
              data-cy="Delete-Annotation-Button"
              Icon={TrashIcon}
            />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};
