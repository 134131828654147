import { GridBox } from '@components/grid-box';
import { Image } from '@components/image';
import { Workspace } from '@generated/UseGraphqlHooks';
import { formatTime } from '@helper-functions/time';
import { navigate } from 'gatsby';

const validatePreviewThumbnail = (thumbnail: string) => {
  return thumbnail;
};

const getGridBoxDescription = (updatedAt: string, recent: boolean, organizationName: string) => {
  const editedAgo = `Edited ${formatTime(updatedAt, 'ago')}`;
  if (recent && organizationName) {
    return `In ${organizationName} • ${editedAgo}`;
  }
  return editedAgo;
};

type LandingPageWorkspacesWorkspacePreviewProps = Partial<Workspace> & {
  recent: boolean;
  expired: boolean;
};

export const LandingPageWorkspacesWorkspacePreview = ({
  name,
  status,
  recent,
  workspaceId,
  updatedAt,
  previewThumbnail,
  expired,
  organization,
}: LandingPageWorkspacesWorkspacePreviewProps) => (
  <GridBox
    sx={{ height: '100%', width: '100%' }}
    title={`${name}${status !== 'ready' ? ' (Syncing)' : ''}` || '...'}
    description={getGridBoxDescription(updatedAt, recent, organization)}
    onClick={() => navigate(`/workspaces/${workspaceId}`)}
    disabled={expired}
    content={<Image url={previewThumbnail} />}
  />
);
