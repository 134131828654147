import { QueryResult } from '@apollo/client';
import { Dispatch, useEffect, useRef, useState } from 'react';

export type GraphqlPagination<D> = {
  data: D[];
  hasMore: boolean;
  loading: boolean;
  fetchMore: () => Promise<void>;
  refetch: VoidFunction;
  setPollInterval: Dispatch<number>;
  startPolling: (interval: number, dataLength: number) => void;
  stopPolling: () => void;
};

export const useGraphqlPagination = <T, D>(
  query: QueryResult<T>,
  dataKey: keyof QueryResult<T>['data'],
  idKey: keyof D = null,
  options: { pollInterval: number } = { pollInterval: null },
): GraphqlPagination<D> => {
  const [hasMore, setHasMore] = useState(true);
  const [pollInterval, setPollInterval] = useState(options.pollInterval);
  const [initialLimit] = useState((query.variables.limit as number) || 30);
  const intervalId = useRef<NodeJS.Timer | undefined>();
  const dataArray = (query.data?.[dataKey] as D[]) || [];

  const stopPolling = () => {
    clearInterval(intervalId.current);
    intervalId.current = undefined;
  };

  const startPolling = (interval: number, dataLength: number) => {
    stopPolling();
    if (!interval || intervalId.current) {
      return;
    }
    intervalId.current = setInterval(() => {
      const count = Math.max(dataLength, initialLimit);
      void query.refetch({
        ...query.variables,
        limit: count,
      });
    }, pollInterval);
  };

  useEffect(() => {
    if (pollInterval) {
      startPolling(pollInterval, dataArray.length);
    } else {
      stopPolling();
    }
    return stopPolling;
  }, [pollInterval, dataArray.length]);

  const onFetchMore = async () => {
    if (dataArray?.length < initialLimit) {
      setHasMore(false);
      return;
    }
    stopPolling();
    const { data: fetchMoreData } = await query.fetchMore({
      variables: {
        cursor: idKey ? dataArray[dataArray.length - 1][idKey] : dataArray[dataArray.length - 1],
        limit: initialLimit,
      },
    });
    if ((fetchMoreData?.[dataKey] as []).length < query.variables.limit) {
      setHasMore(false);
    }
    startPolling(pollInterval, dataArray.length + initialLimit);
  };

  const onRefetch = async (additional = 0) => {
    stopPolling();
    await query.refetch({
      ...query.variables,
      limit: Math.max(initialLimit, dataArray.length + additional),
    });
    startPolling(pollInterval, dataArray.length + additional);
  };

  return {
    data: dataArray,
    loading: query.loading,
    fetchMore: onFetchMore,
    hasMore,
    refetch: onRefetch,
    setPollInterval,
    startPolling,
    stopPolling,
  };
};
