import { LoadingIcon } from '@assets/icons';
import { SearchAndFilter } from '@components/filter';
import { InfiniteScrollTableComponents } from '@components/infiniteScroll';
import { Typography } from '@components/typography';
import { Dataset } from '@generated/UseGraphqlHooks';
import { useFeatureFlag } from '@hooks/FeatureFlags';
import { Box, Stack, TableRow, useTheme } from '@mui/material';
import { useDatasets } from '@subsets/workspaces';
import { useCallback } from 'react';
import { TableVirtuoso } from 'react-virtuoso';
import { DatasetsTableHeader } from './DatasetsTableHeader';
import { DatasetsTableRow } from './DatasetsTableRow';

export const DatasetsTable = () => {
  const {
    datasets,
    filter,
    loading,
    pagination,
    searchDetail,
    selectedDataset,
    selectedDatasets,
    setFilter,
    setSelectedDataset,
    setSelectedDatasets,
  } = useDatasets();

  const { palette } = useTheme();

  const filterFlag = useFeatureFlag('feature_tagging-search-and-filter');

  const handleTableRowSelection = (id: string) => {
    setSelectedDataset(id);
  };

  const tableRow = (props: { item: Dataset }) => (
    <TableRow
      {...props}
      onClick={() => {
        handleTableRowSelection(props.item.datasetId);
      }}
      sx={{
        backgroundColor: props.item.datasetId === selectedDataset && palette.table.row.selected,
      }}
    />
  );

  const handleDatasetSelect = useCallback(
    (newDatasetId) => {
      if (selectedDatasets.includes(newDatasetId)) {
        setSelectedDatasets(selectedDatasets.filter((id) => id !== newDatasetId));
      } else {
        setSelectedDatasets([...selectedDatasets, newDatasetId]);
      }
    },
    [setSelectedDatasets, selectedDatasets],
  );

  const item = (_index, dataset: Dataset) => (
    <DatasetsTableRow
      dataset={dataset}
      selected={selectedDatasets.includes(dataset?.datasetId)}
      onSelect={handleDatasetSelect}
    />
  );

  return (
    <Stack bgcolor={palette.background.paper} height="100%" p={4} pb={0} gap={4}>
      {filterFlag && (
        <SearchAndFilter
          filter={filter}
          searchDetail={searchDetail}
          setFilter={(newFilter) => {
            pagination.stopPolling();
            setFilter(newFilter);
          }}
          variant="secondary"
        />
      )}
      <Stack flex={1}>
        {loading ? (
          <Stack
            bgcolor={palette.background.paper}
            height="100%"
            justifyContent="center"
            alignItems="center"
          >
            <LoadingIcon size={16} />
          </Stack>
        ) : datasets.length === 0 ? (
          <Stack
            bgcolor={palette.background.paper}
            height="100%"
            justifyContent="center"
            alignItems="center"
          >
            <Typography color={palette.text.paper}>No datasets exist in this workspace.</Typography>
          </Stack>
        ) : (
          <Box data-test-id="virtuoso-item-list" sx={{ flex: 1, height: 0 }}>
            <TableVirtuoso
              data={datasets}
              endReached={pagination.fetchMore}
              components={{
                ...(InfiniteScrollTableComponents as any),
                TableRow: tableRow,
              }}
              fixedHeaderContent={DatasetsTableHeader}
              itemContent={item}
              increaseViewportBy={400}
            />
          </Box>
        )}
      </Stack>
    </Stack>
  );
};
