import { AsyncButton, Button } from '@components/button';
import { Form, FormSelect, FormTextInput } from '@components/form';
import {
  ContentCode,
  useCreateWorkspaceMutation,
  useGetOrganizationsQuery,
} from '@generated/UseGraphqlHooks';
import { MenuItem, Stack } from '@mui/material';
import { useNotifications } from '@notifications/Notifications';
import { navigate } from 'gatsby';
import * as yup from 'yup';

const validationSchema = yup.object({
  workspaceName: yup
    .string()
    .trim()
    .max(128, 'Must be less than 128 characters')
    .required('Name is required'),
  organizationId: yup.string().trim().required('Organization is required'),
});

interface CreateContentCodeWorkspaceModalProps {
  contentCode: ContentCode;
  onClose: () => void;
}
type CreateWorkspaceFormType = {
  workspaceName: string;
  organizationId: string;
};

export const CreateContentCodeWorkspaceModal = ({
  contentCode,
  onClose,
}: CreateContentCodeWorkspaceModalProps) => {
  const { data } = useGetOrganizationsQuery();
  const { useAsyncNotification } = useNotifications();

  const [createWorkspaceMutation] = useCreateWorkspaceMutation();

  const organizations = data?.getOrganizations.filter((org) => !org.expired) || [];

  const createWorkspace = useAsyncNotification(
    'New workspace was created successfully',
    async ({ workspaceName, organizationId }: CreateWorkspaceFormType) => {
      const { data: createData } = await createWorkspaceMutation({
        variables: {
          organizationId,
          name: workspaceName,
          code: contentCode.code,
          channelIds: [],
          volumeIds: [],
        },
        update: (cache) => {
          cache.evict({ id: 'ROOT_QUERY', fieldName: 'getChannels', args: { organizationId } });
        },
      });

      if (createData?.createWorkspace) {
        await navigate(`/workspaces/${createData?.createWorkspace}`);
      }
      onClose();
    },
  );

  const handleFormSubmit = async ({ organizationId, workspaceName }: CreateWorkspaceFormType) => {
    await createWorkspace({ workspaceName, organizationId });
  };

  return (
    <Form
      initialValues={{ workspaceName: contentCode.name, organizationId: '' }}
      validateOnBlur={false}
      validationSchema={validationSchema}
      onSubmit={handleFormSubmit}
    >
      {({ handleSubmit, isSubmitting }) => (
        <Stack>
          <FormSelect name="organizationId" label="Organization">
            {organizations.map((org) => (
              <MenuItem key={org.organizationId} value={org.organizationId}>
                {org.name}
              </MenuItem>
            ))}
          </FormSelect>
          <FormTextInput name="workspaceName" label="Workspace name" />
          <Stack gap={4}>
            <AsyncButton
              fullWidth
              loading={isSubmitting}
              disabled={isSubmitting}
              onClick={handleSubmit}
              data-cy="New-Workspace-Modal-Create-Button"
            >
              Create
            </AsyncButton>
            <Button
              fullWidth
              variant="secondary"
              data-cy="New-Workspace-Modal-Cancel-Button"
              onClick={onClose}
            >
              Cancel
            </Button>
          </Stack>
        </Stack>
      )}
    </Form>
  );
};
