import { TreeItem as MUITreeItem, TreeItemProps } from '@mui/x-tree-view';

export const TreeItem = (props: TreeItemProps) => {
  return (
    <MUITreeItem
      sx={{
        '&.MuiTreeItem-root': { backgroundColor: 'transparent' },
        '& .MuiTreeItem-selected': { backgroundColor: 'transparent' },
        '& .MuiTreeItem-group': {
          marginLeft: 0,
          paddingLeft: 0,
        },
        '& .MuiTreeItem-content': {
          backgroundColor: 'transparent',
        },
        '& .MuiTreeItem-iconContainer': {
          '& .close': {
            opacity: 0.3,
          },
        },
      }}
      {...props}
    />
  );
};
