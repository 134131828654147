import { LoadingIcon } from '@assets/icons';
import { useGraphqlPagination } from '@components/infiniteScroll';
import { Group } from '@components/layout';
import { Typography } from '@components/typography';
import { GetStagedGraphsQuery, Graph, useGetStagedGraphsQuery } from '@generated/UseGraphqlHooks';
import { Box, Stack, useTheme } from '@mui/material';
import { JobsConfigurationsPreviews } from '@subsets/workspaces';

interface JobsStagedJobsProps {
  workspaceId: string;
  refetch: (additional: number) => void;
}

export const JobsStagedJobs = ({ workspaceId, refetch: refetchJobs }: JobsStagedJobsProps) => {
  const { palette } = useTheme();
  const {
    data: graphs,
    loading,
    fetchMore,
    refetch,
  } = useGraphqlPagination<GetStagedGraphsQuery, Graph>(
    useGetStagedGraphsQuery({
      variables: { workspaceId, staged: true, limit: 30 },
    }),
    'getGraphs',
    'graphId',
  );

  return (
    <Stack height="100%" gap={10}>
      <Typography variant="h3" color={palette.text.paper}>
        Staged graphs
      </Typography>
      {loading ? (
        <Group height="100%" alignItems="center" justifyContent="center">
          <LoadingIcon size={16} />
        </Group>
      ) : graphs.length > 0 ? (
        <JobsConfigurationsPreviews
          graphs={graphs}
          refetch={refetch}
          fetchMore={fetchMore}
          refetchJobs={refetchJobs}
        />
      ) : (
        <Group height="100%" alignItems="center" justifyContent="center">
          <Typography>No staged graphs found.</Typography>
        </Group>
      )}
    </Stack>
  );
};
