import { useTheme } from '@mui/material';
import { useNotifications } from '@notifications/Notifications';
import { CanvasContext } from '@subsets/workspaces';
import { select } from 'd3';
import { useObservableState } from 'observable-hooks';
import { useContext, useEffect, useRef } from 'react';

export const Canvas = (props) => {
  const { palette } = useTheme();
  const ref = useRef<SVGSVGElement>(null);
  const { setSvg, isEditingMode$ } = useContext(CanvasContext);
  const { addNotification } = useNotifications();
  const isEditing = useObservableState(isEditingMode$);

  useEffect(() => {
    setSvg(select(ref.current));
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

    if (isSafari) {
      addNotification({
        type: 'info',
        text: 'Please use Chrome or Firefox for best performance. Using Safari may cause some technical issues.',
        duration: 1000000,
      });
    }
  }, [ref]);

  const handleTheEditElements = () => {
    if (!isEditing) {
      ref.current.focus();
    }
  };

  return (
    <svg
      ref={ref}
      width="100%"
      height="100%"
      onMouseOver={handleTheEditElements}
      data-testid="canvas"
      // eslint-disable-next-line jsx-a11y/tabindex-no-positive
      tabIndex={1}
      style={{
        backgroundColor: palette.background.default,
        outline: 'none',
        msUserSelect: 'none',
        MozUserSelect: 'none',
        WebkitUserSelect: 'none',
        userSelect: 'none',
      }}
      {...props}
    />
  );
};
