import { Button } from '@components/button';
import { ExternalLink } from '@components/link';
import { Typography } from '@components/typography';
import { DatasetJob } from '@generated/UseGraphqlHooks';
import { Grid, useTheme } from '@mui/material';

export const JobsManagerJobPreviewLogs = ({ workspaceId, datasetId }: DatasetJob) => {
  const { palette } = useTheme();
  return (
    <Grid container alignItems="center">
      <Grid item xs={3}>
        <Typography variant="caption2" color={palette.text.paper}>
          Logs
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <ExternalLink unstyled href={`/workspaces/${workspaceId}/jobs/${datasetId}`}>
          <Button size="small" variant="secondary">
            View Logs
          </Button>
        </ExternalLink>
      </Grid>
    </Grid>
  );
};
