import { Button } from '@components/button';
import { Form, FormSelect } from '@components/form';
import {
  useEditMemberByRoleMutation,
  useGetMembersQuery,
  useGetOrganizationsQuery,
} from '@generated/UseGraphqlHooks';
import { MenuItem, Stack } from '@mui/material';
import { useNotifications } from '@notifications/Notifications';
import * as Yup from 'yup';

const validationSchema = Yup.object({
  role: Yup.string().required('Required'),
});

type OrganizationsMemberEditMemberRoleModalProps = {
  onClose?: () => void;
  organizationId?: string;
  email?: string;
  role?: string;
};

export const OrganizationsMemberEditMemberRoleModal = ({
  onClose,
  organizationId,
  email,
  role: defaultRole,
}: OrganizationsMemberEditMemberRoleModalProps) => {
  const { useAsyncNotification } = useNotifications();
  const [editMemberByRoleMutation] = useEditMemberByRoleMutation();

  const { refetch: selectedOrganizationRefetch } = useGetOrganizationsQuery();
  const { refetch: organizationMembersRefetch } = useGetMembersQuery({
    variables: { organizationId },
  });

  const handleEditSubmit = useAsyncNotification(
    'Member has been edited',
    async ({ role }: { role: string }) => {
      onClose();
      await editMemberByRoleMutation({
        variables: {
          organizationId,
          email,
          role,
        },
      });
      await selectedOrganizationRefetch();
      await organizationMembersRefetch();
    },
  );

  return (
    <Form
      initialValues={{ role: defaultRole }}
      validationSchema={validationSchema}
      onSubmit={handleEditSubmit}
    >
      {() => (
        <Stack>
          <FormSelect name="role" label="Role">
            <MenuItem value="admin">Admin</MenuItem>
            <MenuItem value="member">Member</MenuItem>
          </FormSelect>
          <Stack gap={4}>
            <Button
              fullWidth
              type="submit"
              data-cy="Organizations-Members-Edit-Member-Role-Modal-Save-Button"
            >
              Save
            </Button>
            <Button
              fullWidth
              variant="secondary"
              data-cy="Organizations-Members-Edit-Member-Role-Modal-Cancel-Button"
              onClick={onClose}
            >
              Cancel
            </Button>
          </Stack>
        </Stack>
      )}
    </Form>
  );
};
