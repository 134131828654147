import { Graph } from '@generated/UseGraphqlHooks';
import { Box, Stack } from '@mui/material';
import { JobsConfigurationsPreview } from '@subsets/workspaces';
import { useCallback } from 'react';
import { Virtuoso } from 'react-virtuoso';

type JobsConfigurationsPreviewsProps = {
  graphs: Graph[];
  fetchMore: VoidFunction;
  refetch: VoidFunction;
  refetchJobs: (additional: number) => void;
};

export const JobsConfigurationsPreviews = ({
  graphs,
  fetchMore,
  refetch,
  refetchJobs,
}: JobsConfigurationsPreviewsProps) => {
  const item = useCallback(
    (_index, graph: Graph) => (
      <Box sx={{ marginBottom: 2 }}>
        <JobsConfigurationsPreview graph={graph} refetch={refetch} refetchJobs={refetchJobs} />
      </Box>
    ),
    [refetch],
  );

  return (
    <Stack
      data-test-id="virtuoso-item-list"
      sx={{
        overflowY: 'auto',
        overflowX: 'hidden',
        flex: 1,
      }}
    >
      <Virtuoso
        totalCount={graphs.length}
        overscan={1000}
        endReached={fetchMore}
        data={graphs}
        itemContent={item}
      />
    </Stack>
  );
};
