import { LoadingIcon } from '@assets/icons';
import { useModal } from '@components/modal';
import { useGetAnnotationsQuery } from '@generated/UseGraphqlHooks';
import {
  DatasetsDetailsAnnotationsCreateAnnotation,
  DatasetsDetailsAnnotationsCreateAnnotationHelpContent,
  DatasetsDetailsAnnotationsTable,
} from '@subsets/workspaces';
import posthog from 'posthog-js';
import { useEffect } from 'react';
import { DatasetDetailsAccordion } from './DatasetDetailsAccordion';
import { RUNNING_STATUS } from './types';

type DatasetsDetailsAnnotationsProps = {
  workspaceId: string;
  datasetId: string;
};

export const DatasetsDetailsAnnotations = ({
  datasetId,
  workspaceId,
}: DatasetsDetailsAnnotationsProps) => {
  const {
    data: getAnnotationsData,
    loading: annotationsLoading,
    refetch: refetchAnnotations,
    startPolling,
    stopPolling,
  } = useGetAnnotationsQuery({ variables: { datasetId, workspaceId } });

  const annotations = getAnnotationsData?.getAnnotations || [];

  useEffect(() => {
    if (annotations.length === 0) {
      return;
    }
    if (annotations.some((annotation) => RUNNING_STATUS.includes(annotation.status))) {
      startPolling(5000);
    } else {
      stopPolling();
    }
  }, [annotations]);

  const { showModal } = useModal({
    component: DatasetsDetailsAnnotationsCreateAnnotation,
    componentProps: { datasetId, workspaceId, refetch: refetchAnnotations },
    modalProps: {
      title: 'Create Annotation',
      help: DatasetsDetailsAnnotationsCreateAnnotationHelpContent,
    },
  });

  return (
    <DatasetDetailsAccordion
      title="Annotations"
      details={
        annotationsLoading ? (
          <LoadingIcon size={8} />
        ) : (
          <DatasetsDetailsAnnotationsTable
            annotations={annotations}
            datasetId={datasetId}
            workspaceId={workspaceId}
            refetch={refetchAnnotations}
          />
        )
      }
      count={!annotationsLoading ? annotations.length : null}
      onCreate={() => {
        posthog.capture('user_create-annotation_intent', { source: 'dataset' });
        showModal();
      }}
    />
  );
};
