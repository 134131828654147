import moment from 'moment';

export const formatTime = (
  time: string | Date,
  type?: 'date' | 'ago' | 'seconds' | 'strToDate',
) => {
  switch (type) {
    case 'date':
      return `${moment(time).format('L')} ${moment(time).format('LT')}`;
    case 'ago':
      return moment(time).fromNow();
    case 'seconds': {
      const duration = moment.utc(moment.duration(time as string, 'seconds').asMilliseconds());
      if (duration.hours() > 0) {
        return `${duration.format('h')}h ${duration.format('m')}m ${duration.format('s')}s`;
      }
      return `${duration.format('m')}m ${duration.format('s')}s`;
    }
    case 'strToDate': {
      const date = new Date(time);
      return moment(date).format('L');
    }
    default:
      return moment(time).subtract(10, 'days').calendar();
  }
};
