import { DraggableNode, LinkBetweenPorts, LinkBetweenPortsProps } from '@components/graph';
import { Typography } from '@components/typography';
import { Box } from '@mui/system';
import { map, orderBy } from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { CanvasContext } from './CanvasProvider';
import { GraphDataContext } from './GraphDataProvider';
import { ZoomContext } from './ZoomProvider';
import { Node } from './types';

export const GraphElements = () => {
  const [centered, setCentered] = useState(false);
  const { nodes, links, ready, graphMetadata } = useContext(GraphDataContext);
  const sortedNodesByAsc = orderBy(nodes, ({ createdAt }: { createdAt: Date }) => createdAt, [
    'asc',
  ]) as Node[];
  const { centerGraphElements } = useContext(ZoomContext);

  const { svg } = useContext(CanvasContext);
  const { width: svgWidth, height: svgHeight } = svg?.node().getBoundingClientRect() || {
    width: 0,
    height: 0,
  };

  useEffect(() => {
    if (centered) {
      return;
    }
    if (ready) {
      centerGraphElements();
      setCentered(true);
    }
  }, [nodes, links, ready]);

  const disabled = graphMetadata?.readOnly;

  if (ready) {
    return (
      <>
        {links &&
          map(links, ({ id, ...link }: LinkBetweenPortsProps) => (
            <LinkBetweenPorts key={id} id={id} disabled={disabled} {...link} />
          ))}
        {sortedNodesByAsc &&
          sortedNodesByAsc.map((node, index) => (
            <DraggableNode key={node.name} index={index} disabled={disabled} {...node} />
          ))}
      </>
    );
  }

  return (
    <foreignObject
      height="100"
      width="100"
      transform={`translate(${0 + svgWidth / 2}, ${0 + svgHeight / 2})`}
    >
      <Box height={100} width={100}>
        <Typography data-cy="Graph-Elements-Loading">Loading...</Typography>
      </Box>
    </foreignObject>
  );
};
