import { MoreVerticalIcon } from '@assets/icons';
import { IconButton } from '@components/button';
import { useModal } from '@components/modal';
import { Typography } from '@components/typography';
import { Box, Menu, MenuItem, useTheme } from '@mui/material';
import {
  JobsConfigurationsOptionsRenameModal,
  JobsConfigurationsPreviewOptionsDownloadModal,
} from '@subsets/workspaces';
import { MouseEvent, useState } from 'react';

interface JobsConfigurationsPreviewOptionsDropdownProps {
  graphId: string;
  graphName: string;
  workspaceId: string;
  deleteHandle: () => void;
  refetch: () => void;
}

export const JobsConfigurationsPreviewOptionsDropdown = ({
  graphId,
  graphName,
  deleteHandle,
  workspaceId,
  refetch,
}: JobsConfigurationsPreviewOptionsDropdownProps) => {
  const [anchorEl, setAnchorEl] = useState<Element | (() => Element)>(null);
  const open = Boolean(anchorEl);

  const handleClose = () => setAnchorEl(null);
  const handleClick = (event: MouseEvent) => setAnchorEl(event.currentTarget);

  const { showModal: showRenameModal } = useModal({
    component: JobsConfigurationsOptionsRenameModal,
    componentProps: { graphId, graphName, workspaceId, refetch },
    modalProps: { title: 'Rename graph' },
  });

  const handleRenameOpen = () => {
    showRenameModal();
    handleClose();
  };

  const { showModal: showDownloadModal } = useModal({
    component: JobsConfigurationsPreviewOptionsDownloadModal,
    componentProps: { graphId, name: graphName, workspaceId },
    modalProps: { title: `Download ${graphName}` },
  });

  const handleDownloadOpen = () => {
    showDownloadModal();
    handleClose();
  };
  const handleDeleteOpen = () => {
    deleteHandle();
    handleClose();
  };

  return (
    <Box>
      <IconButton onClick={handleClick} Icon={MoreVerticalIcon} size={22} />
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        <MenuItem onClick={handleRenameOpen}>
          <Typography variant="body2">Rename</Typography>
        </MenuItem>
        <MenuItem onClick={handleDownloadOpen}>
          <Typography variant="body2">Download</Typography>
        </MenuItem>
        <MenuItem onClick={handleDeleteOpen}>
          <Typography variant="body2">Delete</Typography>
        </MenuItem>
      </Menu>
    </Box>
  );
};
