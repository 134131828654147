import { DeckardIconProps } from '@assets/types';
import { useTheme } from '@mui/material';
import { forwardRef } from 'react';

export const LessThanOrEqualIcon = forwardRef<SVGSVGElement, DeckardIconProps>((props, ref) => {
  const { palette } = useTheme();
  const { color, size } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
      width={size || 24}
      height={size || 24}
      viewBox="0 0 24 24"
      fill={color || palette.icon.default}
      className="fill-override"
      {...props}
    >
      <path d="M 17.708 5.842 C 18.253 5.592 18.518 4.886 18.299 4.264 C 18.08 3.641 17.462 3.338 16.918 3.588 L 6.293 8.445 C 5.891 8.627 5.625 9.075 5.625 9.572 C 5.625 10.069 5.891 10.517 6.293 10.699 L 16.918 15.556 C 17.462 15.806 18.08 15.503 18.299 14.881 C 18.518 14.258 18.253 13.552 17.708 13.302 L 9.55 9.572 L 17.708 5.842 Z M 5.625 18.072 C 5.038 18.072 4.563 18.614 4.563 19.286 C 4.563 19.958 5.038 20.5 5.625 20.5 L 18.375 20.5 C 18.963 20.5 19.438 19.958 19.438 19.286 C 19.438 18.614 18.963 18.072 18.375 18.072 L 5.625 18.072 Z" />
    </svg>
  );
});
