import { FormRadio, Slider, TextInput } from '@components/form';
import { Group } from '@components/layout';
import { Typography } from '@components/typography';
import { Grid, useTheme } from '@mui/material';
import { useField } from 'formik';
import { useDataset } from './DatasetsProvider';

type DatasetsCompareModalDatasetProps = {
  datasetId: string;
  fileCount: number;
};

export const DatasetsCompareModalDataset = ({
  datasetId,
  fileCount,
}: DatasetsCompareModalDatasetProps) => {
  const { palette } = useTheme();
  const { name, files } = useDataset(datasetId);
  const [field, , helpers] = useField('samples');

  const updateValue = (newValue: number) => {
    helpers.setValue({
      ...field.value,
      [datasetId]: Math.max(5, Math.min(newValue, Math.min(1000, fileCount))),
    });
  };

  return (
    <Grid container sx={{ height: '44px' }} alignItems="center" justifyContent="space-between">
      <Grid item xs={3}>
        <Typography variant="body2" handleOverFlow noWrap>
          {name || ''}
        </Typography>
      </Grid>
      <Grid item xs={1} />
      {files && files < 5 ? (
        <Grid item xs={6}>
          <Typography variant="body2" color={palette.error.main}>
            Dataset must have at least 5 images
          </Typography>
        </Grid>
      ) : (
        <>
          <Grid item xs={4} display="flex">
            <Slider
              min={5}
              max={Math.min(files, 1000)}
              value={field.value[datasetId]}
              onChange={(_, newValue) => updateValue(Number(newValue))}
            />
          </Grid>

          <Grid item xs={2}>
            <Group alignItems="center" height="100%">
              <TextInput
                size="small"
                type="number"
                min={5}
                max={Math.min(files, 1000)}
                sx={{
                  width: '62px',
                  padding: '0px',
                  '& .MuiInputBase-input': { textAlign: 'center', padding: 0 },
                }}
                containerProps={{ sx: { width: 'fit-content' } }}
                value={field.value[datasetId]}
                onChange={(event) => updateValue(Number(event.target.value))}
              />
              <Typography variant="body2" width="45px" textAlign="center">
                / {files}
              </Typography>
            </Group>
          </Grid>
        </>
      )}

      <Grid item xs={1} sx={{ display: 'flex', justifyContent: 'center' }}>
        <FormRadio name="fitDataset" value={datasetId} />
      </Grid>
    </Grid>
  );
};
