import { CopyIcon, TrashIcon } from '@assets/icons';
import { IconButton } from '@components/button';
import { useConfirmation } from '@components/modal';
import { Tooltip } from '@components/tooltip';
import { Box, Grid, Popper, useTheme } from '@mui/material';
import { useNotifications } from '@notifications/Notifications';
import { GraphDataContext, SelectionContext } from '@subsets/workspaces';
import { useContext } from 'react';

export interface NodeActionControllerProps {
  visible: boolean;
  anchorEl: HTMLElement;
  nodeId: string;
  disabled?: boolean;
}

export const NodeActionController = ({
  visible,
  anchorEl,
  nodeId,
  disabled,
}: NodeActionControllerProps) => {
  const { palette } = useTheme();
  const { duplicateNodes, removeSelectedNode } = useContext(GraphDataContext);
  const { selectedNodeList } = useContext(SelectionContext);
  const { useAsyncNotification } = useNotifications();
  const { showConfirmation } = useConfirmation();

  const onDuplicateNode = () => duplicateNodes(Array.from(selectedNodeList.getValue()));
  const onRemoveNode = () => {
    showConfirmation({
      onAffirm: useAsyncNotification('Deleted', async () => {
        removeSelectedNode(nodeId);
        await Promise.resolve();
      }),
      message: 'Are you sure you want to delete the selected node(s)?',
    });
  };

  if (disabled) {
    return <div />;
  }

  return (
    <Popper open={visible} anchorEl={anchorEl} placement="top">
      <Box
        bgcolor={palette.background.paper}
        border={1}
        borderColor={palette.icon.default}
        borderRadius="4px"
        sx={{
          width: 'fit-content',
          margin: '0 auto',
          py: 1,
          px: 2,
        }}
      >
        <Grid container item alignItems="center" gap={1}>
          <Tooltip placement="top" title="Duplicate node" arrow>
            <Grid item>
              <IconButton
                data-testid="duplicate"
                onClick={onDuplicateNode}
                Icon={CopyIcon}
                size={16}
              />
            </Grid>
          </Tooltip>
          <Tooltip placement="top" title="Delete node" arrow>
            <Grid item>
              <IconButton data-testid="delete" onClick={onRemoveNode} Icon={TrashIcon} size={16} />
            </Grid>
          </Tooltip>
        </Grid>
      </Box>
    </Popper>
  );
};

export default NodeActionController;
