import { Tabs as MuiTabs, TabsProps as MuiTabsProps } from '@mui/material';
import { SyntheticEvent } from 'react';

type TabsProps = Omit<MuiTabsProps, 'value'> & {
  value?: number | string;
  onChange?: (event: SyntheticEvent<Element, Event>, value: unknown) => void;
};

export const Tabs = ({ value, variant = 'standard', onChange, ...rest }: TabsProps) => {
  return <MuiTabs variant={variant} value={value} onChange={onChange} {...rest} />;
};
