import { PlusIcon } from '@assets/icons';
import { Button } from '@components/button';
import { useModal } from '@components/modal';
import { Typography } from '@components/typography';
import { Stack } from '@mui/material';
import posthog from 'posthog-js';
import { NewVolumeModal, NewVolumeModalHelpContent } from './NewVolumeModal';

type VolumeHeaderProps = {
  name: string;
  usage: number;
  limit: string;
  organizationId: string;
};

export const VolumeHeader = ({ name, organizationId, usage, limit }: VolumeHeaderProps) => {
  const { showModal: showNewVolumeModal } = useModal({
    component: NewVolumeModal,
    componentProps: { organizationId },
    modalProps: { title: 'New Volume', help: NewVolumeModalHelpContent },
  });

  const isVolumesReachedLimit = Number(usage) === Number(limit);

  return (
    <Stack spacing="2px">
      <Typography variant="caption2">{name}</Typography>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h2">Volumes</Typography>
        <Button
          disabled={isVolumesReachedLimit}
          startIcon={PlusIcon}
          onClick={() => {
            posthog.capture('user_create-volume_intent', { location: 'organization-landing' });
            showNewVolumeModal();
          }}
        >
          New volume
        </Button>
      </Stack>
    </Stack>
  );
};
