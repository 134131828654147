import { Typography } from '@components/typography';
import {
  GetPreviewLogQueryResult,
  Preview,
  useGetPreviewLogLazyQuery,
} from '@generated/UseGraphqlHooks';
import { Container, Stack, useTheme } from '@mui/material';
import { useCurrentWorkspace } from '@navigation/WorkspaceContext';
import { DateTime } from 'luxon';
import { useCallback, useEffect, useRef, useState } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { VariableSizeList } from 'react-window';

type PreviewLogEntry = {
  message: string;
  timestamp: string;
  ingestionTime: string;
};

type PreviewLogsRowProps = {
  index: number;
  data: PreviewLogEntry[];
  setRowSize: (index: number, height: number) => void;
};

export const PreviewLogRow = ({ data, index, setRowSize }: PreviewLogsRowProps) => {
  const rowRef = useRef<HTMLDivElement>();
  const log = data[index];

  useEffect(() => {
    if (rowRef) {
      setRowSize(index, rowRef.current.getBoundingClientRect().height);
    }
  }, [setRowSize, index, rowRef]);

  return (
    <Container maxWidth="lg">
      <Stack ref={rowRef} pb={2}>
        <Typography>
          {DateTime.fromMillis(parseInt(log.timestamp, 10)).toLocaleString(
            DateTime.DATETIME_SHORT_WITH_SECONDS,
          )}
        </Typography>
        <Typography variant="body2">{log.message}</Typography>
      </Stack>
    </Container>
  );
};

export const GraphPreviewLogs = ({ preview }: { preview: Partial<Preview> }) => {
  const { palette } = useTheme();
  const {
    workspace: { workspaceId },
  } = useCurrentWorkspace();
  const listRef = useRef<VariableSizeList>();
  const sizeMap = useRef({});
  const [logs, setLogs] = useState<PreviewLogEntry[]>([]);
  const [logsLoading, setLogsLoading] = useState(true);
  const [getPreviewLogs] = useGetPreviewLogLazyQuery();

  useEffect(() => {
    if (!['failed', 'success'].includes(preview?.status)) {
      return;
    }
    setLogsLoading(true);
    getPreviewLogs({ variables: { workspaceId, previewId: preview?.previewId } })
      .then((result: GetPreviewLogQueryResult) => {
        if (result?.data?.getPreviewLog?.log) {
          setLogs((JSON.parse(result.data.getPreviewLog.log) as PreviewLogEntry[]) || []);
          setLogsLoading(false);
        }
      })
      .catch((e) => {
        console.error(e);
        setLogsLoading(false);
      });
  }, [preview]);
  const setRowSize = useCallback((index: number, rowSize: number) => {
    sizeMap.current = { ...sizeMap.current, [index]: rowSize };
    listRef.current.resetAfterIndex(index);
  }, []);

  const getRowSize = useCallback(
    (index: number): number => {
      return (sizeMap.current[index] as number) || 20;
    },
    [sizeMap],
  );

  if (!logsLoading && (!logs || logs.length === 0)) {
    return (
      <Stack height="100%" width="100%" justifyContent="center" alignItems="center">
        <Typography>No logs found</Typography>
      </Stack>
    );
  }
  return (
    <AutoSizer>
      {({ height, width }) => (
        <VariableSizeList
          ref={listRef}
          height={height - 34}
          width={width as number}
          itemCount={logs.length}
          itemSize={getRowSize}
          itemData={logs}
          style={{ border: `1px solid ${palette.grey[700]}` }}
        >
          {({ index, style }) => (
            <div style={style}>
              <PreviewLogRow index={index} data={logs} setRowSize={setRowSize} />
            </div>
          )}
        </VariableSizeList>
      )}
    </AutoSizer>
  );
};
