import { hexToRGBA } from '@helper-functions/color';
import { ImageAnnotations } from './DatasetsDetailsImagesSliderTypes';

interface DatasetsDetailsImagesSliderSegmentationOverlayProps {
  color: string;
  data: ImageAnnotations[];
  strokeWidth: number;
  opacity: number;
  fill: boolean;
}

export const DatasetsDetailsImagesSliderSegmentationOverlay = ({
  color,
  data,
  strokeWidth,
  opacity,
  fill,
}: DatasetsDetailsImagesSliderSegmentationOverlayProps) => {
  return (
    <>
      {data?.map(({ segmentation }, objIdx) => {
        if (!segmentation) {
          return null;
        }
        return segmentation.map((seg, segIdx) => {
          return (
            <g key={`${objIdx}-${segIdx}`}>
              <polygon
                points={seg
                  .map((e, i) => {
                    if (i % 2 === 0 && i < seg.length - 1) {
                      return `${e},${seg[i + 1]}`;
                    }
                    return '';
                  })
                  .join(' ')}
                strokeWidth={strokeWidth}
                stroke={color}
                fill={fill ? hexToRGBA(color, opacity) : 'none'}
              />
            </g>
          );
        });
      })}
      Sorry, your browser does not support inline SVG.
    </>
  );
};
