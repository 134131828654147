import { GridAreaPanel } from '@components/panel';
import { Organization } from '@generated/UseGraphqlHooks';
import { Box } from '@mui/material';
import {
  OrganizationsSettingsGeneral,
  OrganizationsSettingsOrganizationsUsageTable,
  OrganizationsSettingsWorkspaceUsage,
} from '@subsets/organizations';

type OrganizationsSettingsProps = {
  organization: Organization;
};

export const OrganizationsSettings = ({ organization }: OrganizationsSettingsProps) => {
  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateAreas: '"general organizationUsage" "general workspaceUsage"',
        gridTemplateColumns: '5fr 7fr',
        gridTemplateRows: '1fr 1fr',
        rowGap: 10,
        columnGap: 6,
        height: '100%',
        width: '100%',
        overflow: 'hidden',
        py: 8,
      }}
    >
      <GridAreaPanel title="General" gridArea="general">
        <OrganizationsSettingsGeneral organization={organization} />
      </GridAreaPanel>
      <GridAreaPanel title="Organization usage" gridArea="organizationUsage">
        <OrganizationsSettingsOrganizationsUsageTable
          organizationId={organization?.organizationId}
        />
      </GridAreaPanel>
      <GridAreaPanel gridArea="workspaceUsage">
        <OrganizationsSettingsWorkspaceUsage organizationId={organization?.organizationId} />
      </GridAreaPanel>
    </Box>
  );
};
