import { DeckardIcon } from '@assets/types';
import { Tooltip } from '@components/tooltip';
import {
  IconButton as MuiIconButton,
  IconButtonProps as MuiIconButtonProps,
  useTheme,
} from '@mui/material';
import { Ref, forwardRef } from 'react';

export type IconButtonProps = Omit<MuiIconButtonProps, 'size'> & {
  Icon: DeckardIcon;
  size?: number;
  tooltip?: string;
  tooltipPlacement?: 'top' | 'bottom' | 'left' | 'right';
  variant?: 'default' | 'error';
};

export const IconButton = forwardRef(
  (
    {
      Icon,
      size = 18,
      tooltip,
      tooltipPlacement = 'top',
      sx,
      variant = 'default',
      ...props
    }: IconButtonProps,
    ref: Ref<HTMLButtonElement>,
  ) => {
    const { palette } = useTheme();
    const activeColor =
      palette.mode === 'dark'
        ? variant === 'error'
          ? palette.error.light
          : palette.grey[400]
        : variant === 'error'
          ? palette.error.main
          : palette.grey[200];
    const baseColor =
      palette.mode === 'dark'
        ? variant === 'error'
          ? palette.error.main
          : palette.grey[500]
        : variant === 'error'
          ? palette.error.dark
          : palette.grey[500];
    return (
      <MuiIconButton
        ref={ref}
        disableFocusRipple
        disableRipple
        disableTouchRipple
        sx={{
          padding: '5px',
          border: '1px solid transparent',
          borderRadius: '50%',
          '&:hover': {
            border: `1px solid ${baseColor}`,
          },
          '&:active': {
            borderColor: activeColor,
            '& *': {
              stroke: activeColor,
              fill: activeColor,
            },
          },
          '&:focus': {
            outline: `3px solid ${palette.button.outline}`,
            '&:not(:focus-visible)': {
              outline: 'none',
            },
          },
          ...sx,
        }}
        size="small"
        {...props}
      >
        {tooltip ? (
          <Tooltip title={tooltip} placement={tooltipPlacement}>
            <Icon size={size} color={baseColor} />
          </Tooltip>
        ) : (
          <Icon size={size} color={baseColor} />
        )}
      </MuiIconButton>
    );
  },
);
