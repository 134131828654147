import { LoadingIcon } from '@assets/icons';
import { Group } from '@components/layout';
import { Typography } from '@components/typography';
import { useGetWorkspacesQuery } from '@generated/UseGraphqlHooks';
import { OrganizationsSettingsWorkspaceUsageTable } from '@subsets/organizations';
import { useEffect, useState } from 'react';
import { WorkspaceDropdown } from './WorkspaceDropdown';

type OrganizationsSettingsWorkspaceUsageProps = {
  organizationId?: string;
};

export const OrganizationsSettingsWorkspaceUsage = ({
  organizationId,
}: OrganizationsSettingsWorkspaceUsageProps) => {
  const [selectedWorkspaceId, setSelectedWorkspaceId] = useState('');
  const { data: workspaceData, loading: workspacesLoading } = useGetWorkspacesQuery({
    variables: {
      organizationId,
    },
  });

  useEffect(() => {
    if (workspaceData?.getWorkspaces.length > 0) {
      setSelectedWorkspaceId(workspaceData.getWorkspaces[0].workspaceId);
    }
  }, [workspaceData]);

  return (
    <>
      <Group alignItems="center" gap={4}>
        <Typography variant="subtitle1">Workspace usage</Typography>
        {workspacesLoading ? (
          <Group alignItems="center" height="34px">
            <LoadingIcon size={8} />
          </Group>
        ) : (
          <WorkspaceDropdown
            organizationId={organizationId}
            selectedWorkspaceId={selectedWorkspaceId}
            onWorkspaceChange={(newWorkspaceId: string) => setSelectedWorkspaceId(newWorkspaceId)}
          />
        )}
      </Group>
      <OrganizationsSettingsWorkspaceUsageTable selectedWorkspaceId={selectedWorkspaceId} />
    </>
  );
};
