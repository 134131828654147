import { Select, TextInput } from '@components/form';
import { Tooltip } from '@components/tooltip';
import { Typography } from '@components/typography';
import { Box, MenuItem, Stack } from '@mui/material';
import { CanvasContext, GraphDataContext } from '@subsets/workspaces';
import { Port } from '@subsets/workspaces/graphs/graph/types';
import { find } from 'lodash';
import { useContext, useEffect, useRef, useState } from 'react';
import { useToggle } from 'react-use';
import { BasePort } from './BasePort';
import { PortIcon } from './PortIcon';
import { NodeState } from './types';

export const InputPort = ({
  description,
  disabled = false,
  name,
  nodeId,
  select: options,
  portId,
  state,
  value,
  index,
  onSelect,
}: Port & {
  disabled?: boolean;
  index: number;
  state: NodeState;
  nodeId: string;
  portId: string;
  onSelect: (portId: string) => void;
}) => {
  const { links, updatePortValue, validationErrors } = useContext(GraphDataContext);
  const error = validationErrors?.find(
    (validationError) => validationError.node === nodeId && validationError.input === name,
  );
  const linked = Boolean(
    find(links, { from: { portId, nodeId } }) || find(links, { to: { portId, nodeId } }),
  );

  const [controlledValue, setControlledValue] = useState(value || '');

  const timer = useRef<NodeJS.Timeout | null>(null);
  const updateValue = (changedValue) => {
    if (!updatePortValue) {
      return;
    }
    if (timer.current) {
      clearTimeout(timer.current);
    }
    timer.current = setTimeout(async () => {
      await updatePortValue(nodeId, portId, changedValue);
    }, 300);
  };

  useEffect(() => {
    setControlledValue(value);
  }, [value]);

  const { isEditingMode$ } = useContext(CanvasContext);
  const handleOnFocus = () => {
    isEditingMode$?.next(true);
  };

  const handleOnBlur = () => {
    isEditingMode$?.next(false);
  };

  const [open, toggleOpen] = useToggle(false);
  const iconRef = useRef();

  const onChange = (event) => {
    setControlledValue(event.target.value);
    updateValue(event.target.value);
  };

  return (
    <BasePort portId={portId} nodeId={nodeId} type="input" onClick={() => onSelect(portId)}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        height="100%"
        gap={2}
      >
        <Typography variant="caption2" noWrap tooltip={description} minWidth="25%" maxWidth="50%">
          {name}
        </Typography>
        {!linked ? (
          options ? (
            <Box flex={1} maxWidth="50%">
              <Tooltip title={error?.errorMessage}>
                <Select
                  sx={{ zIndex: index + 100 }}
                  small
                  name={`${nodeId}-${portId}-select`}
                  open={open}
                  value={controlledValue}
                  disabled={disabled}
                  onChange={onChange}
                  onBlur={handleOnBlur}
                  onFocus={handleOnFocus}
                  onClick={(event: any) => {
                    event.stopPropagation();
                    event.preventDefault();
                    toggleOpen();
                  }}
                >
                  {options.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </Tooltip>
            </Box>
          ) : (
            <Box flex={1} maxWidth="50%">
              <Tooltip title={error?.errorMessage}>
                <TextInput
                  autoComplete="off"
                  name={`${nodeId}-${portId}-text`}
                  disabled={disabled}
                  value={controlledValue}
                  small
                  onChange={onChange}
                  onFocus={handleOnFocus}
                  onBlur={handleOnBlur}
                  error={Boolean(error)}
                />
              </Tooltip>
            </Box>
          )
        ) : null}
        <Box sx={{ position: 'absolute', left: -12, height: '32px' }}>
          <PortIcon
            state={state}
            linked={linked}
            ref={iconRef}
            error={error?.type === 'link' ? error?.errorMessage : null}
            type="input"
          />
        </Box>
      </Stack>
    </BasePort>
  );
};
