/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable no-restricted-globals */
import {
  Notification,
  NotificationProps,
  NotificationType,
} from '@components/notification/Notification';
import { delay } from '@helper-functions/delay';
import { Box, Grid } from '@mui/material';
import { map as lodashMap, slice } from 'lodash';
import { useObservableState, useSubscription } from 'observable-hooks';
import {
  PropsWithChildren,
  ReactElement,
  SyntheticEvent,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { BehaviorSubject, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

type NotificationsContextType = {
  action$: Subject<NotificationAction>;
  addNotification: (props: PayloadType) => void;
  useAsyncNotification: (
    message: string,
    callback: (...data: unknown[]) => Promise<unknown>,
    options?: {
      link?: ReactElement;
      onLinkClick?: (event: SyntheticEvent) => void;
      duration?: number;
      loadingMessage?: string;
    },
    onCallbackError?: () => void,
  ) => (...data: unknown[]) => Promise<void>;
  notifications$: BehaviorSubject<NotificationPropsWithKey[]>;
  removeNotification: (key: string) => void;
  removeAllNotifications: () => void;
};

type PayloadType = {
  text?: string;
  type?: NotificationType;
  duration?: number;
  onClose?: (event?: SyntheticEvent) => void;
  link?: ReactElement;
  onLinkClick?: (event?: SyntheticEvent) => void;
  action?: ReactElement;
  key?: string;
};
type NotificationPropsWithKey = NotificationProps & { key: string };
export const NotificationsContext = createContext<NotificationsContextType>(
  {} as NotificationsContextType,
);

type NotificationAction =
  | { type: 'add'; payload: NotificationProps; key: string }
  | { type: 'update'; payload: NotificationProps; key: string }
  | { type: 'remove'; key: string };

const notificationsReducer = (
  notifications: NotificationPropsWithKey[],
  action: NotificationAction,
): NotificationPropsWithKey[] => {
  switch (action?.type) {
    case 'add': {
      return [{ ...action?.payload, key: action?.key }].concat(notifications);
    }
    case 'remove': {
      return notifications.filter(({ key }) => key !== action.key);
    }
    case 'update': {
      return notifications.map((props) => {
        const { key } = props;
        if (key === action.key) {
          return {
            ...action?.payload,
            key,
          };
        }
        return props;
      });
    }
    default: {
      return undefined;
    }
  }
};

export const NotificationsProvider = ({ children }: PropsWithChildren) => {
  const [notifications$] = useState(new BehaviorSubject<NotificationPropsWithKey[]>([]));
  const [action$] = useState(new Subject<NotificationAction>());

  const addNotification = async (payload: PayloadType) => {
    const key = payload?.key ? payload.key : `${Date.now() * 1000}.${Math.random() * 10000}`;
    action$.next({ type: 'add', payload, key });
    await delay(payload?.duration || 3000);
    action$.next({ type: 'remove', key });
  };

  useSubscription(
    action$.pipe(
      map((action) => {
        const notifications = notifications$.getValue();
        return notificationsReducer(notifications, action);
      }),
    ),
    (notifications: NotificationPropsWithKey[]) => notifications$.next(notifications || []),
  );

  const removeAllNotifications = () => {
    notifications$.value.forEach((notification) => {
      action$.next({ type: 'remove', key: notification.key });
    });
  };

  const removeNotification = (key: string) => {
    action$.next({ type: 'remove', key });
  };

  const useAsyncNotification = (
    message: string,
    callback: (...data: unknown[]) => Promise<unknown>,
    options?: {
      link?: ReactElement;
      onLinkClick?: (event: SyntheticEvent) => void;
      duration?: number;
      loadingMessage?: string;
    },
    onCallbackError?: () => unknown,
  ) => {
    const key = `${Date.now() * 1000}.${Math.random() * 10000000}`;
    return async (...data: unknown[]) => {
      action$.next({
        type: 'add',
        payload: {
          type: 'loading',
          text: options?.loadingMessage || 'Processing...',
        },
        key,
      });
      try {
        await callback(...data);
        action$.next({
          type: 'update',
          key,
          payload: {
            type: 'success',
            text: message,
            link: options?.link,
            onLinkClick: options?.onLinkClick,
          },
        });
        await delay(options?.duration || 3000);
        removeNotification(key);
      } catch (error) {
        if (onCallbackError) {
          onCallbackError();
        }
        if (error instanceof Error) {
          action$.next({
            type: 'update',
            payload: {
              type: 'error',
              text: error.message || 'Something went wrong please try again...',
            },
            key,
          });
        }
        await delay(5000);
        removeNotification(key);
      }
    };
  };

  const providerValue = useMemo(
    () => ({
      action$,
      notifications$,
      addNotification,
      removeNotification,
      useAsyncNotification,
      removeAllNotifications,
    }),
    [
      action$,
      notifications$,
      addNotification,
      removeNotification,
      useAsyncNotification,
      removeAllNotifications,
    ],
  );

  return (
    <NotificationsContext.Provider value={providerValue}>
      {children}
      <NotificationStackContainer /> {/* routeState={params?.location?.state} /> */}
    </NotificationsContext.Provider>
  );
};

type RouteState = { error?: string };

const LIMIT = 10;
const NotificationStackContainer = ({
  routeState,
  children,
}: PropsWithChildren<{ routeState?: RouteState }>) => {
  const { notifications$, removeNotification } = useContext(NotificationsContext);
  const notifications = useObservableState(notifications$);

  const [fromRouteError, setFromRouteError] = useState(routeState?.error);
  const clearRouteStateError = () => {
    setFromRouteError(null);
    history.replaceState({ ...history.state, error: null }, '', '/');
  };
  useEffect(() => {
    if (routeState?.error) {
      setFromRouteError(routeState?.error);
      setTimeout(() => clearRouteStateError(), 3000);
    }
  }, [routeState]);

  return (
    <Box position="fixed" left={10} bottom={10} zIndex={10000}>
      <Grid container spacing={4} direction="column">
        {lodashMap(slice(notifications, 0, LIMIT), ({ key, onClose, ...rest }, index) => (
          <Grid item key={`${key}-${index}`}>
            <Notification
              {...rest}
              onClose={(e) => {
                removeNotification(key);
                if (onClose) {
                  onClose(e);
                }
              }}
            />
          </Grid>
        ))}
        {fromRouteError && (
          <Notification text={fromRouteError} type="error" onClose={clearRouteStateError} />
        )}
      </Grid>
      {children}
    </Box>
  );
};
export const useNotifications = () => useContext(NotificationsContext);
