import { DeckardIcon } from '@assets/types';
import { Group } from '@components/layout';
import {
  FormControlLabel,
  FormControlLabelProps,
  Radio as MuiRadio,
  RadioProps as MuiRadioProps,
  useTheme,
} from '@mui/material';

export type RadioProps = MuiRadioProps & { label?: FormControlLabelProps['label'] };

export const Radio = ({ disabled, label, ...props }: RadioProps) => {
  const { palette } = useTheme();
  return (
    <FormControlLabel
      control={
        <MuiRadio
          disableRipple
          icon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              className="radio-icon-unchecked"
            >
              <circle cx="8" cy="8" r="6" fill={palette.radio.fill.unchecked} strokeWidth="4" />
            </svg>
          }
          checkedIcon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              className="radio-icon-checked"
            >
              <circle cx="8" cy="8" r="6" fill={palette.radio.fill.checked} strokeWidth="4" />
            </svg>
          }
          disabled={disabled}
          {...props}
        />
      }
      sx={{ marginRight: label ? 4 : 0 }}
      label={label}
    />
  );
};

export type RadioIconProps = Omit<MuiRadioProps, 'icon'> & { icon: DeckardIcon };

export const RadioIcon = ({ disabled, icon: Icon, sx, checked, ...props }: RadioIconProps) => {
  const { palette } = useTheme();
  return (
    <Group sx={{ height: '34px', width: '34px', alignItems: 'center', justifyContent: 'center' }}>
      <MuiRadio
        disableRipple
        icon={<Icon />}
        checked={checked}
        checkedIcon={<Icon />}
        disabled={disabled}
        sx={{
          height: '28px',
          width: '28px',
          borderRadius: 1,
          '&:hover': {
            border: `1px solid ${palette.radio.icon.hover}`,
            '& .stroke-override': { stroke: palette.radio.icon.hover },
            '& .fill-override': { fill: palette.radio.icon.hover },
          },
          '&:active': {
            borderColor: palette.input.border.active,
            '& .stroke-override': { stroke: palette.input.border.active },
            '& .fill-override': { fill: palette.input.border.active },
          },
          '&.Mui-checked': {
            backgroundColor: palette.radio.icon.checked,
          },
          ...sx,
        }}
        {...props}
      />
    </Group>
  );
};
