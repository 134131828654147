import { Typography } from '@components/typography';
import { TableCell, TableRow } from '@mui/material';

export const GanModelTableHeader = () => {
  return (
    <TableRow>
      <TableCell>
        <Typography variant="caption1">Id</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="caption1">Name</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="caption1">Description</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="caption1">Owner</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="caption1">Last Modified</Typography>
      </TableCell>
    </TableRow>
  );
};
