import { styled, useTheme } from '@mui/material';
import * as d3 from 'd3';
import { PropsWithChildren, useEffect, useRef } from 'react';
import { Observable, combineLatest } from 'rxjs';

const StyledG = styled(
  ({ onClick, children, ...props }: PropsWithChildren<{ onClick: () => void }>) => (
    <g onClick={onClick} {...props}>
      {children}
    </g>
  ),
)(({ theme }) => ({
  cursor: 'pointer',
  stroke: theme.palette.graph.link.default,
  '&:hover': {
    '& .link,.marker': {
      transition: 'all 0.2s',
    },
  },
}));

export interface LinkProps {
  from?: Observable<[number, number]>;
  to?: Observable<[number, number]>;
  inactive?: boolean;
  focus?: boolean;
  onClick?: () => void;
}

export const spline = d3
  .line()
  .x(([x]) => x)
  .y(([, y]) => y);

const center = ([x, y]: [number, number], [x1, y1]: [number, number]): [number, number] => {
  return [(x + x1) / 2, (y + y1) / 2];
};

const markerId = `link-direction-arrow${Math.random()}`;
export const Link = ({ inactive = false, focus, from, to, onClick, ...props }: LinkProps) => {
  const line = useRef<SVGPathElement>();
  const lineBackground = useRef<SVGPathElement>();
  const { palette } = useTheme();

  useEffect(() => {
    const subscription = combineLatest([from, to]).subscribe(([fromPos, toPos]) => {
      if (!fromPos || !toPos) {
        d3.select(line.current).attr('d', undefined);
        d3.select(lineBackground.current).attr('d', undefined);
        return;
      }
      d3.select(line.current).attr(
        'd',
        spline([
          [fromPos[0], fromPos[1]],
          center([fromPos[0], fromPos[1]], [toPos[0], toPos[1]]),
          [toPos[0], toPos[1]],
        ]),
      );
      d3.select(lineBackground.current).attr(
        'd',
        spline([
          [fromPos[0], fromPos[1]],
          center([fromPos[0], fromPos[1]], [toPos[0], toPos[1]]),
          [toPos[0], toPos[1]],
        ]),
      );
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [from, to, line, lineBackground]);

  const stroke = inactive ? palette.graph.link.inactive : palette.graph.link.default;

  return (
    <StyledG onClick={onClick} {...props}>
      <defs>
        <marker
          id={markerId}
          markerWidth="10"
          markerHeight="10"
          refX="0"
          refY="5"
          orient="auto"
          markerUnits="strokeWidth"
        >
          <rect
            height="10"
            width="10"
            fill={palette.background.default}
            stroke={palette.background.default}
          />
          <path
            d="M3 9L7 5L3 1"
            fill="none"
            className="marker"
            style={{
              stroke,
              transition: 'all 0.2s',
              transformOrigin: 'center',
            }}
            strokeWidth="1"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </marker>
      </defs>
      <path ref={line} strokeWidth="40" stroke="transparent" />
      <path
        ref={lineBackground}
        className="link"
        fill="none"
        style={{
          stroke,
          fill: 'none',
          strokeWidth: 3,
        }}
        markerMid={`url(#${markerId})`}
      />
    </StyledG>
  );
};

export default Link;
