import { Apollo } from '@api/Apollo';
import { Stripe } from '@api/Stripe';
import { AuthProvider } from '@authentication/Authentication';
import { Header } from '@components/header';
import { ModalProvider } from '@components/modal';
import { FileUploadProvider } from '@fileUpload/FileUpload';
import { Stack } from '@mui/material';
import { NotificationsProvider } from '@notifications/Notifications';
import { SeoProvider } from '@seo/SEO';
import { SystemNotificationBanner } from '@subsets/system/SystemNotificationBanner';
import { PostHogProvider } from 'posthog-js/react';
import 'react-loading-skeleton/dist/skeleton.css';
import { FeatureFlagProvider } from './src/hooks/FeatureFlags';
import { Theme } from './src/theme/Theme';
import './src/theme/global.css';

const posthogOptions = {
  api_host: process.env.GATSBY_POSTHOG_HOST,
  autocapture: false,
};

export const wrapPageElement = ({ element, props }) => {
  return (
    <Stack sx={{ height: '100vh', width: '100vw', overflow: 'hidden' }}>
      <Header workspaceId={props?.workspaceId} />
      <SystemNotificationBanner />
      {element}
    </Stack>
  );
};

export const wrapRootElement = ({ element }) => {
  return (
    <SeoProvider>
      <Theme>
        <NotificationsProvider>
          <AuthProvider>
            <PostHogProvider apiKey={process.env.GATSBY_POSTHOG_KEY} options={posthogOptions}>
              <FeatureFlagProvider>
                <Apollo>
                  <FileUploadProvider>
                    <Stripe>
                      <ModalProvider>{element}</ModalProvider>
                    </Stripe>
                  </FileUploadProvider>
                </Apollo>
              </FeatureFlagProvider>
            </PostHogProvider>
          </AuthProvider>
        </NotificationsProvider>
      </Theme>
    </SeoProvider>
  );
};
