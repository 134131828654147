import { Typography } from '@components/typography';
import { Box, useTheme } from '@mui/material';
import { useNotifications } from '@notifications/Notifications';
import { map, size, uniq } from 'lodash';
import { useObservableState } from 'observable-hooks';
import { useContext, useEffect, useState } from 'react';
import { BehaviorSubject } from 'rxjs';
import { GraphsOutdatedNodesContext } from './GraphsOutdatedNodesProvider';
import { ChannelDetailContext } from './graph/ChannelDetailProvider';
import { GraphDataContext } from './graph/GraphDataProvider';
import { ChannelNode } from './graph/types';

export const GraphsOutdatedNodesPreview = () => {
  const { palette } = useTheme();
  const { updateAllNodes } = useContext(GraphsOutdatedNodesContext);
  const { nodes } = useContext(GraphDataContext);

  const { data$ } = useContext(ChannelDetailContext);
  const channelNodes: ChannelNode[] = useObservableState<ChannelNode[]>(data$);

  const [outdatedNodesNotifications$] = useState(new BehaviorSubject<string[]>([]));
  const outdatedNotifications = useObservableState(outdatedNodesNotifications$);
  const { addNotification, removeNotification } = useNotifications();

  // const [outdatedNodeIds, setOutdatedNodeIds] = useState([]);

  const handleUpdateAllOutdatedNodes = async (ids: string[], key: string) => {
    await updateAllNodes(ids);
    if (outdatedNotifications.length > 0) {
      outdatedNotifications.map((outdatedKey) => removeNotification(outdatedKey));
    }
    removeNotification(key);
  };

  useEffect(() => {
    if (!channelNodes?.length || !nodes?.length) {
      return;
    }
    const outdatedNodes = nodes.filter(({ hash, nodeClass }) => {
      if (nodeClass === 'VolumeFile' || nodeClass === 'VolumeDirectory') {
        return false;
      }
      const channelNode = channelNodes.find((findNode) => findNode.name === nodeClass);
      if (!channelNode) {
        return true;
      }
      const isNodeOutdated = channelNode?.hash
        ? hash !== channelNode.hash
        : hash !== channelNode?.hash;
      return isNodeOutdated;
    });
    const outdatedNodeIds = uniq(map(outdatedNodes, 'name'));
    // setOutdatedNodeIds(outdatedNodesIds);

    const key = `${Date.now() * 1000}.${Math.random() * 10000}`;

    if (outdatedNodeIds.length > 0) {
      if (size(outdatedNotifications) > 0) {
        outdatedNotifications.map((key) => removeNotification(key));
      }
      outdatedNodesNotifications$.next([]);

      outdatedNodesNotifications$.next([...outdatedNodesNotifications$.getValue(), key]);

      addNotification({
        key,
        type: 'warning',
        link: (
          <Box>
            <Typography style={{ color: palette.warning.main }}>
              {outdatedNodeIds.length} outdated nodes detected in graph.
            </Typography>
            <Typography
              onClick={() => handleUpdateAllOutdatedNodes(outdatedNodeIds, key)}
              style={{
                textDecoration: 'underline',
                color: palette.warning.main,
                cursor: 'pointer',
              }}
            >
              Update all
            </Typography>
          </Box>
        ),
        duration: 100000,
      });
    }

    return () => {
      removeNotification(key);
    };
  }, [channelNodes, nodes]);

  return null;
};
