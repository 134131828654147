import { DeckardIconProps } from '@assets/types';
import { useTheme } from '@mui/material';
import { forwardRef } from 'react';

export const EqualsIcon = forwardRef<SVGSVGElement, DeckardIconProps>((props, ref) => {
  const { palette } = useTheme();
  const { color, size } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
      width={size || 24}
      height={size || 24}
      viewBox="0 0 24 24"
      fill={color || palette.icon.default}
      className="fill-override"
      {...props}
    >
      <path d="M 5.696 7.743 C 5.062 7.743 4.55 8.219 4.55 8.807 C 4.55 9.396 5.062 9.872 5.696 9.872 L 18.304 9.872 C 18.938 9.872 19.45 9.396 19.45 8.807 C 19.45 8.219 18.938 7.743 18.304 7.743 L 5.696 7.743 Z M 5.696 14.129 C 5.062 14.129 4.55 14.604 4.55 15.193 C 4.55 15.782 5.062 16.257 5.696 16.257 L 18.304 16.257 C 18.938 16.257 19.45 15.782 19.45 15.193 C 19.45 14.604 18.938 14.129 18.304 14.129 L 5.696 14.129 Z" />
    </svg>
  );
});
