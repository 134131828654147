import { AsyncButton, Button } from '@components/button';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  Form,
  FormSelect,
  FormTextInput,
} from '@components/form';
import {
  useAttachPaymentMethodToCustomerMutation,
  useGetCurrentUserQuery,
  useGetCustomerPaymentMethodsQuery,
  useSaveDefaultPaymentMethodMutation,
} from '@generated/UseGraphqlHooks';
import { delay } from '@helper-functions/delay';
import { Box, Grid, MenuItem, Stack } from '@mui/material';
import { useNotifications } from '@notifications/Notifications';
import {
  CardNumberElement as StripeCardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { find, first, isEmpty } from 'lodash';
import { useState } from 'react';
import * as yup from 'yup';
import { STRIPE_AVAILABLE_COUNTRIES_LIST } from './OrganizationsBillingProvider';

const validationSchema = yup.object({
  fullName: yup.string().required('Name is required'),
  organization: yup.string().required('Organization is required'),
  cardNumber: yup.string().required('Card Number is required'),
  expiration: yup.string().required('Expiration is required'),
  cvv: yup.string().required('CVV is required'),
  email: yup.string().email('Enter a valid email').required('Email is required'),
  phone: yup.number().min(10).required('Phone number is required'),
  addressLine1: yup.string().required('Address is required'),
  country: yup.string().required('Country is required'),
  city: yup.string().required('City is required'),
  state: yup.string().required('State is required'),
  zip: yup.string().min(5).required('Postal code is required'),
});

interface HandleFormSubmitInterface {
  addressLine1: string;
  addressLine2: string;
  country: string;
  city: string;
  organization: string;
  state: string;
  zip: string;
  email: string;
  fullName: string;
  phone: string;
}

export const OrganizationsBillingPaymentInformationModalCredit = ({
  onClose,
  organizationId,
}: {
  organizationId: string;
  onClose: () => void;
}) => {
  const [saveDefaultPaymentMethod] = useSaveDefaultPaymentMethodMutation();
  const [attachPaymentMethodToCustomer] = useAttachPaymentMethodToCustomerMutation();
  const { addNotification } = useNotifications();
  const stripe = useStripe();
  const elements = useElements();

  const {
    data: customerPaymentMethodData,
    loading: customerPaymentMethodDataLoading,
    refetch: refetchCustomerPaymentMethod,
  } = useGetCustomerPaymentMethodsQuery({
    variables: { organizationId },
  });
  const customerPaymentMethod =
    !customerPaymentMethodDataLoading &&
    !isEmpty(customerPaymentMethodData?.getCustomerPaymentMethods) &&
    first(customerPaymentMethodData?.getCustomerPaymentMethods);

  const { billing_details: billingDetails, metadata } = customerPaymentMethod;

  const handleFormikSubmit = async ({
    addressLine1,
    addressLine2,
    country,
    city,
    organization,
    state,
    zip,
    email,
    fullName,
    phone,
  }: HandleFormSubmitInterface) => {
    if (!stripe || !elements) {
      return;
    }
    const cardNumberElement = elements.getElement(StripeCardNumberElement);
    const { paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardNumberElement,
      billing_details: {
        email,
        name: fullName,
        phone,
        address: {
          city,
          country,
          state,
          postal_code: zip,
          line1: addressLine1,
          line2: addressLine2,
        },
      },
      metadata: { organization },
    });
    await attachPaymentMethodToCustomer({
      variables: { paymentMethodId: paymentMethod.id, organizationId },
    });
    await saveDefaultPaymentMethod({
      variables: { organizationId, paymentMethodId: paymentMethod.id },
    });
    await delay(1000);
    addNotification({ type: 'success', text: 'Successfully updated payment information.' });
    onClose();
    await refetchCustomerPaymentMethod();
  };

  const formInitialValues = {
    fullName: billingDetails?.name || '',
    organization: metadata?.organization || '',
    addressLine1: billingDetails?.address?.line1 || '',
    addressLine2: billingDetails?.address?.line2 || '',
    cardNumber: '',
    expiration: '',
    cvv: '',
    city: billingDetails?.address?.city || '',
    state: billingDetails?.address?.state || '',
    country: billingDetails?.address?.country || '',
    zip: billingDetails?.address?.postal_code || '',
    email: billingDetails?.email || '',
    phone: billingDetails?.phone || '',
  };

  const countryName = (code: string) => {
    const country = find(STRIPE_AVAILABLE_COUNTRIES_LIST, { alpha2Code: code });
    return country.name;
  };

  return (
    <Form
      validateOnBlur={false}
      enableReinitialize
      validateOnChange={false}
      initialValues={formInitialValues}
      validationSchema={validationSchema}
      onSubmit={handleFormikSubmit}
    >
      {({ errors, isSubmitting }) => (
        <Stack gap={6}>
          <Box width="60vw" style={{ overflowY: 'hidden', overflowX: 'hidden' }}>
            <Grid container spacing={8}>
              <Grid item xs={7}>
                <Stack>
                  <FormTextInput label="Name on Card" name="fullName" />
                  <CardNumberElement label="Card Number" name="cardNumber" />
                  <Stack direction="row" spacing={4}>
                    <CardExpiryElement label="Expiration (MM/YY)" name="expiration" width="70%" />
                    <CardCvcElement label="CVV" name="cvv" width="30%" />
                  </Stack>
                  <FormTextInput name="phone" label="Phone" />
                  <FormTextInput label="Email" name="email" />
                </Stack>
              </Grid>

              <Grid item xs={5}>
                <Stack>
                  <FormTextInput label="Organization" name="organization" />
                  <FormTextInput label="Address Line 1" name="addressLine1" />
                  <FormTextInput label="Address Line 2" name="addressLine2" />
                  <FormTextInput label="City" name="city" />
                  <FormSelect label="Country" name="country">
                    {STRIPE_AVAILABLE_COUNTRIES_LIST.map((country) => (
                      <MenuItem value={country.alpha2Code} key={country.alpha2Code}>
                        {countryName(country.alpha2Code)}
                      </MenuItem>
                    ))}
                  </FormSelect>
                  <Stack direction="row" spacing={4}>
                    <FormTextInput label="State/Province" name="state" />
                    <FormTextInput label="Postal Code" name="zip" />
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
          </Box>
          <Box display="flex" justifyContent="flex-end" gap={4}>
            <AsyncButton
              type="submit"
              disabled={!isEmpty(errors) || isSubmitting}
              sx={{ minWidth: '150px' }}
            >
              Continue
            </AsyncButton>
            <Button variant="secondary" onClick={onClose} sx={{ minWidth: '150px' }}>
              Back
            </Button>
          </Box>
        </Stack>
      )}
    </Form>
  );
};
