import { Header } from '@components/header';
import { Box, Stack } from '@mui/material';
import { StyledRenderedAILogo } from './StyledRenderedAILogo';

export const TransitionScreen = () => {
  return (
    <Stack sx={{ height: '100vh', width: '100vw' }}>
      <Header />
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#1b1b1b',
        }}
      >
        <StyledRenderedAILogo color="white" />
      </Box>
    </Stack>
  );
};
