import { DeckardIconProps } from '@assets/types';
import { useTheme } from '@mui/material';
import { forwardRef } from 'react';

export const ChartIcon = forwardRef<SVGSVGElement, DeckardIconProps>((props, ref) => {
  const { palette } = useTheme();
  const { color, size } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
      width={size || 24}
      height={size || 24}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <rect
        className="fill-override"
        x="5"
        y="11"
        width="4"
        height="8"
        fill={color || palette.icon.default}
      />
      <rect
        className="fill-override"
        x="10"
        y="14"
        width="4"
        height="5"
        fill={color || palette.icon.default}
      />
      <rect
        className="fill-override"
        x="15"
        y="5"
        width="4"
        height="14"
        fill={color || palette.icon.default}
      />
    </svg>
  );
});
