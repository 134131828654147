import { Typography } from '@components/typography';
import { Grid, useTheme } from '@mui/material';

interface JobsManagerJobPreviewDataFieldProps {
  label: string;
  value: string | number;
  width: number;
}

export const JobsManagerJobPreviewDataField = ({
  label,
  value,
  width,
}: JobsManagerJobPreviewDataFieldProps) => {
  const { palette } = useTheme();
  return (
    <Grid item xs={width} display="flex" justifyContent="space-between">
      <Typography color={palette.text.paper} variant="caption2" flex={1}>
        {label}
      </Typography>
      <Typography variant="caption2" color={palette.text.contrast} flex={1}>
        {value}
      </Typography>
    </Grid>
  );
};
