import { Button } from '@components/button';
import { Typography } from '@components/typography';
import {
  useDeleteManagedMapMutation,
  useGetAnnotationMapsQuery,
  useRemoveMapOrganizationMutation,
} from '@generated/UseGraphqlHooks';
import { delay } from '@helper-functions/delay';
import { Stack } from '@mui/material';
import { useNotifications } from '@notifications/Notifications';
import { useState } from 'react';

interface DeleteAnnotationMapModalProps {
  name: string;
  mapId: string;
  organizationId: string;
  onClose: () => void;
  isAnnotationMapOwnedByOrganization: boolean;
}

export const DeleteAnnotationMapModal = ({
  name,
  mapId,
  organizationId,
  onClose,
  isAnnotationMapOwnedByOrganization,
}: DeleteAnnotationMapModalProps) => {
  const { useAsyncNotification } = useNotifications();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [deleteMap] = useDeleteManagedMapMutation();
  const [removeOrganizationMap] = useRemoveMapOrganizationMutation();
  const { refetch } = useGetAnnotationMapsQuery({
    variables: {
      organizationId,
    },
  });

  const handleDeleteMapClick = useAsyncNotification(
    `Successfully ${isAnnotationMapOwnedByOrganization ? 'deleted' : 'removed'} the map`,
    async () => {
      setIsSubmitting(true);
      isAnnotationMapOwnedByOrganization
        ? await deleteMap({ variables: { mapId } })
        : await removeOrganizationMap({
            variables: {
              organizationId,
              mapId,
            },
          });
      await delay(3000);
      await refetch();
      setIsSubmitting(false);
      onClose();
    },
  );

  return (
    <Stack pt={4} gap={10} justifyContent="center">
      <Typography variant="body2" align="center">
        Are you sure you want to {isAnnotationMapOwnedByOrganization ? 'delete' : 'remove'} {name}?
      </Typography>
      <Stack gap={4}>
        <Button fullWidth disabled={isSubmitting} onClick={handleDeleteMapClick}>
          Yes, {isAnnotationMapOwnedByOrganization ? 'delete' : 'remove'}
        </Button>
        <Button fullWidth onClick={onClose} variant="secondary">
          Cancel
        </Button>
      </Stack>
    </Stack>
  );
};
