import { ApolloQueryResult } from '@apollo/client';
import {
  Exact,
  GetBillingHistoryQuery,
  GetOrganizationsQuery,
  Organization,
  StripePaymentIntentInfo,
  StripeProduct,
  useGetBillingHistoryQuery,
  useGetOrganizationsQuery,
} from '@generated/UseGraphqlHooks';
import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

type OrganizationsProviderType = {
  currentPlan: number;
  selectedPlan: number;
  setCurrentPlan: Dispatch<SetStateAction<number>>;
  setSelectedPlan: Dispatch<SetStateAction<number>>;
  plan: string;
  organizationInformation: Organization;
  refetchOrganizationsData: (
    variables?: Partial<
      Exact<{
        organizationId?: string;
      }>
    >,
  ) => Promise<ApolloQueryResult<GetOrganizationsQuery>>;
  organizationsDataLoading: boolean;
  paymentHistory: StripePaymentIntentInfo[];
  billingHistoryLoading: boolean;
  refetchBillingHistory: (
    variables?: Partial<
      Exact<{
        organizationId: string;
      }>
    >,
  ) => Promise<ApolloQueryResult<GetBillingHistoryQuery>>;
  stripeProducts: StripeProduct[];
  organizationId: string;
};

const ORGANIZATION_PLANS_DETAILS = [
  {
    id: 'prod_KmFXAPBS8Agxp3',
    priceId: 'price_1JroBuLdcZz5Xt6sQWIAgJfv',
    object: 'product',
    active: true,
    billing_scheme: 'per_unit',
    created: 1639511503,
    currency: 'usd',
    livemode: true,
    product: 'prod_KmFXAPBS8Agxp3',
    nickname: null,
    recurring: {
      interval: 'month',
      interval_count: 1,
      usage_type: 'licensed',
    },
    name: 'Developer',
    description:
      'For non-production experimentation and development by academic and non-profit customers',
    metadata: {
      name: 'standard',
      enhancedSupportCredits: '0',
      organizationSeat: '1',
      membersPerOrganization: '5',
      workspacesPerOrganization: '50',
      workspaceGuestsWithReadAndWrite: '0',
      channelsPerOrganization: '3',
      dataVolumes: '5',
      dataVolumeGBLimit: '100',
      graphsPerWorkspace: 'Unlimited',
      stagedGraphsPerWorkspace: '100',
      datasetsPerWorkspace: '200',
      datasetGBPerOrganization: '1000',
      maxConcurrentComputerInstances: '3',
      technicalAccountManager: 'No',
      typeOfAWSInstance: 'p2.xlarge',
      annotation: 'Yes',
      analytics: 'Yes',
      UMAP: 'Yes',
      domainAdaptation: 'Yes',
      limitsOnHours: 'Unlimited',
      downloadBandwidth: 'Unknown',
    },
    type: 'service',
    unit_amount: 50000,
    unit_amount_decimal: '50000',
  },
  {
    id: 'prod_KobgIzLvyM8LSq',
    priceId: 'price_1JroCHLdcZz5Xt6supH4Ye06',
    object: 'product',
    active: true,
    billing_scheme: 'per_unit',
    created: 1640055174,
    currency: 'usd',
    livemode: true,
    product: 'prod_KobgIzLvyM8LSq',
    nickname: null,
    recurring: {
      interval: 'month',
      interval_count: 1,
      usage_type: 'licensed',
    },
    name: 'Professional',
    description: 'For teams developing and improving CV models',
    metadata: {
      name: 'Professional',
      enhancedSupportCredits: '0',
      organizationSeat: '1',
      membersPerOrganization: '5',
      workspacesPerOrganization: '50',
      workspaceGuestsWithReadAndWrite: '5',
      channelsPerOrganization: '3',
      dataVolumes: '5',
      dataVolumeGBLimit: '250',
      graphsPerWorkspace: 'Unlimited',
      stagedGraphsPerWorkspace: '500',
      datasetsPerWorkspace: '1000',
      datasetGBPerOrganization: '2500',
      maxConcurrentComputerInstances: '10',
      technicalAccountManager: 'No',
      typeOfAWSInstance: 'p2.xlarge',
      annotation: 'Yes',
      analytics: 'Yes',
      UMAP: 'Yes',
      domainAdaptation: 'Yes',
      limitsOnHours: 'Unlimited',
      downloadBandwidth: 'Unknown',
    },
    type: 'service',
    unit_amount: 500000,
    unit_amount_decimal: '500000',
  },
  {
    id: 'prod_KobgZu29c59jJZ',
    priceId: 'price_1K6h0nLdcZz5Xt6s2uEGhDyp',
    object: 'product',
    active: true,
    billing_scheme: 'per_unit',
    created: 1640055162,
    currency: 'usd',
    livemode: true,
    product: 'prod_KobgZu29c59jJZ',
    nickname: null,
    recurring: {
      interval: 'month',
      interval_count: 1,
      usage_type: 'licensed',
    },
    name: 'Enterprise',
    description:
      'For customers who need the highest compute and help with synthetic data engineering',
    metadata: {
      name: 'Enterprise',
      enhancedSupportCredits: '10',
      organizationSeat: '1',
      membersPerOrganization: '20',
      workspacesPerOrganization: '50',
      workspaceGuestsWithReadAndWrite: '20',
      channelsPerOrganization: '25',
      dataVolumes: '20',
      dataVolumeGBLimit: '500',
      graphsPerWorkspace: 'Unlimited',
      stagedGraphsPerWorkspace: '1000',
      datasetsPerWorkspace: '5000',
      datasetGBPerOrganization: '5000',
      maxConcurrentComputerInstances: '25',
      technicalAccountManager: 'Yes',
      typeOfAWSInstance: 'p2.xlarge',
      annotation: 'Yes',
      analytics: 'Yes',
      UMAP: 'Yes',
      domainAdaptation: 'Yes',
      limitsOnHours: 'Unlimited',
      downloadBandwidth: 'Unknown',
    },
    type: 'service',
    unit_amount: 1500000,
    unit_amount_decimal: '1500000',
  },
];

export const OrganizationsBillingContext = createContext({} as OrganizationsProviderType);
export const ORGANIZATION_PLANS = ['Developer', 'Professional', 'Enterprise'];

type OrganizationsBillingProviderProps = {
  organization: Organization;
};

export const OrganizationsBillingProvider = ({
  organization,
  children,
}: PropsWithChildren<OrganizationsBillingProviderProps>) => {
  const organizationId = organization?.organizationId || '';

  const {
    // data: organizationsData,
    loading: organizationsDataLoading,
    refetch: refetchOrganizationsData,
  } = useGetOrganizationsQuery();
  const {
    data: billingHistory,
    loading: billingHistoryLoading,
    refetch: refetchBillingHistory,
  } = useGetBillingHistoryQuery({
    variables: { organizationId },
  });
  const paymentHistory = billingHistory?.getBillingHistory || [];
  // const organizations = get(organizationsData, 'getOrganizations') as Organization[];
  // const organizationInformation = find(organizations, { organizationId });
  const { plan } = organization || {};
  const [currentPlan, setCurrentPlan] = useState(0);
  const [selectedPlan, setSelectedPlan] = useState(0);

  useEffect(() => {
    if (plan) {
      const index = ORGANIZATION_PLANS.indexOf(plan);
      setCurrentPlan(index);
      setSelectedPlan(index);
    }
  }, [plan]);

  const OrganizationsBillingProviderValues = useMemo(
    () => ({
      currentPlan,
      selectedPlan,
      setCurrentPlan,
      setSelectedPlan,
      plan,
      organizationInformation: organization,
      refetchOrganizationsData,
      organizationsDataLoading,
      paymentHistory,
      billingHistoryLoading,
      refetchBillingHistory,
      stripeProducts: ORGANIZATION_PLANS_DETAILS,
      organizationId,
    }),
    [
      currentPlan,
      selectedPlan,
      setCurrentPlan,
      setSelectedPlan,
      plan,
      organization,
      refetchOrganizationsData,
      organizationsDataLoading,
      paymentHistory,
      billingHistoryLoading,
      refetchBillingHistory,
      ORGANIZATION_PLANS_DETAILS,
      organizationId,
    ],
  );

  return (
    <OrganizationsBillingContext.Provider value={OrganizationsBillingProviderValues}>
      {children}
    </OrganizationsBillingContext.Provider>
  );
};

export const useOrganizationsBilling = () => useContext(OrganizationsBillingContext);

export const STRIPE_AVAILABLE_COUNTRIES_LIST = [
  { name: 'Australia', alpha2Code: 'AU' },
  { name: 'Austria', alpha2Code: 'AT' },
  { name: 'Brazil', alpha2Code: '	BR' },
  { name: 'Bulgaria', alpha2Code: 'BG' },
  { name: 'Canada', alpha2Code: 'CA' },
  { name: 'Cyprus', alpha2Code: 'CY' },
  { name: 'Czech Republic', alpha2Code: 'CZ' },
  { name: 'Denmark', alpha2Code: 'DK' },
  { name: 'Estonia', alpha2Code: 'EE' },
  { name: 'Finland', alpha2Code: 'FI' },
  { name: 'France', alpha2Code: 'FR' },
  { name: 'Germany', alpha2Code: 'DE' },
  { name: 'Greece', alpha2Code: 'GR' },
  { name: 'Lithuania', alpha2Code: 'LT' },
  { name: 'Luxembourg', alpha2Code: 'LU' },
  { name: 'Malaysia', alpha2Code: 'MY' },
  { name: 'Malta', alpha2Code: 'MT' },
  { name: 'Mexico', alpha2Code: 'MX' },
  { name: 'Netherlands', alpha2Code: 'NL' },
  { name: 'New Zealand', alpha2Code: 'NZ' },
  { name: 'Norway', alpha2Code: '	NO' },
  { name: 'Poland', alpha2Code: 'PL' },
  { name: 'Portugal', alpha2Code: 'PT' },
  { name: 'Romania', alpha2Code: 'RO' },
  { name: 'Singapore', alpha2Code: 'SG' },
  { name: 'Slovakia', alpha2Code: 'SK' },
  { name: 'Slovenia', alpha2Code: 'SI' },
  { name: 'Spain', alpha2Code: 'ES' },
  { name: 'Sweden', alpha2Code: 'SE' },
  { name: 'Switzerland', alpha2Code: 'CH' },
  { name: 'United Arab Emirates', alpha2Code: '	AE' },
  { name: 'United Kingdom', alpha2Code: 'GB' },
  { name: 'United States', alpha2Code: 'US' },
];

export const PLAN_SPECIFIC_VALUES = [
  {
    title: 'Enhanced Support Credits per month',
    description:
      'Enhanced Support Credits are consumed by users who need specific, standard tasks completed by the support team such as CycleGAN training, adding content to a channel, or other fixed cost channel updates. Credits are not tied to specific support hours or standard technical support issues.',
    highlightedWordsLength: 3,
  },
  {
    title: 'Technical Account Manager',
    description: 'Access to a Technical Account Manager to coordinate support',
    highlightedWordsLength: 3,
  },
  {
    title: 'Peak Instances',
    description:
      'Instances are compute hardware that can be assigned to complete a job. Organizations will consume up to a set number of concurrent compute instances, as determined by the subscription plan and depending on the number of jobs running and prioritization of jobs.',
    highlightedWordsLength: 2,
  },
  {
    title: 'Members',
    description:
      'Members are users that have been assigned roles within the organization, the role can be an admin with elevated privileges or a basic member. Each member has an associated email address.',
    highlightedWordsLength: 1,
  },
  {
    title: 'Channels',
    description:
      'A Channel is a container for Graphs, Packages (sensors and application specific requirements) and code that is used to define the universe of possible synthetic output for a particular application. For example, Channels may represent synthetic data generation as diverse as video of microscopy or satellite based SAR data acquisition. All of the components of a Channel together define the set of capabilities that solve a specific synthetic generation use-case.',
    highlightedWordsLength: 1,
  },
  {
    title: 'Volumes',
    description:
      'A Volume is used for storing large files during channel deployment to the platform. It is essentially a file system that the organization can transfer data into and out of, but is accessible to the channel during runtime.',
    highlightedWordsLength: 1,
  },
  {
    title: 'Volume Storage (GB)',
    description:
      "The Volume Storage limit determines the total size of data that can be stored in an organization's filesystem.",
    highlightedWordsLength: 2,
  },
  {
    title: 'Dataset Storage (GB)',
    description:
      'The Data Storage limit determines the total size of data that can be stored in the Datasets Library.',
    highlightedWordsLength: 2,
  },
  {
    title: 'Datasets per Workspace',
    description:
      'A Dataset is a variable collection of output images or video, masks, annotation, and other metadata that has been created by execution of a Job. Different Channels may contain different components depending on the specific application and problem domain of the Channel. Some sensor models, for example, may not lend themselves to easy creation of masks (images with pixel values capturing the location of scene assets).',
    highlightedWordsLength: 1,
  },
];

export const AWS_MARKETPLACE_URL =
  'https://aws.amazon.com/marketplace/pp/prodview-5vzxjt7atew7c?sr=0-1&ref_=beagle&applicationId=AWSMPContessa';
