import { Button } from '@components/button';
import { Image } from '@components/image';
import { Group } from '@components/layout';
import { ExternalLink } from '@components/link';
import { useContactUsModal, useModal } from '@components/modal';
import { Typography } from '@components/typography';
import { ContentCode } from '@generated/UseGraphqlHooks';
import { Box, Stack, SxProps, useTheme } from '@mui/material';
import posthog from 'posthog-js';
import { CreateContentCodeWorkspaceModal } from './CreateContentCodeWorkspaceModal';

const CardTag = ({ content }: { content: string }) => {
  const { palette } = useTheme();
  if (!content) return null;
  return (
    <Box
      sx={{
        backgroundColor: palette.mode === 'dark' ? palette.grey[50] : palette.grey[900],
        color: palette.text.secondary,
        height: '1rem',
        lineHeight: '1rem',
        px: 1,
        borderRadius: 1,
        display: 'inline-block',
        mr: 2,
        fontSize: '0.625rem',
        fontWeight: 700,
        mb: 1,
      }}
    >
      {content}
    </Box>
  );
};

const ContentCodeActions = ({ contentCode, sx }: { contentCode: ContentCode; sx?: SxProps }) => {
  const { showModal: openBillingContactUsModal } = useContactUsModal({
    componentProps: {
      defaultSubject: `Question about ${contentCode.name}`,
      defaultDescription: `Please contact me about the '${contentCode.name}' example in the Sample Gallery`,
    },
  });
  const handleContactUsClick = () => {
    posthog.capture('user_contact-us_intent', { location: 'sample-gallery-content-code' });
    openBillingContactUsModal();
  };
  const { showModal: openCreateWorkspaceModal } = useModal({
    component: CreateContentCodeWorkspaceModal,
    componentProps: { contentCode },
    modalProps: { title: 'Create Workspace' },
  });
  return (
    <Group gap={8} alignItems="center" sx={{ flexWrap: 'wrap', ...sx }}>
      {!contentCode.contactUs ? (
        <Button onClick={openCreateWorkspaceModal}>Create Workspace</Button>
      ) : (
        <Button variant="secondary" onClick={handleContactUsClick}>
          Contact Us
        </Button>
      )}
      {contentCode.link && <ExternalLink href={contentCode.link}>Learn more</ExternalLink>}
    </Group>
  );
};

type ContentCodeCardProps = {
  contentCode: ContentCode;
  fullWidth?: boolean;
  sx?: SxProps;
};

export const ContentCodeCard = ({ contentCode, fullWidth = false, sx }: ContentCodeCardProps) => {
  const { palette } = useTheme();

  if (!fullWidth) {
    return (
      <Box
        sx={{
          display: 'flex',
          backgroundColor: palette.background.paper,
          p: 4,
          gap: 5,
          height: '100%',
          ...sx,
        }}
      >
        <Image
          alt="codeThumbnail"
          url={contentCode.thumbnail}
          sx={{ width: '130px', height: '130px', flex: '1 0 auto', aspectRatio: '1' }}
        />
        <Stack sx={{ gap: 2 }}>
          <Typography variant="subtitle1" sx={{ color: palette.text.paper }}>
            {contentCode.name}
          </Typography>
          <Typography
            variant="caption2"
            sx={{ color: palette.text.light, flex: 1, minHeight: '3rem', display: 'inline-block' }}
          >
            {contentCode.featured && <CardTag content="FEATURED" />}
            {contentCode.description}
          </Typography>
          <ContentCodeActions contentCode={contentCode} sx={{ mt: 2 }} />
        </Stack>
      </Box>
    );
  }
  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{ backgroundColor: palette.background.paper, p: 4, pr: 8, gap: 5, ...sx }}
    >
      <Image
        alt="codeThumbnail"
        url={contentCode.thumbnail}
        sx={{ alignSelf: 'flex-start', width: '80px', height: '80px', aspectRatio: '1' }}
      />
      <Stack sx={{ gap: 2, flex: 1, alignSelf: 'flex-start' }}>
        <Typography variant="subtitle1" sx={{ color: palette.text.paper }}>
          {contentCode.name}
        </Typography>
        <Typography variant="caption2" sx={{ color: palette.text.light }}>
          {contentCode.featured && <CardTag content="FEATURED" />}
          {contentCode.description}
        </Typography>
      </Stack>
      <ContentCodeActions contentCode={contentCode} sx={{ justifyContent: 'flex-end' }} />
    </Stack>
  );
};
