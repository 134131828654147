import { Workspace } from '@generated/UseGraphqlHooks';
import { PropsWithChildren, createContext, useContext, useMemo } from 'react';

type WorkspaceContextType = {
  workspace: Workspace;
  refetch: VoidFunction;
  startPolling: (interval: number) => void;
  stopPolling: VoidFunction;
};

const WorkspaceContext = createContext<WorkspaceContextType>(null);

export const WorkspaceContextProvider = ({
  workspace,
  refetch,
  startPolling,
  stopPolling,
  children,
}: PropsWithChildren<WorkspaceContextType>) => {
  const workspaceContext = useMemo(
    () => ({ workspace, refetch, startPolling, stopPolling }),
    [workspace, refetch, startPolling, stopPolling],
  );
  return <WorkspaceContext.Provider value={workspaceContext}>{children}</WorkspaceContext.Provider>;
};

export const useCurrentWorkspace = () => useContext(WorkspaceContext);
export const useCurrentWorkspaceId = () => {
  const { workspace } = useCurrentWorkspace();
  return workspace?.workspaceId;
};
