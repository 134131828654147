import { Typography } from '@components/typography';
import { Annotation } from '@generated/UseGraphqlHooks';
import { Box, Grid, useTheme } from '@mui/material';
import { DatasetsDetailsAnnotationsTablePreview } from '@subsets/workspaces';
import { orderBy } from 'lodash';
import { BehaviorSubject } from 'rxjs';

type DatasetsDetailsAnnotationsTableProps = {
  annotations: Partial<Annotation>[];
  loading$?: BehaviorSubject<boolean>;
  datasetId?: string;
  workspaceId?: string;
  refetch: VoidFunction;
};

export const DatasetsDetailsAnnotationsTable = ({
  annotations,
  datasetId,
  workspaceId,
  refetch,
}: DatasetsDetailsAnnotationsTableProps) => {
  const { palette } = useTheme();
  const availableAnnotations = orderBy(annotations, ['updatedAt', 'desc']);

  const hasNoAnnotations = availableAnnotations.length === 0;

  return (
    <Box height="100%" width="100%">
      {hasNoAnnotations ? (
        <Box>
          <Typography variant="caption2" noWrap>
            This dataset has no annotations.
          </Typography>
        </Box>
      ) : (
        <>
          <Grid container alignItems="center">
            <Grid item xs={1.5}>
              <Typography color={palette.table.header} variant="caption2">
                Status
              </Typography>
            </Grid>
            <Grid item xs={1.5} sx={{ display: 'flex', justifyContent: 'center' }}>
              <Typography color={palette.table.header} variant="caption2">
                Id
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography color={palette.table.header} variant="caption2">
                Format
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography color={palette.table.header} variant="caption2">
                Map
              </Typography>
            </Grid>
          </Grid>
          {availableAnnotations.map((annotation) => (
            <DatasetsDetailsAnnotationsTablePreview
              datasetId={datasetId}
              key={annotation.annotationId}
              workspaceId={workspaceId}
              {...annotation}
              refetch={refetch}
            />
          ))}
        </>
      )}
    </Box>
  );
};
