import { Stack, Typography } from '@mui/material';
import { PropsWithChildren } from 'react';

export const WorkspacesAreaWrapper = ({
  children,
  gridArea,
  title,
}: PropsWithChildren<{ title: string; gridArea: string }>) => {
  return (
    <Stack gap={3} sx={{ height: '100%', width: '100%', overflow: 'hidden', gridArea }}>
      <Typography variant="subtitle1">{title}</Typography>
      {children}
    </Stack>
  );
};
