import { Box, ClickAwayListener, Stack, useTheme } from '@mui/material';
import { PropsWithChildren } from 'react';

export const ExpiredModalWrapper = ({
  children,
  onClickAway,
}: PropsWithChildren<{ onClickAway?: () => void }>) => {
  const { palette } = useTheme();
  return (
    <Box
      position="absolute"
      bottom={0}
      display="flex"
      justifyContent="center"
      alignItems="center"
      bgcolor="rgba(0,0,0,0.5)"
      zIndex={1000}
      width="-webkit-fill-available"
      overflow="auto"
      height="-webkit-fill-available"
      top={48}
    >
      <ClickAwayListener onClickAway={onClickAway}>
        <Stack
          py={8}
          px={15}
          gap={4}
          alignItems="center"
          sx={{ backgroundColor: palette.background.paper, width: '80%', maxWidth: '450px' }}
        >
          {children}
        </Stack>
      </ClickAwayListener>
    </Box>
  );
};
