import { ChevronDownIcon } from '@assets/icons';
import { IconButton } from '@components/button';
import { Image } from '@components/image';
import { Group } from '@components/layout';
import { useConfirmation } from '@components/modal';
import { Graph, useDeleteGraphMutation } from '@generated/UseGraphqlHooks';
import { Box, Stack, useTheme } from '@mui/material';
import { useNotifications } from '@notifications/Notifications';
import {
  JobsConfigurationsPreviewExpandedView,
  JobsConfigurationsPreviewInfo,
  JobsConfigurationsPreviewOptionsDropdown,
} from '@subsets/workspaces';
import { memo, useState } from 'react';

interface JobsConfigurationsPreviewProps {
  graph: Graph;
  refetch: () => void;
  refetchJobs: (additional: number) => void;
}

export const JobsConfigurationsPreview = memo(
  ({ graph, refetch, refetchJobs }: JobsConfigurationsPreviewProps) => {
    const { palette } = useTheme();
    const { showConfirmation } = useConfirmation();
    const { useAsyncNotification } = useNotifications();
    const [expanded, setExpanded] = useState(false);
    const handleClick = () => setExpanded(!expanded);

    const [deleteGraph] = useDeleteGraphMutation();

    const handleJobsPreviewDeleteClick = () => {
      void showConfirmation({
        onAffirm: useAsyncNotification(
          `You have successfully deleted the staged graph.`,
          async () => {
            await deleteGraph({
              variables: {
                workspaceId: graph.workspaceId,
                graphId: graph.graphId,
              },
            });
            refetch();
          },
        ),
        message: `Are you sure you want to delete graph: ${graph.name}?`,
      });
    };

    return (
      <Box data-cy="Jobs-Configurations-Preview">
        <Group p={3} gap={3} sx={{ backgroundColor: palette.background.paper }}>
          <Image
            style={{ aspectRatio: '1', height: '64px', width: '64px' }}
            url={graph?.thumbnail}
          />
          <Group flex={1} justifyContent="space-between" overflow="hidden">
            <JobsConfigurationsPreviewInfo
              name={graph.name}
              updatedAt={graph.updatedAt}
              createdAt={graph.createdAt}
            />
            <Group gap={3} alignItems="flex-start">
              <JobsConfigurationsPreviewOptionsDropdown
                workspaceId={graph.workspaceId}
                graphId={graph.graphId}
                graphName={graph.name}
                deleteHandle={handleJobsPreviewDeleteClick}
                refetch={refetch}
              />
              <IconButton
                onClick={handleClick}
                Icon={ChevronDownIcon}
                style={{
                  transition: 'all 0.2s',
                  transform: !expanded ? 'rotate(0deg)' : 'rotate(180deg)',
                }}
              />
            </Group>
          </Group>
        </Group>

        <JobsConfigurationsPreviewExpandedView
          {...graph}
          checked={expanded}
          refetchJobs={refetchJobs}
        />
      </Box>
    );
  },
);
