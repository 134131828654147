import { DeckardIconProps } from '@assets/types';
import { useTheme } from '@mui/material';
import { forwardRef } from 'react';

export const Outline2DIcon = forwardRef<SVGSVGElement, DeckardIconProps>((props, ref) => {
  const { palette } = useTheme();
  const { color, size } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
      width={size || 24}
      height={size || 24}
      viewBox="0 0 24 24"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      {...props}
    >
      <rect
        x="4.27271"
        y="4.27295"
        width="15.4545"
        height="15.4545"
        stroke={color || palette.icon.default}
        strokeWidth="2"
        className="stroke-override"
      />
      <circle
        cx="4.5"
        cy="4.5"
        r="2.5"
        fill={color || palette.icon.default}
        className="fill-override"
      />
      <circle
        cx="19.5"
        cy="4.5"
        r="2.5"
        fill={color || palette.icon.default}
        className="fill-override"
      />
      <circle
        cx="4.5"
        cy="19.5"
        r="2.5"
        fill={color || palette.icon.default}
        className="fill-override"
      />
      <circle
        cx="19.5"
        cy="19.5"
        r="2.5"
        fill={color || palette.icon.default}
        className="fill-override"
      />
    </svg>
  );
});
