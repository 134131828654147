import { ArrowLeftIcon, ArrowRightIcon } from '@assets/icons';
import { Button } from '@components/button';
import { useGetVolumesQuery } from '@generated/UseGraphqlHooks';
import { Grid, Stack } from '@mui/material';
import { filter, map } from 'lodash';
import { Dispatch, useEffect, useState } from 'react';
import { ResourcesType, TransferList, intersection, not } from './TransferList';

interface VolumesTransferListProps {
  organizationId: string;
  workspaceId: string;
  selectedVolumes: ResourcesType;
  setSelectedVolumes: Dispatch<ResourcesType>;
}

const getVolumes = (
  volumes: { volumeId: string; name: string }[],
  selected: string[],
  available?: boolean,
) => {
  return map(
    filter(volumes, ({ volumeId }) =>
      available ? !selected.includes(volumeId) : selected.includes(volumeId),
    ),
    ({ volumeId, name }) => ({ id: volumeId, name }),
  );
};

export const VolumesTransferList = ({
  organizationId,
  workspaceId,
  selectedVolumes,
  setSelectedVolumes,
}: VolumesTransferListProps) => {
  const [checked, setChecked] = useState<ResourcesType>([]);
  const [right, setRight] = useState<ResourcesType>([]);

  const { data: availableVolumes, loading: availableVolumesLoading } = useGetVolumesQuery({
    variables: {
      organizationId,
    },
  });

  const { data: includedVolumes, loading: includedVolumesLoading } = useGetVolumesQuery({
    variables: {
      workspaceId,
    },
  });
  const selectedVolumesIds = map(includedVolumes?.getVolumes, 'volumeId');

  useEffect(() => {
    if (!availableVolumesLoading && !includedVolumesLoading) {
      setRight(getVolumes(availableVolumes?.getVolumes, selectedVolumesIds, true));
      setSelectedVolumes(getVolumes(availableVolumes?.getVolumes, selectedVolumesIds, false));
    }

    return () => {
      setRight([]);
      setSelectedVolumes([]);
    };
  }, [availableVolumesLoading, includedVolumesLoading]);

  const leftChecked = intersection(checked, selectedVolumes);
  const rightChecked = intersection(checked, right);

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setSelectedVolumes(not(selectedVolumes, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setSelectedVolumes(selectedVolumes.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };
  return (
    <Grid container spacing={2} justifyContent="center" alignItems="center">
      <Grid item xs={5}>
        <TransferList
          title="Included"
          items={selectedVolumes}
          checked={checked}
          setChecked={setChecked}
        />
      </Grid>
      <Grid item xs={2}>
        <Grid container direction="column" alignItems="center">
          <Stack spacing="16px">
            <Button
              variant="secondary"
              onClick={handleCheckedLeft}
              data-cy="Volumes-Transfer-List-Add-Button"
              startIcon={ArrowLeftIcon}
              disabled={rightChecked.length === 0}
            >
              Add
            </Button>
            <Button
              variant="secondary"
              onClick={handleCheckedRight}
              data-cy="Volumes-Transfer-List-Remove-Button"
              disabled={leftChecked.length === 0}
              endIcon={ArrowRightIcon}
            >
              Remove
            </Button>
          </Stack>
        </Grid>
      </Grid>
      <Grid item xs={5}>
        <TransferList title="Excluded" items={right} checked={checked} setChecked={setChecked} />
      </Grid>
    </Grid>
  );
};
