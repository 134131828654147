import { createContext, PropsWithChildren, useContext, useMemo, useState } from 'react';
import { BehaviorSubject } from 'rxjs';

type DatasetsDetailsGanDatasetsProviderType = {
  deletedDatasets$: BehaviorSubject<string[]>;
};

export const DatasetsDetailsGanDatasetsContext =
  createContext<DatasetsDetailsGanDatasetsProviderType>(
    {} as DatasetsDetailsGanDatasetsProviderType,
  );

export const DatasetsDetailsGanDatasetsProvider = ({ children }: PropsWithChildren) => {
  const [deletedDatasets$] = useState(new BehaviorSubject<string[]>([]));
  const value = useMemo(() => ({ deletedDatasets$ }), [deletedDatasets$]);
  return (
    <DatasetsDetailsGanDatasetsContext.Provider value={value}>
      {children}
    </DatasetsDetailsGanDatasetsContext.Provider>
  );
};

export const useGanDatasets = () => useContext(DatasetsDetailsGanDatasetsContext);
