import { LoadingIcon } from '@assets/icons';
import { Typography } from '@components/typography';
import { useGetRecentJobsQuery } from '@generated/UseGraphqlHooks';
import { Box, Stack, useTheme } from '@mui/material';
import { RecentJobPreview } from './WorkspacesRecentJobsPreview';

interface WorkspaceRecentJobsProps {
  workspaceId: string;
}

export const WorkspaceRecentJobs = ({ workspaceId }: WorkspaceRecentJobsProps) => {
  const { palette } = useTheme();
  const { data, loading } = useGetRecentJobsQuery({
    variables: { workspaceId },
    pollInterval: 5000,
    fetchPolicy: 'no-cache',
  });

  const recentJobs = data?.getDatasetJobs || [];

  if (loading || recentJobs.length === 0) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
        width="100%"
        sx={{ backgroundColor: palette.background.paper }}
      >
        {!loading && recentJobs.length === 0 ? (
          <Typography>You have no jobs</Typography>
        ) : (
          <LoadingIcon size={16} />
        )}
      </Box>
    );
  }
  return (
    <Stack
      p={4}
      gap={4}
      sx={{
        height: '100%',
        backgroundColor: palette.background.paper,
        overflowX: 'hidden',
        overflowY: 'auto',
      }}
    >
      {recentJobs.map((job) => (
        <RecentJobPreview key={job.datasetId} job={job} />
      ))}
    </Stack>
  );
};
