import { LoadingIcon } from '@assets/icons';
import { useGetDatasetDetailsUmapsQuery } from '@generated/UseGraphqlHooks';
import { DatasetsDetailsUmapList } from '@subsets/workspaces';
import { DatasetDetailsAccordion } from './DatasetDetailsAccordion';

interface DatasetsDetailsUmapProps {
  workspaceId: string;
  datasetId: string;
}

export const DatasetsDetailsUmap = ({ workspaceId, datasetId }: DatasetsDetailsUmapProps) => {
  const { data, loading, refetch } = useGetDatasetDetailsUmapsQuery({
    variables: { datasetId, workspaceId },
  });

  const umaps = data?.getUMAPs || [];

  return (
    <DatasetDetailsAccordion
      title="UMAP"
      details={
        loading ? (
          <LoadingIcon size={8} />
        ) : (
          <DatasetsDetailsUmapList
            umaps={umaps}
            datasetId={datasetId}
            workspaceId={workspaceId}
            refetch={refetch}
          />
        )
      }
      count={!loading ? umaps.length : null}
    />
  );
};
