/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import * as d3 from 'd3';
import {
  createContext,
  memo,
  MutableRefObject,
  PropsWithChildren,
  SVGProps,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { BehaviorSubject } from 'rxjs';
import { CanvasContext } from './CanvasProvider';

interface ZoomContextType {
  zoomElement?: MutableRefObject<SVGGElement>;
  setZoomElement: (element: MutableRefObject<SVGGElement>) => void;
  percent: BehaviorSubject<number>;
  scale: BehaviorSubject<number>;
  offset: BehaviorSubject<[number, number]>;
  zoomTo: (percent: number, duration?: number) => void;
  percentScale?: d3.ScaleLinear<number, number>;
  moveTo: (position: [number, number], percent?: number) => void;
  centerGraphElements: () => void;
  getCenterPosition: () => [number, number];
}

export const ZoomContext = createContext({} as ZoomContextType);

export const ZoomArea = memo((props: SVGProps<SVGGElement>) => {
  const ref = useRef<SVGGElement>(null);
  const { setZoomElement } = useContext(ZoomContext);
  useEffect(() => {
    setZoomElement(ref);
  }, [ref?.current]);

  return <g ref={ref} width="100%" height="100%" {...props} />;
});

export const ZoomProvider = ({ children }: PropsWithChildren) => {
  const RANGE: [number, number] = [0.2, 2];
  const percentScale = d3.scaleLinear().domain([0, 200]).range(RANGE);
  const [percent$] = useState<BehaviorSubject<number>>(new BehaviorSubject(89));
  const [scale$] = useState<BehaviorSubject<number>>(
    new BehaviorSubject(percentScale(percent$.value)),
  );
  const [offset$] = useState<BehaviorSubject<[number, number]>>(new BehaviorSubject([0, 0]));
  const [zoomElement, setZoomElement] = useState<MutableRefObject<SVGGElement>>(null);
  const { svg } = useContext(CanvasContext);

  const zoom = useCallback(
    () =>
      d3
        .zoom()
        .scaleExtent(RANGE)
        .on('zoom', (event) => {
          percent$.next(percentScale.invert(event.transform.k));
          scale$.next(event.transform.k);
          offset$.next([event.transform.x, event.transform.y]);
          d3.select(zoomElement?.current).attr('transform', event.transform);
        }),
    [zoomElement, percent$, scale$],
  );

  const zoomTo = (percent: number, duration = 200) => {
    percent$.next(percent);
    if (process.env.JEST_WORKER_ID === undefined) {
      zoom().duration(duration).scaleTo(svg, percentScale(percent));
    }
  };

  const moveTo = (position: [number, number], percent?: number) => {
    if (process.env.JEST_WORKER_ID === undefined) {
      const [x, y] = position;
      zoom().duration(300).translateTo(svg, x, y);
      zoomTo(percent < 0 ? 0 : percent, 100);
    }
  };

  const getCenterPosition = (): [number, number] => {
    if (process.env.JEST_WORKER_ID === undefined) {
      const zoomElementPosition = d3.zoomTransform(zoomElement?.current);
      const { height, width } = svg.node().getBoundingClientRect();

      const topCorner = [
        -zoomElementPosition.x / zoomElementPosition.k,
        -zoomElementPosition.y / zoomElementPosition.k,
      ];
      const bottomCorner = [
        -zoomElementPosition.x / zoomElementPosition.k + width / zoomElementPosition.k,
        -zoomElementPosition.y / zoomElementPosition.k + height / zoomElementPosition.k,
      ];

      return [(topCorner[0] + bottomCorner[0]) / 2 || 0, (topCorner[1] + bottomCorner[1]) / 2 || 0];
    }
    return [0, 0];
  };

  const centerGraphElements = () => {
    if (!svg) {
      return;
    }
    const { width: svgWidth, height: svgHeight } = svg.node().getBoundingClientRect();
    const { height, width, x, y } = d3.select(zoomElement?.current).node().getBBox();
    const percentage = Math.min(
      percentScale.invert((0.75 * svgWidth) / width),
      percentScale.invert((0.75 * svgHeight) / height),
      100,
    );
    moveTo([x + width / 1.85, y + height / 2], percentage || 50);
  };

  useEffect(() => {
    svg?.call(
      d3
        .zoom()
        .filter((event) => !event.shiftKey)
        .scaleExtent(RANGE)
        .on('zoom', (event) => {
          percent$.next(percentScale.invert(event.transform.k));
          scale$.next(event.transform.k);
          offset$.next([event.transform.x, event.transform.y]);
          d3.select(zoomElement?.current).attr('transform', event.transform);
        }),
    );
  }, [svg]);

  const providerValue = useMemo(
    () => ({
      percent: percent$,
      scale: scale$,
      offset: offset$,
      zoomTo,
      zoomElement,
      setZoomElement,
      percentScale,
      moveTo,
      centerGraphElements,
      getCenterPosition,
    }),
    [
      percent$,
      scale$,
      offset$,
      zoomTo,
      zoomElement,
      setZoomElement,
      percentScale,
      moveTo,
      centerGraphElements,
      getCenterPosition,
    ],
  );

  return <ZoomContext.Provider value={providerValue}>{children}</ZoomContext.Provider>;
};
