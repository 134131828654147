import { styled } from '@mui/material';
import type { GatsbyLinkProps } from 'gatsby';
import { Link } from 'gatsby';

type InternalLinkProps = Omit<GatsbyLinkProps<Record<string, unknown>>, 'ref'> & {
  unstyled?: boolean;
};

const UnstyledLink = ({ style, unstyled = false, ...props }: InternalLinkProps) => {
  return <Link style={style} {...props} />;
};

export const InternalLink = styled(UnstyledLink, {
  shouldForwardProp: (prop) => prop !== 'unstyled' && prop !== 'sx',
})(({ theme, ...props }) => ({
  cursor: 'pointer',
  color: props.unstyled ? 'inherit' : theme.palette.link.default,
  textDecorationColor: props.unstyled ? 'inherit' : theme.palette.link.default,
  textDecoration: props.unstyled ? 'none' : 'underline',
  '&:hover': {
    color: `${props.unstyled ? 'inherit' : theme.palette.link.hover} !important`,
    textDecoration: props.unstyled ? 'none' : 'underline',
  },
}));
