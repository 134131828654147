import { gql, useMutation } from '@apollo/client';
import { MoreVerticalIcon } from '@assets/icons';
import { IconButton } from '@components/button';
import { useModal } from '@components/modal';
import { Typography } from '@components/typography';
import { saveFile } from '@helper-functions/save-file';
import { Box, Menu, MenuItem } from '@mui/material';
import { useNotifications } from '@notifications/Notifications';
import { MouseEvent, useState } from 'react';
import { DeleteGanModelModal } from './DeleteGanModelModal';
import { EditGanModelModal } from './EditGanModelModal';

const DownloadGanMutation = gql`
  mutation downloadManagedGAN($modelId: String!) {
    downloadManagedGAN(modelId: $modelId)
  }
`;

type GanModelTableRowDropdownProps = {
  name?: string;
  modelId?: string;
  organizationId?: string;
  description?: string;
  flags?: string;
  selectedOrganizationId?: string;
};

export const GanModelTableRowDropdown = ({
  name,
  modelId,
  organizationId,
  selectedOrganizationId,
  description,
  flags,
}: GanModelTableRowDropdownProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const { addNotification, removeNotification } = useNotifications();
  const [downloadManagedGAN] = useMutation<{ downloadManagedGAN: string }>(DownloadGanMutation);

  const isGanModelOwnedByOrganization = selectedOrganizationId === organizationId;
  const { showModal: showDeleteGanModel } = useModal({
    component: DeleteGanModelModal,
    componentProps: {
      name,
      modelId,
      organizationId: selectedOrganizationId,
      isGanModelOwnedByOrganization,
    },
    modalProps: { title: `${isGanModelOwnedByOrganization ? 'Delete' : 'Remove'} GAN model` },
  });
  const { showModal: showEditGanModel } = useModal({
    component: EditGanModelModal,
    componentProps: { name, description, modelId, flags, organizationId: selectedOrganizationId },
    modalProps: { title: 'Edit GAN model' },
  });
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const handleEditGanClick = () => {
    showEditGanModel();
    handleClose();
  };
  const handleDeleteGanClick = () => {
    showDeleteGanModel();
    handleClose();
  };

  const handleDownloadClick = async () => {
    const key = `${Date.now() * 1000}.${Math.random() * 10000}`;
    try {
      addNotification({ text: 'Loading...', type: 'loading', key, duration: 100000 });
      const response = await downloadManagedGAN({
        variables: { modelId },
      });
      if (response?.data?.downloadManagedGAN?.includes('https://')) {
        saveFile({
          url: response?.data?.downloadManagedGAN,
          name: `${modelId || ''}.pth`,
        });
        addNotification({
          text: 'Starting download...',
          type: 'success',
          duration: 6000,
        });
      } else {
        throw new Error('Unable to download gan model.');
      }
      removeNotification(key);
      handleClose();
    } catch (e) {
      removeNotification(key);
    }
  };

  return (
    <Box>
      <IconButton
        onClick={handleClick}
        Icon={MoreVerticalIcon}
        size={20}
        data-cy="Gan-Model-Table-Row-Dropdown-Icon"
      />
      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {isGanModelOwnedByOrganization ? (
          <Box>
            <MenuItem onClick={handleEditGanClick}>
              <Typography variant="caption2" data-cy="Gan-Model-Table-Row-Dropdown-Edit">
                Edit
              </Typography>
            </MenuItem>
            <MenuItem onClick={handleDownloadClick}>
              <Typography variant="caption2" data-cy="Gan-Model-Table-Row-Dropdown-Download">
                Download
              </Typography>
            </MenuItem>
            <MenuItem onClick={handleDeleteGanClick}>
              <Typography variant="caption2" data-cy="Gan-Model-Table-Row-Dropdown-Delete">
                Delete
              </Typography>
            </MenuItem>
          </Box>
        ) : (
          <Box>
            <MenuItem onClick={handleDeleteGanClick}>
              <Typography variant="caption2" data-cy="Gan-Model-Table-Row-Dropdown-Remove">
                Remove
              </Typography>
            </MenuItem>
          </Box>
        )}
      </Menu>
    </Box>
  );
};
