import { Tooltip as MuiTooltip, TooltipProps as MuiTooltipProps, useTheme } from '@mui/material';

export type TooltipProps = Omit<MuiTooltipProps, 'placement'> & {
  placement?: 'top' | 'right' | 'bottom' | 'left';
  variant?: 'primary' | 'secondary';
};

export const Tooltip = ({
  placement = 'top',
  variant = 'primary',
  PopperProps,
  disableInteractive = true,
  ...props
}: TooltipProps) => {
  const { palette } = useTheme();
  return (
    <MuiTooltip
      placement={placement}
      disableInteractive={disableInteractive}
      PopperProps={{
        sx: {
          '& .MuiTooltip-tooltip': {
            backgroundColor:
              variant === 'secondary' && palette.mode === 'dark'
                ? palette.background.paper
                : palette.grey[900],
            color:
              variant === 'secondary' && palette.mode === 'dark'
                ? palette.text.paper
                : palette.common.white,
          },
          '& .MuiTooltip-arrow': {
            color:
              variant === 'secondary' && palette.mode === 'dark'
                ? palette.background.paper
                : palette.grey[900],
          },
          ...PopperProps?.sx,
        },
        ...PopperProps,
      }}
      {...props}
      arrow={placement === 'top'}
    />
  );
};
