import { Button } from '@components/button';
import { Typography } from '@components/typography';
import {
  useDeleteManagedVolumeMutation,
  useGetOrganizationSettingsQuery,
  useGetVolumesQuery,
  useRemoveVolumeOrganizationMutation,
} from '@generated/UseGraphqlHooks';
import { delay } from '@helper-functions/delay';
import { Stack } from '@mui/material';
import { useNotifications } from '@notifications/Notifications';
import { useState } from 'react';

interface DeleteVolumeModalProps {
  name: string;
  volumeId: string;
  organizationId: string;
  onClose: () => void;
  isVolumeOwnedByOrganization: boolean;
}

export const DeleteVolumeModal = ({
  name,
  volumeId,
  organizationId,
  onClose,
  isVolumeOwnedByOrganization,
}: DeleteVolumeModalProps) => {
  const { useAsyncNotification } = useNotifications();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [deleteVolume] = useDeleteManagedVolumeMutation();
  const [removeOrganizationVolume] = useRemoveVolumeOrganizationMutation();
  const { refetch: refetchVolumes } = useGetVolumesQuery({
    variables: {
      organizationId,
    },
  });
  const { refetch: volumesSettingsRefetch } = useGetOrganizationSettingsQuery({
    variables: { organizationId },
  });

  const handleDeleteVolumeClick = useAsyncNotification(
    `Successfully ${isVolumeOwnedByOrganization ? 'deleted' : 'removed'} the volume`,
    async () => {
      setIsSubmitting(true);
      isVolumeOwnedByOrganization
        ? await deleteVolume({ variables: { volumeId } })
        : await removeOrganizationVolume({
            variables: {
              volumeId,
              organizationId,
            },
          });
      await delay(2000);
      await refetchVolumes();
      await volumesSettingsRefetch();
      setIsSubmitting(false);
      onClose();
    },
  );

  return (
    <Stack pt={4} gap={10} justifyContent="center">
      <Typography variant="body2" align="center">
        Are you sure you want to {isVolumeOwnedByOrganization ? 'delete' : 'remove'} {name}?
      </Typography>
      <Stack gap={4}>
        <Button fullWidth disabled={isSubmitting} onClick={handleDeleteVolumeClick}>
          Yes, {isVolumeOwnedByOrganization ? 'delete' : 'remove'}
        </Button>
        <Button fullWidth onClick={onClose} variant="secondary">
          Cancel
        </Button>
      </Stack>
    </Stack>
  );
};
