import { Box, Menu, useTheme } from '@mui/material';
import { MouseEventHandler, useState } from 'react';
import { HexColorPicker } from 'react-colorful';
import { ColorPickerBaseProps } from 'react-colorful/dist/types';

type ColorPickerProps = Partial<ColorPickerBaseProps<string>>;

export const ColorPicker = ({ color, onChange, ...props }: ColorPickerProps) => {
  const { palette } = useTheme();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick: MouseEventHandler<HTMLElement> = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => setAnchorEl(null);

  return (
    <Box sx={{ height: '36px', width: '36px', p: 1.5 }}>
      <Box
        sx={{
          height: '24px',
          width: '24px',
          borderRadius: 1,
          border: `1px solid ${palette.grey[500]}`,
          backgroundColor: color,
          '&:hover': {
            cursor: 'pointer',
          },
        }}
        onClick={handleClick}
      />
      <Menu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        slotProps={{
          paper: {
            sx: {
              transform: 'translate(0px, -12px) !important',
              p: 4,
              borderRadius: 1,
              '& .MuiList-root': {
                py: 0,
              },
              '& .react-colorful__pointer': {
                height: '24px',
                width: '24px',
                '&:hover': {
                  cursor: 'pointer',
                },
              },
              '& .react-colorful__saturation': {
                borderTopLeftRadius: '4px',
                borderTopRightRadius: '4px',
                borderBottomRightRadius: 0,
                borderBottomLeftRadius: 0,
              },
              '& .react-colorful__hue': {
                borderBottomLeftRadius: '4px',
                borderBottomRightRadius: '4px',
                borderTopRightRadius: 0,
                borderTopLeftRadius: 0,
              },
            },
          },
        }}
      >
        <HexColorPicker color={color} onChange={onChange} {...props} />
      </Menu>
    </Box>
  );
};
