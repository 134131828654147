import { DeckardIcon } from '@assets/types';
import { InternalLink } from '@components/link';
import { Typography } from '@components/typography';
import { Stack, useTheme } from '@mui/material';

type HeaderItemType = {
  icon: DeckardIcon;
  label: string;
  partiallyActive?: boolean;
  path: string;
  width: string;
};

export const HeaderItem = ({
  icon: Icon,
  label,
  partiallyActive = false,
  path,
  width,
}: HeaderItemType) => {
  const { palette } = useTheme();

  return (
    <InternalLink
      unstyled
      partiallyActive={partiallyActive}
      activeStyle={{ backgroundColor: palette.grey[800] }}
      to={path}
      style={{ height: '100%', width }}
    >
      <Stack
        sx={{
          height: '100%',
          paddingX: 2,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          '&:hover svg': {
            stroke: palette.grey[200],
          },
        }}
      >
        <Icon size={20} color={palette.grey[500]} />
        {label && (
          <Typography variant="navigation" color={palette.common.white}>
            {label}
          </Typography>
        )}
      </Stack>
    </InternalLink>
  );
};
