import { Select } from '@components/form';
import { useGetWorkspacesQuery } from '@generated/UseGraphqlHooks';
import { Box, MenuItem } from '@mui/material';
import { sortBy } from 'lodash';

interface WorkspaceDropdownProps {
  organizationId: string;
  selectedWorkspaceId: string;
  onWorkspaceChange: (workspaceId: string) => void;
}

export const WorkspaceDropdown = ({
  organizationId,
  selectedWorkspaceId,
  onWorkspaceChange,
}: WorkspaceDropdownProps) => {
  const { data: workspaceData, loading: workspaceDataLoading } = useGetWorkspacesQuery({
    variables: { organizationId },
  });
  const workspaces = workspaceData?.getWorkspaces || [];

  const handleSelectChange = ({ target: { value } }) => {
    if (onWorkspaceChange) {
      onWorkspaceChange(value as string);
    }
  };
  const findWorkspaceNameById = (workspaceId: string) => {
    return workspaces.find((workspace) => workspace.workspaceId === workspaceId)?.name;
  };

  return (
    <Box maxWidth="75%">
      {!workspaceDataLoading && workspaces.length > 0 && selectedWorkspaceId && (
        <Select value={selectedWorkspaceId} onChange={handleSelectChange}>
          {sortBy(workspaces, ['name'])?.map((workspace) => (
            <MenuItem key={workspace.workspaceId} value={workspace.workspaceId}>
              {workspace.name}
            </MenuItem>
          ))}
        </Select>
      )}
    </Box>
  );
};
