import { Group } from '@components/layout';
import { Typography } from '@components/typography';
import { useGetImageInferenceQuery } from '@generated/UseGraphqlHooks';
import { getContrastTextColor } from '@helper-functions/color';
import { Box, Stack } from '@mui/material';
import { InferenceSettings } from './DatasetsDetailsImagesSliderTypes';

export const DatasetsDetailsImagesSliderInferenceOverlay = ({
  fileName,
  imageDimensions,
  settings,
  workspaceId,
}: {
  fileName: string;
  imageDimensions: [number, number];
  settings: InferenceSettings;
  workspaceId: string;
}) => {
  const { data } = useGetImageInferenceQuery({
    variables: { workspaceId, inferenceId: settings?.inferenceId, image: fileName },
  });
  if (!data?.getImageInference?.inferences) {
    return null;
  }
  return (
    <>
      {data?.getImageInference?.inferences?.map(({ bbox, className, confidence }, index) => {
        if (!bbox) {
          return null;
        }
        if (!settings?.visible) {
          return null;
        }
        if (parseFloat(confidence) * 100 < settings?.confidenceThreshold) {
          return null;
        }
        return (
          <g key={index} width={bbox[2]} overflow="visible">
            {settings?.showLabels && (
              <foreignObject x={bbox[0]} y={bbox[1]} height="16px" overflow="visible">
                <Group
                  justifyContent="space-between"
                  gap={1}
                  sx={{
                    backgroundColor: settings?.color,
                    px: 1,
                    position: 'absolute',
                    right:
                      bbox[0] + bbox[2] > imageDimensions[0] * 0.75
                        ? -bbox[2] - settings?.strokeWidth / 4
                        : undefined,
                    top: bbox[1] - 14 < 0 ? bbox[3] - settings?.strokeWidth / 4 : -14,
                  }}
                >
                  <Typography
                    variant="caption2"
                    color={getContrastTextColor(settings?.color)}
                    style={{ fontSize: '8px', lineHeight: '16px', whiteSpace: 'nowrap' }}
                  >
                    {className}
                  </Typography>
                  <Typography
                    variant="caption2"
                    color={getContrastTextColor(settings?.color)}
                    style={{ fontSize: '8px', lineHeight: '16px', whiteSpace: 'nowrap' }}
                  >
                    {(Number.parseFloat(confidence) * 100).toFixed(2)}%
                  </Typography>
                </Group>
              </foreignObject>
            )}
            <foreignObject
              width={bbox[2] + settings?.strokeWidth / 2}
              height={bbox[3]}
              x={bbox[0]}
              y={bbox[1]}
            >
              <Box
                sx={{
                  height: '100%',
                  width: '100%',
                  border: `${settings?.strokeWidth}px solid ${settings?.color}`,
                }}
              />
            </foreignObject>
          </g>
        );
      })}
      Sorry, your browser does not support inline SVG.
    </>
  );
};
