import { GraphDataContext, PositionContext, SelectionContext } from '@subsets/workspaces';
import { useObservableState } from 'observable-hooks';
import { useContext } from 'react';
import { useKey } from 'react-use';
import { Link } from './Link';

interface PortSelection {
  portId: string;
  nodeId: string;
}
export interface LinkBetweenPortsProps {
  id: string;
  from: PortSelection;
  to: PortSelection;
  disabled?: boolean;
}

export const LinkBetweenPorts = ({ from, to, id, disabled }: LinkBetweenPortsProps) => {
  const { getPortPosition } = useContext(PositionContext);
  const { removeSelectedLink } = useContext(GraphDataContext);

  const getPosition = ({ nodeId, portId }: { nodeId: string; portId: string }) =>
    getPortPosition(nodeId, portId);
  const { isSelected, selectedNodeList, selectLink, isSelectedLink } = useContext(SelectionContext);
  const isActive = isSelected(from?.nodeId) || isSelected(to?.nodeId) || isSelectedLink(id);

  useObservableState(selectedNodeList);
  useKey('Delete', async () => {
    if (isSelectedLink(id) && !disabled) {
      await removeSelectedLink(id);
    }
  });
  useKey('Backspace', async () => {
    if (isSelectedLink(id) && !disabled) {
      await removeSelectedLink(id);
    }
  });

  const linkOrigin = getPosition(from);
  const linkDestination = getPosition(to);
  const handleLinkSelection = () => selectLink(id);

  return (
    <Link
      from={linkOrigin}
      to={linkDestination}
      inactive={!isActive}
      onClick={handleLinkSelection}
    />
  );
};
