import { Button } from '@components/button';
import { Typography } from '@components/typography';
import { getCookies } from '@helper-functions/cookies';
import { Box } from '@mui/material';
import { useLandingPageProvider } from '@subsets/landing-page';
import { navigate } from 'gatsby';
import Cookies from 'js-cookie';
import { isNil } from 'lodash';
import { useEffect } from 'react';
import { ExpiredModalWrapper } from './ExpiredModalWrapper';
import { ProgressCircle } from './ProgressCircle';

const setOrganizationExpiringSoonToken = () => {
  const date = new Date();
  return Cookies.set('OrganizationExpiration', 'Shown', {
    path: '/',
    domain: window.location.hostname,
    expires: date.setTime(date.getTime() + +(24 * 60 * 60 * 1000)),
  });
};

const calculateProgressBarPercentage = (day: number) => {
  switch (day) {
    case 1:
      return 90;
    case 2:
      return 75;
    case 3:
      return 60;
    case 4:
      return 45;
    case 5:
      return 30;
    case 6:
      return 15;
    case 7:
      return 0;
    default:
      return undefined;
  }
};

type ExpirationModalProps = {
  days: number;
  organizationId: string;
};

export const ExpiringSoonModal = ({ days, organizationId }: ExpirationModalProps) => {
  const { closeExpirationModal, showExpirationModal$ } = useLandingPageProvider();
  const cookies = getCookies() as { OrganizationExpiration: 'Shown' };

  useEffect(() => {
    if (isNil(cookies.OrganizationExpiration)) {
      setOrganizationExpiringSoonToken();
    } else {
      showExpirationModal$.next(false);
    }
  }, []);

  const handleResubscribeButton = async () => {
    await navigate(`/organizations/${organizationId}/billing`);
  };

  return (
    <ExpiredModalWrapper onClickAway={closeExpirationModal}>
      <Box display="flex" justifyContent="center">
        <Typography variant="subtitle1">Your subscription is expiring soon!</Typography>
      </Box>
      <ProgressCircle
        content={`${days} ${days > 1 ? 'days' : 'day'}`}
        warning
        value={calculateProgressBarPercentage(days)}
      />
      <Typography variant="body2" textAlign="center">
        Your subscription expires in {days} {days > 1 ? 'days' : 'day'}.
      </Typography>
      <Button fullWidth onClick={handleResubscribeButton}>
        Subscribe
      </Button>
      <Button variant="secondary" fullWidth onClick={closeExpirationModal}>
        Continue
      </Button>
    </ExpiredModalWrapper>
  );
};
