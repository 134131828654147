import { LoadingIcon } from '@assets/icons';
import { Select } from '@components/form';
import { Group } from '@components/layout';
import { Typography } from '@components/typography';
import { useGetDatasetLogLazyQuery, useGetDatasetRunsQuery } from '@generated/UseGraphqlHooks';
import { delay } from '@helper-functions/delay';
import { Box, Container, MenuItem, Stack, useTheme } from '@mui/material';
import {
  JobsManagerJobPreviewLogsScreenRow,
  JobsManagerPreviewLogsScreenSaveToFile,
} from '@subsets/workspaces';
import { filter, find, isNil, map, upperFirst } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { VariableSizeList } from 'react-window';

type DatasetLogType = {
  message: string;
  timestamp: string;
  ingestionTime: string;
};

interface JobsManagerJobPreviewLogsScreenProps {
  datasetId: string;
  workspaceId: string;
}

export const JobsManagerJobPreviewLogsScreen = ({
  datasetId,
  workspaceId,
}: JobsManagerJobPreviewLogsScreenProps) => {
  const { palette } = useTheme();
  const [logHelpers, setLogHelpers] = useState({
    state: 'all',
    run: '0',
    runOptions: ['0'],
  });
  const { data: datasetRunsData, loading: getDatasetRunsLoading } = useGetDatasetRunsQuery({
    variables: {
      datasetId,
      workspaceId,
    },
  });
  const [queryDatasetLogs, { data: datasetLogsData, loading: datasetLogsLoading }] =
    useGetDatasetLogLazyQuery();
  const datasetRuns = datasetRunsData?.getDatasetRuns;
  const datasetMetadata = datasetRunsData?.getDatasetJobs?.[0] || { name: '' };

  const states = (datasetRuns || [])
    .map((run) => run.state)
    .filter((state, index, array) => array.indexOf(state) === index && state !== 'cancelled');
  const datasetLogsString = datasetLogsData?.getDatasetLog.log || '[]';
  const datasetLogs = JSON.parse(`${datasetLogsString}`) as DatasetLogType[];
  const listRef = useRef<VariableSizeList>();
  const sizeMap = useRef({});

  const fetchDatasetLogs = async (run?: number) => {
    const runId = find(datasetRuns, { run: !isNil(run) ? run : Number(logHelpers.run) })?.runId;
    await queryDatasetLogs({
      variables: {
        workspaceId,
        datasetId,
        runId,
      },
    });
  };

  useEffect(() => {
    if (datasetRuns && datasetRuns.length > 0) {
      void fetchDatasetLogs();
    }
  }, [datasetRuns]);

  useEffect(() => {
    const { state } = logHelpers;
    const datasets =
      state === 'all'
        ? datasetRuns
        : filter(datasetRuns, (datasetRun) => datasetRun.state === state);
    const runOptions = map(datasets, ({ run }) => run);
    const sortedRuns = runOptions.sort((a, b) => a - b);
    setLogHelpers({
      ...logHelpers,
      runOptions: runOptions.map((option) => option.toString()),
      run: sortedRuns.includes(Number(logHelpers.run))
        ? logHelpers.run
        : sortedRuns.length > 0
          ? sortedRuns[0].toString()
          : '0',
    });
    if (sortedRuns.length > 0) {
      void fetchDatasetLogs(sortedRuns[0]);
    }
  }, [logHelpers.state, datasetRuns]);

  const scrollToBottom = async () => {
    await delay(10).then(() => {
      listRef?.current?.scrollToItem(datasetLogs?.length, 'auto');
    });
  };

  useEffect(() => {
    if (!datasetLogsLoading && !listRef.current && datasetLogs.length > 0) {
      void scrollToBottom();
    }
  }, [listRef, datasetLogsLoading, datasetLogs]);

  const handleRunChange = async ({ target: { value } }) => {
    setLogHelpers({ ...logHelpers, run: value as string });
    await fetchDatasetLogs(Number(value));
  };
  const handleStateChange = ({ target: { value } }) =>
    setLogHelpers({ ...logHelpers, state: value as string });

  const setRowSize = useCallback((index: number, rowSize: number) => {
    sizeMap.current = { ...sizeMap.current, [index]: rowSize };
    listRef.current.resetAfterIndex(index);
  }, []);

  const getRowSize = useCallback(
    (index: number): number => {
      return (sizeMap.current[index] as number) || 20;
    },
    [sizeMap],
  );

  if (getDatasetRunsLoading) {
    return (
      <Container
        maxWidth="lg"
        sx={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <LoadingIcon size={16} />
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ flex: 1, display: 'flex', flexDirection: 'column', gap: 6 }}>
      <Typography variant="h1" sx={{ mt: 10 }}>
        {datasetMetadata?.name || '-'} logs
      </Typography>
      <Group alignItems="flex-end" justifyContent="space-between">
        <Group gap={4}>
          <Select
            label="State"
            value={logHelpers.state}
            onChange={handleStateChange}
            sx={{ minWidth: '200px' }}
          >
            {['all', ...states].map((state) => (
              <MenuItem value={state}>{upperFirst(state)}</MenuItem>
            ))}
          </Select>
          <Select
            label="Run"
            value={logHelpers.run}
            onChange={handleRunChange}
            sx={{ minWidth: '200px' }}
          >
            {logHelpers.runOptions.map((option) => (
              <MenuItem value={option}>{option}</MenuItem>
            ))}
          </Select>
        </Group>
        <JobsManagerPreviewLogsScreenSaveToFile
          datasetId={datasetId}
          run={logHelpers.run}
          datasetLogs={datasetLogs}
          name={datasetMetadata?.name}
        />
      </Group>

      {datasetLogsLoading && (
        <Stack alignItems="center" justifyContent="center" sx={{ height: '100%' }}>
          <LoadingIcon size={16} />
        </Stack>
      )}
      {!datasetLogsLoading && datasetLogs.length > 0 && (
        <Box flex={1} sx={{ backgroundColor: palette.background.paper }}>
          <AutoSizer>
            {({ height, width }) => (
              <VariableSizeList
                ref={listRef}
                height={height as number}
                width={width as number}
                itemCount={datasetLogs.length}
                itemSize={getRowSize}
                itemData={datasetLogs}
              >
                {({ index, style }) => (
                  <div style={style}>
                    <JobsManagerJobPreviewLogsScreenRow
                      index={index}
                      setRowSize={setRowSize}
                      data={datasetLogs}
                    />
                  </div>
                )}
              </VariableSizeList>
            )}
          </AutoSizer>
        </Box>
      )}
      {!datasetLogsLoading && datasetLogs.length === 0 && (
        <Typography color={palette.grey[100]} mt={4}>
          No logs found
        </Typography>
      )}
    </Container>
  );
};
