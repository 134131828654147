import { Typography } from '@components/typography';
import { Box, Stack } from '@mui/material';
import { map } from 'lodash';

const CONTENT = [
  '3 additional 3D models',
  '1 additional radiometrically accurate 3D model',
  'Access to DIRSIG simulator',
  'Access to Rendered.ai SAR simulator',
  'Access to Quadridox X-ray simulator',
  '1 additional compute instance',
  '10 additional 2D backgrounds',
  'Trained CycleGAN',
  'Modtran atmosphere model',
  'Custom 1/2 day end user training per channel',
  'Channel Jump Start',
];

export const CreditsTopics = () => {
  return (
    <Stack height="100%" width="100%" mr={8} pt="34px">
      {map(CONTENT, (line, index) => (
        <Box
          py="8px"
          borderTop={index === 0 && '1px solid #CFD1DE'}
          borderBottom="1px solid #CFD1DE"
          key={index}
        >
          <Typography variant="body2" key={index}>
            {line}
          </Typography>
        </Box>
      ))}
    </Stack>
  );
};
