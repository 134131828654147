import { AsyncButton, Button } from '@components/button';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  Form,
  FormSelect,
  FormTextInput,
} from '@components/form';
import { Group } from '@components/layout';
import { Typography } from '@components/typography';
import {
  useAttachPaymentMethodToCustomerMutation,
  useSaveDefaultPaymentMethodMutation,
} from '@generated/UseGraphqlHooks';
import { delay } from '@helper-functions/delay';
import { Box, Grid, MenuItem, Stack, useTheme } from '@mui/material';
import { useNotifications } from '@notifications/Notifications';
import {
  CardNumberElement as StripeCardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { find, get, isEmpty } from 'lodash';
import { useState } from 'react';
import * as yup from 'yup';
import {
  STRIPE_AVAILABLE_COUNTRIES_LIST,
  useOrganizationsBilling,
} from './OrganizationsBillingProvider';

const formInitialValues = {
  fullName: '',
  organization: '',
  addressLine1: '',
  addressLine2: '',
  cardNumber: '',
  expiration: '',
  cvv: '',
  city: '',
  state: '',
  country: '',
  zip: '',
  email: '',
  phone: '',
};

const validationSchema = yup.object({
  fullName: yup.string().required('Name is required'),
  organization: yup.string().required('Organization is required'),
  cardNumber: yup.string().required('Card Number is required'),
  expiration: yup.string().required('Expiration is required'),
  cvv: yup.string().required('CVV is required'),
  email: yup.string().email('Enter a valid email').required('Email is required'),
  phone: yup.number().min(10).required('Phone number is required'),
  addressLine1: yup.string().required('Address is required'),
  country: yup.string().required('Country is required'),
  city: yup.string().required('City is required'),
  state: yup.string().required('State is required'),
  zip: yup.string().min(5).required('Postal code is required'),
});

export const OrganizationsBillingModalPaymentDetails = ({
  onClose,
  onContinue,
}: {
  onClose: () => void;
  onContinue: () => void;
}) => {
  const { palette } = useTheme();
  const { organizationId, selectedPlan, stripeProducts } = useOrganizationsBilling();
  const { useAsyncNotification } = useNotifications();
  const stripe = useStripe();
  const elements = useElements();
  const { name, unit_amount: unitAmount } = get(stripeProducts, selectedPlan);
  const [saveDefaultPaymentMethod] = useSaveDefaultPaymentMethodMutation();
  const [attachPaymentMethodToCustomer] = useAttachPaymentMethodToCustomerMutation();

  const handleFormikSubmit = useAsyncNotification(
    'Saved card information',
    async ({
      addressLine1,
      addressLine2,
      country,
      city,
      organization,
      state,
      zip,
      email,
      fullName,
      phone,
    }: {
      addressLine1?: string;
      addressLine2?: string;
      country?: string;
      city?: string;
      organization?: string;
      state?: string;
      zip?: string;
      email?: string;
      fullName?: string;
      phone?: string;
    }) => {
      if (!stripe || !elements) {
        return;
      }
      const cardNumberElement = elements.getElement(StripeCardNumberElement);
      const { paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardNumberElement,
        billing_details: {
          email,
          name: fullName,
          phone,
          address: {
            city,
            country,
            state,
            postal_code: zip,
            line1: addressLine1,
            line2: addressLine2,
          },
        },
        metadata: { organization },
      });
      const {
        data: { attachPaymentMethodToCustomer: attachPaymentMethodToCustomerData },
      } = await attachPaymentMethodToCustomer({
        variables: { paymentMethodId: paymentMethod.id, organizationId },
      });
      if (attachPaymentMethodToCustomerData !== 'Attached payment method to customer') {
        throw new Error('Please check your information again');
      }
      const {
        data: { saveDefaultPaymentMethod: saveDefaultPaymentMethodData },
      } = await saveDefaultPaymentMethod({
        variables: { organizationId, paymentMethodId: paymentMethod.id },
      });
      if (saveDefaultPaymentMethodData !== 'Default payment method set.') {
        throw new Error('Please check your information again');
      }
      await delay(1000);
      onContinue();
    },
  );

  const countryName = (code: string) => {
    const country = find(STRIPE_AVAILABLE_COUNTRIES_LIST, { alpha2Code: code });
    return country.name;
  };

  return (
    <Form
      validateOnBlur={false}
      validateOnChange={false}
      initialValues={formInitialValues}
      validationSchema={validationSchema}
      onSubmit={handleFormikSubmit}
      formProps={{ style: { height: '100%' } }}
      formGroupProps={{ style: { height: '100%' } }}
    >
      {({ handleSubmit, errors, isSubmitting }) => (
        <Stack height="100%" overflow="hidden" gap={6}>
          <Typography variant="h2">Payment Details</Typography>
          <Stack
            pr={2}
            pb={8}
            maxHeight="75vh"
            style={{ overflowY: 'auto', overflowX: 'hidden', flex: 1 }}
            gap={10}
          >
            <Box
              bgcolor={palette.mode === 'dark' ? palette.primary[700] : palette.primary.light}
              p="18px"
            >
              <Typography style={{ paddingBottom: '8px' }}>
                You have selected the {name} Plan!
              </Typography>
              <Typography style={{ paddingBottom: '8px' }}>
                Subscription Cost: ${(unitAmount / 100).toFixed(2)}/month
              </Typography>
              <Typography>Due Today: ${(unitAmount / 100).toFixed(2)}</Typography>
            </Box>
            <Box>
              <Grid container spacing={6}>
                <Grid item xs={7}>
                  <FormTextInput label="Name on Card" name="fullName" />
                  <CardNumberElement label="Card Number" name="cardNumber" />
                  <Grid container spacing={5}>
                    <Grid item xs={8}>
                      <CardExpiryElement label="Expiration (MM/YY)" name="expiration" />
                    </Grid>
                    <Grid item xs={4}>
                      <CardCvcElement label="CVV" name="cvv" />
                    </Grid>
                  </Grid>
                  <FormTextInput name="phone" label="Phone" />
                  <FormTextInput label="Email" name="email" />
                </Grid>

                <Grid item xs={5}>
                  <FormTextInput label="Organization" name="organization" />
                  <FormTextInput label="Address Line 1" name="addressLine1" />
                  <FormTextInput label="Address Line 2" name="addressLine2" />
                  <FormTextInput label="City" name="city" />
                  <FormSelect label="Country" name="country">
                    {STRIPE_AVAILABLE_COUNTRIES_LIST.map((country) => (
                      <MenuItem value={country.alpha2Code}>
                        {countryName(country.alpha2Code)}
                      </MenuItem>
                    ))}
                  </FormSelect>
                  <Grid container spacing={5}>
                    <Grid item xs={7}>
                      <FormTextInput label="State/Province" name="state" />
                    </Grid>
                    <Grid item xs={5}>
                      <FormTextInput label="Postal Code" name="zip" />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Stack>
          <Group justifyContent="flex-end" alignItems="center" width="100%" gap={4}>
            <Button variant="secondary" onClick={onClose}>
              Back
            </Button>
            <AsyncButton type="submit" disabled={!isEmpty(errors) || isSubmitting}>
              Continue
            </AsyncButton>
          </Group>
        </Stack>
      )}
    </Form>
  );
};
