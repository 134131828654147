import { ChevronDownIcon, LoadingIcon, SearchIcon } from '@assets/icons';
import { TextInput } from '@components/form';
import { Typography } from '@components/typography';
import {
  useGetOrganizationsQuery,
  useGetWorkspacesLazyQuery,
  useGetWorkspacesQuery,
} from '@generated/UseGraphqlHooks';
import { useSearch } from '@hooks/UseSearch';
import { Box, Grid, Menu, MenuItem, Stack, Tab, Tabs, useTheme } from '@mui/material';
import { navigate } from 'gatsby';
import { filter, isNil, map, size, sortBy } from 'lodash';
import { ChangeEvent, KeyboardEvent, useEffect, useRef, useState } from 'react';

export const WorkspaceSelection = ({ workspaceId }: { workspaceId: string }) => {
  const { data: workspaceData } = useGetWorkspacesQuery({ variables: { workspaceId } });
  const workspace = workspaceData?.getWorkspaces?.[0];
  const containerRef = useRef();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { palette } = useTheme();

  const [value, setValue] = useState<string>();

  useEffect(() => {
    if (workspace?.organizationId) {
      setValue(workspace?.organizationId);
    }
  }, [workspace]);

  const [getOrganizationWorkspaces, { data: workspacesData, loading: workspacesLoading }] =
    useGetWorkspacesLazyQuery();
  const { data: organizationsData, loading: organizationsDataLoading } = useGetOrganizationsQuery();

  const organizations = organizationsData?.getOrganizations || [];
  const workspaces = workspacesData?.getWorkspaces || [];

  useEffect(() => {
    void getOrganizationWorkspaces({ variables: { organizationId: value } });
  }, [value]);

  const handleClick = () => {
    if (organizationsDataLoading) {
      return;
    }
    setAnchorEl(containerRef.current);
  };
  const handleClose = () => setAnchorEl(null);
  const handleChange = (_: ChangeEvent<HTMLInputElement>, tabValue: string) => setValue(tabValue);
  const sortedOrganizations = sortBy(organizations, ({ name: organizationName }) =>
    String(organizationName).toLowerCase(),
  );
  const mappedOrganizations = {};
  if (!isNil(organizations)) {
    map(sortedOrganizations, ({ organizationId }: { organizationId: string }, index) => {
      mappedOrganizations[index] = organizationId;
    });
  }

  const filteredWorkspaces = filter(
    workspaces,
    ({ organizationId }: { organizationId: string }) => organizationId === value,
  );

  const { data: organizedWorkspaces, onSearch } = useSearch(filteredWorkspaces);
  const sortedWorkspaces = sortBy(organizedWorkspaces, ({ name: workspaceName }) =>
    String(workspaceName).toLowerCase(),
  );

  const navigateToWorkspace = (id: string) => async () => {
    await navigate(`/workspaces/${id}`);
    onSearch('');
    handleClose();
  };

  const handleSearchThroughWorkspace = ({
    target: { value: targetValue },
  }: {
    target: { value: string };
  }) => {
    onSearch(targetValue);
  };

  return (
    <>
      <Box
        ref={containerRef}
        sx={{
          height: '100%',
          flex: 1,
          transition: 'all 0.25s',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 2,
          maxWidth: '18vw',
          cursor: 'pointer',
          backgroundColor: Boolean(anchorEl) ? palette.grey[800] : 'transparent',
          paddingX: 2,
        }}
        onClick={handleClick}
      >
        <Typography
          id="color"
          variant="body1"
          color={palette.common.white}
          noWrap
          maxWidth="100%"
          handleOverFlow
          data-cy="Workspace-Selection-Selected-Workspace-Name"
        >
          {workspace?.name || '-'}
        </Typography>
        <ChevronDownIcon
          size={20}
          data-cy="Workspace-Selection-Dropdown-Icon"
          style={{
            transition: 'all 0.25s',
            transform: Boolean(anchorEl) ? 'rotate(180deg)' : 'rotate(0deg)',
          }}
        />
      </Box>
      <Menu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        elevation={0}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        disableAutoFocusItem
        sx={{
          '& .MuiMenu-paper': {
            width: '500px',
            borderTopLeftRadius: 0,
          },
          '& .MuiMenu-list': { padding: 0 },
        }}
      >
        <Grid container>
          <Grid item xs={5} py={4} bgcolor={palette.background.default}>
            {organizationsDataLoading && (
              <Box display="flex" justifyContent="center">
                <Typography>Loading...</Typography>
              </Box>
            )}
            {!organizationsDataLoading && (
              <Tabs
                sx={{
                  '& .MuiTabs-indicator': {
                    backgroundColor: 'transparent',
                  },
                }}
                orientation="vertical"
                value={value}
                onChange={handleChange}
              >
                {map(sortedOrganizations, ({ name: orgName, organizationId }, index) => (
                  <Tab
                    value={organizationId}
                    sx={{
                      color: palette.text.primary,
                      alignItems: 'flex-start',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      textTransform: 'none',
                      whiteSpace: 'nowrap',
                      '&.MuiButtonBase-root.MuiTab-root.Mui-selected': {
                        backgroundColor: palette.background.paper,
                        color: palette.text.primary,
                      },
                      '&:hover': {
                        backgroundColor: palette.backgroundHover.default,
                      },
                    }}
                    label={
                      <Typography handleOverFlow variant="body2">
                        {orgName}
                      </Typography>
                    }
                    key={index}
                  />
                ))}
              </Tabs>
            )}
          </Grid>
          <Grid item xs={7} pt={4} sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
            <TextInput
              name="search"
              onKeyDown={(e: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) =>
                e.stopPropagation()
              }
              startIcon={SearchIcon}
              onChange={handleSearchThroughWorkspace}
              containerProps={{ sx: { paddingX: 4 } }}
            />
            {workspacesLoading && (
              <Box
                marginTop={-4}
                marginBottom={4}
                height="300px"
                display="flex"
                alignItems="center"
                justifyContent="center"
                textAlign="center"
              >
                <LoadingIcon size={8} />
              </Box>
            )}
            {!workspacesLoading && size(organizedWorkspaces) === 0 && (
              <Box
                marginTop={-4}
                marginBottom={4}
                height="300px"
                display="flex"
                alignItems="center"
                justifyContent="center"
                textAlign="center"
              >
                <Typography>No workspaces were found for this organization.</Typography>
              </Box>
            )}
            {!workspacesLoading && size(organizedWorkspaces) > 0 && (
              <Stack px={2} width="100%" height="300px" style={{ overflowY: 'auto' }}>
                {map(
                  sortedWorkspaces as { workspaceId: string; name: string }[],
                  ({ workspaceId: id, name: workspaceName }) => (
                    <MenuItem
                      key={id}
                      className={workspaceId === id ? 'selected' : ''}
                      onClick={navigateToWorkspace(id)}
                    >
                      <Typography variant="body2" handleOverFlow>
                        {workspaceName}
                      </Typography>
                    </MenuItem>
                  ),
                )}
              </Stack>
            )}
          </Grid>
        </Grid>
      </Menu>
    </>
  );
};
