import { AsyncButton, Button } from '@components/button';
import { Form, FormTextInput } from '@components/form';
import { Group } from '@components/layout';
import { ExternalLink } from '@components/link';
import { Modal } from '@components/modal';
import { Typography } from '@components/typography';
import { useCreateUmapMutation } from '@generated/UseGraphqlHooks';
import { Box, Divider, Grid, Stack, useTheme } from '@mui/material';
import { useNotifications } from '@notifications/Notifications';
import { DatasetsCompareModalDataset, useDatasets } from '@subsets/workspaces';
import { navigate } from 'gatsby';
import { mapValues } from 'lodash';
import posthog from 'posthog-js';
import { Fragment, memo } from 'react';
import * as yup from 'yup';

type DatasetsCompareModalProps = {
  onClose: () => void;
  open: boolean;
  workspaceId: string;
};

const validationSchema = yup.object({
  name: yup
    .string()
    .trim()
    .max(128, 'Must be less than 128 characters')
    .required('Name is required'),
  samples: yup.lazy((samples) =>
    yup.object(
      mapValues(samples, (value, key) =>
        yup
          .number()
          .min(5, 'Must include at least 5 images per dataset')
          .max(1000, 'Must include at most 1000 images per dataset'),
      ),
    ),
  ),
});

export const DatasetsCompareModal = memo(
  ({ workspaceId, open, onClose }: DatasetsCompareModalProps) => {
    const { palette } = useTheme();
    const { datasets, selectedDatasets, setSelectedDataset } = useDatasets();
    const { useAsyncNotification } = useNotifications();
    const [createUmapMutation] = useCreateUmapMutation();

    const handleAffirm = useAsyncNotification(
      'Now navigating to comparison page...',
      async ({
        name,
        fitDataset,
        samples,
      }: {
        name: string;
        fitDataset: string;
        samples: Record<string, number>;
      }) => {
        const datasetIds = [
          fitDataset,
          ...selectedDatasets.filter((dataset) => dataset !== fitDataset),
        ];
        const { data } = await createUmapMutation({
          variables: {
            workspaceId,
            datasetIds,
            samples: datasetIds.map((datasetId) => samples[datasetId]),
            seed: '1',
            name,
          },
        });
        posthog.capture('user_create-umap_submitted');
        setSelectedDataset(fitDataset);
        await navigate(`/workspaces/${workspaceId}/experiments/umap/${data?.createUMAP}`);
        onClose();
      },
    );

    return (
      <Modal
        title="Compare Datasets"
        open={open}
        onClose={onClose}
        help={DatasetsCompareModalHelpContent}
      >
        <Form
          initialValues={{
            name: '',
            fitDataset: selectedDatasets[0],
            samples: selectedDatasets.reduce((agg, current) => {
              return {
                ...agg,
                [current]: datasets.find((dataset) => current === dataset.datasetId)?.files || 0,
              };
            }, {}) as Record<string, number>,
          }}
          validateOnBlur={false}
          validationSchema={validationSchema}
          onSubmit={handleAffirm}
        >
          {({ isSubmitting, handleSubmit, isValid, dirty, errors }) => (
            <Stack sx={{ minWidth: '700px' }}>
              <FormTextInput name="name" label="UMAP name *" />

              <Box sx={{ overflowX: 'hidden', overflowY: 'auto', maxHeight: '300px', mb: 4 }}>
                <Grid container>
                  <Grid item xs={11} />
                  <Grid item xs={1}>
                    <Typography textAlign="center" variant="caption2" color={palette.grey[700]}>
                      Fit dataset
                    </Typography>
                  </Grid>
                </Grid>
                {selectedDatasets?.map((datasetId: string) => (
                  <Fragment key={datasetId}>
                    <DatasetsCompareModalDataset
                      datasetId={datasetId}
                      fileCount={datasets.find((dataset) => dataset.datasetId === datasetId)?.files}
                    />
                    <Divider />
                  </Fragment>
                ))}
              </Box>

              <Group justifyContent="flex-end" gap={4} alignItems="center">
                {errors?.['samples'] && (
                  <Typography variant="caption2" color={palette.input.label.error}>
                    {Object.entries(errors['samples'])?.[0]?.[1] as string}
                  </Typography>
                )}
                <AsyncButton disabled={isSubmitting || !(isValid && dirty)} onClick={handleSubmit}>
                  Compare
                </AsyncButton>
                <Button onClick={onClose} variant="secondary">
                  Cancel
                </Button>
              </Group>
            </Stack>
          )}
        </Form>
      </Modal>
    );
  },
);

export const DatasetsCompareModalHelpContent = (
  <Typography variant="body2">
    The compare datasets service compares the imagery between two datasets using a dimensionality
    reduction technique. The way our service works is that we first gather features using a Feature
    Pyramid Network to determine what Object Detection models are seeing in images at different
    levels of feature size. Then we reduce those features using{' '}
    <ExternalLink href="https://umap-learn.readthedocs.io/en/latest/">UMAP</ExternalLink> as a
    dimensionality reduction technique for visualizing datasets in a 2D or 3D space. We generate an
    interactive 3D plot where users can click on data points to view images. Using this, users can
    compare the imagery of two or more datasets and infer what is making them similar or different.
  </Typography>
);
