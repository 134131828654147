import { OverridableStringUnion } from '@mui/types';
import { CSSProperties } from 'react';

declare module '@mui/material/styles' {
  interface TypographyVariants {
    caption1: CSSProperties;
    caption2: CSSProperties;
    navigation: CSSProperties;
  }

  interface TypographyVariantsOptions {
    caption1?: CSSProperties;
    caption2?: CSSProperties;
    navigation?: CSSProperties;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    caption1: true;
    caption2: true;
    navigation: true;
    h4: false;
    h5: false;
    h6: false;
    caption: false;
    overline: false;
  }
}

export interface TypographyVariantOverrides {}

export type DefaultTypographyType = OverridableStringUnion<
  'caption1' | 'caption2' | 'navigation' | 'inherit',
  TypographyVariantOverrides
>;

export const typography = {
  fontFamily: 'Lato, sans-serif',
  fontSize: 14,
  h1: {
    fontSize: '1.5rem',
    lineHeight: '2rem',
    fontWeight: 700,
  },
  h2: {
    fontSize: '1.3125rem',
    lineHeight: '1.75rem',
    fontWeight: 700,
  },
  h3: {
    fontSize: '1.125rem',
    lineHeight: '1.75rem',
    fontWeight: 700,
  },
  subtitle1: {
    fontSize: '1rem',
    lineHeight: '1.5rem',
    fontWeight: 700,
  },
  subtitle2: {
    fontSize: '1rem',
    lineHeight: '1.5rem',
    fontWeight: 400,
  },
  body1: {
    fontSize: '0.875rem',
    lineHeight: '1.3125rem',
    fontWeight: 700,
  },
  body2: {
    fontSize: '0.875rem',
    lineHeight: '1.3125rem',
    fontWeight: 400,
  },
  caption1: {
    fontSize: '0.75rem',
    lineHeight: '1rem',
    fontWeight: 700,
  },
  caption2: {
    fontSize: '0.75rem',
    lineHeight: '1rem',
    fontWeight: 400,
  },
  navigation: {
    fontSize: '0.625rem',
    lineHeight: '0.875rem',
    fontWeight: 700,
  },
  h4: undefined,
  h5: undefined,
  h6: undefined,
  caption: undefined,
  overline: undefined,
};
