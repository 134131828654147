import { useGetChannelSchemaLazyQuery, usePingPreviewMutation } from '@generated/UseGraphqlHooks';
import { PropsWithChildren, createContext, useContext, useEffect, useMemo, useState } from 'react';
import { BehaviorSubject } from 'rxjs';
import { GraphDataContext } from './GraphDataProvider';
import { ChannelNode } from './types';

export const ChannelDetailContext = createContext({
  data$: new BehaviorSubject<ChannelNode[]>([]),
});

export const ChannelDetailProvider = ({ children }: PropsWithChildren) => {
  const [data$] = useState(new BehaviorSubject<ChannelNode[]>(null));
  const { graphMetadata } = useContext(GraphDataContext);
  const [pingPreview] = usePingPreviewMutation();

  const [getChannelSchema] = useGetChannelSchemaLazyQuery();

  useEffect(() => {
    if (graphMetadata?.channelId) {
      getChannelSchema({
        variables: { channelId: graphMetadata?.channelId },
      })
        .then(({ data }) => {
          if (data?.getChannelSchema) {
            const schema = JSON.parse(data?.getChannelSchema || '') as ChannelNode[];
            data$.next(schema);
          }
        })
        .catch(() => console.error('Error retrieving channel schema.'));

      void pingPreview({ variables: { channelId: graphMetadata.channelId } });
      const intervalId = setInterval(() => {
        void pingPreview({ variables: { channelId: graphMetadata.channelId } });
      }, 60000);
      return () => clearInterval(intervalId);
    }
    return () => {};
  }, [graphMetadata?.channelId]);

  const valueProvider = useMemo(() => ({ data$ }), [data$]);
  return (
    <ChannelDetailContext.Provider value={valueProvider}>{children}</ChannelDetailContext.Provider>
  );
};
