export const QueryPolicies = {
  getAnalytics: {
    keyArgs: ['workspaceId', 'datasetId'],
    merge(existing, incoming, { readField }) {
      const merged = { ...existing };
      incoming.forEach((item) => {
        merged[String(readField('analyticsId', item))] = item;
      });
      return merged;
    },
    read(existing) {
      return existing && Object.values(existing);
    },
  },
  getAnnotationMaps: {
    keyArgs: ['organizationId', 'workspaceId', 'mapId'],
    merge(existing, incoming, { readField }) {
      const merged = { ...existing };
      incoming.forEach((item) => {
        merged[String(readField('mapId', item))] = item;
      });
      return merged;
    },
    read(existing) {
      return existing && Object.values(existing);
    },
  },
  getChannels: {
    keyArgs: ['organizationId', 'workspaceId', 'channelId'],
    merge(existing, incoming, { readField }) {
      const merged = { ...existing };
      incoming.forEach((item) => {
        merged[String(readField('channelId', item))] = item;
      });
      return merged;
    },
    read(existing) {
      return existing && Object.values(existing);
    },
  },
  getDatasetJobs: {
    keyArgs: ['organizationId', 'workspaceId'],
    merge(existing, incoming, { readField }) {
      const merged = { ...existing };
      incoming.forEach((item) => {
        merged[String(readField('datasetId', item))] = item;
      });
      return merged;
    },
    read(existing) {
      return existing && Object.values(existing);
    },
  },
  getDatasets: {
    keyArgs: ['workspaceId', 'datasetId', 'filters'],
    merge(existing, incoming, { readField }) {
      const merged = { ...existing };
      incoming.forEach((item) => {
        merged[String(readField('datasetId', item))] = item;
      });
      return merged;
    },
    read(existing) {
      return existing && Object.values(existing);
    },
  },
  getGANModels: {
    keyArgs: ['organizationId', 'workspaceId', 'modelId'],
    merge(existing, incoming, { readField }) {
      const merged = { ...existing };
      incoming.forEach((item) => {
        merged[String(readField('modelId', item))] = item;
      });
      return merged;
    },
    read(existing) {
      return existing && Object.values(existing);
    },
  },
  getGraphs: {
    keyArgs: ['workspaceId', 'graphId', 'staged', 'filters'],
    merge(existing, incoming, { readField }) {
      const merged = { ...existing };
      incoming.forEach((item) => {
        merged[String(readField('graphId', item))] = item;
      });
      return merged;
    },
    read(existing) {
      return existing && Object.values(existing);
    },
  },
  getMLModels: {
    keyArgs: ['workspaceId', 'datasetId', 'modelId'],
    merge(existing, incoming, { readField }) {
      const merged = { ...existing };
      incoming.forEach((item) => {
        merged[String(readField('modelId', item))] = item;
      });
      return merged;
    },
    read(existing) {
      return existing && Object.values(existing);
    },
  },
  getMLInferences: {
    keyArgs: ['workspaceId', 'modelId', 'datasetId', 'inferenceId'],
    merge(existing, incoming, { readField }) {
      const merged = { ...existing };
      incoming.forEach((item) => {
        merged[String(readField('inferenceId', item))] = item;
      });
      return merged;
    },
    read(existing) {
      return existing && Object.values(existing);
    },
  },
  getUMAPs: {
    keyArgs: ['workspaceId', 'datasetId', 'umapId'],
    merge(existing, incoming, { readField }) {
      const merged = { ...existing };
      incoming.forEach((item) => {
        if (item) {
          merged[String(readField('umapId', item))] = item;
        }
      });
      return merged;
    },
    read(existing) {
      return existing && Object.values(existing);
    },
  },
  getVolumeData: {
    keyArgs: ['volumeId', 'dir', 'keys'],
    merge(existing, incoming) {
      if (!existing) {
        return incoming;
      }
      return {
        keys: [...existing.keys, ...incoming.keys],
        pageInfo: incoming.pageInfo,
      };
    },
  },
  getVolumes: {
    keyArgs: ['organizationId', 'workspaceId', 'volumeId'],
    merge(existing, incoming, { readField }) {
      const merged = { ...existing };
      incoming.forEach((item) => {
        merged[String(readField('volumeId', item))] = item;
      });
      return merged;
    },
    read(existing) {
      return existing && Object.values(existing);
    },
  },
  getWorkspaces: {
    keyArgs: ['organizationId', 'workspaceId'],
    merge(existing, incoming, { readField }) {
      const merged = { ...existing };
      incoming.forEach((item) => {
        merged[String(readField('workspaceId', item))] = item;
      });
      return merged;
    },
    read(existing) {
      return existing && Object.values(existing);
    },
  },
};
