import { Stack, StackProps } from '@mui/material';
import { useField } from 'formik';
import { TextInput, TextInputProps } from '../TextInput';
import { FormFieldError } from './FormFieldError';

export type FormTextInputProps = Omit<TextInputProps, 'error'> & {
  fullwidth?: boolean;
  name: string;
  containerProps?: StackProps;
};

export const FormTextInput = ({
  maintainGutters = true,
  name,
  fullwidth = true,
  containerProps = {},
  ...props
}: FormTextInputProps) => {
  const [field, meta] = useField(name);
  const error = meta?.touched && meta?.error;
  return (
    <Stack sx={{ width: fullwidth ? '100%' : 'fit-content', ...containerProps.sx }}>
      <TextInput {...field} {...props} name={name} error={Boolean(error)} />
      <FormFieldError error={error} maintainGutters={maintainGutters} />
    </Stack>
  );
};
