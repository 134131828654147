import { DeckardIconProps } from '@assets/types';
import { useTheme } from '@mui/material';
import { forwardRef } from 'react';

export const GridIcon = forwardRef<SVGSVGElement, DeckardIconProps>((props, ref) => {
  const { palette } = useTheme();
  const { color, size } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
      width={size || 24}
      height={size || 24}
      viewBox="0 0 24 24"
      fill="none"
      stroke={color || palette.icon.default}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="stroke-override"
      {...props}
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M3 3H10V10H3V3Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M14 3H21V10H14V3Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M14 14H21V21H14V14Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M3 14H10V21H3V14Z" />
    </svg>
  );
});
