import { Typography } from '@components/typography';
import { TableCell, TableRow } from '@mui/material';

export const GraphsListViewHeader = () => {
  return (
    <TableRow>
      <TableCell>
        <Typography variant="caption2">Preview</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="caption2">Id</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="caption2">Name</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="caption2">Description</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="caption2">Created by</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="caption2">Created at</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="caption2">Last edited</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="caption2">Channel</Typography>
      </TableCell>
    </TableRow>
  );
};
