import { createContext, PropsWithChildren, useContext, useMemo, useState } from 'react';
import { BehaviorSubject } from 'rxjs';

interface LandingPageContextType {
  tabValue$: BehaviorSubject<string>;
  showExpirationModal$: BehaviorSubject<boolean>;
  closeExpirationModal: () => void;
  showExpirationModal: () => void;
}

export const LandingPageContext = createContext({} as LandingPageContextType);
export const LandingPageProvider = ({
  tab = '',
  children,
}: PropsWithChildren<{ tab?: string }>) => {
  const [tabValue$] = useState(new BehaviorSubject(tab));

  const [showExpirationModal$] = useState(new BehaviorSubject<boolean>(true));

  const closeExpirationModal = () => {
    showExpirationModal$.next(false);
  };

  const showExpirationModal = () => {
    showExpirationModal$.next(true);
  };

  const value = useMemo(
    () => ({
      tabValue$,

      closeExpirationModal,
      showExpirationModal$,
      showExpirationModal,
    }),
    [tabValue$, closeExpirationModal, showExpirationModal$, showExpirationModal],
  );
  return <LandingPageContext.Provider value={value}>{children}</LandingPageContext.Provider>;
};
export const useLandingPageProvider = () => useContext(LandingPageContext);
