import { Typography } from '@components/typography';
import { Container, Grid, useTheme } from '@mui/material';
import { useEffect, useRef } from 'react';

type DatasetLogType = {
  message: string;
  timestamp: string;
  ingestionTime: string;
};

interface JobsManagerJobPreviewLogsScreenRowType {
  index: number;
  data: DatasetLogType[];
  setRowSize: (index: number, height: number) => void;
}

export const JobsManagerJobPreviewLogsScreenRow = ({
  data,
  index,
  setRowSize,
}: JobsManagerJobPreviewLogsScreenRowType) => {
  const { palette } = useTheme();
  const rowRef = useRef<HTMLDivElement>();
  const datasetLog = data[index];

  useEffect(() => {
    if (rowRef) {
      setRowSize(index, rowRef.current.getBoundingClientRect().height);
    }
  }, [setRowSize, index, rowRef]);

  return (
    <Grid container ref={rowRef} px={2} py={1}>
      <Grid item xs={1.5}>
        <Typography color={palette.text.paper}>
          {new Date(datasetLog.timestamp).toISOString()}
        </Typography>
      </Grid>
      <Grid item xs={10.5} style={{ overflowWrap: 'break-word' }}>
        <Typography color={palette.text.paper}>{datasetLog.message}</Typography>
      </Grid>
    </Grid>
  );
};
