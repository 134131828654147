import { EditIcon, LoadingIcon } from '@assets/icons';
import { IconButton } from '@components/button';
import { Group } from '@components/layout';
import { Typography } from '@components/typography';
import { useGetCustomerPaymentMethodsQuery } from '@generated/UseGraphqlHooks';
import { Box, Stack, useTheme } from '@mui/material';
import { find, maxBy, size } from 'lodash';
import { OrganizationsBillingPaymentInformationDataBlock } from './OrganizationsBillingPaymentInformationDataBlock';

interface OrganizationsBillingPaymentInformationPreviewProps {
  organizationId: string;
  onEditInformation: () => void;
}

export const OrganizationsBillingPaymentInformationPreview = ({
  organizationId,
  onEditInformation,
}: OrganizationsBillingPaymentInformationPreviewProps) => {
  const { palette } = useTheme();
  const { data: customerPaymentMethodsData, loading } = useGetCustomerPaymentMethodsQuery({
    variables: { organizationId },
  });
  const customerPaymentMethods = customerPaymentMethodsData?.getCustomerPaymentMethods;
  const paymentMethodUsedForLastTransaction = find(
    customerPaymentMethods,
    ({ created }) => created === maxBy(customerPaymentMethods, 'created')?.created,
  );
  const { billing_details: billingDetails, card } = paymentMethodUsedForLastTransaction || {};

  return (
    <>
      <OrganizationsBillingPaymentInformationDataBlock
        label="Contact Information"
        data={
          <Group
            alignItems="flex-start"
            justifyContent="space-between"
            height="100%"
            width="50%"
            flex={1}
          >
            {loading ? (
              <LoadingIcon color={palette.text.paper} />
            ) : size(customerPaymentMethods) > 0 ? (
              <>
                <Box display="flex" flexDirection="column">
                  {billingDetails?.name && (
                    <Typography handleOverFlow variant="body2">
                      {billingDetails?.name}
                    </Typography>
                  )}
                  {billingDetails?.email && (
                    <Typography handleOverFlow variant="body2">
                      {billingDetails?.email}
                    </Typography>
                  )}
                  {billingDetails?.phone && (
                    <Typography handleOverFlow variant="body2">
                      {billingDetails?.phone}
                    </Typography>
                  )}
                  {billingDetails?.address?.line1 && (
                    <Typography handleOverFlow variant="body2">
                      {billingDetails?.address?.line1}
                    </Typography>
                  )}
                  {(billingDetails?.address?.city ||
                    billingDetails?.address?.state ||
                    billingDetails?.address?.postal_code) && (
                    <Typography handleOverFlow variant="body2">
                      {`${billingDetails?.address?.city}, ${billingDetails?.address?.state} ${billingDetails?.address?.postal_code}` ||
                        'Loading...'}
                    </Typography>
                  )}
                </Box>
                <IconButton onClick={onEditInformation} Icon={EditIcon} />
              </>
            ) : (
              <>
                <Typography variant="body2" sx={{ flex: 1, textAlign: 'center' }}>
                  -
                </Typography>
                <IconButton onClick={onEditInformation} Icon={EditIcon} />
              </>
            )}
          </Group>
        }
      />
      <OrganizationsBillingPaymentInformationDataBlock
        label="Credit Card"
        data={
          <Group
            alignItems="flex-start"
            justifyContent="space-between"
            height="100%"
            width="100%"
            flex={1}
          >
            {loading ? (
              <LoadingIcon color={palette.text.paper} />
            ) : size(customerPaymentMethods) > 0 ? (
              <>
                <Stack>
                  <Typography handleOverFlow variant="body2">
                    XXXX XXXX XXXX {card?.last4 || '####'}
                  </Typography>
                  <Typography handleOverFlow variant="body2">
                    Expires {card?.exp_month}/{Number(card?.exp_year) % 100}
                  </Typography>
                </Stack>
                <IconButton onClick={onEditInformation} Icon={EditIcon} />
              </>
            ) : (
              <>
                <Typography variant="body2" sx={{ flex: 1, textAlign: 'center' }}>
                  -
                </Typography>
                <IconButton onClick={onEditInformation} Icon={EditIcon} />
              </>
            )}
          </Group>
        }
      />
    </>
  );
};
