import { Typography } from '@components/typography';
import { TableCell, TableRow } from '@mui/material';

export const WorkspaceTableHeader = () => {
  return (
    <TableRow>
      <TableCell>
        <Typography variant="caption1">Id</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="caption1">Name</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="caption1">Channels</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="caption1">Last modified</Typography>
      </TableCell>
    </TableRow>
  );
};
