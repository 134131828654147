import { LoadingIcon, MaximizeIcon } from '@assets/icons';
import { IconButton } from '@components/button';
import { Image } from '@components/image';
import { Group } from '@components/layout';
import { useModal } from '@components/modal';
import { Typography } from '@components/typography';
import { useGetDatasetThumbnailsQuery } from '@generated/UseGraphqlHooks';
import { Stack } from '@mui/material';
import { DatasetsDetailsImagesSlider } from '@subsets/workspaces/libraries/datasets/DatasetsDetailsImagesSlider';
import { map } from 'lodash';
import { useSelectedDataset } from './DatasetsProvider';

interface DatasetsDetailsImagesProps {
  datasetId: string;
  workspaceId: string;
}

export const DatasetsDetailsImages = ({ datasetId, workspaceId }: DatasetsDetailsImagesProps) => {
  const { data, loading: renderedImagesLoading } = useGetDatasetThumbnailsQuery({
    variables: { workspaceId, datasetId },
  });
  const previewUrls = data?.getDatasetThumbnails;

  const { name, runs } = useSelectedDataset();

  const { showModal } = useModal({
    component: DatasetsDetailsImagesSlider,
    componentProps: {
      workspaceId,
      datasetId,
      datasetName: name,
      previewUrls,
      loading: renderedImagesLoading,
      runs,
    },
    modalProps: {
      sx: {
        '&.deckard-modal-container': {
          width: '80vw',
          height: '80vh',
        },
      },
    },
  });

  const handleOpenImageSliderOpen = () => {
    showModal({ componentProps: { imageIndex: 0 } });
  };

  const handleOpenIndexedImage = (index: number) => () => {
    showModal({ componentProps: { imageIndex: index } });
  };
  const noImages = previewUrls?.length === 0;

  return (
    <Stack>
      <Group alignItems="center" justifyContent="space-between">
        <Typography variant="body1">Images</Typography>
        {!noImages && <IconButton onClick={handleOpenImageSliderOpen} Icon={MaximizeIcon} />}
      </Group>
      <Group
        gap={2}
        pt={4}
        pb={1}
        style={{ overflowX: 'auto', whiteSpace: 'nowrap', height: '150px', overflowY: 'hidden' }}
      >
        {renderedImagesLoading && (
          <Group height="110px" width="100%" justifyContent="center" alignItems="center">
            <LoadingIcon size={16} />
          </Group>
        )}
        {!renderedImagesLoading && noImages ? (
          <Group height="110px" width="100%" justifyContent="center" alignItems="center">
            <Typography variant="body2">No images</Typography>
          </Group>
        ) : (
          map(previewUrls, (imgUrl, index) => (
            <Image
              url={imgUrl}
              key={imgUrl}
              sx={{
                cursor: 'pointer',
                width: '110px',
                height: '110px',
                flexShrink: 0,
              }}
              onClick={handleOpenIndexedImage(index)}
            />
          ))
        )}
      </Group>
    </Stack>
  );
};
