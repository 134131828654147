import { useConfirmation } from '@components/modal';
import { useDeleteGraphMutation } from '@generated/UseGraphqlHooks';
import { MenuItem } from '@mui/material';
import { useNotifications } from '@notifications/Notifications';
import { navigate } from 'gatsby';

type GraphsListViewPreviewMenuDeleteConfigButtonProps = {
  graphId: string;
  name: string;
  workspaceId: string;
  refetch: () => void;
  onClose: () => void;
};

export const GraphsListViewPreviewMenuDeleteConfigButton = ({
  graphId,
  name,
  workspaceId,
  refetch,
  onClose,
}: GraphsListViewPreviewMenuDeleteConfigButtonProps) => {
  const { useAsyncNotification } = useNotifications();
  const { showConfirmation } = useConfirmation({
    onAffirm: useAsyncNotification('Successfully deleted graph.', async () => {
      await deleteGraph();
      refetch();
      await navigate(`/workspaces/${workspaceId}/graphs/`);
    }),
    message: `Are you sure you want to delete ${`${name} ` || ''}graph?`,
    onReject: onClose,
  });

  const [deleteGraph] = useDeleteGraphMutation({ variables: { workspaceId, graphId } });
  const handleDeleteConfigClick = async () => {
    onClose();
    await showConfirmation();
  };

  return (
    <MenuItem data-cy="Graph-Header-Dropdown-Delete" onClick={handleDeleteConfigClick}>
      Delete
    </MenuItem>
  );
};
