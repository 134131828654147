import { ArrowLeftIcon, ArrowRightIcon } from '@assets/icons';
import { Button } from '@components/button';
import { useGetGanModelsQuery } from '@generated/UseGraphqlHooks';
import { Grid, Stack } from '@mui/material';
import { filter, map } from 'lodash';
import { Dispatch, useEffect, useState } from 'react';
import { ResourcesType, TransferList, intersection, not } from './TransferList';

interface GanTransferListProps {
  organizationId: string;
  workspaceId: string;
  selectedGanModels: ResourcesType;
  setSelectedGanModels: Dispatch<ResourcesType>;
}

const getGANs = (
  gans: { modelId: string; name: string }[],
  selected: string[],
  available?: boolean,
) => {
  return map(
    filter(gans, ({ modelId }) =>
      available ? !selected.includes(modelId) : selected.includes(modelId),
    ),
    ({ modelId, name }) => ({ id: modelId, name }),
  );
};

export const GanTransferList = ({
  organizationId,
  workspaceId,
  selectedGanModels,
  setSelectedGanModels,
}: GanTransferListProps) => {
  const [checked, setChecked] = useState<ResourcesType>([]);
  const [right, setRight] = useState<ResourcesType>([]);
  const { data: availableGanModels, loading: availableGanModelsLoading } = useGetGanModelsQuery({
    variables: { organizationId },
  });
  const { data: includedGanModels, loading: includedGanModelsLoading } = useGetGanModelsQuery({
    variables: {
      workspaceId,
    },
  });
  const selectedGanModelsIds = map(includedGanModels?.getGANModels, 'modelId');

  useEffect(() => {
    if (!availableGanModelsLoading && !includedGanModelsLoading) {
      setRight(getGANs(availableGanModels?.getGANModels, selectedGanModelsIds, true));
      setSelectedGanModels(getGANs(availableGanModels?.getGANModels, selectedGanModelsIds, false));
    }

    return () => {
      setRight([]);
      setSelectedGanModels([]);
    };
  }, [availableGanModelsLoading, includedGanModelsLoading]);

  const leftChecked = intersection(checked, selectedGanModels);
  const rightChecked = intersection(checked, right);

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setSelectedGanModels(not(selectedGanModels, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setSelectedGanModels(selectedGanModels.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };
  return (
    <Grid container spacing={2} justifyContent="center" alignItems="center">
      <Grid item xs={5}>
        <TransferList
          title="Included"
          items={selectedGanModels}
          checked={checked}
          setChecked={setChecked}
        />
      </Grid>
      <Grid item xs={2}>
        <Grid container direction="column" alignItems="center">
          <Stack spacing="16px">
            <Button
              variant="secondary"
              onClick={handleCheckedLeft}
              data-cy="Gan-Transfer-List-Add-Button"
              startIcon={ArrowLeftIcon}
              disabled={rightChecked.length === 0}
            >
              Add
            </Button>
            <Button
              variant="secondary"
              data-cy="Gan-Transfer-List-Remove-Button"
              onClick={handleCheckedRight}
              disabled={leftChecked.length === 0}
              endIcon={ArrowRightIcon}
            >
              Remove
            </Button>
          </Stack>
        </Grid>
      </Grid>
      <Grid item xs={5}>
        <TransferList title="Excluded" items={right} checked={checked} setChecked={setChecked} />
      </Grid>
    </Grid>
  );
};
