import { DatasetJob } from '@generated/UseGraphqlHooks';
import { Box, Collapse, Divider, Grid, useTheme } from '@mui/material';
import {
  JobsManagerJobPreviewInfo,
  JobsManagerJobPreviewInstances,
  JobsManagerJobPreviewLogs,
  JobsManagerJobPreviewRuns,
  JobsManagerJobPreviewStatistics,
} from '@subsets/workspaces';
import { useState } from 'react';

type JobsManagerJobPreviewProps = { job: DatasetJob; refetch: VoidFunction };

export const JobsManagerJobPreview = ({ job, refetch }: JobsManagerJobPreviewProps) => {
  const { palette } = useTheme();
  const [expanded, setExpanded] = useState(false);
  const expand = () => setExpanded(!expanded);

  return (
    <Box sx={{ borderBottom: `1px solid ${palette.icon.default}`, py: 4, mr: 2 }}>
      <JobsManagerJobPreviewInfo {...job} refetch={refetch} expand={expand} expanded={expanded} />
      <Collapse in={expanded} mountOnEnter unmountOnExit>
        <Box sx={{ pl: 4, pt: 5 }}>
          <Grid container>
            <JobsManagerJobPreviewStatistics {...job} />
          </Grid>
          <Divider sx={{ my: 3 }} />
          <JobsManagerJobPreviewInstances {...job} />
          <Divider sx={{ my: 3 }} />
          <JobsManagerJobPreviewRuns {...job} />
          <Divider sx={{ my: 3 }} />
          <JobsManagerJobPreviewLogs {...job} />
        </Box>
      </Collapse>
    </Box>
  );
};
