import { Group } from '@components/layout';
import { Typography } from '@components/typography';
import { Organization } from '@generated/UseGraphqlHooks';
import { Stack } from '@mui/material';
import { OrganizationsBillingHistory } from './OrganizationsBillingHistory';
import { OrganizationsBillingPaymentInformation } from './OrganizationsBillingPaymentInformation';
import { OrganizationsBillingProvider } from './OrganizationsBillingProvider';

type OrganizationsBillingProps = {
  organization: Organization;
};

export const OrganizationsBilling = ({ organization }: OrganizationsBillingProps) => {
  return (
    <OrganizationsBillingProvider organization={organization}>
      <Group sx={{ height: '100%', gap: 6, py: 8 }}>
        <Stack gap={3} sx={{ height: '100%', width: '100%', overflow: 'hidden', flex: 1 }}>
          <Typography variant="subtitle1">Payment information</Typography>
          <OrganizationsBillingPaymentInformation />
        </Stack>
        <Stack gap={3} sx={{ height: '100%', width: '100%', overflow: 'hidden', flex: 2 }}>
          <Typography variant="subtitle1">Payment history</Typography>
          <OrganizationsBillingHistory />
        </Stack>
      </Group>
    </OrganizationsBillingProvider>
  );
};
