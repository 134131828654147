import { ChevronDownIcon, PlusIcon } from '@assets/icons';
import { IconButton } from '@components/button';
import { Group } from '@components/layout';
import { Box, Collapse, Stack, Typography } from '@mui/material';
import { MouseEvent, ReactNode, useState } from 'react';

type DatasetDetailsAccordionProps = {
  title: string;
  count?: number;
  startOpen?: boolean;
  onCreate?: VoidFunction;
  details: ReactNode;
};

export const DatasetDetailsAccordion = ({
  title,
  count,
  startOpen = false,
  onCreate,
  details,
}: DatasetDetailsAccordionProps) => {
  const [shouldExpand, setShouldExpand] = useState<boolean>(startOpen);
  const handleCollapseChange = () => setShouldExpand(!shouldExpand);
  const onCreateClick = (e: MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    if (onCreate) {
      onCreate();
    }
  };
  return (
    <Stack sx={{ background: 'none', pr: 2, width: '100%' }}>
      <Group
        onClick={handleCollapseChange}
        sx={{
          alignItems: 'center',
          justifyContent: 'space-between',
          '&:hover': { cursor: 'pointer' },
        }}
      >
        <Group gap={1} alignItems="center">
          <Typography variant="body1">{title}</Typography>
          {count !== null && count !== undefined && (
            <Typography variant="body1">({count})</Typography>
          )}
        </Group>
        <Group gap={3} alignItems="center">
          {onCreate && <IconButton onClick={onCreateClick} Icon={PlusIcon} />}
          <ChevronDownIcon
            style={{
              transition: 'all 0.2s',
              transform: !shouldExpand ? 'rotate(0deg)' : 'rotate(180deg)',
            }}
          />
        </Group>
      </Group>
      <Collapse in={shouldExpand}>
        <Box sx={{ pt: 3, width: '100%' }}>{details}</Box>
      </Collapse>
    </Stack>
  );
};
