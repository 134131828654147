import { AsyncButton } from '@components/button';
import { Tooltip } from '@components/tooltip';
import { Typography } from '@components/typography';
import {
  useCreateGraphMutation,
  useCreatePreviewMutation,
  useGetStagedGraphsQuery,
} from '@generated/UseGraphqlHooks';
import { Box } from '@mui/material';
import { useCurrentWorkspace } from '@navigation/WorkspaceContext';
import { useNotifications } from '@notifications/Notifications';
import { navigate } from 'gatsby';
import { useContext } from 'react';
import { GraphDataContext } from './GraphDataProvider';

export const GraphConfigStageButton = ({ disabled }: { disabled?: boolean }) => {
  const {
    workspace: { workspaceId },
  } = useCurrentWorkspace();
  const { nodes, links, validationErrors, graphMetadata, graphData } = useContext(GraphDataContext);
  const { removeNotification, addNotification } = useNotifications();
  const [stageGraph] = useCreateGraphMutation();
  const [createPreview] = useCreatePreviewMutation();
  const { refetch: refetchStagedGraphs } = useGetStagedGraphsQuery({
    variables: { workspaceId, staged: true, limit: 30 },
  });

  const handleStageGraph = async () => {
    if (validationErrors.length > 0) {
      addNotification({
        type: 'error',
        text: 'Error evaluating. Please define correct value for marked inputs.',
      });
      return;
    }
    if (nodes.length === 0 || links.length === 0) {
      addNotification({
        type: 'error',
        text: 'You need to create node & link',
      });
      return;
    }
    const key = `${Date.now() * 1000}.${Math.random() * 10000}`;
    addNotification({ type: 'loading', text: 'Creating graph...?', key });
    const { data } = await stageGraph({
      variables: {
        channelId: graphMetadata.channelId,
        description: graphMetadata.description,
        graph: JSON.stringify(graphData),
        name: graphMetadata.name,
        staged: true,
        workspaceId,
      },
    });
    const stagedGraphId = data?.createGraph || '';
    refetchStagedGraphs();
    void createPreview({ variables: { graphId: stagedGraphId, workspaceId } });
    removeNotification(key);
    addNotification({
      type: 'success',
      duration: 50000,
      link: (
        <Box display="flex" flexDirection="row">
          <Typography
            style={{ textDecoration: 'underline', cursor: 'pointer', paddingRight: '4px' }}
            onClick={() => navigate(`/workspaces/${workspaceId}/jobs`)}
          >
            Go to Jobs
          </Typography>
          <Typography> to view or generate a dataset using a graph.</Typography>
        </Box>
      ),
    });
  };

  const stagingDisallowed = validationErrors.length > 0 || nodes.length === 0 || links.length === 0;

  return (
    <Tooltip
      title={disabled ? 'Graph is readonly' : stagingDisallowed ? 'Graph is not valid.' : ''}
      placement="bottom"
    >
      <span>
        <AsyncButton
          data-cy="Graph-Config-Stage-Button"
          variant="secondary"
          size="small"
          onClick={handleStageGraph}
          disabled={disabled || stagingDisallowed}
        >
          Stage
        </AsyncButton>
      </span>
    </Tooltip>
  );
};
