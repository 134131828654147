import * as d3 from 'd3';
import { createContext, PropsWithChildren, useContext, useEffect, useMemo } from 'react';
import { BehaviorSubject } from 'rxjs';
import { CanvasContext } from './CanvasProvider';
import { ZoomContext } from './ZoomProvider';

const mousePosition$ = new BehaviorSubject<[number, number]>([0, 0]);
export const MouseContext = createContext({
  mousePosition$,
});
export const MouseContextProvider = ({ children }: PropsWithChildren) => {
  const { zoomElement } = useContext(ZoomContext);
  const { svg } = useContext(CanvasContext);

  useEffect(() => {
    if (!zoomElement?.current || !svg) {
      return;
    }
    svg.on('mousemove', (event) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      const [x, y]: [number, number] = d3.pointer(event, zoomElement?.current) as [number, number];
      mousePosition$.next([x, y]);
    });
  }, [zoomElement?.current, svg]);

  const providerValue = useMemo(() => ({ mousePosition$ }), [mousePosition$]);
  return <MouseContext.Provider value={providerValue}>{children}</MouseContext.Provider>;
};
