import { Stack, StackProps } from '@mui/material';
import { useField } from 'formik';
import { Select, SelectProps } from '../Select';
import { FormFieldError } from './FormFieldError';

type FormSelectProps = SelectProps & {
  name: string;
  containerProps?: StackProps;
};

export const FormSelect = ({
  containerProps = {},
  maintainGutters = true,
  name,
  fullWidth = true,
  ...props
}: FormSelectProps) => {
  const [field, meta] = useField(name);
  const error = meta?.touched && meta?.error;
  return (
    <Stack sx={{ width: fullWidth ? '100%' : 'fit-content', ...containerProps.sx }}>
      <Select {...field} fullWidth={fullWidth} {...props} name={name} error={Boolean(error)} />
      <FormFieldError error={error} maintainGutters={maintainGutters} />
    </Stack>
  );
};
