import { LoadingIcon } from '@assets/icons';
import { InfiniteScrollTableComponents } from '@components/infiniteScroll';
import { Typography } from '@components/typography';
import { StripePaymentIntentInfo } from '@generated/UseGraphqlHooks';
import { Stack, TableCell, TableRow, useTheme } from '@mui/material';
import moment from 'moment';
import { TableVirtuoso } from 'react-virtuoso';
import { useOrganizationsBilling } from './OrganizationsBillingProvider';

const BillingHistoryPreview = ({
  created,
  amount_received: amountReceived,
}: {
  amount_received?: number;
  created?: number;
}) => {
  return (
    <>
      <TableCell>
        <Typography variant="body2">{moment.unix(created).format('MM/DD/YYYY')}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">
          ${`${Number(amountReceived / 100).toFixed(2)}` || '0.00'}
        </Typography>
      </TableCell>
    </>
  );
};

const TableHeader = () => {
  return (
    <TableRow>
      <TableCell width="50%">
        <Typography variant="caption1">Name</Typography>
      </TableCell>
      <TableCell width="50%">
        <Typography variant="caption1">Email</Typography>
      </TableCell>
    </TableRow>
  );
};

export const OrganizationsBillingHistory = () => {
  const { palette } = useTheme();
  const { paymentHistory, billingHistoryLoading } = useOrganizationsBilling();

  const tableRow = (_index, paymentHistoryEvent: StripePaymentIntentInfo) => {
    return <BillingHistoryPreview {...paymentHistoryEvent} />;
  };

  return (
    <>
      <Stack
        p={4}
        height="100%"
        bgcolor={palette.background.paper}
        style={{ overflowY: 'auto', overflowX: 'hidden' }}
        gap={8}
      >
        {billingHistoryLoading ? (
          <Stack height="100%" alignItems="center" justifyContent="center">
            <LoadingIcon size={16} />
          </Stack>
        ) : paymentHistory.length === 0 ? (
          <Stack height="100%" alignItems="center" justifyContent="center">
            <Typography>No payment history to display.</Typography>
          </Stack>
        ) : (
          <TableVirtuoso
            data={paymentHistory}
            totalCount={paymentHistory.length}
            components={InfiniteScrollTableComponents as any}
            fixedHeaderContent={TableHeader}
            itemContent={tableRow}
          />
        )}
      </Stack>
    </>
  );
};
