import { createContext, PropsWithChildren, useContext, useMemo, useState } from 'react';
import { BehaviorSubject } from 'rxjs';

type UmapProviderType = {
  selectedImage$: BehaviorSubject<string>;
  selectedDatasetName$: BehaviorSubject<string>;
  selectedDatasetId$: BehaviorSubject<string>;
};

export const UmapContext = createContext<UmapProviderType>({} as UmapProviderType);
export const UmapProvider = ({ children }: PropsWithChildren) => {
  const [selectedImage$] = useState(new BehaviorSubject<string>(''));
  const [selectedDatasetName$] = useState(new BehaviorSubject<string>(''));
  const [selectedDatasetId$] = useState(new BehaviorSubject<string>(''));

  const value = useMemo(
    () => ({ selectedImage$, selectedDatasetName$, selectedDatasetId$ }),
    [selectedImage$, selectedDatasetName$, selectedDatasetId$],
  );

  return <UmapContext.Provider value={value}>{children}</UmapContext.Provider>;
};

export const useUmap = () => useContext(UmapContext);
