import { Group } from '@components/layout';
import { Typography } from '@components/typography';
import { formatTime } from '@helper-functions/time';
import { Stack, useTheme } from '@mui/material';
import { DateTime } from 'luxon';

type JobsConfigurationsPreviewInfoProps = {
  name: string;
  createdAt: string;
  updatedAt: string;
};

export const JobsConfigurationsPreviewInfo = ({
  name,
  updatedAt,
  createdAt,
}: JobsConfigurationsPreviewInfoProps) => {
  const { palette } = useTheme();
  return (
    <Stack flexWrap="wrap" overflow="hidden" gap={1}>
      <Typography variant="subtitle2" color={palette.text.paper} handleOverFlow>
        {name || '-'}
      </Typography>
      <Group gap={1}>
        <Typography variant="caption2" color={palette.text.paper}>
          Published:
        </Typography>
        <Typography variant="caption2" color={palette.text.light}>
          {formatTime(createdAt, 'date') || '...'}
        </Typography>
      </Group>
      {updatedAt &&
        createdAt &&
        DateTime.fromISO(updatedAt).diff(DateTime.fromISO(createdAt), ['minutes']).minutes > 2 && (
          <Group gap={1}>
            <Typography variant="caption2" color={palette.text.paper}>
              Last run:
            </Typography>
            <Typography variant="caption2" color={palette.text.light}>
              {formatTime(updatedAt, 'date') || '...'}
            </Typography>
          </Group>
        )}
    </Stack>
  );
};
