import { ArrowLeftIcon, ArrowRightIcon } from '@assets/icons';
import { IconButton } from '@components/button';
import { Box, Stack, StackProps, useTheme } from '@mui/material';
import { CSSProperties, ReactElement, ReactEventHandler, useEffect, useState } from 'react';

type NewImageProps = StackProps & {
  alt?: string;
  url?: string;
  data?: any;
  overlaySVG?: (imageDimensions: [number, number]) => ReactElement;
  overlayImage?: string;
  overlayOpacity?: number;
  overlayStyle?: CSSProperties;
  onPrevClick?: () => void;
  onNextClick?: () => void;
};

export const Image = ({
  alt,
  url,
  sx,
  overlayStyle,
  overlaySVG,
  overlayImage,
  overlayOpacity,
  onPrevClick,
  onNextClick,
  ...props
}: NewImageProps) => {
  const { palette } = useTheme();
  const [imageDimensions, setImageDimensions] = useState<[number, number]>([1, 1]);
  const [imageError, setImageError] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [overlayImageError, setOverlayImageError] = useState(false);

  useEffect(() => {
    setImageError(false);
    setImageLoaded(false);
  }, [url]);

  useEffect(() => {
    setOverlayImageError(false);
  }, [overlayImage]);

  const onImageLoad: ReactEventHandler<HTMLImageElement> = ({ target: loadedImage }) => {
    setImageDimensions([
      (loadedImage as HTMLImageElement).naturalWidth,
      (loadedImage as HTMLImageElement).naturalHeight,
    ]);
    setImageLoaded(true);
  };

  return (
    <Stack
      sx={{
        containerType: 'size',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: palette.background.default,
        width: '100%',
        height: '100%',
        position: 'relative',
        ...sx,
      }}
      {...props}
    >
      {!imageLoaded && (
        <Box sx={{ position: 'absolute', height: '100%', aspectRatio: 1 }}>
          <svg viewBox="0 0 150 150" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M 75.392 43.104 C 75.145 42.961 74.84 42.961 74.592 43.104 L 47.576 58.712 C 47.335 58.855 47.187 59.113 47.184 59.392 L 47.184 90.592 C 47.187 90.872 47.335 91.13 47.576 91.272 L 74.608 106.896 C 74.855 107.039 75.16 107.039 75.408 106.896 L 102.424 91.288 C 102.665 91.145 102.813 90.888 102.816 90.608 L 102.816 59.408 C 102.813 59.128 102.665 58.871 102.424 58.728 L 75.392 43.104 Z M 75.8 45.128 L 100.48 59.392 L 92.296 64.113 L 75.8 54.577 L 75.8 45.128 Z M 74.2 45.128 L 74.2 54.576 L 57.704 64.112 L 49.52 59.392 L 74.2 45.128 Z M 48.744 60.744 L 56.92 65.465 L 56.92 84.537 L 48.744 89.257 L 48.744 60.744 Z M 74.2 104.864 L 49.52 90.608 L 57.704 85.888 L 74.2 95.424 L 74.2 104.864 Z M 74.2 93.664 L 58.488 84.536 L 58.488 66.368 L 74.2 75.448 L 74.2 93.664 Z M 75 74.136 L 59.264 65.016 L 75 55.928 L 90.736 65.016 L 75 74.136 Z M 91.512 66.408 L 91.512 84.536 L 75.8 93.624 L 75.8 75.448 L 91.512 66.408 Z M 75.8 104.864 L 75.8 95.424 L 92.296 85.888 L 100.48 90.609 L 75.8 104.864 Z M 101.256 89.256 L 93.08 84.536 L 93.08 65.464 L 101.256 60.744 L 101.256 89.256 Z"
              fill={palette.primary.main}
              transform="matrix(1, 0, 0, 1, 0, -3.552713678800501e-15)"
            />
            {url && (
              <animate
                attributeName="opacity"
                begin=".0s"
                values="1;0;1;1"
                dur="4s"
                repeatCount="indefinite"
              />
            )}
          </svg>
        </Box>
      )}
      <Box
        sx={{
          aspectRatio: imageDimensions[0] / imageDimensions[1],
          width: '100%',
          position: 'relative',
          [`@container (min-aspect-ratio: ${imageDimensions[0]} / ${imageDimensions[1]})`]: {
            width: 'auto',
            height: '100%',
          },
        }}
      >
        {!imageError && (
          <>
            {url && !imageError && (
              <img
                style={{
                  display: 'block',
                  maxHeight: '100%',
                  maxWidth: '100%',
                  margin: 'auto',
                  width: 'auto',
                  height: '100%',
                  backgroundColor: palette.background.default,
                  visibility: imageLoaded ? 'visible' : 'hidden',
                  zIndex: 1,
                }}
                onLoad={onImageLoad}
                onError={() => setImageError(true)}
                src={url}
                alt={alt || 'image'}
              />
            )}
            {imageLoaded && overlaySVG && (
              <svg
                style={{
                  position: 'absolute',
                  inset: 0,
                  height: '100%',
                  width: '100%',
                  opacity: overlayOpacity ?? 1,
                  ...overlayStyle,
                }}
                viewBox={`0 0 ${imageDimensions[0]} ${imageDimensions[1]}`}
              >
                {overlaySVG(imageDimensions)}
              </svg>
            )}
            {imageLoaded && overlayImage && !overlayImageError && (
              <img
                style={{
                  position: 'absolute',
                  inset: 0,
                  height: '100%',
                  width: '100%',
                  opacity: overlayOpacity ?? 0.5,
                  ...overlayStyle,
                }}
                src={overlayImage}
                onError={() => setOverlayImageError(true)}
              />
            )}
          </>
        )}
      </Box>
      {onPrevClick && (
        <IconButton
          Icon={ArrowLeftIcon}
          sx={{ position: 'absolute', left: 0, top: '50%' }}
          onClick={onPrevClick}
          size={24}
        />
      )}
      {onNextClick && (
        <IconButton
          Icon={ArrowRightIcon}
          sx={{ position: 'absolute', right: 0, top: '50%' }}
          onClick={onNextClick}
          size={24}
        />
      )}
    </Stack>
  );
};
