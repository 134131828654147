import { DeckardIconProps } from '@assets/types';
import { useTheme } from '@mui/material';
import { forwardRef } from 'react';

export const AlertTriangleIcon = forwardRef<SVGSVGElement, DeckardIconProps>((props, ref) => {
  const { palette } = useTheme();
  const { color, size } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
      width={size || 24}
      height={size || 24}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        className="stroke-override"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.2898 4.86002L1.81978 19C1.46442 19.6154 1.4623 20.3732 1.8142 20.9905C2.16611 21.6079 2.81919 21.9922 3.52978 22H20.4698C21.1804 21.9922 21.8334 21.6079 22.1854 20.9905C22.5373 20.3732 22.5351 19.6154 22.1798 19L13.7098 4.86002C13.3472 4.2623 12.6989 3.89728 11.9998 3.89728C11.3007 3.89728 10.6523 4.2623 10.2898 4.86002Z"
        stroke={color || palette.icon.default}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="stroke-override"
        d="M12 10V14"
        stroke={color || palette.icon.default}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        className="fill-override"
        cx="12"
        cy="18"
        r="1"
        fill={color || palette.icon.default}
      />
    </svg>
  );
});
