import { EditIcon } from '@assets/icons';
import { CopyIconButton, IconButton } from '@components/button';
import { Group } from '@components/layout';
import { useModal } from '@components/modal';
import { Typography } from '@components/typography';
import { Organization, RoleType } from '@generated/UseGraphqlHooks';
import { Stack, useTheme } from '@mui/material';
import { ReactNode } from 'react';
import { EditOrganizationModal } from './OrganizationsSettingsEditOrganization';

const OrganizationInfoDisplay = ({ label, value }: { label: string; value: ReactNode }) => {
  const { palette } = useTheme();
  return (
    <Stack>
      <Typography variant="caption2">{label}</Typography>
      <Group color={palette.text.paper} height="28px" alignItems="center">
        {value}
      </Group>
    </Stack>
  );
};

interface OrganizationsSettingsGeneralProps {
  organization: Organization;
}

export const OrganizationsSettingsGeneral = ({
  organization,
}: OrganizationsSettingsGeneralProps) => {
  const { palette } = useTheme();
  const isAdmin = organization?.role === RoleType.Admin;

  const { showModal } = useModal({
    component: EditOrganizationModal,
    componentProps: {
      organizationName: organization?.name,
      organizationId: organization?.organizationId,
    },
  });

  return (
    <Stack bgcolor={palette.background.paper} p={4} flex={1} gap={4}>
      <OrganizationInfoDisplay
        label="Name"
        value={
          <Group alignItems="center" gap={2}>
            <Typography variant="body2" handleOverFlow fitContent>
              {organization?.name}
            </Typography>
            {isAdmin && Boolean(organization?.name) && (
              <IconButton onClick={showModal} Icon={EditIcon} />
            )}
          </Group>
        }
      />
      <OrganizationInfoDisplay
        label="Id"
        value={
          <Group alignItems="center" gap={2}>
            <Typography variant="body2" handleOverFlow fitContent>
              {organization?.organizationId}
            </Typography>
            {Boolean(organization?.organizationId) && (
              <CopyIconButton value={organization?.organizationId} />
            )}
          </Group>
        }
      />
      <OrganizationInfoDisplay
        label="Plan"
        value={<Typography variant="body2">{organization?.plan || '-'}</Typography>}
      />
    </Stack>
  );
};
