import { CheckIcon } from '@assets/icons';
import { CopyIconButton } from '@components/button';
import { Select } from '@components/form';
import { Group } from '@components/layout';
import { Plot } from '@components/plot';
import { Typography } from '@components/typography';
import { useRefDimensions } from '@hooks/UseRefDimensions';
import { Box, MenuItem, Stack, useTheme } from '@mui/material';
import { Dispatch, useRef } from 'react';

interface UmapGraphSectionProps {
  data: Array<{ name: string; marker: { color: string }; datasetId: string }>;
  onClick: (param: unknown) => void;
  setFeatureSize: Dispatch<'4' | '8' | '16' | '32' | '64'>;
  featureSize: '4' | '8' | '16' | '32' | '64';
  fitDatasetId: string;
  umapName: string;
  umapId: string;
}

export const UmapGraphSection = ({
  data,
  featureSize,
  setFeatureSize,
  onClick,
  fitDatasetId,
  umapName,
  umapId,
}: UmapGraphSectionProps) => {
  const graphSectionReference = useRef<HTMLDivElement>(null);
  const { height, width } = useRefDimensions(graphSectionReference);
  const { palette } = useTheme();
  const handleFeatureSizeChange = ({ target: { value } }) => {
    setFeatureSize(value as '4' | '8' | '16' | '32' | '64');
  };

  return (
    <Box
      bgcolor={palette.background.paper}
      position="relative"
      p={4}
      style={{ overflow: 'hidden', height: '100%' }}
      ref={graphSectionReference}
    >
      <Stack top={15} left={15} zIndex={10} position="absolute" gap={2}>
        <Typography color={palette.text.paper}>
          {umapName} - {umapId} <CopyIconButton value={umapId} />
        </Typography>
        <Select value={featureSize} onChange={handleFeatureSizeChange} label="Feature size">
          {['4', '8', '16', '32', '64'].map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
        <Stack gap={2}>
          <Typography>Datasets</Typography>
          <Stack maxHeight="215px" gap={2}>
            {data.map(({ name, marker, datasetId }) => (
              <Group key={`${name} ${marker.color}`} alignItems="center" gap={3}>
                <Box
                  sx={{
                    width: 16,
                    height: 16,
                    borderRadius: '50%',
                    bgcolor: marker.color,
                  }}
                />
                <Typography variant="body1">{name}</Typography>
                {datasetId === fitDatasetId && <CheckIcon size={20} />}
              </Group>
            ))}
          </Stack>
        </Stack>
      </Stack>
      <Box height="100%">
        <Plot
          data={data}
          layout={{
            autosize: false,
            width: width - 50,
            height: height - 50,
            plot_bgcolor: palette.background.paper,
            paper_bgcolor: palette.background.paper,
            margin: {
              l: 0,
              r: 0,
              b: 0,
              t: 0,
              pad: 4,
            },
            showlegend: false,
            font: { color: palette.text.paper },
            scene: {
              aspectratio: {
                x: 1,
                y: 1,
                z: 1,
              },
              camera: {
                center: {
                  x: 0,
                  y: 0,
                  z: 0,
                },
                eye: {
                  x: 2,
                  y: 2,
                  z: 0.5,
                },
                up: {
                  x: 0,
                  y: 0,
                  z: 1,
                },
              },
              xaxis: {
                type: 'linear',
                zeroline: false,
                color: palette.text.paper,
              },
              yaxis: {
                type: 'linear',
                zeroline: false,
                color: palette.text.paper,
              },
              zaxis: {
                type: 'linear',
                zeroline: false,
                color: palette.text.paper,
              },
            },
          }}
          onClick={onClick}
        />
      </Box>
    </Box>
  );
};
