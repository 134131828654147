import { ImageAnnotations } from './DatasetsDetailsImagesSliderTypes';

const hexToHue = (hexColor: string): number => {
  const red = parseInt(hexColor.slice(1, 3), 16);
  const green = parseInt(hexColor.slice(3, 5), 16);
  const blue = parseInt(hexColor.slice(5, 7), 16);

  const max = Math.max(red, green, blue);
  const min = Math.min(red, green, blue);
  let hue = 0;

  if (max === min) {
    hue = 0;
  } else if (max === red) {
    hue = ((green - blue) / (max - min)) % 6;
  } else if (max === green) {
    hue = (blue - red) / (max - min) + 2;
  } else {
    hue = (red - green) / (max - min) + 4;
  }

  hue *= 60;

  if (hue < 0) {
    hue += 360;
  }

  return hue;
};

interface DatasetsDetailsImagesSliderMaskOverlayProps {
  data: ImageAnnotations[];
  color: string;
}

export const DatasetsDetailsImagesSliderMaskOverlay = ({
  data,
  color,
}: DatasetsDetailsImagesSliderMaskOverlayProps) => {
  const hslArray: string[] = [];

  for (let i = 0; i < data?.length; i++) {
    const saturation = (100 / (data?.length + 1)) * (i + 1);
    const hslValue = `hsl(${hexToHue(color)}, ${saturation}%, 50%)`;
    hslArray.push(hslValue);
  }

  return (
    <>
      {data?.map(({ segmentation }, idx) => {
        if (!segmentation) {
          return null;
        }
        return segmentation.map((seg, index) => {
          return (
            <polygon
              // eslint-disable-next-line react/no-array-index-key
              key={`${idx}-${index}`}
              points={seg
                .map((e, i) => {
                  if (i % 2 === 0 && i < seg.length - 1) {
                    return `${e},${seg[i + 1]}`;
                  }
                  return '';
                })
                .join(' ')}
              fill={hslArray[idx]}
            />
          );
        });
      })}
      Sorry, your browser does not support inline SVG.
    </>
  );
};
