import { Button } from '@components/button';
import { Typography } from '@components/typography';
import {
  useGetCurrentUserQuery,
  useGetInvitationsQuery,
  useGetMembersQuery,
  useGetOrganizationSettingsQuery,
  useRemoveMemberMutation,
} from '@generated/UseGraphqlHooks';
import { Stack } from '@mui/material';
import { useNotifications } from '@notifications/Notifications';
import { navigate } from 'gatsby';

type OrganizationsMembersRemoveMemberModalProps = {
  onClose?: () => void;
  organizationId?: string;
  invitationId?: string;
  email?: string;
  userId?: string;
};

export const OrganizationsMembersRemoveMemberModal = ({
  onClose,
  organizationId,
  invitationId,
  email,
  userId,
}: OrganizationsMembersRemoveMemberModalProps) => {
  const { useAsyncNotification } = useNotifications();
  const [removeMemberMutation] = useRemoveMemberMutation();

  const { data: currentUser } = useGetCurrentUserQuery();
  const { userId: currentUserId } = currentUser?.getCurrentUser || {};

  const { refetch: organizationMembersRefetch } = useGetMembersQuery({
    variables: {
      organizationId,
    },
  });
  const { refetch: organizationInvitationsRefetch } = useGetInvitationsQuery({
    variables: { organizationId },
  });
  const { refetch: organizationSettingsRefetch } = useGetOrganizationSettingsQuery({
    variables: { organizationId },
  });

  const handleCancelInvitationSubmit = useAsyncNotification(
    'Invitation has been cancelled',
    async () => {
      onClose();
      await removeMemberMutation({
        variables: {
          organizationId,
          email,
        },
      });
      await organizationSettingsRefetch();
      await organizationInvitationsRefetch();
    },
  );

  const handleDeleteSubmit = useAsyncNotification('Member has been removed', async () => {
    onClose();
    await removeMemberMutation({
      variables: {
        organizationId,
        email,
      },
    });
    if (userId === currentUserId) {
      await navigate('/');
    }
    await organizationMembersRefetch();
    await organizationSettingsRefetch();
  });

  return (
    <Stack alignItems="center" pt={4} gap={6}>
      <Typography variant="h3">
        Are you sure you want to remove this Organization member?
      </Typography>
      <Typography variant="body1">{email}</Typography>
      <Stack gap={4} width="100%">
        <Button
          fullWidth
          onClick={invitationId ? handleCancelInvitationSubmit : handleDeleteSubmit}
        >
          Yes, Remove
        </Button>
        <Button fullWidth variant="secondary" onClick={onClose}>
          Cancel
        </Button>
      </Stack>
    </Stack>
  );
};
