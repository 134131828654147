import { DownloadIcon, EditIcon, ExternalLinkIcon, TrashIcon } from '@assets/icons';
import { IconButton } from '@components/button';
import { Group } from '@components/layout';
import { useConfirmation, useModal } from '@components/modal';
import { Typography } from '@components/typography';
import {
  GanDataset,
  useDeleteGanDatasetMutation,
  useDownloadDatasetMutation,
} from '@generated/UseGraphqlHooks';
import { saveFile } from '@helper-functions/save-file';
import { Box, Grid } from '@mui/material';
import { useNotifications } from '@notifications/Notifications';
import {
  DatasetsDetailsGanDatasetsEditModal,
  DatasetsDetailsMicroserviceStatus,
  useDatasets,
} from '@subsets/workspaces';
import { useState } from 'react';

interface DatasetDetailsGanDatasetsListItemPreviewProps {
  ganDataset: Partial<GanDataset>;
  workspaceId: string;
  refetch: VoidFunction;
}

export const DatasetsDetailsGanDatasetsListItemPreview = ({
  ganDataset,
  workspaceId,
  refetch,
}: DatasetDetailsGanDatasetsListItemPreviewProps) => {
  const { status, name, model, description, datasetId } = ganDataset || {};
  const { setSelectedDataset } = useDatasets();
  const handleSelectGanDataset = () => setSelectedDataset(datasetId);
  const { useAsyncNotification, addNotification, removeNotification } = useNotifications();
  const { showConfirmation } = useConfirmation();
  const [downloadDataset] = useDownloadDatasetMutation();
  const [deleteGanDatasetMutation] = useDeleteGanDatasetMutation();
  const [modalState, setModalState] = useState(false);

  const { showModal: showEditGanDatasetModal } = useModal({
    component: DatasetsDetailsGanDatasetsEditModal,
    componentProps: {
      workspaceId,
      datasetId,
      datasetName: name || '',
      description: description || '',
      refetchGanDatasets: refetch,
    },
    modalProps: { title: 'Edit GAN Dataset' },
  });

  const startDownload = async () => {
    const key = `${Date.now() * 1000}.${Math.random() * 10000}`;
    try {
      addNotification({ text: 'Loading...', type: 'loading', key, duration: 100000 });
      const response = await downloadDataset({
        variables: {
          workspaceId,
          datasetId,
        },
      });
      saveFile({
        name: `${name || datasetId}.zip`,
        url: response?.data?.downloadDataset,
      });
      removeNotification(key);
      addNotification({
        text: 'Starting download...',
        type: 'success',
        duration: 6000,
      });
    } catch (e) {
      removeNotification(key);
    }
  };

  const handleDeleteGanDataset = () =>
    showConfirmation({
      onAffirm: useAsyncNotification('Successfully deleted GAN Dataset.', async () => {
        await deleteGanDatasetMutation({
          variables: {
            datasetId,
            workspaceId,
          },
        });
        refetch();
      }),
      message: `Are you sure you want to delete this GAN Dataset?`,
    });

  return (
    <Grid container alignItems="center" justifyContent="flex-start">
      <Grid item xs={1.5}>
        <Box display="flex" marginLeft={1}>
          <DatasetsDetailsMicroserviceStatus
            status={status}
            workspaceId={workspaceId}
            microServiceName="gan"
            datasetId={datasetId}
          />
        </Box>
      </Grid>
      <Grid item xs={3} paddingRight={3}>
        <Typography
          noWrap
          handleOverFlow
          variant="body2"
          tooltip={datasetId}
          tooltipProps={{ variant: 'secondary' }}
        >
          {name || '-'}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography noWrap handleOverFlow variant="body2">
          {model || '-'}
        </Typography>
      </Grid>
      <Grid item lg={4.5}>
        <Group justifyContent="flex-end" gap={2}>
          {status === 'success' && (
            <>
              <IconButton
                onClick={handleSelectGanDataset}
                data-cy="GAN-Datasets-Create-Dataset-Link-Icon"
                Icon={ExternalLinkIcon}
              />
              <IconButton
                onClick={showEditGanDatasetModal}
                data-cy="GAN-Datasets-Create-Dataset-Edit-Icon"
                Icon={EditIcon}
              />

              <IconButton
                onClick={startDownload}
                data-cy="GAN-Datasets-Create-Download-Link-Icon"
                Icon={DownloadIcon}
              />
            </>
          )}
          <IconButton
            onClick={handleDeleteGanDataset}
            data-cy="GAN-Datasets-Create-Delete-Link-Icon"
            Icon={TrashIcon}
          />
        </Group>
      </Grid>
    </Grid>
  );
};
