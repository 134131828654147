import { Organization } from '@generated/UseGraphqlHooks';
import { Box } from '@mui/material';
import {
  LandingPageWorkspaces,
  OrganizationChannels,
  OrganizationVolumes,
  useLandingPageProvider,
} from '@subsets/landing-page';
import { useObservableState } from 'observable-hooks';
import { OrganizationAnnotationMaps } from './annotation-maps';
import { OrganizationGanModels } from './gan-models';
import { SampleGallery } from './sampleGallery/SampleGallery';

type LandingPageOrganizationsTabPanelProps = {
  organizations: Organization[];
};

export const LandingPageOrganizationsTabPanel = ({
  organizations,
}: LandingPageOrganizationsTabPanelProps) => {
  const { tabValue$ } = useLandingPageProvider();
  const tabValue = useObservableState(tabValue$);

  const tabValueParts = tabValue.split('.');

  const selectedOrganization = tabValueParts[0]
    ? organizations.find((org) => org.organizationId === tabValueParts[0])
    : null;

  return (
    <Box width="100%" height="100%" data-cy="Landing-Page-Organizations-Tab-Panel">
      {tabValue === '' && (
        <LandingPageWorkspaces label="Recent" recent organizations={organizations} />
      )}
      {tabValue === 'sample-gallery' && (
        <Box sx={{ height: '100%' }}>
          <SampleGallery />
        </Box>
      )}
      {tabValue !== '' && tabValue !== 'sample-gallery' && (
        <Box sx={{ height: '100%' }}>
          {tabValueParts.length === 1 && (
            <LandingPageWorkspaces
              label={selectedOrganization?.name}
              organizations={organizations}
              organization={selectedOrganization}
            />
          )}
          {tabValueParts[1] === 'channels' && <OrganizationChannels {...selectedOrganization} />}
          {tabValueParts[1] === 'volumes' && <OrganizationVolumes {...selectedOrganization} />}
          {tabValueParts[1] === 'gan' && <OrganizationGanModels {...selectedOrganization} />}
          {tabValueParts[1] === 'annotation' && (
            <OrganizationAnnotationMaps {...selectedOrganization} />
          )}
        </Box>
      )}
    </Box>
  );
};
