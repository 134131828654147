import { gql, useQuery } from '@apollo/client';
import { LoadingIcon } from '@assets/icons';
import { Button } from '@components/button';
import { Typography } from '@components/typography';
import {
  useEditWorkspaceThumbnailMutation,
  useGetWorkspacesQuery,
} from '@generated/UseGraphqlHooks';
import { Box, Stack } from '@mui/material';
import { useNotifications } from '@notifications/Notifications';
import { useMemo, useState } from 'react';
import { WorkspacesDropdownChangeThumbnailModalThumbnailBox } from './WorkspacesMoreDropdownChangeThumbnailModalThumbnailBox';

const GetWorkspaceGraphThumbnails = gql`
  query GetGraphs($workspaceId: String!) {
    getGraphs(workspaceId: $workspaceId) {
      graphId
      thumbnail
    }
  }
`;

interface WorkspacesMoreDropdownChangeThumbnailModalProps {
  onClose?: () => void;
  workspaceId?: string;
}

export const WorkspacesMoreDropdownChangeThumbnailModal = ({
  onClose,
  workspaceId,
}: WorkspacesMoreDropdownChangeThumbnailModalProps) => {
  const { data: thumbnailsData, loading: thumbnailsDataLoading } = useQuery<{
    getGraphs: { graphId: string; thumbnail: string }[];
  }>(GetWorkspaceGraphThumbnails, { variables: { workspaceId } });
  const {
    data: workspaceData,
    loading: workspaceDataLoading,
    refetch: workspaceDataRefetch,
  } = useGetWorkspacesQuery({
    variables: { workspaceId },
  });
  const [editWorkspaceThumbnail] = useEditWorkspaceThumbnailMutation();
  const workspaceCurrentThumbnail =
    !workspaceDataLoading && workspaceData?.getWorkspaces[0].previewThumbnail;

  const [selectedThumbnail, setSelectedThumbnail] = useState<string>(workspaceCurrentThumbnail);
  const { useAsyncNotification } = useNotifications();

  const thumbnails = useMemo(() => {
    const thumbnailsSet = new Set<string>();
    thumbnailsData?.getGraphs.forEach((thumbnailData) => {
      if (thumbnailData.thumbnail) {
        thumbnailsSet.add(thumbnailData.thumbnail);
      }
    });
    return Array.from(thumbnailsSet);
  }, [thumbnailsData]);

  const noThumbnail = !thumbnailsDataLoading && thumbnails.length === 0;

  const handleSelection = (thumbnail: string) => () => {
    setSelectedThumbnail(thumbnail);
  };

  const handleClose = () => {
    onClose();
    setSelectedThumbnail(null);
  };

  const handleSetThumbnail = useAsyncNotification(
    'You have successfully changed the thumbnail',
    async () => {
      handleClose();
      if (selectedThumbnail === 'default') {
        return;
      }
      await editWorkspaceThumbnail({
        variables: {
          workspaceId,
          thumbnail: selectedThumbnail,
        },
      });
      await workspaceDataRefetch();
    },
  );

  return (
    <Stack style={{ overflowY: 'hidden', overflowX: 'hidden' }} gap={9}>
      {thumbnailsDataLoading && workspaceDataLoading ? (
        <Stack height="100px" justifyContent="center" alignItems="center">
          <LoadingIcon size={16} />
        </Stack>
      ) : (
        <>
          {!thumbnailsDataLoading && noThumbnail && !workspaceDataLoading && (
            <Typography variant="body2">No thumbnails were found for this workspace</Typography>
          )}
          <Box
            minHeight="100px"
            maxHeight="300px"
            style={{
              overflowY: 'auto',
              display: 'grid',
              gridTemplateColumns: 'repeat(auto-fill, minmax(100px, 1fr))',
              gap: 4,
            }}
          >
            {!thumbnailsDataLoading && noThumbnail && !workspaceDataLoading && (
              <WorkspacesDropdownChangeThumbnailModalThumbnailBox
                thumbnail=""
                handleSelection={handleSelection}
                selected={!selectedThumbnail}
              />
            )}
            {!thumbnailsDataLoading &&
              !noThumbnail &&
              !workspaceDataLoading &&
              thumbnails.map((thumbnail) => (
                <WorkspacesDropdownChangeThumbnailModalThumbnailBox
                  key={thumbnail}
                  thumbnail={thumbnail}
                  handleSelection={handleSelection}
                  selected={selectedThumbnail === thumbnail}
                />
              ))}
          </Box>
        </>
      )}
      <Stack direction="row" justifyContent="flex-end" gap={4}>
        <Button
          variant="primary"
          onClick={handleSetThumbnail}
          sx={{ width: '100%', maxWidth: '128px' }}
          disabled={
            !selectedThumbnail || (!thumbnailsDataLoading && noThumbnail && !workspaceDataLoading)
          }
        >
          Set as thumbnail
        </Button>
        <Button variant="secondary" onClick={onClose} sx={{ width: '100%', maxWidth: '128px' }}>
          Cancel
        </Button>
      </Stack>
    </Stack>
  );
};
