import { RenderedAiIcon } from '@assets/logos';
import { useAuthContext } from '@authentication/Authentication';
import { InternalLink } from '@components/link';
import { Box, Container, Stack } from '@mui/material';
import { HeaderFlow } from './HeaderFlow';
import { Help } from './Help';
import { Profile } from './Profile';
import { WorkspaceSelection } from './WorkspaceSelection';

export const Header = ({ workspaceId }: { workspaceId?: string }) => {
  const { user } = useAuthContext();
  return (
    <Box bgcolor="header.primary" height="48px" minHeight="48px" paddingX={4}>
      <Container
        maxWidth="lg"
        disableGutters
        sx={{
          position: 'relative',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Stack direction="row" alignItems="center" zIndex={1} gap={2} height="100%">
          <InternalLink to="/" style={{ display: 'flex', justifyContent: 'center' }}>
            <RenderedAiIcon
              size={28}
              data-cy="Header-Rendered-Icon"
              style={{ cursor: 'pointer' }}
            />
          </InternalLink>
          {workspaceId && <WorkspaceSelection workspaceId={workspaceId} />}
        </Stack>

        {workspaceId && <HeaderFlow workspaceId={workspaceId} />}

        {user && (
          <Stack direction="row" alignItems="center" zIndex={1} height="100%">
            <Help />
            <Profile />
          </Stack>
        )}
      </Container>
    </Box>
  );
};
