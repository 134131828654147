import { useState } from 'react';
import { OrganizationsBillingModalChangePlan } from './OrganizationsBillingModalChangePlan';
import { OrganizationsBillingModalConfirmation } from './OrganizationsBillingModalConfirmation';
import { OrganizationsBillingModalPaymentDetails } from './OrganizationsBillingModalPaymentDetails';

interface OrganizationsBillingModalProps {
  onClose: () => void;
  organizationId: string;
  trialSubscription: boolean;
}

export const OrganizationsBillingModal = ({
  onClose,
  organizationId,
  trialSubscription,
}: OrganizationsBillingModalProps) => {
  const [currentStep, setCurrentStep] = useState('change-plan');
  const switchToPaymentDetails = () => setCurrentStep('payment-details');
  const switchToChangePlan = () => setCurrentStep('change-plan');
  const switchToConfirmation = () => setCurrentStep('confirmation');
  return (
    <>
      {currentStep === 'change-plan' && (
        <OrganizationsBillingModalChangePlan
          onContinue={switchToPaymentDetails}
          onClose={onClose}
          organizationId={organizationId}
          trialSubscription={trialSubscription}
        />
      )}
      {currentStep === 'payment-details' && (
        <OrganizationsBillingModalPaymentDetails
          onContinue={switchToConfirmation}
          onClose={switchToChangePlan}
        />
      )}
      {currentStep === 'confirmation' && (
        <OrganizationsBillingModalConfirmation
          onContinue={onClose}
          onClose={switchToPaymentDetails}
        />
      )}
    </>
  );
};
