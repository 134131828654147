import { DeckardIconProps } from '@assets/types';
import { useTheme } from '@mui/material';
import { forwardRef } from 'react';

export const CameraIcon = forwardRef<SVGSVGElement, DeckardIconProps>((props, ref) => {
  const { palette } = useTheme();
  const { color, size } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
      width={size || 24}
      height={size || 24}
      viewBox="0 0 24 24"
      fill="none"
      stroke={color || palette.icon.default}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="stroke-override"
      {...props}
    >
      <path d="M23 19C23 19.5304 22.7893 20.0391 22.4142 20.4142C22.0391 20.7893 21.5304 21 21 21H3C2.46957 21 1.96086 20.7893 1.58579 20.4142C1.21071 20.0391 1 19.5304 1 19V8C1 7.46957 1.21071 6.96086 1.58579 6.58579C1.96086 6.21071 2.46957 6 3 6H7L9 3H15L17 6H21C21.5304 6 22.0391 6.21071 22.4142 6.58579C22.7893 6.96086 23 7.46957 23 8V19Z" />
      <path d="M12 17C14.2091 17 16 15.2091 16 13C16 10.7909 14.2091 9 12 9C9.79086 9 8 10.7909 8 13C8 15.2091 9.79086 17 12 17Z" />
    </svg>
  );
});
