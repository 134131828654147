import { DeckardIconProps } from '@assets/types';
import { useTheme } from '@mui/material';
import { forwardRef } from 'react';

export const LoadingIcon = forwardRef<SVGSVGElement, DeckardIconProps>((props, ref) => {
  const { palette } = useTheme();
  const { color, size } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
      height={size || 7}
      viewBox="0 0 24 7"
      fill={color || palette.primary.main}
      className="fill-override"
      {...props}
    >
      <rect
        width="5"
        height="5"
        rx="1"
        transform="matrix(0.707107 0.707107 0.707107 -0.707107 17 3.46436)"
      >
        <animate
          attributeName="opacity"
          begin=".75s"
          values="1;1;0;1"
          dur="1.5s"
          repeatCount="indefinite"
        />
      </rect>
      <rect
        width="5"
        height="5"
        rx="1"
        transform="matrix(0.707107 0.707107 0.707107 -0.707107 8.5 3.46436)"
      >
        <animate
          attributeName="opacity"
          begin=".5s"
          values="1;1;0;1"
          dur="1.5s"
          repeatCount="indefinite"
        />
      </rect>
      <rect
        width="5"
        height="5"
        rx="1"
        transform="matrix(0.707107 0.707107 0.707107 -0.707107 0 3.46436)"
      >
        <animate
          attributeName="opacity"
          begin=".25s"
          values="1;1;0;1"
          dur="1.5s"
          repeatCount="indefinite"
        />
      </rect>
    </svg>
  );
});
