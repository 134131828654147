import { DeckardIconProps } from '@assets/types';
import { useTheme } from '@mui/material';
import { forwardRef } from 'react';

export const HelpCircleIcon = forwardRef<SVGSVGElement, DeckardIconProps>((props, ref) => {
  const { palette } = useTheme();
  const { color, size } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
      width={size || 24}
      height={size || 24}
      viewBox="0 0 24 24"
      fill="none"
      stroke={color || palette.icon.default}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...props}
    >
      <path
        className="stroke-override"
        d="M9.08984 8.99999C9.57528 7.62004 10.9852 6.7914 12.427 7.0387C13.8688 7.28601 14.922 8.53714 14.9198 9.99999C14.9198 12 11.9198 13 11.9198 13"
      />
      <path
        className="stroke-override"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
      />
      <circle
        className="fill-override"
        cx="12"
        cy="17"
        r="0.5"
        fill={color || palette.icon.default}
      />
    </svg>
  );
});
