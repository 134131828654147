import { DeckardIcon } from '@assets/types';
import { Typography } from '@components/typography';
import { Box, useTheme } from '@mui/material';

export const ActionButton = ({
  Icon,
  label,
  onClick,
  disabled,
}: {
  Icon: DeckardIcon;
  label: string;
  onClick: VoidFunction;
  disabled?: boolean;
}) => {
  const { palette } = useTheme();
  const handleClick = () => {
    if (!disabled) {
      onClick();
    }
  };
  return (
    <Box
      sx={{
        display: 'flex',
        cursor: disabled ? 'default' : 'pointer',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        gap: 2,
        width: '76px',
        height: '76px',
        '&:hover': {
          ...(disabled
            ? {}
            : {
                '& .stroke-override': {
                  stroke: palette.text.contrast,
                },
                '& .fill-override': {
                  fill: palette.text.contrast,
                },
                '& .MuiTypography-root': {
                  color: palette.text.contrast,
                },
              }),
        },
      }}
      onClick={handleClick}
    >
      <Icon {...(disabled ? { color: palette.icon.disabled } : {})} />
      <Typography
        variant="caption1"
        color={disabled ? palette.icon.disabled : palette.text.primary}
      >
        {label}
      </Typography>
    </Box>
  );
};
