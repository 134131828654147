import { Modal, ModalProps } from '@components/modal';
import { Box } from '@mui/material';
import { keys, map } from 'lodash';
import { useObservableState } from 'observable-hooks';
import {
  ComponentProps,
  JSXElementConstructor,
  PropsWithChildren,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { BehaviorSubject } from 'rxjs';
import { JsxElement } from 'typescript';

type ModalContextType = {
  showModal?: (
    component: ReactNode | JsxElement | ((props: Record<string, unknown>) => JSX.Element) | unknown,
    modalKey: string,
    onModalOpen: () => void,
    componentProps?: unknown,
    modalProps?: Partial<ModalProps>,
  ) => void;
  hideModal?: (modalKey?: string) => void;
  isOpenedModal?: boolean;
  modalsConfiguration$?: BehaviorSubject<object>;
};

type ModalsConfigProps = {
  isOpen: boolean;
  component: JSXElementConstructor<Record<string, unknown>>;
  componentProps: Record<string, unknown> & { onClose: () => void };
  modalProps: Partial<ModalProps>;
};

export type UseModalProps<
  T extends JSXElementConstructor<any> | keyof React.JSX.IntrinsicElements,
> = {
  component?: T;
  componentProps?: Omit<ComponentProps<T>, 'onClose'> & Partial<Pick<ComponentProps<T>, 'onClose'>>;
  modalProps?: Partial<ModalProps>;
  onModalOpen?: () => void | Promise<void>;
};

const ModalContext = createContext<ModalContextType>({} as ModalContextType);

export const ModalProvider = ({ children }: PropsWithChildren) => {
  const [modalsConfiguration$] = useState<BehaviorSubject<object>>(new BehaviorSubject({}));
  const hideModal = (modalKey: string) => {
    modalsConfiguration$.next({
      ...modalsConfiguration$.value,
      [modalKey]: { ...modalsConfiguration$.value[modalKey], isOpen: false },
    });
  };

  const showModal = async (
    component: ReactNode | JsxElement,
    modalKey,
    onModalOpen: () => void | Promise<void>,
    componentProps?: unknown,
    modalProps?: Partial<ModalProps>,
  ) => {
    modalsConfiguration$.next({
      ...modalsConfiguration$.value,
      [modalKey]: {
        isOpen: true,
        component,
        componentProps,
        modalProps: modalProps,
      },
    });

    if (onModalOpen) {
      await onModalOpen();
    }
  };

  const isModalOpen = Object.values(modalsConfiguration$.value).some(({ isOpen }) => isOpen);
  const providerValue = useMemo(
    () => ({
      showModal,
      hideModal,
      modalsConfiguration$,
      isOpenedModal: isModalOpen,
    }),
    [hideModal, showModal, isModalOpen],
  );

  const isWindow = typeof window !== 'undefined';
  if (!isWindow) {
    return <div />;
  }
  return (
    <ModalContext.Provider value={providerValue}>
      {children}
      <ModalContainer />
    </ModalContext.Provider>
  );
};

export const useModal = <T extends JSXElementConstructor<any> | keyof React.JSX.IntrinsicElements>({
  component,
  componentProps,
  modalProps,
  onModalOpen,
}: UseModalProps<T>) => {
  const key = useMemo(() => {
    let count = 0;
    count += 1;
    return `${count}`;
  }, []);
  const { showModal, hideModal } = useContext(ModalContext);

  const showModalCallback = useCallback(
    (modalData?: any | any[]) => {
      showModal(
        component,
        key,
        onModalOpen,
        { ...componentProps, ...modalData?.componentProps },
        { ...modalProps, ...modalData?.modalProps },
      );
    },
    [modalProps, showModal],
  );

  const hideModalCallBack = useCallback(() => hideModal(key), [key, hideModal]);

  return { showModal: showModalCallback, hideModal: hideModalCallBack };
};

const ModalContainer = ({ children }: PropsWithChildren) => {
  const { modalsConfiguration$, hideModal } = useContext(ModalContext);
  const configs = useObservableState(modalsConfiguration$);
  return (
    <Box>
      {children}
      {map(keys(configs || {})).map((modalKey) => {
        const {
          component: Component,
          isOpen,
          componentProps,
          modalProps,
        } = configs[modalKey] as ModalsConfigProps;
        const { onClose } = componentProps || { onClose: () => {} };
        const handleModalClose = () => {
          if (onClose) {
            onClose();
          }
          hideModal(modalKey);
        };
        if (!isOpen) {
          return <div key={modalKey} />;
        }
        return (
          <Modal open={isOpen} key={modalKey} onClose={handleModalClose} {...modalProps}>
            <Component {...componentProps} onClose={handleModalClose} />
          </Modal>
        );
      })}
    </Box>
  );
};
