import { useApolloClient } from '@apollo/client';
import { UserIcon } from '@assets/icons';
import { useAuthContext } from '@authentication/Authentication';
import { ExternalLink, InternalLink } from '@components/link';
import { Typography } from '@components/typography';
import { useGetCurrentUserQuery } from '@generated/UseGraphqlHooks';
import { Divider, MenuItem, Stack, useTheme } from '@mui/material';
import { signOut } from 'aws-amplify/auth';
import { navigate } from 'gatsby';
import { useState } from 'react';
import { HeaderMenu } from './HeaderMenu';

export const Profile = () => {
  const { user, setUser } = useAuthContext();
  const email = user?.email;
  const displayName = user?.name;
  const { palette } = useTheme();
  const { data: currentUserData } = useGetCurrentUserQuery();
  const name = currentUserData?.getCurrentUser?.name || '-';

  const client = useApolloClient();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClose = () => setAnchorEl(null);

  const handleLogout = async () => {
    if (process.env.JEST_WORKER_ID !== undefined) {
      return;
    }
    client.clearStore();
    await signOut();
    setUser(null);
    handleClose();
    await navigate('/sign-in');
  };

  return (
    <HeaderMenu icon={UserIcon} anchorEl={anchorEl} setAnchorEl={setAnchorEl}>
      <Stack px={4}>
        <Typography variant="caption2" unselectable="on" style={{ userSelect: 'none' }}>
          {name || displayName || '-'}
        </Typography>
        <Typography variant="caption2" unselectable="on" style={{ userSelect: 'none' }}>
          {email || '-'}
        </Typography>
      </Stack>
      <Divider color={palette.text.light} sx={{ my: 2 }} />
      <InternalLink to="/profile" unstyled>
        <MenuItem onClick={handleClose}>
          <Typography variant="body2">Profile</Typography>
        </MenuItem>
      </InternalLink>
      <InternalLink to="/organizations" unstyled>
        <MenuItem onClick={handleClose}>
          <Typography variant="body2">Organizations</Typography>
        </MenuItem>
      </InternalLink>
      <ExternalLink href="https://rendered.ai/privacy-policy/" unstyled>
        <MenuItem onClick={handleClose}>
          <Typography variant="body2" color="text.primary">
            Legal
          </Typography>
        </MenuItem>
      </ExternalLink>
      <MenuItem onClick={handleLogout}>
        <Typography variant="body2" color="text.primary">
          Logout
        </Typography>
      </MenuItem>
    </HeaderMenu>
  );
};
