import { LoadingIcon } from '@assets/icons';
import { Group } from '@components/layout';
import { Typography } from '@components/typography';
import { OrganizationSetting, useGetOrganizationSettingsQuery } from '@generated/UseGraphqlHooks';
import { Stack, useTheme } from '@mui/material';

const UsageInfoBox = ({
  name,
  setting,
  fractional = false,
}: {
  name: string;
  setting: OrganizationSetting;
  fractional?: boolean;
}) => {
  const { palette } = useTheme();

  return (
    <Group justifyContent="space-between" alignItems="center">
      <Typography variant="body1">{name}</Typography>
      {!setting ? (
        <LoadingIcon color={palette.text.paper} />
      ) : (
        <Typography variant="caption2" display="flex">
          {`${!setting?.usage ? 0 : fractional ? Number(setting?.usage).toFixed(2) : setting?.usage}`}
          {setting?.limit ? ` / ${setting?.limit}` : ''}
          {fractional && setting?.units ? ` ${setting?.units}` : ''}
        </Typography>
      )}
    </Group>
  );
};

export const LandingPageWorkspacesDetailsResources = ({
  organizationId,
}: {
  organizationId: string;
}) => {
  const { palette } = useTheme();
  const { data } = useGetOrganizationSettingsQuery({ variables: { organizationId } });

  return (
    <Stack gap={3}>
      <Typography variant="h3">Resources</Typography>
      <Stack
        sx={{ backgroundColor: palette.background.paper, p: 4, gap: 3, color: palette.text.paper }}
      >
        <UsageInfoBox
          name="Members"
          setting={data?.getOrganizationSettings.find((setting) => setting.setting === 'Members')}
        />
        <UsageInfoBox
          name="Workspaces"
          setting={data?.getOrganizationSettings.find(
            (setting) => setting.setting === 'Workspaces',
          )}
        />
        <UsageInfoBox
          name="Dataset Storage"
          setting={data?.getOrganizationSettings.find(
            (setting) => setting.setting === 'Dataset Storage',
          )}
          fractional
        />
        <UsageInfoBox
          name="Channels"
          setting={data?.getOrganizationSettings.find(
            (setting) => setting.setting === 'Managed Channels',
          )}
        />
        <UsageInfoBox
          name="Volumes"
          setting={data?.getOrganizationSettings.find(
            (setting) => setting.setting === 'Managed Volumes',
          )}
        />
        <UsageInfoBox
          name="Volume Storage"
          setting={data?.getOrganizationSettings.find(
            (setting) => setting.setting === 'Volume Storage',
          )}
          fractional
        />
      </Stack>
    </Stack>
  );
};
