import { UploadIcon, XIcon } from '@assets/icons';
import { Button, IconButton } from '@components/button';
import { Group } from '@components/layout';
import { Typography } from '@components/typography';
import { Stack } from '@mui/material';
import { useField } from 'formik';
import { ChangeEvent, useRef } from 'react';
import { FormFieldError } from './FormFieldError';
import { FormTextInput } from './FormTextInput';

type FormFileUploadProps = {
  accept?: string;
  label?: string;
  multiple?: boolean;
  name: string;
  onFileLoad?: (file: File) => void;
};

const MultipleFileUpload = ({ accept, label, name }: Omit<FormFileUploadProps, 'multiple'>) => {
  const [field, meta, helpers] = useField<File[]>(name);
  const error = meta?.touched && meta?.error;
  const inputRef = useRef<HTMLInputElement>(null);
  return (
    <Stack gap={1}>
      <Stack sx={{ maxHeight: '200px', overflowY: 'auto' }} gap={1}>
        {field?.value?.map((file, index) => (
          <Group alignItems="center" gap={2} key={file.name}>
            <Typography key={file.name}>{file.name}</Typography>
            <IconButton
              Icon={XIcon}
              onClick={() => {
                helpers.setValue([
                  ...field?.value.slice(0, index),
                  ...field?.value.slice(index + 1),
                ]);
              }}
            />
          </Group>
        ))}
      </Stack>
      <Button size="small" onClick={() => inputRef.current.click()}>
        {label || 'Add file'}
      </Button>
      <FormFieldError error={error} maintainGutters />
      <input
        accept={accept}
        ref={inputRef}
        type="file"
        hidden
        multiple
        name={name}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          helpers.setValue([...field?.value, ...Array.from(e.currentTarget.files)]);
        }}
      />
    </Stack>
  );
};

const SingleFileUpload = ({
  accept,
  label,
  name,
  onFileLoad,
}: Omit<FormFileUploadProps, 'multiple'>) => {
  const [field, , helpers] = useField<File>(name);
  const inputRef = useRef<HTMLInputElement>(null);
  return (
    <Stack>
      <FormTextInput
        sx={{ cursor: 'pointer', '& .MuiInputBase-input': { cursor: 'pointer' } }}
        onClick={() => inputRef.current.click()}
        value={field?.value?.name || ''}
        readOnly
        label={label}
        name={name}
        endIcon={UploadIcon}
      />
      <input
        accept={accept}
        ref={inputRef}
        type="file"
        hidden
        name={name}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          helpers.setValue(e.currentTarget.files[0]);
          onFileLoad && onFileLoad(e.currentTarget.files[0]);
        }}
      />
    </Stack>
  );
};

export const FormFileUpload = ({ multiple = false, ...props }: FormFileUploadProps) => {
  if (multiple) {
    return <MultipleFileUpload {...props} />;
  }
  return <SingleFileUpload {...props} />;
};
