import { LoadingIcon } from '@assets/icons';
import { Button } from '@components/button';
import { Group } from '@components/layout';
import { useContactUsModal } from '@components/modal';
import { Typography } from '@components/typography';
import { useGetOrganizationsQuery } from '@generated/UseGraphqlHooks';
import { Box, Grid, Stack, useTheme } from '@mui/material';
import posthog from 'posthog-js';
import { OrganizationsBillingModalChangePlanPreview } from './OrganizationsBillingModalChangePlanPreview';
import { OrganizationsBillingModalChangePlanTopics } from './OrganizationsBillingModalChangePlanTopics';
import { useOrganizationsBilling } from './OrganizationsBillingProvider';
import { EnhancedSupportCreditsTable } from './enhanced-support-credits';

interface OrganizationsBillingModalChangePlanProps {
  onClose: () => void;
  onContinue: () => void;
  organizationId: string;
  trialSubscription: boolean;
}

export const OrganizationsBillingModalChangePlan = ({
  onContinue,
  onClose,
  organizationId,
  trialSubscription,
}: OrganizationsBillingModalChangePlanProps) => {
  const { palette } = useTheme();
  const { selectedPlan, currentPlan, organizationsDataLoading } = useOrganizationsBilling();
  if (organizationsDataLoading) {
    return (
      <Group height="100%" alignItems="center" justifyContent="center">
        <LoadingIcon size={16} />
      </Group>
    );
  }
  const { data: organizationData } = useGetOrganizationsQuery({
    variables: {
      organizationId,
    },
  });
  const organizationName = organizationData?.getOrganizations.find(
    (org) => org.organizationId === organizationId,
  )?.name;
  const { showModal: openBillingContactUsModal } = useContactUsModal({
    componentProps: {
      defaultSubject: `Billing for ${organizationName} for id: ${organizationId}`,
    },
  });
  const handleContactUsClick = () => {
    posthog.capture('user_contact-us_intent', { location: 'organizations-billing-settings' });
    openBillingContactUsModal();
  };

  return (
    <Stack height="100%" overflow="hidden" position="relative" gap={6}>
      <Typography variant="h2">Upgrade your Organization Subscription</Typography>
      <Stack pr={2} maxHeight="75vh" style={{ overflowY: 'auto', overflowX: 'hidden' }}>
        <Stack>
          <Box display="flex">
            <Grid container style={{ position: 'relative' }} wrap="nowrap">
              <Grid item xs={3}>
                <OrganizationsBillingModalChangePlanTopics />
              </Grid>
              <Grid item xs={9}>
                <Grid container style={{ position: 'relative' }} wrap="nowrap" spacing={3}>
                  <OrganizationsBillingModalChangePlanPreview />
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Stack>
        <EnhancedSupportCreditsTable />
      </Stack>
      <Group justifyContent="flex-end" alignItems="center" width="100%" gap={4}>
        <Typography variant="body2">
          Need more or something else?
          <Box
            color={palette.primary.dark}
            component="span"
            pl={1}
            sx={{ cursor: 'pointer', textDecoration: 'underline' }}
            onClick={handleContactUsClick}
          >
            Talk to us.
          </Box>
        </Typography>
        {(selectedPlan !== currentPlan || trialSubscription) && (
          <Button onClick={onContinue}>Continue</Button>
        )}
        <Button onClick={onClose} variant="secondary">
          Cancel
        </Button>
      </Group>
    </Stack>
  );
};
