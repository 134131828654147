import { ArrowLeftIcon } from '@assets/icons';
import { Button, IconButton } from '@components/button';
import { TextInput } from '@components/form';
import { Group } from '@components/layout';
import { Typography } from '@components/typography';
import { Divider, Stack } from '@mui/material';
import { capitalize } from 'lodash';
import { useState } from 'react';

type TextFilterProps = {
  field: string;
  value: string[];
  onApply: (newValue: string[]) => void;
  onBack: () => void;
  onClose: () => void;
};

export const TextFilter = ({ field, value, onApply, onBack, onClose }: TextFilterProps) => {
  const [dirtyValue, setDirtyValue] = useState(value?.join(' ') || '');
  return (
    <Stack maxHeight="500px">
      <Group alignItems="center" gap={1} sx={{ px: 2 }}>
        <IconButton size={16} onClick={onBack} Icon={ArrowLeftIcon} />
        <Typography variant="body2">{capitalize(field)}</Typography>
      </Group>
      <Divider sx={{ m: 2 }} flexItem />
      <Stack sx={{ overflowY: 'auto', px: 6, gap: 2 }}>
        <TextInput
          value={dirtyValue}
          onChange={(e) => setDirtyValue(e.target.value)}
          sx={{ minWidth: '50px' }}
        />
      </Stack>
      <Divider sx={{ m: 2 }} flexItem />
      <Group justifyContent="flex-end" sx={{ px: 2, gap: 2 }}>
        <Button
          size="small"
          variant="secondary"
          onClick={() => {
            setDirtyValue('');
          }}
        >
          Clear
        </Button>
        <Button
          size="small"
          variant="primary"
          onClick={() => {
            onApply(dirtyValue.trim().split(' '));
          }}
        >
          Apply
        </Button>
      </Group>
    </Stack>
  );
};
