import { RadioGroup, RadioGroupProps, Stack } from '@mui/material';
import { useField } from 'formik';
import { Radio, RadioProps } from '../Radio';
import { FormFieldError } from './FormFieldError';

export const FormRadioGroup = ({
  maintainGutters = true,
  name,
  ...props
}: RadioGroupProps & { maintainGutters?: boolean; name: string }) => {
  const [, meta] = useField(name);
  const error = meta?.touched && meta?.error;
  return (
    <Stack>
      <RadioGroup {...props} />
      <FormFieldError error={error} maintainGutters={maintainGutters} />
    </Stack>
  );
};

type FormRadioProps = RadioProps & {
  name: string;
};

export const FormRadio = ({ name, ...props }: FormRadioProps) => {
  const [field] = useField(name);
  return (
    <Radio
      {...props}
      {...field}
      value={props.value}
      checked={field.value === props.value}
      name={name}
    />
  );
};
