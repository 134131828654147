import { Typography } from '@components/typography';
import { Organization } from '@generated/UseGraphqlHooks';
import { calculateDateDifference } from '@helper-functions/calculateDateDifference';
import { Box, Divider, Tab, Tabs, useTheme } from '@mui/material';
import { LandingPageOrganizationsSideTab, useLandingPageProvider } from '@subsets/landing-page';
import { useObservableState } from 'observable-hooks';
import posthog from 'posthog-js';
import { ChangeEvent, useEffect } from 'react';

const isOrganizationExpired = (organizations: Organization[], selectedOrganizationId: string) => {
  const organization = organizations.find(
    ({ organizationId }) => organizationId === selectedOrganizationId,
  );
  const { hours } = calculateDateDifference(organization?.expirationDate);
  return organization.expired || hours < 1;
};

type LandingPageOrganizationsSideTabsProps = {
  organizations: Organization[];
};

export const LandingPageOrganizationsSideTabs = ({
  organizations,
}: LandingPageOrganizationsSideTabsProps) => {
  const { palette } = useTheme();
  const { tabValue$ } = useLandingPageProvider();
  const value = useObservableState(tabValue$);

  const handleChange = (_: ChangeEvent<unknown>, val: string) => {
    tabValue$.next(val);
    if (typeof window !== 'undefined') {
      window.history.pushState({}, '', `/${val}`);
    }
  };

  useEffect(() => {
    if (value && value === 'sample-gallery') {
      posthog.capture('user_viewed_sample_gallery');
    }
  }, [value]);

  return (
    <Box
      width="100%"
      height="100%"
      overflow="auto"
      sx={{ overflowX: 'hidden', paddingTop: 6, backgroundColor: palette.background.paper }}
    >
      <Tabs
        orientation="vertical"
        value={value.split('.').length > 1 ? false : value}
        onChange={handleChange}
        sx={{
          '& .MuiTabs-indicator': { backgroundColor: 'transparent' },
          '& .MuiTabs-fixed': { borderBottom: 'none' },
        }}
      >
        <LandingPageOrganizationsSideTab value="" label="Recent workspaces" id="recent" isRecent />
        <LandingPageOrganizationsSideTab
          value="sample-gallery"
          label="Sample gallery"
          id="sample-gallery"
          isRecent
        />
        <Tab
          label=""
          sx={{ p: 0 }}
          icon={
            <Divider
              sx={{
                marginTop: 4,
                marginBottom: 4,
                borderColor: palette.background.default,
                borderTopWidth: '1px',
                width: '100%',
              }}
            />
          }
          disabled
        />
        {organizations.map(({ name, organizationId, role }: Organization) => (
          <LandingPageOrganizationsSideTab
            label={
              <Typography
                variant="body2"
                handleOverFlow
                noWrap
                data-cy="Landing-Page-Organizations-Side-Tab-Organization-Name"
                paddingRight="16px"
                textAlign="left"
              >
                {name}
              </Typography>
            }
            organizationId={organizationId}
            role={role}
            expired={isOrganizationExpired(organizations, organizationId)}
            value={organizationId}
            key={organizationId}
          />
        ))}
      </Tabs>
    </Box>
  );
};
