import { Typography } from '@components/typography';
import { useTheme } from '@mui/material';

export const FormFieldError = ({
  error,
  maintainGutters = false,
}: {
  error?: string;
  maintainGutters?: boolean;
}) => {
  const { palette } = useTheme();
  return (
    <Typography
      sx={{
        ...(!maintainGutters && !error ? {} : { mt: 1, '&::before': { content: '"\u200B"' } }),
        mb: error ? 2 : 0,
      }}
      color={palette.input.text.error}
      variant="caption1"
    >
      {error}
    </Typography>
  );
};
