import { LoadingIcon, PlusIcon } from '@assets/icons';
import { Button } from '@components/button';
import { Typography } from '@components/typography';
import { Stack, useTheme } from '@mui/material';

interface LandingPageOrganizationResourcesLoadingProps {
  name: string;
  resource: string;
  buttonLabel: string;
}

export const LandingPageOrganizationResourcesLoading = ({
  name,
  resource,
  buttonLabel,
}: LandingPageOrganizationResourcesLoadingProps) => {
  const { palette } = useTheme();
  return (
    <Stack pt={5} pl={10} spacing={5} height="100%">
      <Stack spacing="2px">
        <Typography variant="caption2">{name}</Typography>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="h2">{resource}</Typography>
          <Button disabled startIcon={PlusIcon}>
            {buttonLabel}
          </Button>
        </Stack>
      </Stack>
      <Stack
        alignItems="center"
        justifyContent="center"
        flex="1"
        sx={{ backgroundColor: palette.background.paper }}
      >
        <LoadingIcon size={16} />
      </Stack>
    </Stack>
  );
};
