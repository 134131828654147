import { FilterIcon } from '@assets/icons';
import { Box, Menu, MenuItem, useTheme } from '@mui/material';
import { capitalize } from 'lodash';
import { useRef, useState } from 'react';
import { DateFilter } from './DateFilter';
import { MultiSelectFilter } from './MultiSelectFilter';
import { NumberFilter } from './NumberFilter';
import { SizeFilter } from './SizeFilter';
import { TextFilter } from './TextFilter';
import { DeckardFilter, Filter, SearchDetailTypes } from './types';

type FilterMenuProps<T> = {
  filter: DeckardFilter<T>;
  searchDetail: SearchDetailTypes;
  variant: 'primary' | 'secondary';
  onFilterChange: (newFilter: DeckardFilter<T>) => void;
};

export const FilterMenu = <T,>({
  filter,
  searchDetail,
  variant,
  onFilterChange,
}: FilterMenuProps<T>) => {
  const { palette } = useTheme();

  const updateFilter = (key, value, display) => {
    onFilterChange({
      ...filter,
      [key]: { ...filter[key], value, display },
    });
  };

  const anchorEl = useRef(null);
  const [filtersMenuOpen, setFiltersMenuOpen] = useState(false);
  const [showMultiSelect, setShowMultiSelect] = useState(null);
  const [showDateFilter, setShowDateFilter] = useState(null);
  const [showNumberFilter, setShowNumberFilter] = useState(null);
  const [showSizeFilter, setShowSizeFilter] = useState(null);
  const [showTextFilter, setShowTextFilter] = useState(null);

  const handleFilterClick = (key: string) => {
    setFiltersMenuOpen(false);
    if (['exact', 'string'].includes(filter[key].type)) {
      setShowMultiSelect({
        field: key,
        selected: filter[key].value,
        options: searchDetail[key],
        type: filter[key].type,
      });
    } else if (filter[key].type === 'date') {
      setShowDateFilter({
        field: key,
        current: filter[key].value,
      });
    } else if (filter[key].type === 'number') {
      setShowNumberFilter({
        field: key,
        value: filter[key].value?.[0],
      });
    } else if (filter[key].type === 'size') {
      setShowSizeFilter({
        field: key,
        value: filter[key].value?.[0],
        display: filter[key].display?.[0],
      });
    } else if (filter[key].type === 'text') {
      setShowTextFilter({
        field: key,
        value: filter[key].value,
      });
    }
  };

  return (
    <>
      <Box
        ref={anchorEl}
        py={1}
        px={1.5}
        display="flex"
        alignItems="center"
        onClick={(e) => {
          !showMultiSelect && setFiltersMenuOpen(true);
        }}
        style={{ cursor: 'pointer', outline: 'none' }}
        border="1px solid"
        borderColor={palette.table.modeButton.border.selected}
        sx={{
          borderRadius: '0 3px 3px 0',
          backgroundColor:
            variant === 'primary' ? palette.background.paper : palette.input.background.default,
          borderLeftWidth: 0,

          '&:hover': {
            backgroundColor: palette.table.modeButton.hover,
          },
          '&:active': {
            backgroundColor: palette.table.modeButton.border.selected,
          },
        }}
      >
        <FilterIcon color={palette.table.modeButton.icon.default} />
      </Box>
      <Menu
        anchorEl={anchorEl.current}
        open={filtersMenuOpen}
        disablePortal
        onClose={() => setFiltersMenuOpen(false)}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {Object.entries<Filter<unknown>>(filter)
          .filter(
            ([key]) => key !== 'texts' && (!searchDetail?.[key] || searchDetail?.[key].length > 0),
          )
          .map(([key]) => (
            <MenuItem key={key} onClick={() => handleFilterClick(key)}>
              {capitalize(key)}
            </MenuItem>
          ))}
      </Menu>
      <Menu
        open={Boolean(showMultiSelect)}
        onClose={() => setShowMultiSelect(null)}
        anchorEl={anchorEl.current}
        disablePortal={true}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MultiSelectFilter
          {...showMultiSelect}
          onApply={(selected, selectedDisplay) => {
            setShowMultiSelect(null);
            updateFilter(showMultiSelect.field, selected, selectedDisplay);
          }}
          onBack={() => {
            setShowMultiSelect(null);
            setFiltersMenuOpen(true);
          }}
        />
      </Menu>
      <Menu
        open={Boolean(showDateFilter)}
        onClose={() => {
          setShowDateFilter(null);
        }}
        anchorEl={anchorEl.current}
        disablePortal={true}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <DateFilter
          {...showDateFilter}
          onApply={(dates) => {
            setShowDateFilter(null);
            updateFilter(showDateFilter.field, dates, []);
          }}
          onBack={() => {
            setShowDateFilter(null);
            setFiltersMenuOpen(true);
          }}
          onClose={() => setShowDateFilter(null)}
        />
      </Menu>
      <Menu
        open={Boolean(showNumberFilter)}
        onClose={() => setShowNumberFilter(null)}
        anchorEl={anchorEl.current}
        disablePortal={true}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <NumberFilter
          {...showNumberFilter}
          onApply={(newValue) => {
            setShowNumberFilter(null);
            updateFilter(showNumberFilter.field, newValue, []);
          }}
          onBack={() => {
            setShowNumberFilter(null);
            setFiltersMenuOpen(true);
          }}
          onClose={() => setShowNumberFilter(null)}
        />
      </Menu>
      <Menu
        open={Boolean(showSizeFilter)}
        onClose={() => setShowSizeFilter(null)}
        anchorEl={anchorEl.current}
        disablePortal={true}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <SizeFilter
          {...showSizeFilter}
          onApply={(newValue, display) => {
            setShowSizeFilter(null);
            updateFilter(showSizeFilter.field, newValue, [display]);
          }}
          onBack={() => {
            setShowSizeFilter(null);
            setFiltersMenuOpen(true);
          }}
          onClose={() => setShowSizeFilter(null)}
        />
      </Menu>
      <Menu
        open={Boolean(showTextFilter)}
        onClose={() => setShowTextFilter(null)}
        anchorEl={anchorEl.current}
        disablePortal={true}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <TextFilter
          {...showTextFilter}
          onApply={(newValue) => {
            setShowTextFilter(null);
            updateFilter(showTextFilter.field, newValue, []);
          }}
          onBack={() => {
            setShowTextFilter(null);
            setFiltersMenuOpen(true);
          }}
          onClose={() => setShowTextFilter(null)}
        />
      </Menu>
    </>
  );
};
