import { LoadingIcon, SearchIcon } from '@assets/icons';
import { Select, TextInput } from '@components/form';
import { Typography } from '@components/typography';
import { ContentCode, useGetContentCodesQuery } from '@generated/UseGraphqlHooks';
import { useSearch } from '@hooks/UseSearch';
import { Box, MenuItem, SelectChangeEvent, Stack } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useMemo, useState } from 'react';
import { ContentCodeCard } from './components/ContentCodeCard';

export const SampleGallery = () => {
  const { data, loading } = useGetContentCodesQuery();
  const codes = data?.getContentCodes.filter((code) => Boolean(code)) || [];
  const featuredCodes: ContentCode[] = [];
  const nonfeaturedCodes: ContentCode[] = [];
  codes.forEach((code) => {
    if (code.featured) {
      featuredCodes.push(code);
    } else {
      nonfeaturedCodes.push(code);
    }
  });
  const { data: filteredCodes, onSearch } = useSearch(nonfeaturedCodes, ['name']);
  const [sortKey, setSortKey] = useState('Alpha ascending');
  const sortedCodes = useMemo(() => {
    if (sortKey === '') {
      return filteredCodes as ContentCode[];
    }
    const sorted = (filteredCodes as ContentCode[]).sort((a, b) => a.name.localeCompare(b.name));
    if (sortKey === 'Alpha ascending') {
      return sorted;
    }
    return sorted.reverse();
  }, [filteredCodes, sortKey]);

  if (loading) {
    return (
      <Stack height="100%" alignItems="center" justifyContent="center">
        <LoadingIcon size={14} />
      </Stack>
    );
  }

  return (
    <Stack pt={10} pl={10} spacing={10} height="100%">
      <Typography maxWidth="400px" variant="h1">
        Sample Gallery
      </Typography>
      <Stack spacing={10} overflow="auto" pr={2}>
        <Stack spacing={2}>
          <Grid
            container
            spacing={2}
            columns={featuredCodes.length === 1 ? 4 : featuredCodes.length === 2 ? 8 : 12}
          >
            {featuredCodes.map((code) => (
              <Grid xs={8} md={4} alignItems="stretch" key={code.code}>
                <ContentCodeCard contentCode={code} />
              </Grid>
            ))}
          </Grid>
        </Stack>
        <Stack spacing={4}>
          <Stack direction="row" spacing={4}>
            <Box flex="3">
              <TextInput
                name="code-search"
                startIcon={SearchIcon}
                onChange={(e) => onSearch(e.target.value)}
              />
            </Box>
            <Box flex="1">
              <Select
                data-cy="Organization-Channels-Sort-By"
                value={sortKey}
                onChange={(e: SelectChangeEvent) => setSortKey(e.target.value)}
                style={{ flex: '1' }}
              >
                <MenuItem value="Alpha ascending">Ascending</MenuItem>
                <MenuItem value="Alpha descending">Descending</MenuItem>
              </Select>
            </Box>
          </Stack>

          <Stack spacing={2}>
            {sortedCodes.map((code) => (
              <ContentCodeCard key={code.code} contentCode={code} fullWidth />
            ))}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};
