import { InfiniteScrollTableComponents, useGraphqlPagination } from '@components/infiniteScroll';
import { Typography } from '@components/typography';
import { GanModel, GetGanModelsQuery, useGetGanModelsQuery } from '@generated/UseGraphqlHooks';
import { Box, Stack, useTheme } from '@mui/material';
import { TableVirtuoso } from 'react-virtuoso';
import { LandingPageOrganizationResourcesLoading } from '../LandingPageOrganizationResourcesLoading';
import { GanModelHeader } from './GanModelHeader';
import { GanModelTableHeader } from './GanModelTableHeader';
import { GanModelTableRow } from './GanModelTableRow';

type OrganizationGanModelsProps = {
  name: string;
  organizationId: string;
};

export const OrganizationGanModels = ({ name, organizationId }: OrganizationGanModelsProps) => {
  const { palette } = useTheme();

  const {
    data: ganModels,
    loading: ganModelsLoading,
    fetchMore,
  } = useGraphqlPagination<GetGanModelsQuery, GanModel>(
    useGetGanModelsQuery({
      variables: { organizationId, limit: 30 },
    }),
    'getGANModels',
    'modelId',
  );

  if (ganModelsLoading) {
    return (
      <LandingPageOrganizationResourcesLoading
        name={name}
        resource="GAN Models"
        buttonLabel="New GAN Model"
      />
    );
  }

  const tableRow = (_index, ganModel) => (
    <GanModelTableRow {...ganModel} selectedOrganizationId={organizationId} />
  );

  return (
    <Stack pt={5} pl={10} spacing={5} height="100%" overflow="hidden">
      <GanModelHeader organizationId={organizationId} name={name} />
      <Stack flex={1} overflow="hidden" p={6} sx={{ backgroundColor: palette.background.paper }}>
        {ganModels.length === 0 ? (
          <Box
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flex: 1,
            }}
          >
            <Typography>This Organization has no GAN Models.</Typography>
          </Box>
        ) : (
          <TableVirtuoso
            data={ganModels}
            endReached={fetchMore}
            components={InfiniteScrollTableComponents as any}
            fixedHeaderContent={GanModelTableHeader}
            itemContent={tableRow}
            increaseViewportBy={400}
          />
        )}
      </Stack>
    </Stack>
  );
};
