type PromiseQueueItem = {
  task: () => Promise<unknown>;
  resolve: (value: unknown) => void;
  reject: (reason?: any) => void;
};

export const createPromiseQueue = (concurrency = 1) => {
  let running = 0;
  const queue: PromiseQueueItem[] = [];

  const runNext = () => {
    if (running >= concurrency || queue.length === 0) {
      return;
    }

    const { task, resolve, reject } = queue.shift();
    running++;

    task()
      .then(resolve)
      .catch(reject)
      .finally(() => {
        running--;
        runNext();
      });
  };

  return (task: () => Promise<unknown>) => {
    return new Promise((resolve, reject) => {
      queue.push({ task, resolve, reject });
      runNext();
    });
  };
};

export const runPromiseQueue = async (
  tasks: Array<() => Promise<unknown>> = [],
  concurrency: number,
) => {
  const enqueue = createPromiseQueue(concurrency);
  return Promise.all(tasks.map((task) => enqueue(task)));
};
