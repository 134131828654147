import { AwsMarketPlace } from '@assets/logos';
import { ExternalLink } from '@components/link';
import { Tooltip } from '@components/tooltip';
import { Typography } from '@components/typography';
import { Box, useTheme } from '@mui/material';
import { map, size } from 'lodash';
import { AWS_MARKETPLACE_URL, PLAN_SPECIFIC_VALUES } from './OrganizationsBillingProvider';

export const OrganizationsBillingModalChangePlanTopics = () => {
  const { palette } = useTheme();
  return (
    <Box height="100%" width="100%" mr={8}>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        mt="31px"
        height="225px"
        width="100%"
      >
        <AwsMarketPlace />
        <ExternalLink unstyled href={AWS_MARKETPLACE_URL}>
          <Typography
            variant="body2"
            sx={{
              textDecoration: 'underline',
              marginTop: '23px',
              width: '183px',
              textAlign: 'center',
            }}
          >
            Buy Rendered.ai through the AWS Marketplace
          </Typography>
        </ExternalLink>
      </Box>
      <Box width="100%" position="absolute" left={0} right={0} zIndex={0}>
        {map(PLAN_SPECIFIC_VALUES, (plan, index) => (
          <Box
            key={index}
            py={2}
            borderBottom={`1px solid ${palette.grey[400]}`}
            style={{
              borderTop: index === 0 && `1px solid ${palette.grey[400]}`,
            }}
          >
            {plan?.highlightedWordsLength > 0 ? (
              <Box display="flex">
                <Tooltip title={plan?.description} placement="top">
                  <Typography
                    variant="subtitle2"
                    style={{ borderBottom: `1px dashed ${palette.primary.dark}` }}
                  >
                    {plan?.title?.split(' ').slice(0, plan?.highlightedWordsLength).join(' ')}
                  </Typography>
                </Tooltip>
                <Typography variant="subtitle2" style={{ paddingLeft: '4px' }}>
                  {plan?.title
                    ?.split(' ')
                    .slice(plan?.highlightedWordsLength, size(plan?.title) - 1)
                    .join(' ')}
                </Typography>
              </Box>
            ) : (
              <Typography variant="subtitle2" style={{ borderBottom: `1px dashed transparent` }}>
                {plan?.title}
              </Typography>
            )}
          </Box>
        ))}
      </Box>
    </Box>
  );
};
