import { ArrowLeftIcon } from '@assets/icons';
import { Button, IconButton } from '@components/button';
import { Group } from '@components/layout';
import { Typography } from '@components/typography';
import { Divider, Stack } from '@mui/material';
import {
  DateCalendar,
  DateTimeField,
  LocalizationProvider,
  MultiSectionDigitalClock,
} from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { capitalize } from 'lodash';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';

type DateFilterProps = {
  current: { gte?: DateTime; lte?: DateTime }[];
  field: string;
  onApply: (dates: any[]) => void;
  onBack: () => void;
  onClose: () => void;
};

export const DateFilter = ({ field, current, onApply, onBack, onClose }: DateFilterProps) => {
  const [from, setFrom] = useState<DateTime>(() => {
    current.forEach((date) => {
      if (date?.gte) {
        return date?.gte;
      }
    });
    return null;
  });
  const [to, setTo] = useState<DateTime>(() => {
    current.forEach((date) => {
      if (date?.lte) {
        return date?.lte;
      }
    });
    return null;
  });
  const [fromDirty, setFromDirty] = useState(false);
  const [toDirty, setToDirty] = useState(false);
  const [active, setActive] = useState<'from' | 'to'>('from');
  useEffect(() => {
    current?.forEach((date) => {
      if (date?.gte) {
        setFrom(date?.gte);
      }
      if (date?.lte) {
        setTo(date?.lte);
      }
    });
  }, [current]);
  const updateDate = (newDate: DateTime) => {
    if (active === 'from') {
      setFrom(newDate);
      setFromDirty(true);
    } else {
      setTo(newDate);
      setToDirty(true);
    }
  };
  return (
    <Stack
      onKeyDown={(e) => {
        if (e.key === 'Tab') e.stopPropagation();
      }}
    >
      <Group alignItems="center" gap={1} sx={{ px: 2 }}>
        <IconButton size={16} onClick={onBack} Icon={ArrowLeftIcon} />
        <Typography variant="body2">{capitalize(field)}</Typography>
      </Group>
      <Divider sx={{ m: 2 }} flexItem />
      <LocalizationProvider dateAdapter={AdapterLuxon}>
        <Group gap={2} alignItems="center" sx={{ px: 4 }}>
          <DateTimeField
            clearable
            onClear={() => setFrom(null)}
            focused={active === 'from'}
            value={from}
            onChange={updateDate}
            onFocus={() => setActive('from')}
            inputProps={{
              autoComplete: 'off',
              autoCorrect: 'off',
              autoCapitalize: 'off',
              spellCheck: 'false',
            }}
            sx={{
              flex: 1,
              minWidth: '212px',
              '& .MuiInputBase-input': { pl: 3, pr: 0 },
              '& .MuiOutlinedInput-root': { border: 'none' },
            }}
          />
          -
          <DateTimeField
            clearable={true}
            onClear={() => setTo(null)}
            focused={active === 'to'}
            value={to}
            onChange={updateDate}
            onFocus={() => setActive('to')}
            inputProps={{
              autoComplete: 'off',
              autoCorrect: 'off',
              autoCapitalize: 'off',
              spellCheck: 'false',
            }}
            sx={{
              flex: 1,
              minWidth: '212px',
              '& .MuiInputBase-input': { pl: 3, pr: 2 },
              '& .MuiOutlinedInput-root': { border: 'none' },
            }}
          />
        </Group>
        <Group>
          <DateCalendar value={active === 'from' ? from : to} onChange={updateDate} />
          <MultiSectionDigitalClock
            sx={{
              width: 'auto',
              pt: 4,
              '& .MuiList-root': {
                maxHeight: '100%',
                scrollbarGutter: 'stable',
                width: 'auto',
                '&:after': {
                  height: 0,
                },
              },
              '& .MuiList-root-MuiMultiSectionDigitalClockSection-root::after': {
                height: 0,
              },
            }}
            value={active === 'from' ? from : to}
            onChange={updateDate}
          />
        </Group>
      </LocalizationProvider>
      <Divider sx={{ m: 2 }} flexItem />
      <Group justifyContent="flex-end" sx={{ px: 2, gap: 2 }}>
        <Button
          size="small"
          variant="secondary"
          onClick={() => {
            if (from) {
              setFrom(null);
              setFromDirty(true);
            }
            if (to) {
              setTo(null);
              setToDirty(true);
            }
          }}
        >
          Clear all
        </Button>
        <Button
          size="small"
          variant="primary"
          onClick={() => {
            if (!fromDirty && !toDirty && onClose) {
              onClose();
              return;
            }
            const newDates = [];
            if (from) newDates.push({ gte: from });
            if (to) newDates.push({ lte: to });
            onApply(newDates);
          }}
        >
          Apply
        </Button>
      </Group>
    </Stack>
  );
};
