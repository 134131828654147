interface SaveFileType {
  name: string;
  url?: string;
}

export const saveFile = ({ url, name }: SaveFileType) => {
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', name);
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
};
