import { useActiveFeatureFlags } from 'posthog-js/react';
import { createContext, useContext, useEffect, useState } from 'react';

export const FeatureFlagContext = createContext<string[]>([]);

export const FeatureFlagProvider = ({ children }) => {
  const [featureFlags, setFeatureFlags] = useState<string[]>([]);
  const flags = useActiveFeatureFlags();

  useEffect(() => {
    setFeatureFlags(flags);
  }, [flags]);

  return <FeatureFlagContext.Provider value={featureFlags}>{children}</FeatureFlagContext.Provider>;
};

export const useFeatureFlags = () => {
  const flags = useContext(FeatureFlagContext);
  return flags;
};

export const useFeatureFlag = (flag: string) => {
  const flags = useContext(FeatureFlagContext);
  return Boolean(flags?.includes(flag));
};
