import { Button } from '@components/button';
import { Typography } from '@components/typography';
import { Box, Grid, useTheme } from '@mui/material';
import { filter, isNil, map } from 'lodash';
import { useOrganizationsBilling } from './OrganizationsBillingProvider';

type StripeMetadata = {
  analytics?: string;
  annotation?: string;
  enhancedSupportCredits?: string;
  channelsPerOrganization?: string;
  datasetGBPerOrganization?: string;
  datasetsPerWorkspace?: string;
  dataVolumeGBLimit?: string;
  dataVolumes?: string;
  domainAdaptation?: string;
  graphsPerWorkspace?: string;
  limitsOnHours?: string;
  maxConcurrentComputerInstances?: string;
  membersPerOrganization?: string;
  name?: string;
  organizationSeat?: string;
  stagedGraphsPerWorkspace?: string;
  technicalAccountManager?: string;
  typeOfAWSInstance?: string;
  UMAP?: string;
  workspaceGuestsWithReadAndWrite?: string;
  workspacesPerOrganization?: string;
};

interface PlanPreviewProps {
  name?: string;
  selected: boolean;
  description?: string;
  current: boolean;
  onPlanSelect: () => void;
  metadata?: StripeMetadata;
  unit_amount?: number;
}

const PlanPreview = ({
  name,
  selected,
  description,
  current,
  onPlanSelect,
  metadata,
  unit_amount: unitAmount,
}: PlanPreviewProps) => {
  const { palette } = useTheme();

  return (
    <Box width="100%" height="100%" zIndex={2} position="relative">
      <Box textAlign="center" height="30px" alignItems="center">
        {current && (
          <Typography color={palette.billingSelector[selected ? 'selected' : 'default'].label}>
            CURRENT
          </Typography>
        )}
        {!current && selected && (
          <Typography color={palette.billingSelector.selected.label}>NEW</Typography>
        )}
      </Box>
      <Box
        width="100%"
        mb={4}
        borderRadius="4px"
        border={`2px solid ${palette.billingSelector[selected ? 'selected' : 'default'].border}`}
        color={palette.billingSelector[selected ? 'selected' : 'default'].text}
        style={{ cursor: selected ? 'default' : 'pointer', transition: 'all 0.2s' }}
        sx={{
          '&:hover': {
            backgroundColor: selected ? 'transparent' : palette.billingSelector.default.hover,
          },
        }}
        onClick={onPlanSelect}
      >
        <Box
          display="flex"
          alignItems="center"
          flexDirection="column"
          justifyContent="center"
          textAlign="center"
          bgcolor={palette.billingSelector[selected ? 'selected' : 'default'].background}
          height="225px"
          borderBottom={`1px solid ${palette.grey[400]}`}
        >
          <Typography variant="h3" style={{ paddingBottom: '10px', paddingTop: '0px' }}>
            {name || 'Loading...'}
          </Typography>
          <Typography variant="h2" style={{ paddingBottom: '14px' }}>
            {unitAmount === 1 ? 'Custom' : `$${unitAmount / 100} per month`}
          </Typography>
          <Typography
            variant="body2"
            style={{ paddingBottom: '14px', paddingLeft: '4px', paddingRight: '4px' }}
          >
            {description}
          </Typography>
          <Box paddingBottom="18px" />
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          color={palette.billingSelector.default.text}
        >
          <Box width="100%" textAlign="center">
            <Typography
              py={2}
              borderBottom={`1px solid ${palette.grey[400]}`}
              style={{ borderTop: '1px solid transparent' }}
              variant="subtitle2"
            >
              {metadata?.enhancedSupportCredits}
            </Typography>
            <Typography
              py={2}
              borderBottom={`1px solid ${palette.grey[400]}`}
              style={{ borderTop: '1px solid transparent' }}
              variant="subtitle2"
            >
              {metadata?.technicalAccountManager}
            </Typography>
            <Typography
              py={2}
              borderBottom={`1px solid ${palette.grey[400]}`}
              style={{ borderTop: '1px solid transparent' }}
              variant="subtitle2"
            >
              {metadata?.maxConcurrentComputerInstances}
            </Typography>
            <Typography
              py={2}
              borderBottom={`1px solid ${palette.grey[400]}`}
              style={{ borderTop: '1px solid transparent' }}
              variant="subtitle2"
            >
              {metadata?.membersPerOrganization}
            </Typography>
            <Typography
              py={2}
              borderBottom={`1px solid ${palette.grey[400]}`}
              style={{ borderTop: '1px solid transparent' }}
              variant="subtitle2"
            >
              {metadata?.channelsPerOrganization}
            </Typography>
            <Typography
              py={2}
              borderBottom={`1px solid ${palette.grey[400]}`}
              style={{ borderTop: '1px solid transparent' }}
              variant="subtitle2"
            >
              {metadata?.dataVolumes}
            </Typography>
            <Typography
              py={2}
              borderBottom={`1px solid ${palette.grey[400]}`}
              style={{ borderTop: '1px solid transparent' }}
              variant="subtitle2"
            >
              {metadata?.dataVolumeGBLimit}
            </Typography>
            <Typography
              py={2}
              borderBottom={`1px solid ${palette.grey[400]}`}
              style={{ borderTop: '1px solid transparent' }}
              variant="subtitle2"
            >
              {metadata?.datasetGBPerOrganization}
            </Typography>
            <Typography
              py={2}
              borderBottom={`1px solid ${palette.grey[400]}`}
              style={{ borderTop: '1px solid transparent' }}
              variant="subtitle2"
            >
              {metadata?.datasetsPerWorkspace}
            </Typography>
          </Box>
        </Box>
        <Box
          py={2}
          display="flex"
          alignItems="center"
          justifyContent="center"
          bgcolor={palette.billingSelector[selected ? 'selected' : 'default'].background}
        >
          <Button variant="primary" style={{ visibility: selected ? 'hidden' : 'visible' }}>
            Select
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export const OrganizationsBillingModalChangePlanPreview = () => {
  const { selectedPlan, setSelectedPlan, currentPlan, stripeProducts } = useOrganizationsBilling();
  const products = filter(stripeProducts, (product) => !isNil(product));
  const handlePlanSelect = (index: number) => () => {
    setSelectedPlan(index);
  };

  return (
    <>
      {map(products, (plan, index) => {
        return (
          <Grid item xs={currentPlan > 0 ? 6 : 4} key={index}>
            <PlanPreview
              {...plan}
              selected={selectedPlan === index}
              current={currentPlan === index}
              onPlanSelect={handlePlanSelect(index)}
            />
          </Grid>
        );
      })}
    </>
  );
};
