import { FormSelect } from '@components/form';
import { MenuItem } from '@mui/material';

type ChannelType = { channelId: string; name: string };
interface GraphsAddGraphModalAvailableChannelsProps {
  availableChannels: ChannelType[];
  name: string;
}

export const GraphsAddGraphModalAvailableChannels = ({
  availableChannels,
  name,
}: GraphsAddGraphModalAvailableChannelsProps) => {
  return (
    <FormSelect name={name} label="Channel">
      {availableChannels?.map((channel) => (
        <MenuItem key={channel.channelId} value={channel.channelId}>
          {channel.name}
        </MenuItem>
      ))}
    </FormSelect>
  );
};
