import { HelpCircleIcon, XIcon } from '@assets/icons';
import { IconButton } from '@components/button';
import { Typography } from '@components/typography';
import {
  Box,
  ModalProps as MUIModalProps,
  Modal as MuiModal,
  Popover,
  useTheme,
} from '@mui/material';
import { globalHistory } from '@reach/router';
import { PropsWithChildren, ReactNode, useEffect, useState } from 'react';

export type ModalProps = MUIModalProps & {
  help?: ReactNode;
  showClose?: boolean;
  title?: string;
  onClose?: () => void;
};

export const Modal = ({
  children,
  help = null,
  showClose = true,
  sx,
  title,
  onClose,
  ...rest
}: PropsWithChildren<ModalProps>) => {
  useEffect(() => {
    return globalHistory.listen(({ action }) => {
      if (action === 'PUSH' || action === 'POP') {
        onClose();
      }
    });
  }, []);
  return (
    <MuiModal onClose={onClose} closeAfterTransition {...rest}>
      <Box
        className="deckard-modal-container"
        sx={{ position: 'relative', p: 9, pt: title ? 7.25 : 9, minWidth: '450px', ...sx }}
      >
        <Box position="absolute" top={8} right={8}>
          {help && <ModalHelp help={help} />}
          {showClose && <IconButton onClick={onClose} size={18} Icon={XIcon} aria-label="Close" />}
        </Box>
        {title && (
          <Typography variant="h2" mb={6}>
            {title}
          </Typography>
        )}
        {children}
      </Box>
    </MuiModal>
  );
};

const ModalHelp = ({ help }: { help: ModalProps['help'] }) => {
  const { palette } = useTheme();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  return (
    <>
      <IconButton
        onClick={(e) => setAnchorEl(e.currentTarget)}
        size={18}
        Icon={HelpCircleIcon}
        aria-label="Help"
      />
      <Popover
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          '.MuiPopover-paper': {
            maxWidth: '400px',
            p: 3,
            backgroundColor: palette.menu.background,
          },
        }}
      >
        {help}
      </Popover>
    </>
  );
};
