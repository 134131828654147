import { Typography } from '@components/typography';
import { DatasetJob, useGetOrganizationSettingsQuery } from '@generated/UseGraphqlHooks';
import { formatTime } from '@helper-functions/time';
import { Grid, useTheme } from '@mui/material';
import { useCurrentWorkspace } from '@navigation/WorkspaceContext';
import { JobsManagerJobPreviewDataField } from './JobsManagerJobPreviewDataField';

export const JobsManagerJobPreviewInstances = ({
  averageRuntime,
  instancesStarting,
  instancesRunning,
}: DatasetJob) => {
  const { palette } = useTheme();
  const {
    workspace: { organizationId },
  } = useCurrentWorkspace();
  const { data: organizationSettingsData } = useGetOrganizationSettingsQuery({
    variables: { organizationId, setting: 'Instances' },
  });
  return (
    <Grid container columns={20}>
      <Grid item xs={5}>
        <Typography variant="caption2" color={palette.text.paper}>
          Instances
        </Typography>
      </Grid>
      <JobsManagerJobPreviewDataField
        label="Limit"
        value={organizationSettingsData?.getOrganizationSettings?.[0]?.limit || 0}
        width={3}
      />
      <JobsManagerJobPreviewDataField
        label="Assigned"
        value={instancesStarting + instancesRunning || 0}
        width={3}
      />
      <JobsManagerJobPreviewDataField label="Starting" value={instancesStarting || 0} width={3} />
      <JobsManagerJobPreviewDataField label="Running" value={instancesRunning || 0} width={3} />
      <JobsManagerJobPreviewDataField
        label="Avg time"
        value={formatTime(averageRuntime, 'seconds')}
        width={3}
      />
    </Grid>
  );
};
