import { Fragment } from 'react';
import { ImageAnnotations } from './DatasetsDetailsImagesSliderTypes';

interface DatasetsDetailsImagesSlider3DBoxesOverlayProps {
  data: ImageAnnotations[];
  color: string;
  strokeWidth: number;
}

export const DatasetsDetailsImagesSlider3DBoxesOverlay = ({
  data,
  color,
  strokeWidth,
}: DatasetsDetailsImagesSlider3DBoxesOverlayProps) => {
  return (
    <>
      {data?.map(({ id, bbox3d }, index) => {
        if (!bbox3d) {
          return null;
        }
        return (
          <Fragment key={id || index}>
            <path
              d={`M${bbox3d[0]} ${bbox3d[1]} L${bbox3d[3]} ${bbox3d[4]} Z`}
              stroke={color}
              strokeWidth={strokeWidth}
            />
            <path
              d={`M${bbox3d[3]} ${bbox3d[4]} L${bbox3d[6]} ${bbox3d[7]} Z`}
              stroke={color}
              strokeWidth={strokeWidth}
            />
            <path
              d={`M${bbox3d[6]} ${bbox3d[7]} L${bbox3d[9]} ${bbox3d[10]} Z`}
              stroke={color}
              strokeWidth={strokeWidth}
            />
            <path
              d={`M${bbox3d[9]} ${bbox3d[10]} L${bbox3d[0]} ${bbox3d[1]} Z`}
              stroke={color}
              strokeWidth={strokeWidth}
            />

            <path
              d={`M${bbox3d[12]} ${bbox3d[13]} L${bbox3d[15]} ${bbox3d[16]} Z`}
              stroke={color}
              strokeWidth={strokeWidth}
            />
            <path
              d={`M${bbox3d[15]} ${bbox3d[16]} L${bbox3d[18]} ${bbox3d[19]} Z`}
              stroke={color}
              strokeWidth={strokeWidth}
            />
            <path
              d={`M${bbox3d[18]} ${bbox3d[19]} L${bbox3d[21]} ${bbox3d[22]} Z`}
              stroke={color}
              strokeWidth={strokeWidth}
            />
            <path
              d={`M${bbox3d[21]} ${bbox3d[22]} L${bbox3d[12]} ${bbox3d[13]} Z`}
              stroke={color}
              strokeWidth={strokeWidth}
            />

            <path
              d={`M${bbox3d[0]} ${bbox3d[1]} L${bbox3d[12]} ${bbox3d[13]} Z`}
              stroke={color}
              strokeWidth={strokeWidth}
            />
            <path
              d={`M${bbox3d[3]} ${bbox3d[4]} L${bbox3d[15]} ${bbox3d[16]} Z`}
              stroke={color}
              strokeWidth={strokeWidth}
            />
            <path
              d={`M${bbox3d[6]} ${bbox3d[7]} L${bbox3d[18]} ${bbox3d[19]} Z`}
              stroke={color}
              strokeWidth={strokeWidth}
            />
            <path
              d={`M${bbox3d[9]} ${bbox3d[10]} L${bbox3d[21]} ${bbox3d[22]} Z`}
              stroke={color}
              strokeWidth={strokeWidth}
            />
          </Fragment>
        );
      })}
      Sorry, your browser does not support inline SVG.
    </>
  );
};
