import { Stack } from '@mui/material';
import { useField } from 'formik';
import { Toggle, ToggleProps } from '../Toggle';
import { FormFieldError } from './FormFieldError';

type FormToggleProps = ToggleProps & {
  maintainGutters?: boolean;
  name: string;
};

export const FormToggle = ({ maintainGutters = true, name, ...props }: FormToggleProps) => {
  const [field, meta] = useField(name);
  const error = meta?.touched && meta?.error;
  return (
    <Stack>
      <Toggle checked={field.value} {...props} {...field} name={name} />
      <FormFieldError error={error} maintainGutters={maintainGutters} />
    </Stack>
  );
};
