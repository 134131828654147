import { UploadIcon, XIcon } from '@assets/icons';
import { AsyncButton, Button, IconButton } from '@components/button';
import { FormTextInput } from '@components/form';
import { Typography } from '@components/typography';
import { useCreateFeedbackMutation } from '@generated/UseGraphqlHooks';
import { Box } from '@mui/material';
import { useNotifications } from '@notifications/Notifications';
import axios from 'axios';
import { Formik } from 'formik';
import posthog from 'posthog-js';
import { ChangeEvent, memo, useRef, useState } from 'react';
import * as yup from 'yup';
import { UseModalProps, useModal } from './ModalProvider';

const validationSchema = yup.object({
  summary: yup.string().trim().required('Subject is required'),
  description: yup
    .string()
    .trim()
    .max(2000, 'Must be less than 2000 characters')
    .required('Details are required'),
});

interface ContactUsModalProps {
  onClose?: () => void;
  handleContactModalSubmit?: (subject: string, details: string, paths: string[]) => void;
  fixedSubject?: string;
  defaultSubject?: string;
  defaultDescription?: string;
}

const ContactUsModal = memo(
  ({
    handleContactModalSubmit,
    onClose,
    fixedSubject,
    defaultSubject,
    defaultDescription,
  }: ContactUsModalProps) => {
    const { useAsyncNotification } = useNotifications();
    const [createFeedback] = useCreateFeedbackMutation();
    const [files, setFiles] = useState<File[]>([]);
    const fileInputRef = useRef<HTMLInputElement>(null);

    const handleInputButtonClick = () => {
      fileInputRef.current.click();
    };

    const handleInputChange = ({
      target,
    }: ChangeEvent<HTMLInputElement> & { target: { files: FileList } }) => {
      setFiles(Object.values(target.files));
    };

    const handleRemoveFile = (key) => setFiles(files.filter((_, index) => index !== key));

    const submitFeedback = useAsyncNotification(
      'Submitted feedback.',
      async ({ summary, description }: { summary: string; description: string }) => {
        const { data } = await createFeedback({
          variables: {
            summary,
            description,
            files: files.map((file) => file.name),
          },
        });
        posthog.capture('user_contact-us_submitted');
        if (!data) {
          return;
        }
        await Promise.all(
          files.map(async (file, index) => {
            const metadata = data?.createFeedback?.uploads?.[index];
            if (!metadata) {
              return Promise.resolve();
            }
            const formData = new FormData();
            formData.append('key', metadata.fields?.key);
            formData.append('policy', metadata.fields?.policy);
            formData.append('bucket', metadata.fields?.bucket);
            formData.append('X-Amz-Algorithm', metadata.fields?.algorithm);
            formData.append('X-Amz-Credential', metadata.fields?.credential);
            formData.append('X-Amz-Signature', metadata.fields?.signature);
            formData.append('X-Amz-Date', metadata.fields?.date);
            formData.append('X-Amz-Security-Token', metadata.fields?.token);
            formData.append('file', file as unknown as Blob);
            return axios({
              method: 'POST',
              url: data.createFeedback.uploads[index].url,
              data: formData,
            });
          }),
        );
        if (handleContactModalSubmit) {
          handleContactModalSubmit(
            summary,
            description,
            files.map((file) => file.name),
          );
        }
        onClose();
      },
    );

    return (
      <Formik
        onSubmit={submitFeedback}
        initialValues={{
          summary: fixedSubject || defaultSubject || '',
          description: defaultDescription || '',
        }}
        validationSchema={validationSchema}
      >
        {({ handleSubmit, isSubmitting }) => (
          <Box data-cy="Contact-Us-Modal">
            <Box display="flex" flexDirection="column">
              <Typography variant="caption2">Questions? Issues? Feedback?</Typography>
              <Typography variant="caption2">
                Complete the form below and we’ll get back to you shortly.
              </Typography>
            </Box>
            {!fixedSubject && <FormTextInput label="Subject" name="summary" autoFocus />}
            <FormTextInput
              label="Details"
              name="description"
              multiline
              rows={3}
              autoFocus={defaultSubject && !defaultDescription}
            />
            {files.length > 0 && (
              <Box mb={4}>
                {files.map((file, index) => (
                  <Box display="flex" alignItems="center" key={file.name}>
                    <IconButton
                      onClick={() => handleRemoveFile(index)}
                      Icon={XIcon}
                      size={12}
                      data-cy="Contact-Us-Modal-Remove-File-Icon"
                    />
                    <Typography variant="caption2">{file?.name}</Typography>
                  </Box>
                ))}
              </Box>
            )}
            <Box mb={8} display="flex" alignItems="center">
              <UploadIcon />
              <Typography
                variant="body1"
                style={{ paddingLeft: '4px', textDecoration: 'underline', cursor: 'pointer' }}
                data-cy="Contact-Us-Modal-Attach-Button"
                onClick={handleInputButtonClick}
              >
                <input
                  hidden
                  type="file"
                  ref={fileInputRef}
                  multiple
                  onChange={handleInputChange}
                />
                Attach a file
              </Typography>
            </Box>
            <AsyncButton
              sx={{ mb: 1.5 }}
              fullWidth
              onClick={handleSubmit}
              data-cy="Contact-Us-Modal-Send-Button"
              disabled={isSubmitting}
              autoFocus={Boolean(defaultSubject) && Boolean(defaultDescription)}
            >
              Send
            </AsyncButton>
            <Button
              fullWidth
              variant="secondary"
              data-cy="Contact-Us-Modal-Cancel-Button"
              onClick={onClose}
            >
              Cancel
            </Button>
          </Box>
        )}
      </Formik>
    );
  },
);

export const useContactUsModal = (props?: Partial<UseModalProps<typeof ContactUsModal>>) => {
  return useModal({
    component: ContactUsModal,
    modalProps: {
      showClose: true,
      title: 'Contact Us',
      ...props?.modalProps,
    },
    ...props,
  });
};
