import { AlertTriangleIcon, CheckIcon, InfoIcon, LoadingIcon } from '@assets/icons';
import { Typography } from '@components/typography';
import { Alert, AlertProps, Box, useTheme } from '@mui/material';
import { useNotifications } from '@notifications/Notifications';
import { ReactElement, ReactNode, SyntheticEvent, useState } from 'react';

const frontIcons = {
  success: CheckIcon,
  error: AlertTriangleIcon,
  warning: AlertTriangleIcon,
  info: InfoIcon,
  loading: LoadingIcon,
};

export type NotificationType = 'success' | 'info' | 'warning' | 'error' | 'loading';
export type NotificationProps = AlertProps & {
  text?: ReactNode;
  type?: NotificationType;
  link?: ReactElement | string;
  onLinkClick?: (event?: SyntheticEvent) => void;
};

export const Notification = ({
  text,
  type,
  onClose,
  link,
  onLinkClick,
  ...rest
}: NotificationProps) => {
  const { palette, typography } = useTheme();
  const [loading, setLoading] = useState(false);
  const { addNotification } = useNotifications();

  const handleClick = async () => {
    setLoading(true);
    try {
      if (onLinkClick) {
        await onLinkClick();
      }
    } catch (error) {
      addNotification({ type: 'error', text: (error as { message: string }).message });
    }
    setLoading(false);
  };

  const backgroundColor =
    type === 'info'
      ? palette.grey[900]
      : type === 'success'
        ? palette.primary.main
        : type === 'warning'
          ? palette.warning.dark
          : type === 'loading'
            ? palette.grey[900]
            : type === 'error'
              ? palette.error.dark
              : 'unset';
  const foregroundColor = type === 'success' ? palette.grey[900] : palette.common.white;
  const foregroundColorHover = type === 'success' ? palette.grey[600] : palette.grey[300];
  const Icon = frontIcons[type];

  return (
    <Alert
      sx={{
        maxWidth: '500px',
        py: 0,
        pl: 3,
        pr: 1.5,
        color: foregroundColor,
        backgroundColor,

        '& .MuiAlert-message': {
          ...typography.body1,
          width: '100%',
          textTransform: 'none',
          cursor: 'default',
          whiteSpace: 'initial',
          minWidth: '200px',
          marginRight: 1.5,
        },
        '& .MuiAlert-icon': {
          display: 'flex',
          alignItems: 'center',
        },
        '& .MuiAlert-action': {
          marginRight: 0,
          display: 'flex',
          alignItems: 'center',
          padding: 0,
          cursor: 'pointer',
          color: foregroundColor,
          '& :hover': {
            color: foregroundColorHover,
            backgroundColor: 'transparent',
          },
        },
      }}
      icon={<Icon color={foregroundColor} />}
      onClose={type !== 'loading' ? onClose : null}
      {...rest}
    >
      <Box display="flex" flexDirection="column">
        {text}
        {link && loading && (
          <Box height="19px" width="100%" display="flex" alignItems="center" pl={2}>
            <LoadingIcon color={foregroundColor} />
          </Box>
        )}
        {link &&
          (!loading && typeof link === 'string' ? (
            <Typography
              onClick={handleClick}
              sx={{ backgroundColor }}
              variant="body2"
              style={{ textDecoration: 'underline', cursor: 'pointer' }}
            >
              {link}
            </Typography>
          ) : (
            link
          ))}
      </Box>
    </Alert>
  );
};
