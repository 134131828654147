import { DeckardIconProps } from '@assets/types';
import { useTheme } from '@mui/material';
import { forwardRef } from 'react';

export const AtomicBurstIcon = forwardRef<SVGSVGElement, DeckardIconProps>((props, ref) => {
  const { palette } = useTheme();
  const { color, size } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
      width={size || 24}
      height={size || 24}
      viewBox="0 0 24 24"
      fill={color || palette.icon.default}
      stroke={color || palette.icon.default}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="stroke-override"
      {...props}
    >
      <path d="M20.9582 12.7143C20.6059 12.7143 20.304 12.9406 20.1698 13.2653L12.6667 11.5739L15.9108 4.75085C15.959 4.7594 16.0093 4.7658 16.0596 4.7658C16.5356 4.7658 16.9215 4.35148 16.9215 3.84109C16.9215 3.33069 16.5357 2.91637 16.0596 2.91637C15.5837 2.91637 15.1978 3.33069 15.1978 3.84109C15.1978 4.14432 15.3362 4.41341 15.5459 4.58214L12.3333 11.3433L7.04051 3.66385C7.15164 3.50793 7.21662 3.31578 7.21662 3.10647C7.21662 2.59608 6.83081 2.18176 6.35476 2.18176C5.87878 2.18176 5.4929 2.59608 5.4929 3.10647C5.4929 3.61687 5.87871 4.03119 6.35476 4.03119C6.49319 4.03119 6.62104 3.9949 6.73641 3.93511L11.8824 11.401L3.90338 9.60287C3.89498 9.10103 3.51334 8.69741 3.04363 8.69741C2.56764 8.69741 2.18176 9.11173 2.18176 9.62212C2.18176 10.1325 2.56757 10.5468 3.04363 10.5468C3.39176 10.5468 3.69156 10.3247 3.82788 10.0044L12.0858 11.8666L8.22126 19.9922C8.16044 19.9773 8.09757 19.9687 8.03251 19.9687C7.55653 19.9687 7.17065 20.383 7.17065 20.8934C7.17065 21.4038 7.55646 21.8181 8.03251 21.8181C8.50849 21.8181 8.89437 21.4038 8.89437 20.8934C8.89437 20.6051 8.77068 20.3467 8.57771 20.178L12.3984 12.1462L16.324 17.8417C16.215 17.9955 16.1499 18.1877 16.1499 18.397C16.1499 18.9074 16.5357 19.3217 17.0118 19.3217C17.4878 19.3217 17.8737 18.9074 17.8737 18.397C17.8737 17.8866 17.4878 17.4722 17.0118 17.4722C16.8734 17.4722 16.7413 17.5085 16.626 17.5705L12.8011 12.0223L20.0965 13.6666C20.1112 14.1643 20.4907 14.5615 20.9563 14.5615C21.4322 14.5615 21.8181 14.1471 21.8181 13.6368C21.8202 13.1285 21.4344 12.7142 20.9584 12.7142L20.9582 12.7143Z" />
    </svg>
  );
});
