import { InfiniteScrollTableComponents, useGraphqlPagination } from '@components/infiniteScroll';
import { Typography } from '@components/typography';
import {
  GetVolumesQuery,
  Volume,
  useGetOrganizationSettingsQuery,
  useGetVolumesQuery,
} from '@generated/UseGraphqlHooks';
import { Box, Stack, useTheme } from '@mui/material';
import { find } from 'lodash';
import { TableVirtuoso } from 'react-virtuoso';
import { LandingPageOrganizationResourcesLoading } from '../LandingPageOrganizationResourcesLoading';
import { VolumeHeader } from './VolumeHeader';
import { VolumeTableHeader } from './VolumeTableHeader';
import { VolumeTableRow } from './VolumeTableRow';

type OrganizationVolumesProps = {
  name: string;
  organizationId: string;
};

export const OrganizationVolumes = ({ name, organizationId }: OrganizationVolumesProps) => {
  const { palette } = useTheme();
  const {
    data: volumes,
    loading: volumesLoading,
    fetchMore: fetchMoreVolumes,
  } = useGraphqlPagination<GetVolumesQuery, Volume>(
    useGetVolumesQuery({
      variables: {
        organizationId,
        limit: 30,
      },
    }),
    'getVolumes',
    'volumeId',
  );
  const { data: organizationSettingsData, loading: organizationSettingsLoading } =
    useGetOrganizationSettingsQuery({
      variables: { organizationId },
    });
  const { usage, limit } =
    (!organizationSettingsLoading &&
      find(organizationSettingsData?.getOrganizationSettings, {
        setting: 'Managed Volumes',
      })) ||
    {};

  if (volumesLoading || organizationSettingsLoading) {
    return (
      <LandingPageOrganizationResourcesLoading
        name={name}
        buttonLabel="New volume"
        resource="Volumes"
      />
    );
  }
  const tableRow = (_index, volume) => (
    <VolumeTableRow {...volume} selectedOrganizationId={organizationId} />
  );
  return (
    <Stack pt={5} pl={10} spacing={5} height="100%" overflow="hidden">
      <VolumeHeader organizationId={organizationId} usage={usage} limit={limit} name={name} />
      <Stack flex={1} overflow="hidden" p={6} sx={{ backgroundColor: palette.background.paper }}>
        {volumes.length === 0 ? (
          <Box
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flex: 1,
            }}
          >
            <Typography>This organization has no volumes.</Typography>
          </Box>
        ) : (
          <TableVirtuoso
            data={volumes}
            endReached={fetchMoreVolumes}
            components={InfiniteScrollTableComponents as any}
            fixedHeaderContent={VolumeTableHeader}
            itemContent={tableRow}
            increaseViewportBy={400}
          />
        )}
      </Stack>
    </Stack>
  );
};
