import { SearchIcon } from '@assets/icons';
import { TextInput } from '@components/form';
import { Typography } from '@components/typography';
import { useSearch } from '@hooks/UseSearch';
import { List, ListItem, ListItemText, Stack, useTheme } from '@mui/material';
import { map, sortBy, toLower } from 'lodash';
import { Dispatch, SetStateAction } from 'react';

export type ResourcesType = { id: string; name: string }[];

interface TransferListProps {
  title: string;
  items: ResourcesType;
  checked: ResourcesType;
  setChecked: Dispatch<SetStateAction<ResourcesType>>;
}

export const not = (a: ResourcesType, b: ResourcesType) => {
  return a.filter((value) => b?.indexOf(value) === -1);
};

export const intersection = (a: ResourcesType, b: ResourcesType) => {
  return a.filter((value) => b?.indexOf(value) !== -1);
};

export const union = (a: ResourcesType, b: ResourcesType) => {
  return [...a, ...not(b, a)];
};

export const TransferList = ({ title, items, checked, setChecked }: TransferListProps) => {
  const { palette } = useTheme();
  const handleToggle = (value: { id: string; name: string }) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  const sortedItems = sortBy(items, ({ name }) => toLower(name));
  const { data, onSearch } = useSearch(sortedItems);
  const handleSearchBarChange = ({ target: { value } }: { target: { value: string } }) => {
    onSearch(value);
  };

  return (
    <Stack spacing="12px">
      <Typography>{title}</Typography>
      <TextInput
        startIcon={SearchIcon}
        onChange={handleSearchBarChange}
        data-cy={`Transfer-List-${title}-Search-Bar`}
      />
      <List
        sx={{
          width: '100%',
          height: 230,
          bgcolor: palette.background.default,
          color: palette.text.primary,
          overflow: 'auto',
          border: `1px solid ${palette.input.border.default}`,
          borderRadius: '4px',
        }}
        dense
        component="div"
        role="list"
      >
        {map(data, (item: { id: string; name: string }) => (
          <ListItem
            key={item.id}
            role="listitem"
            onClick={handleToggle(item)}
            data-cy={`Transfer-List-${title}-List-Item`}
            sx={{
              bgcolor: checked.indexOf(item) !== -1 ? palette.menu.item.selected : 'transparent',
              cursor: 'pointer',
            }}
          >
            <ListItemText
              primary={
                <Typography
                  variant="body2"
                  component="div"
                  data-cy="Transfer-List-Item-Text"
                  handleOverFlow
                >
                  {item.name}
                </Typography>
              }
            />
          </ListItem>
        ))}
        <ListItem />
      </List>
    </Stack>
  );
};
