import { CopyIcon } from '@assets/icons';
import { IconButton } from '@components/button';
import { Tooltip, TooltipProps } from '@components/tooltip';
import { delay } from '@helper-functions/delay';
import { useNotifications } from '@notifications/Notifications';
import { MouseEvent, useState } from 'react';

type CopyIconButtonProps = {
  value: string | number;
  showValue?: boolean;
  tooltipProps?: Partial<TooltipProps>;
};

export const CopyIconButton = ({ value, showValue, tooltipProps }: CopyIconButtonProps) => {
  const { addNotification } = useNotifications();
  const [isCopied, setIsCopied] = useState(false);

  const copyToClipboard = async (event: MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
    try {
      setIsCopied(true);
      await navigator.clipboard.writeText(String(value));
      await delay(3000);
      setIsCopied(false);
    } catch (error) {
      let message = 'Unknown Error';
      if (error instanceof Error) message = error.message;
      addNotification({ type: 'error', text: message });
    }
  };

  return (
    <Tooltip
      title={isCopied ? 'Copied' : showValue ? value : 'Copy'}
      placement="bottom"
      {...tooltipProps}
    >
      <IconButton onClick={copyToClipboard} Icon={CopyIcon} size={16} />
    </Tooltip>
  );
};
