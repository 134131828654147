import { LoadingIcon } from '@assets/icons';
import { Group } from '@components/layout';
import { InternalLink } from '@components/link';
import { Tooltip } from '@components/tooltip';
import { Typography } from '@components/typography';
import {
  DatasetJob,
  useGetOrganizationJobsQuery,
  useGetWorkspaceNameQuery,
} from '@generated/UseGraphqlHooks';
import { LinearProgress, Stack, useTheme } from '@mui/material';
import { upperFirst } from 'lodash';
import moment from 'moment';
import { useEffect, useMemo, useRef } from 'react';

const getTimeDifferenceInSeconds = (startTime: Date, endTime: Date) =>
  moment(endTime).diff(moment(startTime), 'seconds');

const OrganizationJobCard = ({
  name,
  status,
  runs,
  workspaceId,
  estimatedEndAt,
  createdAt,
}: DatasetJob) => {
  const { palette } = useTheme();
  const { data } = useGetWorkspaceNameQuery({ variables: { workspaceId } });
  const workspaceName = (
    <Typography variant="caption2" handleOverFlow>
      {data?.getWorkspaces?.[0]?.name || <LoadingIcon color={palette.text.paper} />}
    </Typography>
  );
  const percent = useMemo(() => {
    if (
      status === 'success' ||
      status === 'partially_success' ||
      status === 'failed' ||
      status === 'cancelled' ||
      status === 'timeout'
    ) {
      return 100;
    }
    if (!estimatedEndAt) {
      return 0;
    }
    const elapsedTime = getTimeDifferenceInSeconds(new Date(createdAt), new Date());
    const totalTime = getTimeDifferenceInSeconds(new Date(createdAt), new Date(estimatedEndAt));
    return Math.min(99, Math.round((elapsedTime / totalTime) * 100));
  }, [status, createdAt, estimatedEndAt]);

  return (
    <InternalLink unstyled to={`/workspaces/${workspaceId}/jobs`}>
      <Tooltip
        title={
          <Stack alignItems="center">
            <Typography variant="caption2">{upperFirst(status)}</Typography>
            <Typography variant="caption2">({percent}% complete)</Typography>
          </Stack>
        }
        placement="left"
        enterDelay={500}
        disableInteractive
        leaveDelay={0}
        id="job-card-tooltip"
      >
        <Stack sx={{ cursor: 'pointer' }}>
          <Stack
            gap={1}
            p={2}
            sx={{ backgroundColor: palette.background.paper, color: palette.text.paper }}
          >
            <Typography variant="body1" handleOverFlow>
              {name}
            </Typography>
            {workspaceName}
            <Typography variant="caption2">{runs} runs</Typography>
          </Stack>
          <LinearProgress
            variant="determinate"
            value={percent}
            sx={{
              margin: 0,
              padding: '1.5px',
              borderRadius: 0,
              backgroundColor: palette.mode === 'dark' ? palette.grey[700] : palette.grey[200],
              '& .MuiLinearProgress-bar': {
                borderRadius: 0,
                backgroundColor: palette.text.paper,
              },
            }}
          />
        </Stack>
      </Tooltip>
    </InternalLink>
  );
};

export const LandingPageOrganizationDetailsJobs = ({
  organizationId,
}: {
  organizationId: string;
}) => {
  const { palette } = useTheme();
  const { data: organizationJobsData, loading } = useGetOrganizationJobsQuery({
    variables: { organizationId },
    pollInterval: 5000,
    fetchPolicy: 'no-cache',
  });
  const organizationJobs = organizationJobsData?.getDatasetJobs || [];

  const jobQueueRef = useRef<HTMLDivElement>();
  useEffect(() => {
    if (jobQueueRef.current && document) {
      jobQueueRef.current.addEventListener('scroll', (e) => {
        const tooltip = document.getElementById('job-card-tooltip');
        if (tooltip) {
          tooltip.style.display = 'none';
        }
      });
    }
    return () => {
      if (jobQueueRef.current) {
        jobQueueRef.current.removeEventListener('scroll', () => {});
      }
    };
  }, []);

  const inProgressCount = organizationJobs.filter(
    (job) => job.status === 'running' || job.status === 'starting' || job.status === 'postprocess',
  ).length;
  const queuedCount = organizationJobs.length - inProgressCount;
  return (
    <Stack gap={3} sx={{ overflow: 'hidden', flex: 1 }}>
      <Typography variant="h3">Job Queue</Typography>
      {loading ? (
        <Typography variant="body2" textAlign="center">
          <LoadingIcon size={8} color={palette.text.primary} />
        </Typography>
      ) : organizationJobs.length === 0 ? (
        <Typography variant="body2">This organization has no active jobs</Typography>
      ) : (
        <>
          <Group gap={2}>
            <Typography variant="body2">
              {inProgressCount} <Typography variant="caption2">In progress</Typography>
            </Typography>
            <Typography variant="body2">/</Typography>
            <Typography variant="body2">
              {queuedCount} <Typography variant="caption2">Queued</Typography>
            </Typography>
          </Group>
          <Stack gap={2} sx={{ overflow: 'auto' }} ref={jobQueueRef}>
            {organizationJobs.map((job) => (
              <OrganizationJobCard key={job.datasetId} {...job} />
            ))}
          </Stack>
        </>
      )}
    </Stack>
  );
};
