import { Image as DeckardImage } from '@components/image';
import { OptionsMenu } from '@components/menu';
import {
  useEditWorkspaceThumbnailMutation,
  useGetDatasetThumbnailsQuery,
} from '@generated/UseGraphqlHooks';
import { Stack, useTheme } from '@mui/material';
import { useNotifications } from '@notifications/Notifications';
import { useState } from 'react';

type WorkspaceRecentDatasetsImagesSliderProps = {
  workspaceId: string;
  datasetId: string;
};

export const WorkspaceRecentDatasetsImagesSlider = ({
  workspaceId,
  datasetId,
}: WorkspaceRecentDatasetsImagesSliderProps) => {
  const { palette } = useTheme();
  const { data } = useGetDatasetThumbnailsQuery({ variables: { workspaceId, datasetId } });
  const thumbnails = data?.getDatasetThumbnails || [];
  const { useAsyncNotification } = useNotifications();
  const [editWorkspaceThumbnail] = useEditWorkspaceThumbnailMutation();

  const handleSetThumbnail = useAsyncNotification(
    'You have successfully changed the workspace thumbnail',
    async (url: string) => {
      await editWorkspaceThumbnail({
        variables: {
          workspaceId,
          thumbnail: url,
        },
      });
    },
  );

  const [imageIndex, setImageIndex] = useState(0);

  return (
    <Stack sx={{ height: '100%', width: '100%', position: 'relative' }}>
      <DeckardImage
        url={thumbnails?.[imageIndex]}
        onPrevClick={imageIndex > 0 ? () => setImageIndex(imageIndex - 1) : undefined}
        onNextClick={
          imageIndex < thumbnails?.length - 1 ? () => setImageIndex(imageIndex + 1) : undefined
        }
      />
      <OptionsMenu
        sx={{
          position: 'absolute',
          right: 0,
          top: 8,
          borderRadius: '20px',
          backgroundColor: palette.background.default,
        }}
        items={[
          {
            label: 'Set workspace thumbnail',
            onClick: () => handleSetThumbnail(thumbnails?.[imageIndex]),
          },
        ]}
        menuProps={{ transformOrigin: { vertical: 'top', horizontal: 'right' } }}
      />
    </Stack>
  );
};
