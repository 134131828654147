import { LoadingIcon } from '@assets/icons';
import { AsyncButton, Button } from '@components/button';
import { Group } from '@components/layout';
import { Typography } from '@components/typography';
import {
  CustomerPaymentInformation,
  useCreateSubscriptionForUserMutation,
  useGetCustomerPaymentMethodsQuery,
  useGetStripeProductsListQuery,
  useUpdateOrganizationPlanMutation,
} from '@generated/UseGraphqlHooks';
import { delay } from '@helper-functions/delay';
import { Box, Stack, useTheme } from '@mui/material';
import { useNotifications } from '@notifications/Notifications';
import { find, get } from 'lodash';
import { useEffect } from 'react';
import { useOrganizationsBilling } from './OrganizationsBillingProvider';

export const OrganizationsBillingModalConfirmation = ({
  onClose,
  onContinue,
}: {
  onClose: () => void;
  onContinue: () => void;
}) => {
  const { palette } = useTheme();
  const {
    organizationId,
    selectedPlan,
    refetchOrganizationsData,
    refetchBillingHistory,
    stripeProducts,
  } = useOrganizationsBilling();
  const { name, unit_amount: unitAmount } = get(stripeProducts, selectedPlan);

  const { data: products } = useGetStripeProductsListQuery({
    variables: { organizationId },
  });
  const { priceId } = find(products?.getStripeProductsList, { name }) || {};
  const { useAsyncNotification } = useNotifications();
  const { data: customerPaymentMethods, refetch: refetchCustomerPaymentMethods } =
    useGetCustomerPaymentMethodsQuery({
      variables: { organizationId },
    });

  const [updateOrganizationPlan] = useUpdateOrganizationPlanMutation();
  const [createSubscriptionForUser] = useCreateSubscriptionForUserMutation();

  const paymentMethod = get(
    customerPaymentMethods,
    'getCustomerPaymentMethods.0',
  ) as CustomerPaymentInformation;
  const { billing_details: billingDetails, card } = paymentMethod || {};
  const { last4, exp_year: expirationYear, exp_month: expirationMonth } = card || {};

  useEffect(() => {
    void refetchCustomerPaymentMethods();
  }, [paymentMethod]);

  const handleConfirmAndSave = useAsyncNotification(
    'Successfully purchased the plan.',
    async () => {
      const response = await createSubscriptionForUser({
        variables: {
          organizationId,
          priceId,
          paymentId: paymentMethod?.id,
        },
      });
      if (response?.data?.createSubscriptionForUser === 'Successfully created subscription') {
        await updateOrganizationPlan({
          variables: { organizationId, tier: name },
        });
        await delay(5000);
        await refetchOrganizationsData();
        await refetchBillingHistory();
      }

      onContinue();
    },
  );

  return (
    <Stack height="100%" overflow="hidden" gap={6}>
      <Typography variant="h2">Review Order</Typography>
      <Stack
        pr={2}
        pb={8}
        maxHeight="75vh"
        style={{ overflowY: 'auto', overflowX: 'hidden', flex: 1 }}
        gap={10}
      >
        <Box
          bgcolor={palette.mode === 'dark' ? palette.primary[700] : palette.primary.light}
          p="18px"
        >
          <Typography style={{ paddingBottom: '8px' }}>
            You have selected the {name} Plan!
          </Typography>
          <Typography style={{ paddingBottom: '8px' }}>
            Subscription Cost: ${(unitAmount / 100).toFixed(2)}/month
          </Typography>
          <Typography>Due Today: ${(unitAmount / 100).toFixed(2)}</Typography>
        </Box>
        <Stack gap={6}>
          <Typography variant="h3">Payment Information</Typography>
          <Group gap={4}>
            <Stack gap={2} width="100px">
              <Typography>Cardholder:</Typography>
              <Typography>Card Number:</Typography>
              <Typography>Card Expires:</Typography>
            </Stack>
            <Stack gap={2}>
              <Typography variant="body2">
                {billingDetails?.name || <LoadingIcon color={palette.text.paper} />}
              </Typography>
              <Typography variant="body2">**** **** **** {last4 || '****'}</Typography>
              <Typography variant="body2">
                {expirationMonth && expirationYear ? (
                  `${expirationMonth}/${expirationYear % 100}`
                ) : (
                  <LoadingIcon color={palette.text.paper} />
                )}
              </Typography>
            </Stack>
          </Group>
          <Group gap={4}>
            <Stack gap={2} width="100px">
              <Typography>Organization:</Typography>
              <Typography>Email:</Typography>
              <Typography>Phone Number:</Typography>
              <Typography>Address:</Typography>
              <Typography>
                <br />
              </Typography>
            </Stack>
            <Stack gap={2}>
              <Typography variant="body2">
                {paymentMethod?.metadata?.organization || (
                  <LoadingIcon color={palette.text.paper} />
                )}
              </Typography>
              <Typography variant="body2">
                {billingDetails?.address?.line1 || <LoadingIcon color={palette.text.paper} />}
              </Typography>
              <Typography variant="body2">
                {billingDetails?.email || <LoadingIcon color={palette.text.paper} />}
              </Typography>
              <Typography variant="body2">
                {billingDetails?.phone || <LoadingIcon color={palette.text.paper} />}
              </Typography>
              <Typography variant="body2">
                {(billingDetails?.address &&
                  `${billingDetails?.address?.city || ''}, ${billingDetails?.address?.state || ''} ${billingDetails?.address?.postal_code || ''}`) || (
                  <br />
                )}
              </Typography>
            </Stack>
          </Group>
        </Stack>
      </Stack>
      <Box display="flex" flexDirection="column" mb={6} px={10}>
        <Box textAlign="right" />
      </Box>

      <Group justifyContent="flex-end" alignItems="center" width="100%" gap={4}>
        <AsyncButton onClick={handleConfirmAndSave}>Purchase</AsyncButton>
        <Button onClick={onClose} variant="secondary">
          Back
        </Button>
      </Group>
    </Stack>
  );
};
