import { LoadingIcon } from '@assets/icons';
import { InfiniteScrollTableComponents } from '@components/infiniteScroll';
import { Modal } from '@components/modal';
import { Typography } from '@components/typography';
import {
  Dataset,
  useDownloadDatasetMutation,
  useGetRecentDatasetsQuery,
} from '@generated/UseGraphqlHooks';
import { saveFile } from '@helper-functions/save-file';
import { Box, TableCell, TableRow, useTheme } from '@mui/material';
import { useNotifications } from '@notifications/Notifications';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { TableVirtuoso } from 'react-virtuoso';
import { WorkspaceRecentDatasetsImagesSlider } from './WorkspacesRecentDatasetsPreviewImagesSlider';
import { WorkspacesRecentDatasetsRow } from './WorkspacesRecentDatasetsRow';

const TableHeader = () => {
  return (
    <TableRow>
      <TableCell>
        <Typography variant="caption1">Name</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="caption1">Created at</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="caption1">Images</Typography>
      </TableCell>
      <TableCell></TableCell>
    </TableRow>
  );
};

interface WorkspaceRecentDatasetsProps {
  workspaceId: string;
}

export const WorkspaceRecentDatasets = ({ workspaceId }: WorkspaceRecentDatasetsProps) => {
  const { palette } = useTheme();
  const { data, loading } = useGetRecentDatasetsQuery({
    variables: { workspaceId: workspaceId },
    pollInterval: 5000,
    fetchPolicy: 'no-cache',
  });

  const recentDatasets = data?.getDatasets || [];

  const { addNotification, removeNotification } = useNotifications();
  const [downloadDataset] = useDownloadDatasetMutation();
  const handleDownloadDataset = async (dataset: Dataset) => {
    const key = `${Date.now() * 1000}.${Math.random() * 10000}`;
    try {
      const response = await downloadDataset({
        variables: {
          workspaceId,
          datasetId: dataset?.datasetId,
        },
      });
      addNotification({ text: 'Loading...', type: 'loading', key, duration: 100000 });
      saveFile({
        url: response?.data?.downloadDataset,
        name: `${dataset?.name || DateTime.now().toISODate()}.zip`,
      });
      removeNotification(key);
      addNotification({
        text: 'Starting download...',
        type: 'success',
        duration: 6000,
      });
    } catch (e) {
      removeNotification(key);
    }
  };

  const [modalOpen, setModalOpen] = useState<string | null>(null);

  const tableRow = (_index, dataset: Dataset) => (
    <WorkspacesRecentDatasetsRow
      dataset={dataset}
      workspaceId={workspaceId}
      onDownload={handleDownloadDataset}
      onPreview={setModalOpen}
    />
  );

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
        width="100%"
        sx={{ backgroundColor: palette.background.paper }}
      >
        <LoadingIcon size={16} />
      </Box>
    );
  }

  return (
    <Box sx={{ p: 4, height: '100%', backgroundColor: palette.background.paper }}>
      <TableVirtuoso
        data={recentDatasets}
        totalCount={30}
        components={InfiniteScrollTableComponents as any}
        fixedHeaderContent={TableHeader}
        itemContent={tableRow}
      />
      <Modal
        open={Boolean(modalOpen)}
        onClose={() => setModalOpen(null)}
        sx={{ '&.deckard-modal-container': { width: '75vw', height: '60vh' } }}
        disablePortal
      >
        <WorkspaceRecentDatasetsImagesSlider workspaceId={workspaceId} datasetId={modalOpen} />
      </Modal>
    </Box>
  );
};
