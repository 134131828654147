import { LoadingIcon } from '@assets/icons';
import { DeckardIcon } from '@assets/types';
import { Typography } from '@components/typography';
import { Button as MuiButton, ButtonProps as MuiButtonProps, useTheme } from '@mui/material';
import { Ref, forwardRef } from 'react';

export type ButtonType = 'primary' | 'secondary' | 'accent';
export type ButtonSize = 'small' | 'large';

export type ButtonProps = Omit<MuiButtonProps, 'variant' | 'startIcon' | 'endIcon' | 'size'> & {
  endIcon?: DeckardIcon;
  loading?: boolean;
  size?: ButtonSize;
  startIcon?: DeckardIcon;
  variant?: ButtonType;
};

export const Button = forwardRef(
  (
    {
      children,
      loading,
      startIcon: StartIcon,
      endIcon: EndIcon,
      fullWidth,
      size = 'large',
      sx,
      variant = 'primary',
      ...rest
    }: ButtonProps,
    ref: Ref<HTMLButtonElement>,
  ) => {
    const { palette, spacing, typography } = useTheme();

    return (
      <MuiButton
        ref={ref}
        disableFocusRipple
        sx={{
          height: size === 'small' ? '28px' : '34px',
          width: fullWidth ? '100%' : 'fit-content',
          minWidth: 'fit-content',
          borderRadius: 1,
          padding: `${spacing(1.5)} ${size === 'small' ? spacing(3) : spacing(4)}`,
          paddingLeft:
            StartIcon && !loading ? spacing(2) : size === 'small' ? spacing(3) : spacing(4),
          paddingRight:
            EndIcon && !loading ? spacing(2) : size === 'small' ? spacing(3) : spacing(4),
          textTransform: 'none',
          fontStyle: typography.fontFamily,
          textAlign: 'center',
          pointerEvents: loading ? 'none' : 'initial',
          '&:disabled': {
            opacity: 0.4,
            color: palette.button.text[variant],
          },
          '& .MuiSvgIcon-root': {
            opacity: loading ? 0 : 1,
          },
          color: palette.button.text[variant],
          backgroundColor: palette.button.background[variant],
          border: `1px solid ${palette.button.border[variant]}`,
          '&:hover': {
            backgroundColor: palette.button.hover[variant],
          },
          '&:focus': {
            outline: `3px solid ${palette.button.outline}`,
            '&:not(:focus-visible)': {
              outline: 'none',
            },
          },
          ...sx,
        }}
        {...rest}
      >
        <Typography
          sx={{
            opacity: loading ? 0 : 1,
            display: 'flex',
            alignItems: 'center',
            gap: 2,
          }}
          variant={size === 'small' ? 'caption1' : 'body1'}
          noWrap
        >
          {StartIcon && <StartIcon size={16} color={palette.button.icon[variant]} />}
          {children}
          {EndIcon && <EndIcon size={16} color={palette.button.icon[variant]} />}
        </Typography>
        {loading && (
          <LoadingIcon
            color={palette.button.text[variant]}
            size={4}
            style={{ position: 'absolute' }}
          />
        )}
      </MuiButton>
    );
  },
);
