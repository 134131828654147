import { CheckCircleIcon, InfoIcon } from '@assets/icons';
import { Button } from '@components/button';
import { useModal } from '@components/modal';
import { Typography } from '@components/typography';
import { useDeleteOrganizationMutation, useGetCurrentUserQuery } from '@generated/UseGraphqlHooks';
import { Box, useTheme } from '@mui/material';
import { navigate } from 'gatsby';
import { useState } from 'react';
import { OrganizationsBillingPaymentInformationUnableToRemoveOrganizationModal } from './OrganizationsBillingPaymentInformationUnableToRemoveOrganizationModal';

type OrganizationsBillingPaymentInformationRemoveOrganizationModalProps = {
  onClose: () => void;
};

export const OrganizationsBillingPaymentInformationRemoveOrganizationModal = ({
  onClose,
}: OrganizationsBillingPaymentInformationRemoveOrganizationModalProps) => {
  const { palette } = useTheme();

  const { data } = useGetCurrentUserQuery();
  const organizationId = data?.getCurrentUser?.selectedOrganizationId || '';
  const [deleteOrganizationMutation, { loading }] = useDeleteOrganizationMutation();

  const { showModal: openUnableToRemoveOrganizationModal } = useModal({
    component: OrganizationsBillingPaymentInformationUnableToRemoveOrganizationModal,
  });

  const [state, setState] = useState('');

  const handleDeleteOrganizationButton = async () => {
    await deleteOrganizationMutation({ variables: { organizationId } })
      .then(() => setState('success'))
      .catch(() => {
        openUnableToRemoveOrganizationModal();
      });
  };

  const handleGoToLandingPageButton = async () => {
    await navigate('/');
  };

  if (state === 'success') {
    return (
      <Box
        width="645px"
        display="flex"
        flexDirection="column"
        pt={6}
        pb={10}
        height="100%"
        alignItems="center"
        justifyContent="center"
      >
        <Box pb={21}>
          <Typography color="grey.90" variant="body2">
            Hope we see you again!
          </Typography>
        </Box>
        <CheckCircleIcon color={palette.grey[700]} />
        <Box pt={10}>
          <Typography variant="body2" color={palette.common.black}>
            Your organization has been deleted.
          </Typography>
        </Box>
        <Box pt={14} width="283px">
          <Button onClick={handleGoToLandingPageButton} fullWidth>
            Go to landing page
          </Button>
        </Box>
      </Box>
    );
  }

  return (
    <Box width="516px" px={8} pt={12} pb={10} height="100%">
      <Box pb={9}>
        <Typography variant="h2" color="black">
          Are you sure you want delete this organization ?
        </Typography>
      </Box>
      <Box display="flex" alignItems="center" pb={2}>
        <InfoIcon />
        <Box pl={4}>
          <Typography variant="h3" color="grey.900">
            Subscription cancellation
          </Typography>
        </Box>
      </Box>
      <Box pb={6}>
        <Typography variant="body2" color="grey.900">
          If you delete the organization, your existing developer subscription will also get
          canceled.
        </Typography>
      </Box>
      <Box display="flex" alignItems="center" pb={2}>
        <InfoIcon />
        <Box pl={4}>
          <Typography variant="h3" color="grey.900">
            Unlinking all members
          </Typography>
        </Box>
      </Box>
      <Box pb={8}>
        <Typography variant="body2" color="grey.900">
          After deletion no user will be able to access this organization or any organization data.
        </Typography>
      </Box>
      <Box>
        <Button
          variant="accent"
          loading={loading}
          fullWidth
          onClick={handleDeleteOrganizationButton}
        >
          Delete
        </Button>
        <Box pt={4}>
          <Button variant="secondary" fullWidth onClick={onClose}>
            Cancel
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
