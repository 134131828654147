import { Button } from '@components/button';
import { Typography } from '@components/typography';
import {
  useDeleteManagedGanMutation,
  useGetGanModelsQuery,
  useRemoveGanOrganizationMutation,
} from '@generated/UseGraphqlHooks';
import { delay } from '@helper-functions/delay';
import { Stack } from '@mui/material';
import { useNotifications } from '@notifications/Notifications';
import { useState } from 'react';

interface DeleteGanModelModalProps {
  name: string;
  modelId: string;
  organizationId: string;
  onClose: () => void;
  isGanModelOwnedByOrganization: boolean;
}

export const DeleteGanModelModal = ({
  name,
  modelId,
  organizationId,
  onClose,
  isGanModelOwnedByOrganization,
}: DeleteGanModelModalProps) => {
  const { useAsyncNotification } = useNotifications();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [deleteGAN] = useDeleteManagedGanMutation();
  const [removeOrganizationGanModel] = useRemoveGanOrganizationMutation();
  const { refetch } = useGetGanModelsQuery({
    variables: {
      organizationId,
    },
  });

  const handleDeleteGanClick = useAsyncNotification(
    `Successfully ${isGanModelOwnedByOrganization ? 'deleted' : 'removed'} gan model`,
    async () => {
      setIsSubmitting(true);
      isGanModelOwnedByOrganization
        ? await deleteGAN({ variables: { modelId } })
        : await removeOrganizationGanModel({
            variables: {
              modelId,
              organizationId,
            },
          });
      await delay(3000);
      await refetch();
      setIsSubmitting(false);
      onClose();
    },
  );

  return (
    <Stack pt={4} gap={10} justifyContent="center">
      <Typography variant="body2" align="center">
        Are you sure you want to {isGanModelOwnedByOrganization ? 'delete' : 'remove'} {name}?
      </Typography>
      <Stack gap={4}>
        <Button fullWidth disabled={isSubmitting} onClick={handleDeleteGanClick}>
          Yes, {isGanModelOwnedByOrganization ? 'delete' : 'remove'}
        </Button>
        <Button fullWidth onClick={onClose} variant="secondary">
          Cancel
        </Button>
      </Stack>
    </Stack>
  );
};
