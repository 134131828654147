import { ChevronDownIcon } from '@assets/icons';
import { Typography } from '@components/typography';
import {
  FormControl,
  FormControlProps,
  Select as MuiSelect,
  SelectProps as MuiSelectProps,
  useTheme,
} from '@mui/material';

export type SelectProps = Omit<MuiSelectProps, 'error'> & {
  containerProps?: FormControlProps;
  error?: boolean;
  label?: string;
  maintainGutters?: boolean;
  small?: boolean;
  fetchMore?: () => void;
};

export const Select = ({
  containerProps = {},
  disabled,
  fullWidth = true,
  error,
  label,
  maintainGutters = false,
  small = false,
  sx,
  fetchMore,
  ...props
}: SelectProps) => {
  const { palette, typography } = useTheme();
  return (
    <FormControl fullWidth={fullWidth} {...containerProps}>
      {label && (
        <Typography
          className="deckard-text-input-label"
          mb={1}
          variant="caption2"
          color={
            disabled
              ? palette.input.label.disabled
              : error
                ? palette.input.label.error
                : palette.input.label.default
          }
        >
          {label}
        </Typography>
      )}
      <MuiSelect
        variant="standard"
        IconComponent={ChevronDownIcon}
        MenuProps={{
          slotProps: {
            paper: {
              onScroll: (scrollEvent) => {
                if (
                  scrollEvent.currentTarget.scrollTop + scrollEvent.currentTarget.clientHeight ===
                    scrollEvent.currentTarget.scrollHeight &&
                  fetchMore
                ) {
                  void fetchMore();
                }
              },
            },
          },
          sx: { '& .MuiMenu-paper': { marginTop: small ? 1 : 2 } },
        }}
        fullWidth={fullWidth}
        error={error}
        disabled={disabled}
        sx={{
          ...(small
            ? {
                ...typography.caption2,
                height: '24px',
                minHeight: '24px',
                '& .MuiSelect-iconStandard': { height: '12px', width: '12px' },
              }
            : {}),
          ...sx,
        }}
        {...props}
      />
    </FormControl>
  );
};
