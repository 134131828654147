import { Image } from '@components/image';
import { useTheme } from '@mui/material';

interface WorkspacesDropdownChangeThumbnailModalThumbnailBoxProps {
  thumbnail?: string;
  handleSelection?: (previewThumbnail: string) => () => void;
  selected?: boolean;
}
export const WorkspacesDropdownChangeThumbnailModalThumbnailBox = ({
  thumbnail,
  handleSelection,
  selected,
}: WorkspacesDropdownChangeThumbnailModalThumbnailBoxProps) => {
  const { palette } = useTheme();
  return (
    <Image
      url={thumbnail || ''}
      sx={{
        aspectRatio: 1,
        paddingTop: 0,
        borderBottom: selected ? `4px solid ${palette.primary.main}` : `4px solid transparent`,
        cursor: 'pointer',
      }}
      onClick={handleSelection(thumbnail || 'default')}
    />
  );
};
