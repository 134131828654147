import { EyeIcon, EyeOffIcon, SlidersIcon, TrashIcon } from '@assets/icons';
import { IconButton } from '@components/button';
import { Checkbox, ColorPicker, Select, Slider } from '@components/form';
import { Group } from '@components/layout';
import { Typography } from '@components/typography';
import { useGetDatasetInferencesQuery } from '@generated/UseGraphqlHooks';
import { Box, Menu, MenuItem, Stack, useTheme } from '@mui/material';
import { useState } from 'react';
import { InferenceSettings } from './DatasetsDetailsImagesSliderTypes';

type InferenceSettingsRowProps = {
  datasetId: string;
  settings: InferenceSettings;
  workspaceId: string;
  removeRow: () => void;
  updateSettings: (settings: InferenceSettings) => void;
};

export const InferenceSettingsRow = ({
  datasetId,
  settings,
  workspaceId,
  removeRow,
  updateSettings,
}: InferenceSettingsRowProps) => {
  const { typography } = useTheme();
  const [optionsMenuAnchorRef, setOptionsMenuAnchorRef] = useState<HTMLElement | null>(null);
  const { data } = useGetDatasetInferencesQuery({ variables: { workspaceId, datasetId } });
  const inferences = data?.getMLInferences || [];
  const { color, visible, inferenceId } = settings;
  const updateSetting = <K extends keyof InferenceSettings>(
    key: K,
    value: InferenceSettings[K],
  ) => {
    updateSettings({ ...settings, [key]: value });
  };

  return (
    <Group alignItems="center" gap={4} py={1} maxWidth="md">
      <Select
        onChange={(e) => updateSetting('inferenceId', e.target.value as string)}
        value={inferenceId || ''}
        fullWidth={false}
        sx={{ minWidth: '200px' }}
      >
        {inferences.map((i) => (
          <MenuItem key={i.inferenceId} value={i.inferenceId}>
            {i.model}
          </MenuItem>
        ))}
      </Select>
      <Box flex={1} />
      <ColorPicker color={color} onChange={(newColor) => updateSetting('color', newColor)} />
      <IconButton
        Icon={SlidersIcon}
        size={24}
        onClick={(e) => setOptionsMenuAnchorRef(e.currentTarget)}
      />
      <Menu
        open={Boolean(optionsMenuAnchorRef)}
        anchorEl={optionsMenuAnchorRef}
        onClose={() => setOptionsMenuAnchorRef(null)}
        sx={{ '& .MuiList-root': { py: 3, px: 4 } }}
      >
        <Stack gap={1}>
          <Checkbox
            label="Show inference labels"
            checked={settings?.showLabels}
            onClick={() => updateSetting('showLabels', !settings?.showLabels)}
            sx={{ '& .MuiTypography-root': { ...typography.caption2 } }}
          />
          <Stack>
            <Group justifyContent="space-between" gap={2}>
              <Typography variant="caption2">Confidence threshold</Typography>
              <Typography variant="caption2">{settings?.confidenceThreshold}</Typography>
            </Group>
            <Slider
              min={0}
              max={100}
              step={1}
              value={settings?.confidenceThreshold}
              onChange={(_, newThreshold) =>
                updateSetting('confidenceThreshold', newThreshold as number)
              }
            />
          </Stack>
          <Stack>
            <Typography variant="caption2">Outline width</Typography>
            <Slider
              min={0.5}
              max={5}
              step={0.5}
              value={settings?.strokeWidth}
              onChange={(_, newWidth) => updateSetting('strokeWidth', newWidth as number)}
            />
          </Stack>
        </Stack>
      </Menu>
      <IconButton
        Icon={visible ? EyeIcon : EyeOffIcon}
        size={24}
        onClick={() => updateSetting('visible', !visible)}
      />
      <IconButton Icon={TrashIcon} size={24} onClick={removeRow} />
    </Group>
  );
};
