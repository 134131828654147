import { LoadingIcon } from '@assets/icons';
import { Umap, useDeleteUmapMutation } from '@generated/UseGraphqlHooks';
import { Box, useTheme } from '@mui/material';
import { useNotifications } from '@notifications/Notifications';
import { UmapLoadingDatasetsRow, UmapLoadingHeader } from '@subsets/workspaces';
import { navigate } from 'gatsby';
import { isNil } from 'lodash';

interface UmapLoadingProps {
  workspaceId: string;
  fitDatasetId: string;
  umapLoading: boolean;
  datasets: Umap['datasets'];
  samples: number[];
  status: string;
  umapId: string;
}

export const UmapLoading = ({
  status,
  datasets,
  umapLoading,
  samples,
  workspaceId,
  umapId,
  fitDatasetId,
}: UmapLoadingProps) => {
  const { palette } = useTheme();
  const [deleteUmapQuery] = useDeleteUmapMutation();
  const { useAsyncNotification } = useNotifications();

  const deleteUmap = useAsyncNotification('Terminating', async () => {
    try {
      await deleteUmapQuery({ variables: { workspaceId, umapId } });
      await navigate(-1);
    } catch (err) {
      throw new Error('Termination interrupted');
    }
  });
  const isCompletedUmapLoading =
    (umapLoading && isNil(status)) || (umapLoading && status === 'completed');

  return (
    <Box height="100%" bgcolor={palette.background.paper} p={4}>
      {isCompletedUmapLoading ? (
        <LoadingIcon size={12} />
      ) : (
        <UmapLoadingHeader type={status} onCancel={deleteUmap} />
      )}
      <UmapLoadingDatasetsRow datasets={datasets} samples={samples} fitDatasetId={fitDatasetId} />
    </Box>
  );
};
