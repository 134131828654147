import { Modal, useContactUsModal, useModal } from '@components/modal';
import { Typography } from '@components/typography';
import { useGetOrganizationsQuery } from '@generated/UseGraphqlHooks';
import { Box, Stack, useTheme } from '@mui/material';
import {
  OrganizationsBillingModal,
  OrganizationsBillingPaymentInformationButtons,
  OrganizationsBillingPaymentInformationModalCredit,
  OrganizationsBillingPaymentInformationPreview,
  useOrganizationsBilling,
} from '@subsets/organizations';
import { isNil } from 'lodash';
import { DateTime } from 'luxon';
import posthog from 'posthog-js';
import { useState } from 'react';
import { OrganizationsBillingPaymentInformationDataBlock } from './OrganizationsBillingPaymentInformationDataBlock';

export const OrganizationsBillingPaymentInformation = () => {
  const { palette } = useTheme();
  const [modalState, setModalState] = useState(false);
  const handleModalOpen = () => setModalState(true);
  const handleModalClose = () => setModalState(false);

  const { organizationInformation, organizationsDataLoading, organizationId } =
    useOrganizationsBilling();

  const { showModal: openBillingUpdatePaymentInformationModal } = useModal({
    component: OrganizationsBillingPaymentInformationModalCredit,
    componentProps: { organizationId },
    modalProps: { disablePortal: true, title: 'Update payment information' },
  });

  const trialSubscription =
    organizationInformation?.plan === 'Developer' &&
    organizationInformation?.stripeCustomerId === '';

  const { data: organizationData } = useGetOrganizationsQuery({
    variables: {
      organizationId,
    },
  });
  const organizationName = organizationData?.getOrganizations.find(
    (org) => org.organizationId === organizationId,
  )?.name;
  const { showModal: openBillingContactUsModal } = useContactUsModal({
    componentProps: {
      defaultSubject: `Billing for ${organizationName} for id: ${organizationId}`,
    },
  });

  const handleContactUsButton = () => {
    posthog.capture('user_contact-us_intent', {
      location: 'organizations-billing-payment-information',
    });
    openBillingContactUsModal();
  };

  if (organizationsDataLoading) {
    return (
      <Box pr="25px">
        <Typography mb="7px" mt={9} variant="h3">
          Payment information
        </Typography>
        <Typography
          pt={5}
          px={8}
          height="calc(100vh - 400px)"
          bgcolor="white"
          display="flex"
          flexDirection="column"
          style={{ overflowY: 'auto', overflowX: 'hidden' }}
        >
          Loading...
        </Typography>
      </Box>
    );
  }

  return (
    <>
      <Stack
        p={4}
        height="100%"
        bgcolor={palette.background.paper}
        style={{ overflowY: 'auto', overflowX: 'hidden' }}
        gap={8}
      >
        <Stack>
          {!trialSubscription && (
            <OrganizationsBillingPaymentInformationPreview
              organizationId={organizationId}
              onEditInformation={openBillingUpdatePaymentInformationModal}
            />
          )}
          <OrganizationsBillingPaymentInformationDataBlock
            label="Subscription"
            data={
              <Typography variant="body2" sx={{ width: '50%', flex: 1 }}>
                {isNil(organizationInformation)
                  ? 'No Plan'
                  : `${organizationInformation?.plan} ${trialSubscription ? '(Trial)' : ''}`}
              </Typography>
            }
          />
          {organizationInformation?.expirationDate && trialSubscription && (
            <OrganizationsBillingPaymentInformationDataBlock
              label="Trial Expiration"
              data={
                <Typography sx={{ width: '50%', flex: 1 }} variant="body2">
                  {DateTime.fromISO(organizationInformation.expirationDate).toFormat('MM/dd/yyyy')}
                </Typography>
              }
            />
          )}
        </Stack>
        <OrganizationsBillingPaymentInformationButtons
          handleChangePlanButton={handleModalOpen}
          organizationTier={organizationInformation?.plan}
          trialSubscription={trialSubscription}
        />
        <Typography variant="body2">
          Have questions about additional billing options?{' '}
          <Typography variant="body1" link onClick={handleContactUsButton}>
            Contact Us
          </Typography>
        </Typography>
      </Stack>
      <Modal
        open={modalState}
        onClose={handleModalClose}
        disablePortal
        sx={{
          width: '120vh',
          height: '100%',
          overflowY: 'auto',
          overflowX: 'auto',
          maxHeight: '80vh',
        }}
      >
        <OrganizationsBillingModal
          onClose={handleModalClose}
          organizationId={organizationId}
          trialSubscription={trialSubscription}
        />
      </Modal>
    </>
  );
};
