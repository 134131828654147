import { EditIcon, ExternalLinkIcon, TrashIcon } from '@assets/icons';
import { CopyIconButton, IconButton } from '@components/button';
import { Group } from '@components/layout';
import { InternalLink } from '@components/link';
import { useConfirmation, useModal } from '@components/modal';
import { Typography } from '@components/typography';
import { Umap, useDeleteUmapMutation } from '@generated/UseGraphqlHooks';
import { Box, Grid } from '@mui/material';
import { useNotifications } from '@notifications/Notifications';
import {
  DatasetsDetailsMicroserviceStatus,
  DatasetsDetailsUmapEditModal,
} from '@subsets/workspaces';

type DatasetsDetailsUmapListItemPreviewProps = {
  datasetId: string;
  umap: Partial<Umap>;
  workspaceId: string;
  refetch: VoidFunction;
};

export const DatasetsDetailsUmapListItemPreview = ({
  datasetId,
  umap: { status, umapId, name },
  workspaceId,
  refetch,
}: DatasetsDetailsUmapListItemPreviewProps) => {
  const { useAsyncNotification } = useNotifications();
  const { showConfirmation } = useConfirmation();
  const [deleteUmapMutation] = useDeleteUmapMutation();

  const { showModal: showEditModal } = useModal({
    component: DatasetsDetailsUmapEditModal,
    componentProps: {
      workspaceId,
      umapId,
      name,
      refetch,
    },
    modalProps: { title: 'Edit UMAP' },
  });

  const handleDeleteUmap = () =>
    showConfirmation({
      onAffirm: useAsyncNotification('Successfully deleted umap.', async () => {
        await deleteUmapMutation({
          variables: {
            umapId,
            workspaceId,
          },
        });
        void refetch();
      }),
      message: `Are you sure you want to delete this umap?`,
    });

  return (
    <Grid container alignItems="center" justifyContent="flex-start">
      <Grid item xs={1.5}>
        <Box display="flex" marginLeft={1}>
          <DatasetsDetailsMicroserviceStatus
            status={status}
            workspaceId={workspaceId}
            microServiceName="umap"
            datasetId={datasetId}
            microServiceId={umapId}
          />
        </Box>
      </Grid>
      <Grid item xs={1.5} sx={{ display: 'flex', justifyContent: 'center' }}>
        <CopyIconButton value={umapId} showValue tooltipProps={{ variant: 'secondary' }} />
      </Grid>
      <Grid item xs={3}>
        <Typography variant="body2" handleOverFlow noWrap>
          {name || '-'}
        </Typography>
      </Grid>
      <Grid item lg={6}>
        <Group justifyContent="flex-end" gap={2}>
          {status !== 'failed' && (
            <>
              <InternalLink unstyled to={`/workspaces/${workspaceId}/experiments/umap/${umapId}`}>
                <IconButton Icon={ExternalLinkIcon} />
              </InternalLink>
              <IconButton onClick={showEditModal} Icon={EditIcon} size={18} />
            </>
          )}
          <IconButton onClick={handleDeleteUmap} Icon={TrashIcon} />
        </Group>
      </Grid>
    </Grid>
  );
};
