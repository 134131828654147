import { Button } from '@components/button';
import { saveFile } from '@helper-functions/save-file';
import { useNotifications } from '@notifications/Notifications';

type DatasetLogType = {
  message: string;
  timestamp: string;
  ingestionTime: string;
};

interface JobsManagerPreviewLogsScreenSaveToFileProps {
  datasetId: string;
  run: string;
  datasetLogs: DatasetLogType[];
  name: string;
}

export const JobsManagerPreviewLogsScreenSaveToFile = ({
  datasetId,
  run,
  datasetLogs,
  name,
}: JobsManagerPreviewLogsScreenSaveToFileProps) => {
  const { addNotification } = useNotifications();
  const downloadLogs = () => {
    const data = datasetLogs
      .map(
        (datasetLog) =>
          `${new Date(datasetLog.timestamp).toISOString()}  ${new Date(
            datasetLog.ingestionTime,
          ).toISOString()} ${datasetLog.message}`,
      )
      .join('\n');
    const filename = `${datasetId}_${name}_${run}.log`;
    const url = window.URL.createObjectURL(new Blob([data], { type: 'text/plain' }));
    saveFile({ url, name: filename });
    addNotification({ type: 'success', text: 'Starting download...', duration: 6000 });
  };

  return (
    <Button variant="secondary" onClick={downloadLogs}>
      Save to File
    </Button>
  );
};
