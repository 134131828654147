import { PlusIcon } from '@assets/icons';
import { Button } from '@components/button';
import { Image as DeckardImage } from '@components/image';
import { OptionsMenu } from '@components/menu';
import { Typography } from '@components/typography';
import {
  useEditWorkspaceThumbnailMutation,
  useGetDatasetImageFilenamesQuery,
  useGetDatasetImageLazyQuery,
} from '@generated/UseGraphqlHooks';
import { generateColorTriad } from '@helper-functions/color';
import { useFeatureFlag } from '@hooks/FeatureFlags';
import { Container, Divider, Stack, useTheme } from '@mui/material';
import { useNotifications } from '@notifications/Notifications';
import { useEffect, useState } from 'react';
import { DatasetsDetailsImagesSliderInferenceOverlay } from './DatasetsDetailsImagesSliderInferenceOverlay';
import { DatasetsDetailsImagesSliderOverlay } from './DatasetsDetailsImagesSliderOverlay';
import {
  GroundTruthSettings,
  ImageAnnotations,
  InferenceSettings,
} from './DatasetsDetailsImagesSliderTypes';
import { GroundTruthSettingsRow } from './GroundTruthSettingsRow';
import { InferenceSettingsRow } from './InferenceSettingsRow';

type DatasetsDetailsImagesSliderProps = {
  previewUrls?: string[];
  imageIndex?: number | null;
  workspaceId?: string;
  datasetId?: string;
  datasetName?: string;
  loading?: boolean;
  defaultInferenceId?: string;
  runs?: number;
};

export const DatasetsDetailsImagesSlider = ({
  imageIndex: initialImageIndex = 0,
  workspaceId,
  datasetId,
  datasetName,
  defaultInferenceId,
  runs,
}: DatasetsDetailsImagesSliderProps) => {
  const featureFlagEnabled = useFeatureFlag('feature_ml-training-and-inference');
  const { palette } = useTheme();
  const { data: fileNameData } = useGetDatasetImageFilenamesQuery({
    variables: { workspaceId, datasetId, path: 'images/' },
  });
  const [imageIndex, setImageIndex] = useState(initialImageIndex);
  const fileName = fileNameData?.getDatasetFiles?.[imageIndex || 0] || null;
  const [imageData, setImageData] = useState<{ url: string; annotations: ImageAnnotations[] }[]>(
    Array(runs),
  );
  const [groundTruthSettings, setGroundTruthSettings] = useState<GroundTruthSettings>({
    mode: '2d',
    color: '#ff0000',
    opacity: 1,
    strokeWidth: 2,
    visible: false,
  });
  const [layers, setLayers] = useState<InferenceSettings[]>(
    defaultInferenceId
      ? [
          {
            color: generateColorTriad(groundTruthSettings.color)[0],
            confidenceThreshold: 50,
            inferenceId: defaultInferenceId,
            opacity: 1,
            showLabels: false,
            strokeWidth: 2,
            visible: true,
          },
        ]
      : [],
  );
  const [getDatasetImage] = useGetDatasetImageLazyQuery();
  useEffect(() => {
    const loadImageData = async (index: number) => {
      const {
        data: {
          getDatasetAnnotation: {
            data: [imageData],
          },
          getDatasetImage: {
            data: [imageUrl],
          },
        },
      } = await getDatasetImage({
        variables: { workspaceId, datasetId, run: index },
      });
      if (!imageUrl) {
        return;
      }
      const img = (new Image().src = imageUrl);
      setImageData((old) => {
        const updated = [...old];
        updated[index] = {
          url: imageUrl,
          annotations: imageData
            ? (JSON.parse(imageData) as { annotations: ImageAnnotations[] }).annotations
            : [],
        };
        return updated;
      });
    };
    if (!imageData[imageIndex]) {
      void loadImageData(imageIndex);
    }
    if (imageIndex < runs - 1 && !imageData[imageIndex + 1]) {
      void loadImageData(imageIndex + 1);
    }
    if (imageIndex > 0 && !imageData[imageIndex - 1]) {
      void loadImageData(imageIndex - 1);
    }
  }, [imageIndex]);

  const { useAsyncNotification } = useNotifications();
  const [editWorkspaceThumbnail] = useEditWorkspaceThumbnailMutation();
  const handleSetThumbnail = useAsyncNotification(
    'You have successfully changed the workspace thumbnail',
    async (url: string) => {
      await editWorkspaceThumbnail({
        variables: {
          workspaceId,
          thumbnail: url,
        },
      });
    },
  );

  return (
    <Stack
      data-cy="Datasets-Details-Images-Slider"
      sx={{ height: '100%', gap: 2, position: 'relative' }}
    >
      <Typography variant="subtitle1">{datasetName}</Typography>
      <DeckardImage
        url={imageData?.[imageIndex]?.url}
        onPrevClick={imageIndex > 0 ? () => setImageIndex(imageIndex - 1) : undefined}
        onNextClick={imageIndex < runs - 1 ? () => setImageIndex(imageIndex + 1) : undefined}
        overlaySVG={(imageDimensions) => (
          <>
            groundTruthSettings.visible && (
            <DatasetsDetailsImagesSliderOverlay
              {...groundTruthSettings}
              imageAnnotations={imageData?.[imageIndex]?.annotations}
              imageIndex={imageIndex}
              state={groundTruthSettings.mode}
              renderErrors={null}
              setRenderErrors={null}
            />
            )
            {layers.map((layer, index) =>
              layer.inferenceId ? (
                <DatasetsDetailsImagesSliderInferenceOverlay
                  key={index}
                  fileName={fileName}
                  settings={layer}
                  workspaceId={workspaceId}
                  imageDimensions={imageDimensions}
                />
              ) : null,
            )}
          </>
        )}
        overlayStyle={
          imageData?.[imageIndex]?.annotations &&
          groundTruthSettings.mode === 'segmentation' &&
          groundTruthSettings.segmentationMask // &&
            ? { backgroundColor: 'black' }
            : {}
        }
      />
      <OptionsMenu
        sx={{
          position: 'absolute',
          right: 0,
          top: 32,
          borderRadius: '20px',
          backgroundColor: palette.background.default,
        }}
        items={[
          {
            label: 'Set workspace thumbnail',
            onClick: () => handleSetThumbnail(imageData?.[imageIndex]?.url),
          },
        ]}
        menuProps={{ transformOrigin: { vertical: 'top', horizontal: 'right' } }}
      />
      <Container maxWidth="md" sx={{ mx: 'auto' }}>
        <GroundTruthSettingsRow
          settings={groundTruthSettings}
          updateSettings={setGroundTruthSettings}
        />
        <Divider />
        {layers.map((layer, index) => (
          <InferenceSettingsRow
            key={index}
            datasetId={datasetId}
            settings={layer}
            workspaceId={workspaceId}
            removeRow={() => {
              const nextLayers = layers.filter((_, i) => i !== index);
              setLayers(nextLayers);
            }}
            updateSettings={(newSettings) => {
              const nextLayers = [...layers];
              nextLayers[index] = newSettings;
              setLayers(nextLayers);
            }}
          />
        ))}
        {layers.length < 2 && featureFlagEnabled && (
          <Button
            startIcon={PlusIcon}
            disabled={layers.length >= 2}
            onClick={() =>
              setLayers((currentLayers) => [
                ...currentLayers,
                {
                  color: generateColorTriad(groundTruthSettings.color)[currentLayers.length],
                  confidenceThreshold: 50,
                  opacity: 1,
                  strokeWidth: 2,
                  visible: true,
                  inferenceId: '',
                  showLabels: false,
                },
              ])
            }
            sx={{ my: 1.25 }}
          >
            Add inference layer
          </Button>
        )}
      </Container>
    </Stack>
  );
};
