import { Button } from '@components/button';
import { useContactUsModal } from '@components/modal';
import { Typography } from '@components/typography';
import { Stack } from '@mui/material';
import posthog from 'posthog-js';

export const LandingPageOrganizationsTabPanelEmpty = () => {
  const { showModal } = useContactUsModal();
  const handleContactUsClick = () => {
    posthog.capture('user_contact-us_intent', { location: 'landing-page-no-orgs' });
    showModal();
  };

  return (
    <Stack height="100%" width="100%" gap={6} alignItems="center" justifyContent="center">
      <Typography variant="h2">
        No user organizations or workspaces found, please contact support.
      </Typography>
      <Button type="submit" onClick={handleContactUsClick}>
        Contact customer support
      </Button>
    </Stack>
  );
};
