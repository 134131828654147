import { Button } from '@components/button';
import { FormTextInput } from '@components/form';
import { Typography } from '@components/typography';
import { Box } from '@mui/material';
import { Form, Formik } from 'formik';
import { memo } from 'react';
import * as yup from 'yup';

const validationSchema = yup.object({
  limit: yup.number().integer().required('Required'),
});

type EditUsageLimitModalProps = {
  onClose?: () => void;
  limit?: string;
  setting?: string;
  units?: string;
  onSave?: (...data: unknown[]) => Promise<void>;
};

export const EditUsageLimitModal = memo(
  ({
    onClose,
    limit: selectedLimitLimit,
    setting: selectedLimitSetting,
    units: selectedLimitUnits,
    onSave,
  }: EditUsageLimitModalProps) => {
    const handleSubmit = async ({ limit, setting }: { limit: string; setting: string }) => {
      await onSave({ limit, setting });
      onClose();
    };

    return (
      <Formik
        onSubmit={handleSubmit}
        initialValues={{ limit: selectedLimitLimit, setting: selectedLimitSetting }}
        validationSchema={validationSchema}
      >
        <Form>
          <Typography pb={8} variant="h3">
            Edit usage limit
          </Typography>

          <FormTextInput name="limit" label={`${selectedLimitSetting} in ${selectedLimitUnits}`} />

          <Box pt={8}>
            <Button fullWidth type="submit">
              Save
            </Button>
          </Box>
          <Box pt={1.5}>
            <Button fullWidth variant="secondary" onClick={onClose}>
              Cancel
            </Button>
          </Box>
        </Form>
      </Formik>
    );
  },
);
