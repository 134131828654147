import { Typography } from '@components/typography';
import { DatasetJob } from '@generated/UseGraphqlHooks';
import { Grid, useTheme } from '@mui/material';
import { JobsManagerJobPreviewDataField } from './JobsManagerJobPreviewDataField';

export const JobsManagerJobPreviewRuns = ({
  runsCancelled,
  runsFailed,
  runsQueued,
  runsRunning,
  runsSuccess,
  runsTimeout,
}: DatasetJob) => {
  const { palette } = useTheme();
  return (
    <Grid container columns={20}>
      <Grid item xs={5}>
        <Typography variant="caption2" color={palette.text.paper}>
          Runs
        </Typography>
      </Grid>
      <JobsManagerJobPreviewDataField label="Queued" value={runsQueued || 0} width={3} />
      <JobsManagerJobPreviewDataField label="Running" value={runsRunning || 0} width={3} />
      <JobsManagerJobPreviewDataField label="Success" value={runsSuccess || 0} width={3} />
      <JobsManagerJobPreviewDataField
        label="Failed"
        value={(runsFailed || 0) + (runsCancelled || 0)}
        width={3}
      />
      <JobsManagerJobPreviewDataField label="Timeout" value={runsTimeout || 0} width={3} />
    </Grid>
  );
};
