import { EditIcon } from '@assets/icons';
import { IconButton } from '@components/button';
import { Group } from '@components/layout';
import { useModal } from '@components/modal';
import { Typography } from '@components/typography';
import { Dataset } from '@generated/UseGraphqlHooks';
import { formatSizeFromBytes } from '@helper-functions/formatSize';
import { Stack, useTheme } from '@mui/material';
import {
  DatasetsDetailsEditModal,
  DatasetsDetailsInfoPreview,
  useDatasets,
} from '@subsets/workspaces';
import { navigate } from 'gatsby';

interface DatasetsDetailsInfoProps {
  workspaceId: string;
  dataset: Partial<Dataset>;
}

export const DatasetsDetailsInfo = ({
  workspaceId,
  dataset: { channel, datasetId, description, files, graphId, name, runs, size, seed },
}: DatasetsDetailsInfoProps) => {
  const { palette } = useTheme();
  const navigateToSnapshotGraph = () => navigate(`/workspaces/${workspaceId}/graphs/${graphId}/`);
  const { refetchDatasets } = useDatasets();

  const { showModal } = useModal({
    component: DatasetsDetailsEditModal,
    componentProps: {
      workspaceId,
      detailName: name,
      detailDescription: description || '',
      datasetId,
      refetchDatasets,
    },
    modalProps: { title: 'Edit Dataset' },
  });
  return (
    <Stack>
      <Group alignItems="center" justifyContent="space-between">
        <Typography handleOverFlow variant="subtitle1" data-cy="Datasets-Details-Info-Dataset-Name">
          {name}
        </Typography>
        <IconButton onClick={() => showModal()} Icon={EditIcon} size={18} />
      </Group>
      <Typography variant="caption2">{description || 'No description'}</Typography>
      <Group flexWrap="wrap" alignItems="center" gap={1} my={4}>
        <Typography variant="body2">{channel}</Typography>
        <Typography variant="caption2">•</Typography>
        <Typography variant="body2">{runs} runs</Typography>

        <Typography variant="caption2">•</Typography>
        <Typography variant="body2">
          {formatSizeFromBytes(Number(size) || 0)} ({files} images)
        </Typography>
      </Group>
      <DatasetsDetailsInfoPreview name="Workspace Id" value={workspaceId} />
      <DatasetsDetailsInfoPreview name="Dataset Id" value={datasetId} />
      <DatasetsDetailsInfoPreview
        name="Graph Id"
        title="Go to graph"
        value={graphId}
        onClick={graphId && navigateToSnapshotGraph}
      />
      <DatasetsDetailsInfoPreview name="Seed" value={seed} />
    </Stack>
  );
};
