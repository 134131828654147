import { Group } from '@components/layout';
import { Tag } from '@components/tag';
import { Tooltip } from '@components/tooltip';
import { IntComparator } from '@generated/UseGraphqlHooks';
import { Stack, Typography, useTheme } from '@mui/material';
import { capitalize } from 'lodash';
import { DateTime } from 'luxon';
import { DeckardFilter, Filter } from './types';

type FilterChipsProps<T> = {
  filter: DeckardFilter<T>;
  variant?: 'primary' | 'secondary';
  clearFilterKey: (key: string) => void;
  updateFilter: (key: string, newValue: unknown) => void;
};

export const FilterChips = <T,>({
  filter,
  variant,
  clearFilterKey,
  updateFilter,
}: FilterChipsProps<T>) => {
  return (
    <Group flexWrap="wrap">
      {Object.keys(filter)
        .filter((key) => filter[key].value.length > 0 && key !== 'texts')
        .map((key) =>
          filter[key].type === 'date' ? (
            <DateFilterChip
              key={key}
              filter={filter[key]}
              variant={variant}
              onDelete={(newValue) => updateFilter('dates', newValue)}
            />
          ) : filter[key].type === 'number' ? (
            <NumberFilterChip
              key={key}
              filter={filter[key]}
              name={key}
              variant={variant}
              onDelete={() => clearFilterKey(key)}
            />
          ) : filter[key].type === 'size' ? (
            <SizeFilterChip
              key={key}
              filter={filter[key]}
              name={key}
              variant={variant}
              onDelete={() => clearFilterKey(key)}
            />
          ) : filter[key].type === 'text' ? (
            <TextFilterChip
              key={key}
              filter={filter[key]}
              name={key}
              variant={variant}
              onDelete={() => clearFilterKey(key)}
            />
          ) : (
            <FilterChip
              key={key}
              filter={filter[key]}
              name={key}
              variant={variant}
              onDelete={() => clearFilterKey(key)}
            />
          ),
        )}
    </Group>
  );
};

const DateFilterChip = ({
  filter,
  variant,
  onDelete,
}: {
  filter: Filter<{ gte?: DateTime; lte?: DateTime }>;
  variant?: 'primary' | 'secondary';
  onDelete: (newValue: unknown) => void;
}) => {
  const { palette } = useTheme();
  if (filter.value.length === 0) {
    return null;
  }
  return (
    <>
      {filter.value.map((value, idx) => (
        <Tag
          key={idx}
          label={`${value?.gte ? 'From' : 'To'}: ${value?.gte?.toLocaleString(DateTime.DATETIME_MED) || value?.lte?.toLocaleString(DateTime.DATETIME_MED)}`}
          onDelete={() => onDelete(filter.value.filter((_, i) => i !== idx))}
          sx={{
            backgroundColor:
              variant === 'primary' ? palette.background.paper : palette.input.background.default,
          }}
        />
      ))}
    </>
  );
};

const FilterChip = <T,>({
  filter,
  name,
  variant,
  onDelete,
}: {
  filter: Filter<T>;
  name: string;
  variant?: 'primary' | 'secondary';
  onDelete: VoidFunction;
}) => {
  const { palette } = useTheme();
  if (filter.value.length > 1) {
    return (
      <Tooltip
        title={
          <Stack gap={1}>
            {filter.display.map((value) => (
              <Typography variant="caption2" key={value}>
                {value}
              </Typography>
            ))}
          </Stack>
        }
        placement="bottom"
        variant="secondary"
        PopperProps={{
          disablePortal: true,
          sx: {
            '& .MuiTooltip-tooltip': {
              backgroundColor: palette.background.paper,
              boxShadow: palette.shadow.modal,
            },
          },
        }}
      >
        <Tag
          label={`${capitalize(name)}: (${filter.value.length})`}
          onDelete={onDelete}
          sx={{
            backgroundColor:
              variant === 'primary' ? palette.background.paper : palette.input.background.default,
          }}
        />
      </Tooltip>
    );
  }
  return (
    <Tag
      label={`${capitalize(name)}: ${filter.display[0]}`}
      onDelete={onDelete}
      sx={{
        backgroundColor:
          variant === 'primary' ? palette.background.paper : palette.input.background.default,
      }}
    />
  );
};

const NumberFilterChip = ({
  filter,
  name,
  variant,
  onDelete,
}: {
  filter: Filter<IntComparator>;
  name: string;
  variant?: 'primary' | 'secondary';
  onDelete: VoidFunction;
}) => {
  const { palette } = useTheme();
  const key = Object.keys(filter?.value?.[0])?.[0];
  const symbol = key === 'lte' ? '≤' : key === 'gte' ? '≥' : '';
  return (
    <Tag
      label={`${capitalize(name)}: ${symbol} ${filter.value?.[0]?.[key]}`}
      onDelete={onDelete}
      sx={{
        backgroundColor:
          variant === 'primary' ? palette.background.paper : palette.input.background.default,
      }}
    />
  );
};

const SizeFilterChip = ({
  filter,
  name,
  variant,
  onDelete,
}: {
  filter: Filter<IntComparator>;
  name: string;
  variant?: 'primary' | 'secondary';
  onDelete: VoidFunction;
}) => {
  const { palette } = useTheme();
  const key = Object.keys(filter?.value?.[0])?.[0];
  const symbol = key === 'lte' ? '≤' : key === 'gte' ? '≥' : '';
  const value =
    filter.value?.[0]?.[key] /
    (filter.display[0] === 'KB' ? 1000 : filter.display[0] === 'MB' ? 1000000 : 1000000000);
  return (
    <Tag
      label={`${capitalize(name)}: ${symbol} ${value} ${filter.display[0]}`}
      onDelete={onDelete}
      sx={{
        backgroundColor:
          variant === 'primary' ? palette.background.paper : palette.input.background.default,
      }}
    />
  );
};

const TextFilterChip = ({
  filter,
  name,
  variant,
  onDelete,
}: {
  filter: Filter<string>;
  name: string;
  variant?: 'primary' | 'secondary';
  onDelete: VoidFunction;
}) => {
  const { palette } = useTheme();
  if (filter.value.length > 1) {
    return (
      <Tooltip
        title={
          <Stack gap={1}>
            {filter.value.map((value) => (
              <Typography variant="caption2" key={value}>
                {value}
              </Typography>
            ))}
          </Stack>
        }
        placement="bottom"
        variant="secondary"
        PopperProps={{
          disablePortal: true,
          sx: {
            '& .MuiTooltip-tooltip': {
              backgroundColor: palette.background.paper,
              boxShadow: palette.shadow.modal,
            },
          },
        }}
      >
        <Tag
          label={`${capitalize(name)}: (${filter.value.length})`}
          onDelete={onDelete}
          sx={{
            backgroundColor:
              variant === 'primary' ? palette.background.paper : palette.input.background.default,
          }}
        />
      </Tooltip>
    );
  }
  return (
    <Tag
      label={`${capitalize(name)}: ${filter.display[0]}`}
      onDelete={onDelete}
      sx={{
        backgroundColor:
          variant === 'primary' ? palette.background.paper : palette.input.background.default,
      }}
    />
  );
};
