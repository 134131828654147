import { DeckardFilter } from '@components/filter/types';
import { GraphqlPagination, useGraphqlPagination } from '@components/infiniteScroll';
import {
  Dataset,
  DatasetFilter,
  DatasetSearchDetail,
  GetDatasetsWithFilterQuery,
  useGetDatasetsSearchDetailQuery,
  useGetDatasetsWithFilterQuery,
} from '@generated/UseGraphqlHooks';
import { Dispatch, PropsWithChildren, createContext, useContext, useMemo, useState } from 'react';

export type DatasetsProviderType = {
  datasets: Dataset[];
  filter: DeckardFilter<DatasetFilter>;
  loading: boolean;
  pagination: GraphqlPagination<Dataset>;
  searchDetail: DatasetSearchDetail;
  selectedDataset: string;
  selectedDatasets: string[];
  refetchDatasets: VoidFunction;
  setFilter: Dispatch<DeckardFilter<DatasetFilter>>;
  setSelectedDataset: Dispatch<string>;
  setSelectedDatasets: Dispatch<string[]>;
};

interface DatasetsProviderProps {
  workspaceId: string;
}

export const DatasetsContext = createContext<DatasetsProviderType>({} as DatasetsProviderType);
export const DatasetsProvider = ({
  children,
  workspaceId,
}: PropsWithChildren<DatasetsProviderProps>) => {
  const [selectedDataset, setSelectedDataset] = useState<string>();
  const [selectedDatasets, setSelectedDatasets] = useState<string[]>([]);

  const [filter, setFilter] = useState<DeckardFilter<DatasetFilter>>({
    channels: { type: 'exact', value: [], display: [] },
    dates: { type: 'date', value: [], display: [] },
    graphs: { type: 'text', value: [], display: [] },
    runs: { type: 'number', value: [], display: [] },
    size: { type: 'size', value: [], display: [] },
    tags: { type: 'string', value: [], display: [] },
    texts: { type: 'string', value: [], display: [] },
    type: { type: 'exact', value: [], display: [] },
    users: { type: 'exact', value: [], display: [] },
  });

  const queryFilter = useMemo(() => {
    return Object.entries(filter)
      .filter(([_, value]) => value.value.length > 0)
      .reduce((acc, [key, value]) => {
        acc[key] = value.value;
        return acc;
      }, {} as DatasetFilter);
  }, [filter]);

  const { data } = useGetDatasetsSearchDetailQuery({
    variables: { workspaceId, filters: queryFilter },
  });
  const searchDetail = data?.getDatasetsSearchDetail;

  const pagination = useGraphqlPagination<GetDatasetsWithFilterQuery, Dataset>(
    useGetDatasetsWithFilterQuery({
      variables: {
        workspaceId,
        limit: 50,
        filters: queryFilter,
      },
    }),
    'getDatasets',
    'datasetId',
    { pollInterval: 5000 },
  );

  const datasets = pagination.data;

  const value = useMemo(
    () => ({
      datasets,
      filter,
      loading: pagination.loading,
      pagination,
      searchDetail,
      selectedDataset,
      selectedDatasets,
      refetchDatasets: pagination.refetch,
      setFilter,
      setSelectedDataset,
      setSelectedDatasets,
    }),
    [
      datasets,
      filter,
      pagination.hasMore,
      pagination.loading,
      pagination.fetchMore,
      pagination.refetch,
      searchDetail,
      selectedDataset,
      selectedDatasets,
      setFilter,
      setSelectedDataset,
      setSelectedDatasets,
    ],
  );

  return <DatasetsContext.Provider value={value}>{children}</DatasetsContext.Provider>;
};

export const useDatasets = () => useContext(DatasetsContext);
export const useDataset = (datasetId: string) => {
  const { datasets } = useDatasets();
  return datasets.find((dataset) => dataset.datasetId === datasetId);
};
export const useSelectedDataset = () => {
  const { datasets, selectedDataset } = useDatasets();
  return datasets.find((dataset) => dataset.datasetId === selectedDataset);
};
