import { Tooltip, TooltipProps } from '@components/tooltip';
import {
  Box,
  Divider,
  Typography as MUITypography,
  Stack,
  TypographyProps,
  useTheme,
} from '@mui/material';
import { ReactNode, useEffect, useRef, useState } from 'react';

type TextProps = TypographyProps & {
  disableTooltip?: boolean;
  fitContent?: boolean;
  handleOverFlow?: boolean;
  htmlFor?: string;
  placement?: TooltipProps['placement'];
  link?: boolean;
  tooltip?: ReactNode;
  tooltipProps?: Partial<TooltipProps>;
};

const calculateOverflow = (element: HTMLElement) => {
  if (!element) return false;
  return element.offsetHeight < element.scrollHeight || element.offsetWidth < element.scrollWidth;
};

export const Typography = ({
  children,
  disableTooltip,
  fitContent = false,
  handleOverFlow = false,
  link = false,
  placement,
  tooltip,
  tooltipProps,
  sx,
  ...rest
}: TextProps) => {
  const { palette } = useTheme();
  const [overflowing, setOverflowing] = useState(false);
  const textRef = useRef<HTMLElement>(null);

  useEffect(() => {
    if (!handleOverFlow) {
      return;
    }
    const handleResize = () => {
      if (calculateOverflow(textRef.current)) {
        setOverflowing(true);
      } else {
        setOverflowing(false);
      }
    };
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', handleResize);
      handleResize();
    }
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleOverFlow, textRef, children]);

  return (
    <Tooltip
      title={
        !disableTooltip ? (
          overflowing ? (
            tooltip ? (
              <Stack>
                <Box textAlign="center">{children}</Box>
                <Divider sx={{ borderColor: palette.text.secondary, my: 1 }} />
                <Box textAlign="center">{tooltip}</Box>
              </Stack>
            ) : (
              children
            )
          ) : tooltip ? (
            tooltip
          ) : (
            ''
          )
        ) : (
          ''
        )
      }
      placement={placement || 'top'}
      {...tooltipProps}
    >
      <MUITypography
        ref={textRef}
        sx={{
          ...(link
            ? {
              cursor: 'pointer',
              textDecoration: 'underline',
              display: 'inline-block',
              color: palette.link.default,
              '&:hover': { color: palette.link.hover },
            }
            : {}),
          ...(handleOverFlow
            ? {
              width: fitContent ? 'fit-content' : '100%',
              textAlign: 'left',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              textWrap: 'nowrap',
            }
            : {}),
          ...sx,
        }}
        {...rest}
      >
        {children}
      </MUITypography>
    </Tooltip>
  );
};
