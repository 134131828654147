import { MultipartInput } from '@generated/UseGraphqlHooks';
import { delay } from '@helper-functions/delay';
import { runPromiseQueue } from '@helper-functions/promiseQueue';
import axios, { AxiosProgressEvent } from 'axios';
import React, { createContext, PropsWithChildren, useContext } from 'react';

const MAX_RETRIES = 3;

type UploadFileProps = {
  file: File;
  urls: string[];
  partSize: number;
  onUploadProgress?: (percent: number) => void;
};
interface FileUploadContextProps {
  uploadFile: (props: UploadFileProps) => Promise<MultipartInput[]>;
}

export const FileUploadContext = createContext<FileUploadContextProps>({
  uploadFile: () => Promise.resolve([]),
});

type UploadPart = {
  url: string;
  part: Blob;
  partNumber: number;
  retries: number;
};

export const FileUploadProvider = ({ children }: PropsWithChildren) => {
  const uploadFile = async ({ file, urls, partSize, onUploadProgress }: UploadFileProps) => {
    if (!file || !urls.length) {
      return;
    }

    try {
      const etags: MultipartInput[] = [];

      const fileParts: Blob[] = [];
      const uploadProgress: number[] = [];
      if (partSize === 0 || urls.length === 1) {
        fileParts.push(file);
      } else {
        for (let i = 0; i < urls.length; i++) {
          fileParts.push(file.slice(i * partSize, (i + 1) * partSize));
          uploadProgress.push(0);
        }
      }

      const uploadPart = async ({ url, part, partNumber, retries }: UploadPart) =>
        axios
          .put(url, part, {
            headers: {
              'Content-Type': 'application/octet-stream',
            },
            onUploadProgress: (progressEvent: AxiosProgressEvent) => {
              if (onUploadProgress) {
                uploadProgress[partNumber - 1] = progressEvent.progress;
                const totalProgress =
                  uploadProgress.reduce((acc, curr) => acc + curr, 0) / urls.length;
                onUploadProgress(totalProgress);
              }
            },
          })
          .then((response) => {
            etags.push({
              eTag: response.headers['etag'],
              partNumber,
            });
          })
          .catch(async (error) => {
            retries++;
            if (retries === MAX_RETRIES) {
              throw new Error(`Failed to upload dataset.`);
            } else {
              await delay(5000);
              await uploadPart({ url, part, partNumber, retries });
            }
          });

      await runPromiseQueue(
        urls.map((url, index) => {
          return () =>
            uploadPart({ url, part: fileParts[index], partNumber: index + 1, retries: 0 });
        }),
        3,
      );

      return etags.sort((a, b) => a.partNumber - b.partNumber);
    } catch (error) {
      throw error;
    }
  };

  return <FileUploadContext.Provider value={{ uploadFile }}>{children}</FileUploadContext.Provider>;
};

export const useFileUpload = () => {
  const uploadFile = useContext(FileUploadContext).uploadFile;
  return uploadFile;
};
