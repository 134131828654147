import { DeckardIconProps } from '@assets/types';
import { useTheme } from '@mui/material';
import { forwardRef } from 'react';

export const FitInWindowIcon = forwardRef<SVGSVGElement, DeckardIconProps>((props, ref) => {
  const { palette } = useTheme();
  const { color, size } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
      width={size || 24}
      height={size || 24}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        className="stroke-override"
        d="M19 3H5C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3Z"
        stroke={color || palette.icon.default}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="fill-override"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 8.11111C6 6.94518 6.94518 6 8.11111 6H10.0556C10.6078 6 11.0556 6.44772 11.0556 7C11.0556 7.55228 10.6078 8 10.0556 8H8.11111C8.04975 8 8 8.04975 8 8.11111V10.0556C8 10.6078 7.55228 11.0556 7 11.0556C6.44772 11.0556 6 10.6078 6 10.0556V8.11111ZM12.9444 7C12.9444 6.44772 13.3922 6 13.9444 6H15.8889C17.0548 6 18 6.94518 18 8.11111V10.0556C18 10.6078 17.5523 11.0556 17 11.0556C16.4477 11.0556 16 10.6078 16 10.0556V8.11111C16 8.04975 15.9503 8 15.8889 8H13.9444C13.3922 8 12.9444 7.55228 12.9444 7ZM7 12.9444C7.55228 12.9444 8 13.3922 8 13.9444V15.8889C8 15.9503 8.04975 16 8.11111 16H10.0556C10.6078 16 11.0556 16.4477 11.0556 17C11.0556 17.5523 10.6078 18 10.0556 18H8.11111C6.94518 18 6 17.0548 6 15.8889V13.9444C6 13.3922 6.44772 12.9444 7 12.9444ZM17 12.9444C17.5523 12.9444 18 13.3922 18 13.9444V15.8889C18 17.0548 17.0548 18 15.8889 18H13.9444C13.3922 18 12.9444 17.5523 12.9444 17C12.9444 16.4477 13.3922 16 13.9444 16H15.8889C15.9503 16 16 15.9503 16 15.8889V13.9444C16 13.3922 16.4477 12.9444 17 12.9444Z"
        fill={color || palette.icon.default}
      />
    </svg>
  );
});
