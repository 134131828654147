import { InfiniteScrollTableComponents, useGraphqlPagination } from '@components/infiniteScroll';
import { Typography } from '@components/typography';
import {
  Channel,
  GetChannelsQuery,
  useGetChannelsQuery,
  useGetOrganizationSettingsQuery,
} from '@generated/UseGraphqlHooks';
import { Box, Stack, useTheme } from '@mui/material';
import { find } from 'lodash';
import { TableVirtuoso } from 'react-virtuoso';
import { LandingPageOrganizationResourcesLoading } from '../LandingPageOrganizationResourcesLoading';
import { ChannelHeader } from './ChannelHeader';
import { ChannelTableHeader } from './ChannelTableHeader';
import { ChannelTableRow } from './ChannelTableRow';

interface OrganizationChannelsProps {
  name: string;
  organizationId: string;
}

export const OrganizationChannels = ({ name, organizationId }: OrganizationChannelsProps) => {
  const { palette } = useTheme();

  const {
    data: channels,
    loading: channelsLoading,
    fetchMore,
  } = useGraphqlPagination<GetChannelsQuery, Channel>(
    useGetChannelsQuery({
      variables: { organizationId, limit: 30 },
    }),
    'getChannels',
    'channelId',
  );

  const { data: organizationSettingsData, loading: organizationSettingsLoading } =
    useGetOrganizationSettingsQuery({
      variables: { organizationId },
    });
  const { usage, limit } =
    (!organizationSettingsLoading &&
      find(organizationSettingsData?.getOrganizationSettings, {
        setting: 'Managed Channels',
      })) ||
    {};

  if (channelsLoading || organizationSettingsLoading) {
    return (
      <LandingPageOrganizationResourcesLoading
        name={name}
        resource="Channels"
        buttonLabel="New channel"
      />
    );
  }

  const tableRow = (_index, channel) => (
    <ChannelTableRow {...channel} selectedOrganizationId={organizationId} />
  );

  return (
    <Stack pt={5} pl={10} spacing={5} height="100%" overflow="hidden">
      <ChannelHeader name={name} organizationId={organizationId} usage={usage} limit={limit} />
      <Stack flex={1} overflow="hidden" p={6} sx={{ backgroundColor: palette.background.paper }}>
        {channels.length === 0 ? (
          <Box
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flex: 1,
            }}
          >
            <Typography>This Organization has no Channels.</Typography>
          </Box>
        ) : (
          <TableVirtuoso
            data={channels}
            endReached={fetchMore}
            components={InfiniteScrollTableComponents as any}
            fixedHeaderContent={ChannelTableHeader}
            itemContent={tableRow}
            increaseViewportBy={400}
          />
        )}
      </Stack>
    </Stack>
  );
};
