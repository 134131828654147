import { CopyIconButton } from '@components/button';
import { Image } from '@components/image';
import { InternalLink } from '@components/link';
import { Typography } from '@components/typography';
import { Graph } from '@generated/UseGraphqlHooks';
import { formatTime } from '@helper-functions/time';
import { Box, TableCell } from '@mui/material';
import { GraphsListViewPreviewMenu } from './GraphsListViewPreviewMenu';

interface GraphsListViewPreviewProps {
  graph: Graph;
  refetch: () => void;
}

export const GraphsListViewPreview = ({ graph, refetch }: GraphsListViewPreviewProps) => {
  return (
    <>
      <TableCell>
        <Image
          alt="graph thumbnail"
          url={graph.thumbnail}
          style={{ height: '48px', width: '48px' }}
        />
      </TableCell>
      <TableCell>
        <CopyIconButton value={graph?.graphId} />
      </TableCell>
      <TableCell>
        <InternalLink unstyled to={`/workspaces/${graph.workspaceId}/graphs/${graph.graphId}`}>
          <Typography
            handleOverFlow
            variant="body2"
            sx={{
              cursor: 'pointer',
              outline: 'none',
              userSelect: 'none',
              paddingRight: 4,
              '&:hover': {
                textDecoration: 'underline',
              },
            }}
          >
            {graph.name}
          </Typography>
        </InternalLink>
      </TableCell>
      <TableCell>
        <Typography variant="body2" pr={4} handleOverFlow>
          {graph.description}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2" pr={1} handleOverFlow>
          {graph.createdBy}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2" pr={1} handleOverFlow>
          {formatTime(graph.createdAt, 'date')}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2" pr={1} handleOverFlow>
          {formatTime(graph.updatedAt, 'date')}
        </Typography>
      </TableCell>
      <TableCell>
        <Box display="flex" justifyContent="space-between" alignItems="center" paddingRight="16px">
          <Typography variant="body2">{graph.channel}</Typography>
          <GraphsListViewPreviewMenu graph={graph} refetch={refetch} />
        </Box>
      </TableCell>
    </>
  );
};
