import { Box, useTheme } from '@mui/material';
import { PositionContext } from '@subsets/workspaces';
import { MouseEventHandler, PropsWithChildren, useContext, useEffect, useRef } from 'react';

export const BasePort = ({
  nodeId,
  portId,
  type,
  onClick,
  ...props
}: PropsWithChildren<{
  portId?: string;
  nodeId?: string;
  type: 'input' | 'output';
  onClick?: MouseEventHandler;
}>) => {
  const { palette } = useTheme();
  const { registerPortReference } = useContext(PositionContext);
  const container = useRef();
  useEffect(() => {
    registerPortReference(nodeId, portId, container);
  }, [portId, nodeId, container]);

  return (
    <Box
      ref={container}
      px={4}
      height="32px"
      onClick={onClick}
      sx={{
        overflow: 'visible',
        zIndex: 1,
        backgroundColor: palette.graph.port.background[type],
      }}
      {...props}
    />
  );
};
