import { gql, useQuery } from '@apollo/client';
import { ArrowLeftIcon } from '@assets/icons';
import { RenderedAiIcon } from '@assets/logos';
import { Slider } from '@components/form';
import { Image } from '@components/image';
import { Group } from '@components/layout';
import { Typography } from '@components/typography';
import { Box, Divider, Stack, useTheme } from '@mui/material';
import { size } from 'lodash';
import { useObservableState } from 'observable-hooks';
import { useState } from 'react';
import { DatasetsDetailsInfoPreview } from '../libraries';
import { useUmap } from './UmapProvider';

type UmapImagePreviewProps = {
  workspaceId: string;
  featureSize: '4' | '8' | '16' | '32' | '64';
};

export const UmapImagePreview = ({ workspaceId, featureSize }: UmapImagePreviewProps) => {
  const { selectedDatasetId$, selectedDatasetName$, selectedImage$ } = useUmap();
  const { palette } = useTheme();
  const image = useObservableState(selectedImage$);
  const datasetId = useObservableState(selectedDatasetId$);
  const datasetName = useObservableState(selectedDatasetName$);
  const hasUserSelectedImage = size(image) !== 0;
  const { data: umapPointData } = useQuery<{
    getUMAPPoint: {
      image: string;
      heatmap4?: string;
      heatmap8?: string;
      heatmap16?: string;
      heatmap32?: string;
      heatmap64?: string;
    };
  }>(
    gql`
      query getUMAPPoint($workspaceId: String!, $datasetId: String!, $image: String!) {
        getUMAPPoint(workspaceId: $workspaceId, datasetId: $datasetId, image: $image) {
          image
          ${process.env.GATSBY_ATTENTION_MAPS_ENABLED === 'true' ? `heatmap${featureSize}` : ''}
        }
      }
    `,
    {
      variables: { workspaceId, datasetId, image },
    },
  );
  const [heatmapOpacity, setHeatmapOpacity] = useState(0.0);

  return (
    <Box bgcolor={palette.background.paper} height="100%" width="100%" overflow="hidden">
      {hasUserSelectedImage ? (
        <Stack sx={{ width: '100%', padding: 4, height: '100%', overflowY: 'auto' }}>
          <DatasetsDetailsInfoPreview name="Dataset name" value={datasetName} noCopy />
          <DatasetsDetailsInfoPreview name="Dataset Id" value={datasetId} />
          <DatasetsDetailsInfoPreview name="Image name" value={image} noCopy />
          <Divider sx={{ my: 4 }} />
          <Image
            url={umapPointData?.getUMAPPoint?.image}
            sx={{ height: 'auto', aspectRatio: 1 }}
            overlayImage={
              umapPointData?.getUMAPPoint?.[`heatmap${featureSize}`] &&
              process.env.GATSBY_ATTENTION_MAPS_ENABLED === 'true' &&
              umapPointData.getUMAPPoint[`heatmap${featureSize}`]
            }
            overlayOpacity={heatmapOpacity}
          />
          {process.env.GATSBY_ATTENTION_MAPS_ENABLED === 'true' && (
            <Group
              visibility={
                umapPointData?.getUMAPPoint?.[`heatmap${featureSize}`] ? 'visible' : 'hidden'
              }
              alignItems="center"
              justifyContent="space-between"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
              gap={8}
              pt={4}
            >
              <Typography variant="caption2" color={palette.text.paper}>
                Heatmap opacity
              </Typography>
              <Slider
                min={0}
                max={1}
                step={0.01}
                value={heatmapOpacity}
                onChange={(e, value) => setHeatmapOpacity(value as number)}
              />
            </Group>
          )}
        </Stack>
      ) : (
        <Stack justifyContent="center" alignItems="center" height="100%" gap={4}>
          <RenderedAiIcon color={palette.text.light} size="54px" />
          <Group alignItems="center" justifyContent="center">
            <ArrowLeftIcon color={palette.grey[500]} />

            <Typography color={palette.grey[600]} ml={3} mb={1} variant="subtitle1">
              Select a point to view details
            </Typography>
          </Group>
        </Stack>
      )}
    </Box>
  );
};
