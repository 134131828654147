import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, Stripe as StripeType } from '@stripe/stripe-js';
import { PropsWithChildren, useEffect, useState } from 'react';

export const Stripe = ({ children }: PropsWithChildren) => {
  const [stripe, setStripe] = useState<StripeType>();
  useEffect(() => {
    const init = async () => {
      setStripe(await loadStripe(process.env.GATSBY_STRIPE_PUBLISHABLE_KEY));
    };
    init();
  }, []);
  if (!stripe) {
    return null;
  }
  return <Elements stripe={stripe}>{children}</Elements>;
};
