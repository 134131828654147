import { Typography } from '@components/typography';
import { Umap } from '@generated/UseGraphqlHooks';
import { Grid, useTheme } from '@mui/material';
import { DatasetsDetailsUmapListItemPreview } from '@subsets/workspaces';

type DatasetsDetailsUmapListProps = {
  workspaceId: string;
  datasetId: string;
  umaps: Partial<Umap>[];
  refetch: VoidFunction;
};

export const DatasetsDetailsUmapList = ({
  workspaceId,
  datasetId,
  umaps,
  refetch,
}: DatasetsDetailsUmapListProps) => {
  const { palette } = useTheme();
  if (umaps.length === 0) {
    return (
      <Typography variant="caption2" noWrap>
        This dataset has no UMAP analyses.
      </Typography>
    );
  }
  return (
    <>
      <Grid container alignItems="center">
        <Grid item xs={1.5}>
          <Typography color={palette.table.header} variant="caption2">
            Status
          </Typography>
        </Grid>
        <Grid item xs={1.5} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Typography color={palette.table.header} variant="caption2">
            Id
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography color={palette.table.header} variant="caption2">
            Name
          </Typography>
        </Grid>
      </Grid>
      {umaps.map((umap) => (
        <DatasetsDetailsUmapListItemPreview
          datasetId={datasetId}
          umap={umap}
          workspaceId={workspaceId}
          key={umap.umapId}
          refetch={refetch}
        />
      ))}
    </>
  );
};
