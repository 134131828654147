import { Button } from '@components/button';
import { Typography } from '@components/typography';
import {
  useDeleteWorkspaceMutation,
  useGetOrganizationSettingsQuery,
} from '@generated/UseGraphqlHooks';
import { Stack } from '@mui/material';
import { useNotifications } from '@notifications/Notifications';
import { navigate } from 'gatsby';
import { delay } from 'lodash';

interface WorkspacesMoreDropdownDeleteModalProps {
  onClose: () => void;
  workspaceId: string;
  workspaceName: string;
  workspaceOrganizationId?: string;
  workspacesRefetch?: () => Promise<void> | void;
  isLandingPageWorkspace: boolean;
}

export const WorkspacesMoreDropdownDeleteModal = ({
  onClose,
  workspaceId,
  workspaceOrganizationId,
  workspacesRefetch,
  isLandingPageWorkspace,
}: WorkspacesMoreDropdownDeleteModalProps) => {
  const { useAsyncNotification } = useNotifications();
  const [deleteWorkspaceMutation] = useDeleteWorkspaceMutation();
  const { refetch: organizationSettingsRefetch } = useGetOrganizationSettingsQuery({
    variables: { organizationId: workspaceOrganizationId },
  });
  const handleDelete = useAsyncNotification('Deleted workspace successfully', async () => {
    onClose();
    await deleteWorkspaceMutation({ variables: { workspaceId } });
    if (isLandingPageWorkspace) {
      delay(() => {}, 2000);
      await workspacesRefetch();
      await organizationSettingsRefetch();
    } else {
      await navigate('/');
    }
  });

  return (
    <Stack pt={4} gap={10} justifyContent="center">
      <Typography variant="body2" textAlign="center">
        Are you sure you want to delete this workspace?
      </Typography>
      <Stack gap={4}>
        <Button fullWidth onClick={handleDelete}>
          Delete
        </Button>
        <Button fullWidth variant="secondary" onClick={onClose}>
          Cancel
        </Button>
      </Stack>
    </Stack>
  );
};
