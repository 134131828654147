import { LoadingIcon } from '@assets/icons';
import { TableView, TableViewButton } from '@components/button';
import { SearchAndFilter } from '@components/filter';
import { DeckardFilter } from '@components/filter/types';
import { useGraphqlPagination } from '@components/infiniteScroll';
import { Group } from '@components/layout';
import { Typography } from '@components/typography';
import {
  GetGraphsQuery,
  Graph,
  GraphFilter,
  useGetGraphsSearchDetailQuery,
  useGetGraphsWithFiltersQuery,
} from '@generated/UseGraphqlHooks';
import { useFeatureFlag } from '@hooks/FeatureFlags';
import { Box, Stack } from '@mui/material';
import { useMemo, useState } from 'react';
import { GraphsGridView } from './GraphsGridView';
import { GraphsListView } from './GraphsListView';

interface GraphsWorkspaceGraphsProps {
  workspaceId: string;
}

export const GraphsWorkspaceGraphs = ({ workspaceId }: GraphsWorkspaceGraphsProps) => {
  const filterFlag = useFeatureFlag('feature_tagging-search-and-filter');
  const [filter, setFilter] = useState<DeckardFilter<GraphFilter>>({
    channels: { type: 'exact', value: [], display: [] },
    dates: { type: 'date', value: [], display: [] },
    tags: { type: 'string', value: [], display: [] },
    texts: { type: 'string', value: [], display: [] },
    users: { type: 'exact', value: [], display: [] },
  });

  const queryFilter = useMemo(() => {
    return Object.entries(filter)
      .filter(([_, value]) => value.value.length > 0)
      .reduce((acc, [key, value]) => {
        acc[key] = value.value;
        return acc;
      }, {} as GraphFilter);
  }, [filter]);
  const { data } = useGetGraphsSearchDetailQuery({ variables: { workspaceId, staged: false } });
  const searchDetail = data?.getGraphsSearchDetail;

  const pagination = useGraphqlPagination<GetGraphsQuery, Graph>(
    useGetGraphsWithFiltersQuery({
      variables: { limit: 30, staged: false, workspaceId, filters: queryFilter },
    }),
    'getGraphs',
    'graphId',
  );

  const [tableView, setTableView] = useState<TableView>('grid');

  const isItemInListView = tableView === 'list';

  return (
    <Stack data-cy="Graphs-Workspace-Graphs" height="100%" pt={8} gap={8}>
      <Group justifyContent="space-between" alignItems="flex-start" gap={8}>
        {filterFlag ? (
          <SearchAndFilter filter={filter} setFilter={setFilter} searchDetail={searchDetail} />
        ) : (
          <Box flex={1} />
        )}
        <TableViewButton mode={tableView} onChange={setTableView} />
      </Group>
      {!pagination?.loading ? (
        pagination?.data?.length > 0 ? (
          isItemInListView ? (
            <GraphsListView pagination={pagination} />
          ) : (
            <GraphsGridView pagination={pagination} />
          )
        ) : (
          <Stack height="100%" width="100%" alignItems="center" justifyContent="center">
            <Typography>You have no graphs.</Typography>
          </Stack>
        )
      ) : (
        <Stack height="100%" width="100%" alignItems="center" justifyContent="center">
          <LoadingIcon size={16} />
        </Stack>
      )}
    </Stack>
  );
};
