import { InfiniteScrollTableComponents } from '@components/infiniteScroll';
import { ProgressBar } from '@components/progress';
import { Typography } from '@components/typography';
import { OrganizationSetting, useGetOrganizationSettingsQuery } from '@generated/UseGraphqlHooks';
import { Box, TableCell, TableRow, useTheme } from '@mui/material';
import { TableVirtuoso } from 'react-virtuoso';

const TableHeader = () => {
  return (
    <TableRow>
      <TableCell width="25%">
        <Typography variant="caption1">Setting</Typography>
      </TableCell>
      <TableCell width="25%">
        <Typography variant="caption1">Usage</Typography>
      </TableCell>
      <TableCell width="25%">
        <Typography variant="caption1">Limit</Typography>
      </TableCell>
      <TableCell width="25%"></TableCell>
    </TableRow>
  );
};

type OrganizationSettingsUsageTableProps = {
  organizationId: string;
};
export const OrganizationsSettingsOrganizationsUsageTable = ({
  organizationId,
}: OrganizationSettingsUsageTableProps) => {
  const { palette } = useTheme();
  const { data: organizationSettingsData } =
    useGetOrganizationSettingsQuery({
      variables: { organizationId },
    });

  const organizationSettings = organizationSettingsData?.getOrganizationSettings || [];

  const tableRow = (_index, setting: OrganizationSetting) => (
    <>
      <TableCell>
        <Typography handleOverFlow>{setting.setting}</Typography>
      </TableCell>
      <TableCell>
        <Typography handleOverFlow>
          {String(setting.usage).includes('.') ? Number(setting.usage).toFixed(3) : setting.usage}{' '}
          {setting.units}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography handleOverFlow>
          {setting.limit === '∞' ? '-' : `${setting.limit} ${setting.units}`}
        </Typography>
      </TableCell>
      <TableCell>
        <ProgressBar percent={(Number(setting.usage) / Number(setting.limit)) * 100} />
      </TableCell>
    </>
  );

  return (
    <Box sx={{ backgroundColor: palette.background.paper, padding: 4, flex: 1 }}>
      <TableVirtuoso
        data={organizationSettings}
        totalCount={organizationSettings.length}
        components={InfiniteScrollTableComponents as any}
        fixedHeaderContent={TableHeader}
        itemContent={tableRow}
      />
    </Box>
  );
};
