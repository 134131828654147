import { RenderedAILogo } from '@assets/logos';
import { keyframes, styled } from '@mui/material';

const blinker = keyframes`0% {
    opacity: 0
  }
  100% {
    opacity: 1
  }`;

export const StyledRenderedAILogo = styled(RenderedAILogo)(() => ({
  animation: `${blinker} 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) infinite alternate`,
}));
