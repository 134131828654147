import { Button } from '@components/button';
import { Group } from '@components/layout';
import { useModal } from '@components/modal';
import {
  OrganizationsBillingPaymentInformationRemoveOrganizationModal,
  OrganizationsBillingPaymentInformationUnableToRemoveOrganizationModal,
} from '@subsets/organizations';

type OrganizationsBillingPaymentInformationButtonsProps = {
  handleChangePlanButton: () => void;
  trialSubscription: boolean;
  organizationTier: string;
};

export const OrganizationsBillingPaymentInformationButtons = ({
  handleChangePlanButton,
  trialSubscription,
  organizationTier,
}: OrganizationsBillingPaymentInformationButtonsProps) => {
  // Disabling org removal until we can find a better place for this functionality
  // const { showModal: openUnableToRemoveOrganizationModal } = useModal({
  //   component: OrganizationsBillingPaymentInformationUnableToRemoveOrganizationModal,
  // });

  const { showModal: openRemoveOrganizationsModal } = useModal({
    component: OrganizationsBillingPaymentInformationRemoveOrganizationModal,
  });

  return (
    <Group flexWrap="wrap" gap={4}>
      <Button variant="secondary" onClick={handleChangePlanButton}>
        {trialSubscription ? 'Subscribe' : 'Upgrade subscription'}
      </Button>
      {/* <Button
        variant="secondary"
        onClick={
          organizationTier === 'Enterprise'
            ? openUnableToRemoveOrganizationModal
            : openRemoveOrganizationsModal
        }
        style={{ flex: 1 }}
      >
        Delete
      </Button> */}
    </Group>
  );
};
