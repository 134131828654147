import { gql, useMutation } from '@apollo/client';
import { AsyncButton, Button } from '@components/button';
import { Form, FormTextInput } from '@components/form';
import { Stack } from '@mui/material';
import { useNotifications } from '@notifications/Notifications';
import { FormikProps } from 'formik';
import * as yup from 'yup';

const updateGraphMutation = gql`
  mutation EditGraph($graphId: String!, $workspaceId: String!, $name: String) {
    editGraph(graphId: $graphId, workspaceId: $workspaceId, name: $name)
  }
`;

interface JobsConfigurationsOptionsRenameModalProps {
  onClose: () => void;
  graphId: string;
  graphName: string;
  workspaceId: string;
  refetch: () => void;
}

const validationSchema = yup.object({
  graphName: yup
    .string()
    .trim()
    .max(128, 'Must be less than 128 characters.')
    .required('Name is required'),
});

export const JobsConfigurationsOptionsRenameModal = ({
  onClose,
  graphId,
  graphName,
  workspaceId,
  refetch,
}: JobsConfigurationsOptionsRenameModalProps) => {
  const { useAsyncNotification } = useNotifications();
  const [updateGraph] = useMutation(updateGraphMutation, { variables: { graphId, workspaceId } });
  const handleSubmit = useAsyncNotification(
    'Renamed the graph successfully.',
    async ({ graphName: name }: { graphName: string }) => {
      await updateGraph({ variables: { name } });
      refetch();
      onClose();
    },
  );

  return (
    <Form onSubmit={handleSubmit} initialValues={{ graphName }} validationSchema={validationSchema}>
      {({ errors }: FormikProps<{ graphName: string }>) => (
        <Stack>
          <FormTextInput label="Graph name" name="graphName" />
          <Stack gap={4}>
            <AsyncButton fullWidth type="submit" disabled={!!errors?.graphName}>
              Save
            </AsyncButton>
            <Button
              fullWidth
              variant="secondary"
              data-cy="Jobs-Configurations-Options-Rename-Modal-Cancel-Button"
              onClick={onClose}
            >
              Cancel
            </Button>
          </Stack>
        </Stack>
      )}
    </Form>
  );
};
