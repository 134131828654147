import { ApolloQueryResult } from '@apollo/client';
import { GetUmapQuery, Umap } from '@generated/UseGraphqlHooks';
import { first, size } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { UmapGraphSection } from './UmapGraphSection';
import { UmapLoading } from './UmapLoading';
import { useUmap } from './UmapProvider';

type PointDataType = {
  name: string;
  datasetId: string;
  image: Array<string>;
};

type PointType = {
  data: PointDataType;
  pointNumber: number;
};

interface UmapGraphProps {
  umap: Partial<Umap>;
  workspaceId: string;
  umapId: string;
  loading: boolean;
  refetch: () => Promise<ApolloQueryResult<GetUmapQuery>>;
  featureSize: '4' | '8' | '16' | '32' | '64';
  setFeatureSize: (newSize: '4' | '8' | '16' | '32' | '64') => void;
}

type UmapDatasetType = {
  [fileName: string]: [number, number, number];
};

type UmapResults = {
  [featureSize: string]: {
    [datasetId: string]: UmapDatasetType;
  };
};

const COLORS = [
  '#1f77b4',
  '#ff7f0e',
  '#2ca02c',
  '#d62728',
  '#9467bd',
  '#8c564b',
  '#e377c2',
  '#7f7f7f',
  '#bcbd22',
  '#17becf',
];

export const UmapGraph = ({
  umap,
  workspaceId,
  umapId,
  loading,
  refetch,
  featureSize,
  setFeatureSize,
}: UmapGraphProps) => {
  const { selectedDatasetId$, selectedDatasetName$, selectedImage$ } = useUmap();

  const datasetsData: Record<string, { name: string; samples: number }> = useMemo(() => {
    const result = {};
    umap?.datasets.forEach((dataset, index) => {
      result[dataset.datasetId] = { name: dataset.dataset, samples: umap.samples?.[index] || 0 };
    });
    return result;
  }, [umap]);

  const [data, setData] = useState([]);

  useEffect(() => {
    return () => setData([]);
  }, []);

  useEffect(() => {
    if (umap?.status === 'success' && refetch) {
      void refetch();
    }
  }, [umap?.status]);

  const requestImage = ({ points }: { points: Array<PointType> }) => {
    const { data: pointData, pointNumber } = first(points);
    selectedDatasetName$.next(datasetsData[pointData.datasetId].name);
    selectedDatasetId$.next(pointData.datasetId);
    selectedImage$.next(pointData.image[pointNumber]);
  };

  const convertData = () => {
    const umapResults = umap?.results ? (JSON.parse(umap.results) as UmapResults) : null;
    if (!umapResults) {
      setData([]);
      return;
    }

    const convertedData = Object.entries(umapResults?.[featureSize]).map(
      ([datasetId, points], index) => {
        return {
          x: Object.values(points).map((point) => point[0]),
          y: Object.values(points).map((point) => point[1]),
          z: Object.values(points).map((point) => point[2]),
          name: `${datasetsData[datasetId].name} (${datasetsData[datasetId].samples})`,
          datasetId,
          image: Object.keys(points),
          type: 'scatter3d',
          mode: 'markers',
          marker: {
            color: COLORS[index % size(COLORS)],
          },
        };
      },
    );
    setData(convertedData);
  };

  useEffect(() => {
    convertData();
  }, [umap, featureSize]);

  if (loading || size(data) === 0) {
    return (
      <UmapLoading
        umapLoading={loading}
        status={umap?.status}
        umapId={umapId}
        datasets={umap?.datasets}
        workspaceId={workspaceId}
        fitDatasetId={umap?.datasets[0]?.datasetId}
        samples={umap?.samples}
      />
    );
  }

  return (
    <UmapGraphSection
      umapName={umap?.name}
      umapId={umapId}
      data={data}
      onClick={requestImage}
      featureSize={featureSize}
      setFeatureSize={setFeatureSize}
      fitDatasetId={umap?.datasets[0]?.datasetId}
    />
  );
};
