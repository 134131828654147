import { GraphqlPagination } from '@components/infiniteScroll';
import { Graph } from '@generated/UseGraphqlHooks';
import { Box } from '@mui/material';
import { forwardRef, useCallback } from 'react';
import { VirtuosoGrid } from 'react-virtuoso';
import { GraphsGridViewPreview } from './GraphsGridViewPreview';

const ItemContainer = (props) => (
  <Box
    data-test-id="virtuoso-item-list"
    sx={{
      display: 'flex',
      flex: 'none',
      alignContent: 'stretch',
      boxSizing: 'border-box',
    }}
    {...props}
  />
);

const ListContainer = forwardRef<HTMLDivElement>((props, ref) => (
  <Box
    sx={{
      pr: 1,
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
      gap: 6,
    }}
    {...props}
    ref={ref}
  />
));
interface GraphsGridViewProps {
  pagination: GraphqlPagination<Graph>;
}

export const GraphsGridView = ({
  pagination: { data: graphs, fetchMore, refetch },
}: GraphsGridViewProps) => {
  const item = useCallback(
    (_index, graph: Graph) => <GraphsGridViewPreview graph={graph} refetch={refetch} />,
    [refetch],
  );

  return (
    <VirtuosoGrid
      totalCount={graphs.length}
      overscan={400}
      endReached={fetchMore}
      components={{
        Item: ItemContainer,
        // there is a type mismatch with the expected ref and the actual ref, this does not affect functionality
        List: ListContainer as any,
      }}
      data={graphs}
      itemContent={item}
    />
  );
};
