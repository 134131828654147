import { InfoIcon } from '@assets/icons';
import { Button } from '@components/button';
import { ExternalLink } from '@components/link';
import { Typography } from '@components/typography';
import { Box } from '@mui/material';

type OrganizationsBillingPaymentInformationUnableToRemoveOrganizationModalProps = {
  onClose: () => void;
};

export const OrganizationsBillingPaymentInformationUnableToRemoveOrganizationModal = ({
  onClose,
}: OrganizationsBillingPaymentInformationUnableToRemoveOrganizationModalProps) => {
  return (
    <Box width="528px" height="100%" p={9}>
      <Box display="flex" alignItems="center">
        <InfoIcon />
        <Box pl={4}>
          <Typography variant="h3" color="grey.900">
            Unable to delete the organization
          </Typography>
        </Box>
      </Box>
      <Box pt={4} pl={1}>
        <Typography variant="subtitle2" color="grey.900">
          Please{' '}
          <ExternalLink href="https://rendered.ai/talk-to-sales/">contact support</ExternalLink> to
          terminate your organization.
        </Typography>
      </Box>
      <Box pt={8}>
        <Button fullWidth variant="secondary" onClick={onClose}>
          Cancel
        </Button>
      </Box>
    </Box>
  );
};
