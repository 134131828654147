import { Box, SxProps } from '@mui/material';
import Plotly from 'plotly.js/dist/plotly';
import React, { useEffect, useRef } from 'react';

interface Props {
  data?: Plotly.Data[];
  layout: Partial<Plotly.Layout>;
  frames?: Plotly.Frame[];
  config?: Partial<Plotly.Config>;
  onClick?: (param: unknown) => void;
  sx?: SxProps;
}

export const Plot = ({ sx = {}, onClick, data, ...props }: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    Plotly.react(ref.current, { data, ...props });
    (ref.current as Plotly.Plot).on('plotly_click', (data: unknown) => {
      if (onClick) {
        onClick(data);
      }
    });
  }, [props, data]);

  return <Box ref={ref} sx={{ ...sx }} />;
};
