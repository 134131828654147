import { Button } from '@components/button';
import { FormTextInput } from '@components/form';
import { Typography } from '@components/typography';
import { useEditOrganizationMutation, useGetOrganizationsQuery } from '@generated/UseGraphqlHooks';
import { Box } from '@mui/material';
import { useNotifications } from '@notifications/Notifications';
import { Form, Formik } from 'formik';
import { memo } from 'react';
import * as yup from 'yup';

const validationSchema = yup.object({
  organizationUpdatedName: yup
    .string()
    .trim()
    .max(128, 'Must be less than 128 characters')
    .required('Name is required'),
});

interface EditOrganizationModalProps {
  onClose: () => void;
  organizationName: string;
  organizationId: string;
}

export const EditOrganizationModal = memo(
  ({ onClose, organizationName, organizationId }: EditOrganizationModalProps) => {
    const { refetch: refetchOrganizations } = useGetOrganizationsQuery();
    const { useAsyncNotification } = useNotifications();
    const [editOrganizationMutation] = useEditOrganizationMutation();

    const editOrganizationSave = useAsyncNotification(
      'Successfully edited organization name',
      async ({ organizationUpdatedName }: { organizationUpdatedName: string }) => {
        onClose();
        await editOrganizationMutation({
          variables: {
            organizationId,
            name: organizationUpdatedName,
          },
        });
        await refetchOrganizations();
      },
    );

    return (
      <Formik
        enableReinitialize
        onSubmit={editOrganizationSave}
        validationSchema={validationSchema}
        initialValues={{ organizationUpdatedName: organizationName }}
      >
        {() => (
          <Form data-cy="Organizations-Settings-General-Admin-Rename-Organization-Modal">
            <Typography pb={10} variant="h3">
              Rename organization
            </Typography>
            <Box>
              <FormTextInput label="Organization name" name="organizationUpdatedName" />
            </Box>
            <Box pt={8}>
              <Button fullWidth type="submit">
                Save
              </Button>
            </Box>
            <Box pt={1.5}>
              <Button
                fullWidth
                variant="secondary"
                onClick={onClose}
                data-cy="Organizations-Settings-Edit-Organization-Cancel-Button"
              >
                Cancel
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    );
  },
);
