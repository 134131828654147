import { useCallback, useState } from 'react';

export const useSearch = (list: unknown[], attribute?: string[]) => {
  const [query, setQuery] = useState<string>();
  const onData = useCallback(
    () =>
      (list || []).filter((data: Record<string, string>) => {
        const modified = attribute ? attribute.map((attr: string) => data[attr]) : data;
        const value = JSON.stringify(modified);
        return value?.match(RegExp(query, 'gi'))?.length > 0;
      }),
    [list, query],
  );
  const onSearch = (q: string) => {
    setQuery(q);
  };

  return {
    data: onData(),
    onSearch,
  };
};
