import { AsyncButton, Button, IconButton } from '@components/button';
import { Form, FormTextInput } from '@components/form';
import { useEditGanDatasetMutation } from '@generated/UseGraphqlHooks';
import { Stack } from '@mui/material';
import { useNotifications } from '@notifications/Notifications';
import * as yup from 'yup';
import { useDatasets } from './DatasetsProvider';

const validationSchema = yup.object({
  newName: yup
    .string()
    .trim()
    .max(128, 'Must be less than 128 characters')
    .required('Name is required'),
});

interface DatasetsDetailsGanDatasetsEditModalProps {
  datasetId: string;
  workspaceId: string;
  datasetName: string;
  description: string;
  onClose?: VoidFunction;
  refetchGanDatasets: VoidFunction;
}

export const DatasetsDetailsGanDatasetsEditModal = ({
  datasetId,
  description,
  workspaceId,
  datasetName,
  onClose,
  refetchGanDatasets,
}: DatasetsDetailsGanDatasetsEditModalProps) => {
  const { useAsyncNotification } = useNotifications();
  const { refetchDatasets } = useDatasets();

  const [editGanDataset] = useEditGanDatasetMutation();

  const handleEditGanDataset = useAsyncNotification(
    'Successfully edited GAN Dataset',
    async ({ newDescription, newName }: { newDescription: string; newName: string }) => {
      await editGanDataset({
        variables: { datasetId, description: newDescription, name: newName, workspaceId },
      });
      refetchDatasets();
      refetchGanDatasets();
      onClose();
    },
  );

  return (
    <Form
      enableReinitialize
      onSubmit={handleEditGanDataset}
      initialValues={{ newName: datasetName, newDescription: description || '' }}
      validationSchema={validationSchema}
    >
      {({ handleSubmit }) => (
        <Stack>
          <FormTextInput label="Name" name="newName" />
          <FormTextInput label="GAN Description" name="newDescription" multiline rows={3} />
          <Stack gap={4}>
            <AsyncButton
              fullWidth
              onClick={handleSubmit}
              data-cy="GAN-Datasets-Edit-Dataset-Save-Button"
            >
              Save
            </AsyncButton>
            <Button
              fullWidth
              variant="secondary"
              onClick={onClose}
              data-cy="GAN-Datasets-Edit-Dataset-Cancel-Button"
            >
              Cancel
            </Button>
          </Stack>
        </Stack>
      )}
    </Form>
  );
};
