import { useGetOrganizationsQuery, useGetWorkspacesQuery } from '@generated/UseGraphqlHooks';
import { calculateDateDifference } from '@helper-functions/calculateDateDifference';
import { Box } from '@mui/material';
import { ExpiredModal } from '@subsets/expiration';
import { first } from 'lodash';

type WorkspacesExpirationModalProps = {
  workspaceId: string;
};

export const WorkspacesExpirationsModal = ({ workspaceId }: WorkspacesExpirationModalProps) => {
  const { data: workspacesData, loading: workspacesLoading } = useGetWorkspacesQuery({
    variables: { workspaceId },
  });
  const { data: organizationsData, loading: organizationsLoading } = useGetOrganizationsQuery();
  const workspace = first(workspacesData?.getWorkspaces);
  if (!workspace) {
    return null;
  }
  const organizations = organizationsData?.getOrganizations;

  const { role, expirationDate, expired, organizationId } =
    organizations?.find(
      (organization) => organization.organizationId === workspace?.organizationId,
    ) || {};

  const isAdmin = role === 'admin';

  const { hours } = calculateDateDifference(expirationDate);

  const isWorkspaceExpired = expired || hours < 1;

  if (!workspacesLoading && !organizationsLoading && isWorkspaceExpired) {
    return <ExpiredModal isAdmin={isAdmin} workspacesPage organizationId={organizationId} />;
  }
  return <Box />;
};
