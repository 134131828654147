import { DeckardIconProps } from '@assets/types';
import { useTheme } from '@mui/material';
import { forwardRef } from 'react';

export const FolderIcon = forwardRef<SVGSVGElement, DeckardIconProps>((props, ref) => {
  const { palette } = useTheme();
  const { color, size } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
      width={size || 24}
      height={size || 24}
      viewBox="0 0 24 24"
      fill="none"
      stroke={color || palette.icon.default}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="stroke-override"
      {...props}
    >
      <path d="M22 19C22 19.5304 21.7893 20.0391 21.4142 20.4142C21.0391 20.7893 20.5304 21 20 21H4C3.46957 21 2.96086 20.7893 2.58579 20.4142C2.21071 20.0391 2 19.5304 2 19V5C2 4.46957 2.21071 3.96086 2.58579 3.58579C2.96086 3.21071 3.46957 3 4 3H9L11 6H20C20.5304 6 21.0391 6.21071 21.4142 6.58579C21.7893 6.96086 22 7.46957 22 8V19Z" />
    </svg>
  );
});
