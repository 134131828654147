import { LoadingIcon, XIcon } from '@assets/icons';
import { Button, IconButton } from '@components/button';
import { Image } from '@components/image';
import { Modal } from '@components/modal';
import { Typography } from '@components/typography';
import { Preview, useCreatePreviewMutation } from '@generated/UseGraphqlHooks';
import { Box, Stack, useTheme } from '@mui/material';
import { useCurrentWorkspace } from '@navigation/WorkspaceContext';
import { ReactEventHandler, useEffect, useState } from 'react';
import { GraphPreviewLogs } from './GraphPreviewLogs';

const GraphPreviewContent = ({ preview }: { preview: Partial<Preview> }) => {
  const [showLogs, setShowLogs] = useState(false);
  const { palette } = useTheme();
  const [imageDimensions, setImageDimensions] = useState<[number, number]>([1, 1]);
  const onImageLoad: ReactEventHandler<HTMLImageElement> = ({ target: loadedImage }) => {
    setImageDimensions([
      (loadedImage as HTMLImageElement).naturalWidth,
      (loadedImage as HTMLImageElement).naturalHeight,
    ]);
  };

  if (!preview) {
    return (
      <Box
        sx={{
          height: '70vh',
          width: '70vw',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <LoadingIcon size={14} />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        height: '70vh',
        width: '70vw',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: palette.background.default,
      }}
    >
      {['failed', 'timeout'].includes(preview?.status) ? (
        <Stack
          height="100%"
          width="100%"
          justifyContent={showLogs ? 'flex-start' : 'center'}
          alignItems={showLogs ? 'flex-start' : 'center'}
          gap={4}
        >
          <Typography sx={{ alignSelf: 'center', mt: 2 }} variant="body1">
            An error occurred generating the preview
          </Typography>
          {showLogs ? (
            <GraphPreviewLogs preview={preview} />
          ) : (
            <Button onClick={() => setShowLogs(true)}>Show logs</Button>
          )}
        </Stack>
      ) : preview?.status === 'success' ? (
        <Image alt="graph preview" url={preview?.thumbnail} />
      ) : (
        <Stack alignItems="center">
          <LoadingIcon size={16} style={{ marginBottom: 4 }} />
          <Typography variant="h3">Generating preview</Typography>
          <Typography variant="caption2">Current status: {preview?.status}</Typography>
        </Stack>
      )}
    </Box>
  );
};

export const GraphPreviewModal = ({
  graphId,
  graphName,
  preview,
  previewId,
  open,
  onClose,
  setPreviewId,
}: {
  graphId: string;
  graphName: string;
  previewId: string;
  setPreviewId: (newPreviewId: string) => void;
  preview: Partial<Preview>;
  open: boolean;
  onClose: VoidFunction;
}) => {
  const {
    workspace: { workspaceId },
  } = useCurrentWorkspace();
  const [createPreview] = useCreatePreviewMutation({ variables: { workspaceId, graphId } });
  useEffect(() => {
    if (!open || !graphId || !workspaceId) {
      return;
    }
    createPreview()
      .then(({ data }) => {
        setPreviewId(data?.createPreview);
      })
      .catch(() => console.error('Error creating preview'));
  }, [open]);
  if (!previewId) {
    return null;
  }
  return (
    <Modal disablePortal disableAutoFocus open={open} onClose={onClose}>
      <Stack minWidth="516px" height="auto" gap={4}>
        <Typography variant="h3" sx={{ textAlign: 'center' }}>
          Preview: {graphName}
        </Typography>
        <Box pb={8} px={8}>
          <GraphPreviewContent preview={preview} />
        </Box>
      </Stack>
    </Modal>
  );
};
