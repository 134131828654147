import { Organization } from '@generated/UseGraphqlHooks';
import { calculateDateDifference } from '@helper-functions/calculateDateDifference';
import { ExpiredModal, ExpiringSoonModal } from '@subsets/expiration';
import { useLandingPageProvider } from '@subsets/landing-page';
import { inRange } from 'lodash';
import { useObservableState } from 'observable-hooks';

type LandingPageExpirationModalProps = {
  expirationDate?: Organization['expirationDate'];
  role?: Organization['role'];
  expired?: Organization['expired'];
  organizationId?: Organization['organizationId'];
};

export const LandingPageExpirationWrapper = ({
  expirationDate,
  role,
  expired,
  organizationId,
}: LandingPageExpirationModalProps) => {
  const { showExpirationModal$ } = useLandingPageProvider();
  const { days, hours } = calculateDateDifference(expirationDate);

  const showExpirationModal = useObservableState(showExpirationModal$);
  const isDaysBetweenOneToSeven = inRange(days, 1, 8);
  const isExpiringIn24hours = hours < 24;
  const admin = role === 'admin';

  if (admin && isDaysBetweenOneToSeven && !expired && showExpirationModal) {
    return <ExpiringSoonModal days={days} organizationId={organizationId} />;
  }
  if (admin && isExpiringIn24hours && !expired && showExpirationModal) {
    return <ExpiringSoonModal days={1} organizationId={organizationId} />;
  }
  if (expired) {
    return <ExpiredModal isAdmin={admin} organizationId={organizationId} />;
  }
  return null;
};
