import { FormControlLabel, Switch, SwitchProps } from '@mui/material';

export type ToggleProps = SwitchProps & {
  label?: string;
};

export const Toggle = ({ label, ...props }: ToggleProps) => {
  return (
    <FormControlLabel
      control={<Switch disableFocusRipple disableRipple disableTouchRipple {...props} />}
      label={label}
    />
  );
};
