import { Button } from '@components/button';
import { Tab, TabPanel, Tabs } from '@components/tabs';
import {
  useEditWorkspaceMutation,
  useGetAnnotationMapsQuery,
  useGetChannelsQuery,
  useGetGanModelsQuery,
  useGetVolumesQuery,
} from '@generated/UseGraphqlHooks';
import { Stack } from '@mui/material';
import { useNotifications } from '@notifications/Notifications';
import { map } from 'lodash';
import { ChangeEvent, useState } from 'react';
import { AnnotationTransferList } from './AnnotationTransferList';
import { ChannelsTransferList } from './ChannelsTransferList';
import { GanTransferList } from './GanTransferList';
import { ResourcesType } from './TransferList';
import { VolumesTransferList } from './VolumesTransferList';

interface WorkspaceResourcesModalProps {
  onClose: () => void;
  name: string;
  organizationId: string;
  tab?: string;
  workspaceId: string;
}

export const WorkspaceResourcesModal = ({
  name,
  organizationId,
  tab = 'channels',
  workspaceId,
  onClose,
}: WorkspaceResourcesModalProps) => {
  const [selectedChannels, setSelectedChannels] = useState<ResourcesType>();
  const [selectedVolumes, setSelectedVolumes] = useState<ResourcesType>();
  const [selectedGanModels, setSelectedGanModels] = useState<ResourcesType>();
  const [selectedAnnotations, setSelectedAnnotations] = useState<ResourcesType>();

  const [tabValue, setTabValue] = useState<string>(tab);
  const handleTabsChange = (_: ChangeEvent<HTMLInputElement>, value: string) => {
    setTabValue(value);
  };

  const [editWorkspaceMutation] = useEditWorkspaceMutation();
  const { useAsyncNotification } = useNotifications();

  const { refetch: availableChannelsRefetch } = useGetChannelsQuery({
    variables: {
      organizationId,
    },
  });
  const { refetch: selectedChannelsRefetch } = useGetChannelsQuery({
    variables: {
      workspaceId,
    },
  });
  const { refetch: availableVolumesRefetch } = useGetVolumesQuery({
    variables: {
      organizationId,
    },
  });
  const { refetch: selectedVolumesRefetch } = useGetVolumesQuery({
    variables: {
      workspaceId,
    },
  });
  const { refetch: availableGanModelsRefetch } = useGetGanModelsQuery({
    variables: { organizationId },
  });
  const { refetch: selectedGanModelsRefetch } = useGetGanModelsQuery({
    variables: {
      workspaceId,
    },
  });
  const { refetch: availableAnnotationsRefetch } = useGetAnnotationMapsQuery({
    variables: { organizationId },
  });
  const { refetch: selectedAnnotationsRefetch } = useGetAnnotationMapsQuery({
    variables: {
      workspaceId,
    },
  });

  const handleSave = useAsyncNotification('Successfully saved resources', async () => {
    onClose();
    await editWorkspaceMutation({
      variables: {
        workspaceId,
        channelIds: map(selectedChannels, 'id'),
        volumeIds: map(selectedVolumes, 'id'),
        ganIds: map(selectedGanModels, 'id'),
        mapIds: map(selectedAnnotations, 'id'),
      },
    });
    await availableChannelsRefetch();
    await selectedChannelsRefetch();
    await availableVolumesRefetch();
    await selectedVolumesRefetch();
    await availableGanModelsRefetch();
    await selectedGanModelsRefetch();
    await availableAnnotationsRefetch();
    await selectedAnnotationsRefetch();
  });
  const channelsTransferListProps = {
    organizationId,
    workspaceId,
    selectedChannels,
    setSelectedChannels,
  };
  const volumesTransferListProps = {
    organizationId,
    workspaceId,
    selectedVolumes,
    setSelectedVolumes,
  };
  const ganModelsTransferListProps = {
    organizationId,
    workspaceId,
    selectedGanModels,
    setSelectedGanModels,
  };
  const annotationsTransferListProps = {
    organizationId,
    workspaceId,
    selectedAnnotations,
    setSelectedAnnotations,
  };

  return (
    <Stack gap={9} data-cy="Workspaces-Resources-Modal">
      <Tabs value={tabValue} onChange={handleTabsChange}>
        <Tab value="channels" label="Channels" data-cy="Workspaces-Resources-Modal-Channels-Tab" />
        <Tab value="volumes" label="Volumes" data-cy="Workspaces-Resources-Modal-Volumes-Tab" />
        <Tab value="gan" label="GAN models" data-cy="Workspaces-Resources-Modal-GAN-Tab" />
        <Tab
          value="annotation"
          label="Annotation maps"
          data-cy="Workspaces-Resources-Modal-Annotations-Tab"
        />
      </Tabs>

      <TabPanel value={tabValue} index="channels">
        <ChannelsTransferList {...channelsTransferListProps} />
      </TabPanel>
      <TabPanel value={tabValue} index="volumes">
        <VolumesTransferList {...volumesTransferListProps} />
      </TabPanel>
      <TabPanel value={tabValue} index="gan">
        <GanTransferList {...ganModelsTransferListProps} />
      </TabPanel>
      <TabPanel value={tabValue} index="annotation">
        <AnnotationTransferList {...annotationsTransferListProps} />
      </TabPanel>

      <Stack direction="row" justifyContent="flex-end" gap={4}>
        <Button variant="primary" onClick={handleSave} sx={{ width: '100%', maxWidth: '128px' }}>
          Save
        </Button>
        <Button variant="secondary" onClick={onClose} sx={{ width: '100%', maxWidth: '128px' }}>
          Cancel
        </Button>
      </Stack>
    </Stack>
  );
};
