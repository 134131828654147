import { DownloadIcon, ExternalLinkIcon, TrashIcon } from '@assets/icons';
import { CopyIconButton, IconButton } from '@components/button';
import { Group } from '@components/layout';
import { useConfirmation } from '@components/modal';
import { Typography } from '@components/typography';
import { useDeleteAnalyticsMutation, useGetAnalyticsLazyQuery } from '@generated/UseGraphqlHooks';
import { saveFile } from '@helper-functions/save-file';
import { Grid } from '@mui/material';
import { useNotifications } from '@notifications/Notifications';
import { DatasetsDetailsMicroserviceStatus } from '@subsets/workspaces';
import { navigate } from 'gatsby';
import { first } from 'lodash';
import { MouseEvent } from 'react';

interface DatasetsDetailsAnalyticsListItemPreviewProps {
  workspaceId: string;
  status: string;
  datasetId: string;
  analyticsId: string;
  type: string;
  refetch: () => void;
}

export const DatasetsDetailsAnalyticsListItemPreview = ({
  workspaceId,
  status,
  analyticsId,
  datasetId,
  type,
  refetch,
}: DatasetsDetailsAnalyticsListItemPreviewProps) => {
  const { useAsyncNotification, addNotification, removeNotification } = useNotifications();
  const { showConfirmation } = useConfirmation();
  const [getAnalytics] = useGetAnalyticsLazyQuery();
  const [deleteAnalyticsMutation] = useDeleteAnalyticsMutation();

  const handleDownloadDatasetAnalytics = async () => {
    const key = `${Date.now() * 1000}.${Math.random() * 10000}`;
    try {
      addNotification({ text: 'Loading...', type: 'loading', key, duration: 100000 });
      const {
        data: { getAnalytics: analyticsData },
      } = await getAnalytics({ variables: { analyticsId, workspaceId } });
      const href = `data:application/json;charset=utf-8;,${encodeURIComponent(
        first(analyticsData)?.result,
      )}`;
      saveFile({ url: href, name: `${type}.json` });
      removeNotification(key);
      addNotification({
        text: 'Starting download...',
        type: 'success',
        duration: 6000,
      });
    } catch (e) {
      removeNotification(key);
    }
  };

  const handleDeleteDatasetAnalytics = () =>
    showConfirmation({
      onAffirm: useAsyncNotification('Successfully deleted analytics.', async () => {
        await deleteAnalyticsMutation({
          variables: {
            workspaceId,
            analyticsId,
          },
        });
        void refetch();
      }),
      message: `Are you sure you want to delete this analytics?`,
    });

  const handleSelectAnalytic = (analysisId: string) => {
    return (event: MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();
      navigate(`/workspaces/${workspaceId}/experiments/analytics/${analysisId}`);
    };
  };

  return (
    <Grid container alignItems="center" justifyContent="flex-start">
      <Grid item xs={1.5}>
        <Group marginLeft={1}>
          <DatasetsDetailsMicroserviceStatus
            status={status}
            microServiceName="analytics"
            workspaceId={workspaceId}
            datasetId={datasetId}
            microServiceId={analyticsId}
          />
        </Group>
      </Grid>
      <Grid item xs={1.5} sx={{ display: 'flex', justifyContent: 'center' }}>
        <CopyIconButton value={analyticsId} showValue tooltipProps={{ variant: 'secondary' }} />
      </Grid>
      <Grid item xs={5}>
        <Typography handleOverFlow variant="body2">
          {type}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Group justifyContent="flex-end" gap={2}>
          {status === 'success' && (
            <>
              <IconButton onClick={handleSelectAnalytic(analyticsId)} Icon={ExternalLinkIcon} />
              <IconButton onClick={handleDownloadDatasetAnalytics} Icon={DownloadIcon} />
            </>
          )}
          <IconButton onClick={handleDeleteDatasetAnalytics} Icon={TrashIcon} />
        </Group>
      </Grid>
    </Grid>
  );
};
