import { useConfirmation } from '@components/modal';
import { useNotifications } from '@notifications/Notifications';
import { size } from 'lodash';
import { PropsWithChildren, createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useToggle } from 'react-use';
import { BehaviorSubject } from 'rxjs';
import { CanvasContext } from './CanvasProvider';
import { GraphDataContext } from './GraphDataProvider';
import { PositionContext } from './PositionProvider';

interface SelectedPortType {
  nodeId: string;
  portId: string;
}

interface SelectionContextType {
  selectNode: (nodeId: string, event: MouseEvent) => void;
  selectNodesFromArea: (boxStart: [number, number], boxEnd: [number, number]) => void;
  selectLink: (linkId: string) => void;
  isSelected: (nodeId: string) => boolean;
  isSelectedLink: (linkId: string) => boolean;
  toggleInformationDrawer: () => void;
  informationDrawerOpen: boolean;
  selectedOutputPort?: BehaviorSubject<SelectedPortType | undefined>;
  selectedNodeList: BehaviorSubject<Set<string>>;
  activeNodeInformationNode?: BehaviorSubject<string>;
}

interface SelectionProviderProps {
  disabled?: boolean;
}

export const SelectionContext = createContext({} as SelectionContextType);
export const SelectionProvider = ({
  children,
  disabled,
}: PropsWithChildren<SelectionProviderProps>) => {
  const { svg, isEditingMode$ } = useContext(CanvasContext);
  const { getNodeListInBound } = useContext(PositionContext);
  const { duplicateNodes, removeSelectedNode } = useContext(GraphDataContext);
  const { showConfirmation } = useConfirmation();
  const { useAsyncNotification } = useNotifications();

  const [copiedNodeList] = useState(new BehaviorSubject(new Set([])));
  const [selectedNodeList] = useState(new BehaviorSubject(new Set([])));
  const [selectedLink] = useState(new BehaviorSubject(undefined));
  const [selectedOutputPort] = useState<BehaviorSubject<SelectedPortType | undefined>>(
    new BehaviorSubject(undefined),
  );

  const [informationDrawerOpen, toggleInformationDrawer] = useToggle(false);
  const [activeNodeInformationNode] = useState(new BehaviorSubject<string>(null));

  const { addNotification } = useNotifications();

  const set = (nodeList?: string[]) => {
    selectedNodeList.next(new Set(nodeList));
    selectedLink.next(undefined);
  };

  const add = (nodeId) => {
    const selectedNodes = selectedNodeList.getValue();
    selectedNodes.add(nodeId);
    selectedNodeList.next(new Set(selectedNodes));
  };

  const has = (nodeId) => {
    const selectedNodes = selectedNodeList.getValue();
    return selectedNodes.has(nodeId);
  };

  const clearSelection = () => {
    selectedNodeList.next(new Set([]));
    selectedOutputPort.next(undefined);
    selectedLink.next(undefined);
  };

  const deleteSelectedNodes = () => {
    const selectedNodes = Array.from(selectedNodeList.getValue());
    if (isEditingMode$.getValue() || disabled || size(selectedNodes) < 1) {
      return;
    }

    void showConfirmation({
      onAffirm: useAsyncNotification('Deleted node(s)', async () => {
        const removeAllSelectedNodes = selectedNodes.map((nodeId: string, index) =>
          removeSelectedNode(nodeId, index === selectedNodes.length - 1),
        );
        await Promise.all(removeAllSelectedNodes);
      }),
      message: 'Are you sure you want to delete the selected node(s)?',
    });

    clearSelection();
  };

  const copy = () => {
    if (isEditingMode$.getValue()) {
      return;
    }
    const selectedNodeSize = selectedNodeList.getValue()?.size;
    if (!selectedNodeSize) {
      return;
    }
    copiedNodeList.next(selectedNodeList.getValue());

    addNotification({
      type: 'info',
      text: 'Copied selected nodes',
    });
  };

  const paste = () => {
    if (isEditingMode$.getValue()) {
      return;
    }
    const copiedNodeSize = copiedNodeList.getValue()?.size;
    if (!copiedNodeSize) {
      return;
    }
    duplicateNodes(Array.from(copiedNodeList.getValue()) as string[]);
  };

  useEffect(() => {
    svg
      ?.on('click', (event: MouseEvent) => {
        if (!event.shiftKey && event.target === svg.node()) {
          clearSelection();
        }
      })
      .on('keyup', (event: KeyboardEvent & { target: { localName: string } }) => {
        if (
          (event.key === 'Delete' || event.key === 'Backspace') &&
          event.target.localName === 'svg'
        ) {
          deleteSelectedNodes();
        } else if (event.key === 'Escape') {
          clearSelection();
        } else if (event.ctrlKey && event.key === 'c') {
          copy();
        } else if (event.ctrlKey && event.key === 'v') {
          void paste();
        }
      });
  }, [svg, duplicateNodes]);

  const selectNode = (nodeId: string, event: MouseEvent) => {
    if (!event.shiftKey) {
      set([nodeId]);
    } else {
      add(nodeId);
    }
  };

  const selectNodesFromArea = (boxStart: [number, number], boxEnd: [number, number]) => {
    set(getNodeListInBound(boxStart, boxEnd));
  };

  const selectLink = (linkId: string) => {
    selectedNodeList.next(new Set([]));
    selectedLink.next(linkId);
  };

  const isSelected = (nodeId: string) => {
    return has(nodeId);
  };

  const isSelectedLink = (linkId: string) => {
    return linkId === selectedLink.getValue();
  };

  const providerValue = useMemo(
    () => ({
      selectNode,
      selectNodesFromArea,
      selectLink,
      isSelected,
      isSelectedLink,
      toggleInformationDrawer,
      selectedNodeList,
      selectedOutputPort,
      activeNodeInformationNode,
      informationDrawerOpen,
    }),
    [
      selectNode,
      selectNodesFromArea,
      selectLink,
      isSelected,
      isSelectedLink,
      toggleInformationDrawer,
      selectedNodeList,
      selectedOutputPort,
      activeNodeInformationNode,
      informationDrawerOpen,
    ],
  );

  return <SelectionContext.Provider value={providerValue}>{children}</SelectionContext.Provider>;
};
