import { Selection } from 'd3';
import { createContext, PropsWithChildren, useMemo, useState } from 'react';
import { BehaviorSubject } from 'rxjs';

interface CanvasContextType {
  svg: Selection<SVGGElement, unknown, any, any>;
  setSvg: (svg) => void;
  isEditingMode$: BehaviorSubject<boolean>;
}

export const CanvasContext = createContext({} as CanvasContextType);
export const CanvasProvider = ({ children }: PropsWithChildren) => {
  const [svg, setSvg] = useState<Selection<SVGGElement, unknown, any, any>>(null);
  const [isEditingMode$] = useState(new BehaviorSubject<boolean>(false));

  const providerValue = useMemo(
    () => ({ svg, setSvg, isEditingMode$ }),
    [svg, setSvg, isEditingMode$],
  );

  return <CanvasContext.Provider value={providerValue}>{children}</CanvasContext.Provider>;
};
