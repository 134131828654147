import { MoreVerticalIcon } from '@assets/icons';
import { IconButton } from '@components/button';
import { useModal } from '@components/modal';
import { Typography } from '@components/typography';
import { Box, Menu, MenuItem, useTheme } from '@mui/material';
import { MouseEvent, useState } from 'react';
import { DeleteChannelModal } from './DeleteChannelModal';
import { EditChannelModal } from './EditChannelModal';

type ChannelTableRowDropdownProps = {
  selectedOrganizationId?: string;
  name?: string;
  channelId?: string;
  organizationId?: string;
  description?: string;
};

export const ChannelTableRowDropdown = ({
  selectedOrganizationId,
  name,
  channelId,
  organizationId,
  description,
}: ChannelTableRowDropdownProps) => {
  const { palette } = useTheme();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isChannelOwnedByOrganization = selectedOrganizationId === organizationId;
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const { showModal: showDeleteChannelModal } = useModal({
    component: DeleteChannelModal,
    componentProps: {
      name,
      channelId,
      organizationId: selectedOrganizationId,
      isChannelOwnedByOrganization,
    },
    modalProps: { title: `${isChannelOwnedByOrganization ? 'Delete' : 'Remove'} channel` },
  });
  const { showModal: showEditChannelModal } = useModal({
    component: EditChannelModal,
    componentProps: { name, description, channelId, organizationId: selectedOrganizationId },
    modalProps: { title: 'Edit channel' },
  });

  const handleEditChannelClick = () => {
    showEditChannelModal();
    handleClose();
  };
  const handleDeleteChannelClick = () => {
    showDeleteChannelModal();
    handleClose();
  };

  return (
    <Box>
      <IconButton
        onClick={handleClick}
        Icon={MoreVerticalIcon}
        size={20}
        data-cy="Channel-Table-Row-Dropdown-Icon"
      />
      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {isChannelOwnedByOrganization ? (
          <Box>
            <MenuItem onClick={handleEditChannelClick}>
              <Typography variant="caption2" data-cy="Channel-Table-Row-Dropdown-Edit">
                Edit
              </Typography>
            </MenuItem>
            <MenuItem onClick={handleDeleteChannelClick}>
              <Typography variant="caption2" data-cy="Channel-Table-Row-Dropdown-Delete">
                Delete
              </Typography>
            </MenuItem>
          </Box>
        ) : (
          <Box>
            <MenuItem onClick={handleDeleteChannelClick}>
              <Typography variant="caption2" data-cy="Channel-Table-Row-Dropdown-Remove">
                Remove
              </Typography>
            </MenuItem>
          </Box>
        )}
      </Menu>
    </Box>
  );
};
