import { AlertTriangleIcon, CheckCircleIcon, LoadingIcon } from '@assets/icons';
import { Button } from '@components/button';
import { Group } from '@components/layout';
import { Typography } from '@components/typography';
import { useTheme } from '@mui/material';

interface UmapLoadingHeaderProps {
  type: string;
  onCancel: () => void;
}

export const UmapLoadingHeader = ({ type, onCancel }: UmapLoadingHeaderProps) => {
  const { palette } = useTheme();

  switch (type) {
    case 'success':
      return (
        <Group gap={4} alignItems="center">
          <CheckCircleIcon color={palette.primary.main} />
          <Typography variant="subtitle2">Success!</Typography>
        </Group>
      );
    case 'failed':
      return (
        <Group alignItems="center" justifyContent="space-between" width="100%">
          <Group alignItems="center" gap={4}>
            <AlertTriangleIcon color={palette.error.main} />
            <Typography variant="subtitle2" color={palette.error.main}>
              Sorry, something went wrong... please try again
            </Typography>
          </Group>
          <Button variant="secondary" onClick={onCancel}>
            Cancel
          </Button>
        </Group>
      );
    case undefined:
      return <LoadingIcon size={12} />;
    default:
      return (
        <Group alignItems="center" justifyContent="space-between" width="100%">
          <Group gap={4} alignItems="center">
            <LoadingIcon size={12} />
            <Typography variant="subtitle2">This may take several minutes...</Typography>
          </Group>
          <Button variant="secondary" onClick={() => type && onCancel()}>
            Cancel
          </Button>
        </Group>
      );
  }
};
