import { CssBaseline, PaletteMode } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { PropsWithChildren, createContext, useMemo, useState } from 'react';
import { SkeletonTheme } from 'react-loading-skeleton';
import { componentOverrides } from './componentOverrides';
import { darkPalette, lightPalette } from './palette';
import { typography } from './typography';

const customTheme = (mode: PaletteMode) =>
  createTheme({
    spacing: 4,
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1660,
        xl: 1920,
      },
    },
    palette: {
      mode,
      ...(mode === 'light' ? lightPalette : darkPalette),
    },
    typography: typography,
    components: componentOverrides,
  });

export const ToggleThemeMode = createContext<VoidFunction>(() => {});

export const Theme = ({ children }: PropsWithChildren) => {
  const [mode, setMode] = useState<PaletteMode>(() => {
    if (typeof window === 'undefined') return 'light';
    const mode = localStorage.getItem('deckard-theme');
    if (!mode) {
      localStorage.setItem('deckard-theme', 'light');
      document.getElementsByTagName('body')[0].style.colorScheme = 'normal';
      return 'light';
    } else {
      document.getElementsByTagName('body')[0].style.colorScheme = mode;
      return mode as PaletteMode;
    }
  });
  const theme = useMemo(() => customTheme(mode), [mode]);
  const toggleTheme = () => {
    const newMode = mode === 'dark' ? 'light' : 'dark';
    localStorage.setItem('deckard-theme', newMode);
    setMode(newMode);
    if (document) {
      document.getElementsByTagName('body')[0].style.colorScheme = newMode;
    }
  };
  return (
    <ToggleThemeMode.Provider value={toggleTheme}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <SkeletonTheme
          baseColor={theme.palette.grey['200']}
          highlightColor={theme.palette.grey['50']}
        >
          {children}
        </SkeletonTheme>
      </ThemeProvider>
    </ToggleThemeMode.Provider>
  );
};
