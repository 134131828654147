import { Group } from '@components/layout';
import { FormControlLabel, SliderProps, Stack, StackProps, Typography } from '@mui/material';
import { useField } from 'formik';
import { Slider } from '../Slider';
import { FormFieldError } from './FormFieldError';

type FormSliderProps = SliderProps & {
  label: string;
  maintainGutters?: boolean;
  fullWidth?: boolean;
  showCount?: boolean;
  containerProps?: StackProps;
};

export const FormSlider = ({
  label,
  maintainGutters = true,
  name,
  fullWidth,
  showCount = false,
  sx,
  containerProps = {},
  ...props
}: FormSliderProps) => {
  const [field, meta] = useField(name);
  const error = meta?.touched && meta?.error;
  return (
    <Stack sx={{ width: fullWidth ? '100%' : 'fit-content', ...containerProps.sx }}>
      <FormControlLabel
        label={label}
        slotProps={{ typography: { variant: 'caption2' } }}
        control={
          <Group alignItems="center" width="100%" gap={4} height="34px">
            <Slider min={0} max={1} step={0.05} {...field} {...props} name={name} />
            {showCount && <Typography variant="body2">{field.value}</Typography>}
          </Group>
        }
        sx={{
          flexDirection: 'column-reverse',
          alignItems: 'flex-start',
          gap: 0,
          height: 'auto',
          '& .MuiTypography-root': { color: 'text.paper' },
          '& .MuiFormControlLabel-label': { mb: 1 },
          ...sx,
        }}
      />

      <FormFieldError error={error} maintainGutters={maintainGutters} />
    </Stack>
  );
};
