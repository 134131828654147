import { Button } from '@components/button';
import { useModal } from '@components/modal';
import { Typography } from '@components/typography';
import { Stack } from '@mui/material';
import { useKeyPressEvent } from 'react-use';

type ConfirmationModalProps = {
  message?: string;
  affirmText?: string;
  onAffirm?: () => void | Promise<unknown>;
  onReject?: () => void | Promise<unknown>;
};

const ConfirmationModal = ({
  message,
  affirmText,
  onAffirm,
  onClose,
  onReject,
}: ConfirmationModalProps & { onClose: () => void }) => {
  const handleAffirm = () => {
    if (onAffirm) {
      onAffirm();
    }
    if (onClose) {
      onClose();
    }
  };

  const handleReject = () => {
    if (onReject) {
      onReject();
    }
    if (onClose) {
      onClose();
    }
  };

  useKeyPressEvent(
    'Enter',
    async (keyboardEvent: KeyboardEvent & { target: { localName: string } }) => {
      if (keyboardEvent.target.localName !== 'div') {
        return;
      }
      handleAffirm();
    },
  );

  return (
    <Stack
      data-cy="Confirmation-Modal"
      direction="column"
      minWidth="516px"
      maxWidth="650px"
      height="auto"
      data-testid="confirmationDiv"
    >
      <Typography
        variant="subtitle1"
        handleOverFlow
        data-cy="Confirmation-Modal-Message"
        sx={{ mt: 6, mb: 9, textAlign: 'center' }}
      >
        {message || 'Are you sure?'}
      </Typography>
      <Button
        fullWidth
        onClick={handleAffirm}
        data-cy="Confirmation-Modal-Delete-Button"
        sx={{ mb: 4 }}
        autoFocus
      >
        {affirmText || 'Yes'}
      </Button>
      <Button
        fullWidth
        onClick={handleReject}
        data-cy="Confirmation-Modal-Cancel-Button"
        variant="secondary"
      >
        Cancel
      </Button>
    </Stack>
  );
};

export const useConfirmation = (props?: ConfirmationModalProps) => {
  const { showModal } = useModal({
    component: ConfirmationModal,
    componentProps: props,
  });
  const showConfirmation = async (props?: ConfirmationModalProps) => {
    return await showModal({ componentProps: props });
  };
  return { showConfirmation };
};
