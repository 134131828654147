/* eslint-disable no-bitwise */
import { useTheme } from '@mui/material';
import { CanvasContext, SelectionContext, ZoomContext } from '@subsets/workspaces';
import { pointer, select } from 'd3';
import { useContext, useEffect, useRef, useState } from 'react';

export const SelectionArea = () => {
  const theme = useTheme();
  const ref = useRef();
  const { svg } = useContext(CanvasContext);
  const { zoomElement } = useContext(ZoomContext);
  const { selectNodesFromArea } = useContext(SelectionContext);
  const [boxStart, setBoxStart] = useState([0, 0]);

  useEffect(() => {
    svg
      ?.on('mousedown', (event: MouseEvent) => {
        if ((event.buttons & 1) !== 1 || !event.shiftKey) {
          return;
        }
        const [x, y] = pointer(event, zoomElement?.current) as [number, number];
        setBoxStart([x, y]);
        select(ref.current).attr('x', x).attr('y', y);
      })
      .on('mousemove', (event: MouseEvent) => {
        if ((event.buttons & 1) !== 1 || !event.shiftKey) {
          return;
        }
        const [x1, y1] = pointer(event, zoomElement?.current) as [number, number];
        const width = Math.abs(boxStart[0] - x1);
        const height = Math.abs(boxStart[1] - y1);
        select(ref.current)
          .attr('x', Math.min(boxStart[0], x1))
          .attr('y', Math.min(boxStart[1], y1))
          .attr('width', width)
          .attr('height', height);
      })
      .on('mouseup', (event: MouseEvent) => {
        selectNodesFromArea(
          [boxStart[0], boxStart[1]],
          pointer(event, zoomElement?.current) as [number, number],
        );
        select(ref.current).attr('height', 0).attr('width', 0);
      });
  }, [svg, zoomElement?.current, boxStart]);

  return (
    <rect
      ref={ref}
      stroke={theme.palette.grey[500]}
      strokeWidth={3}
      strokeDasharray={3}
      fill="none"
      height="0"
      width="0"
    />
  );
};
