import { MoreVerticalIcon } from '@assets/icons';
import { IconButton, IconButtonProps } from '@components/button';
import { ClickAwayListener, Menu, MenuItem, MenuProps } from '@mui/material';
import { MouseEventHandler, PropsWithChildren, ReactNode, useState } from 'react';

export type OptionsMenuItem = {
  label: ReactNode;
  onClick: () => void;
};

export const OptionsMenu = ({
  items,
  keepMounted = true,
  menuProps,
  ...props
}: PropsWithChildren<
  Partial<IconButtonProps> & {
    items: OptionsMenuItem[];
    keepMounted?: boolean;
    menuProps?: Partial<MenuProps>;
  }
>) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick: MouseEventHandler<HTMLElement> = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };
  const handleItemClick = (event, onClick) => {
    event.stopPropagation();
    setAnchorEl(null);
    onClick();
  };
  return (
    <>
      <IconButton
        Icon={MoreVerticalIcon}
        size={20}
        aria-label="options"
        onClick={handleClick}
        {...props}
      />
      <ClickAwayListener onClickAway={handleClose}>
        <Menu
          anchorEl={anchorEl}
          keepMounted={keepMounted}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          elevation={0}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          {...menuProps}
        >
          {items &&
            items.map(({ label, onClick }, index) => (
              <MenuItem key={index} onClick={(event) => handleItemClick(event, onClick)}>
                {label}
              </MenuItem>
            ))}
        </Menu>
      </ClickAwayListener>
    </>
  );
};
