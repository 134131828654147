import { ChevronDownIcon, LoadingIcon } from '@assets/icons';
import { IconButton } from '@components/button';
import { Group } from '@components/layout';
import { Typography } from '@components/typography';
import { Organization, useGetOrganizationsQuery } from '@generated/UseGraphqlHooks';
import { Menu, MenuItem } from '@mui/material';
import { navigate } from 'gatsby';
import { sortBy } from 'lodash';
import { MouseEvent, useState } from 'react';

type OrganizationDropdownProps = {
  organizations: Organization[];
  selectedOrganizationId: string | undefined;
};

export const OrganizationDropdown = ({
  organizations,
  selectedOrganizationId,
}: OrganizationDropdownProps) => {
  const { loading: organizationsLoading } = useGetOrganizationsQuery();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const sortedOrganizations = sortBy(organizations, ({ name }) => name.toLowerCase());

  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const ready = !organizationsLoading;

  const handleDropdownClose = (organizationId: string) => {
    handleClose();
    const tab = window && window.location.pathname.split('/')[3];
    void navigate(`/organizations/${organizationId}/${tab || 'settings'}`);
  };

  if (ready) {
    return (
      <Group alignItems="center" justifyContent="flex-start" gap={4} sx={{ pt: 7, pb: 10 }}>
        <Typography variant="h1" handleOverFlow fitContent>
          {(!selectedOrganizationId
            ? organizations[0]?.name
            : organizations.find((org) => org.organizationId === selectedOrganizationId)?.name) ||
            '-'}
        </Typography>
        <IconButton Icon={ChevronDownIcon} onClick={handleClick} size={20} />
        <Menu
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          defaultValue=""
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        >
          {sortedOrganizations.map((organization) => (
            <MenuItem
              onClick={() => handleDropdownClose(organization?.organizationId)}
              key={organization?.organizationId}
            >
              {organization?.name}
            </MenuItem>
          ))}
        </Menu>
      </Group>
    );
  }

  return (
    <Group alignItems="center" sx={{ pt: 11.25, pb: 14.25, height: '34px' }}>
      <LoadingIcon size={16} />
    </Group>
  );
};
