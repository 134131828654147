import { ProgressBar } from '@components/progress';
import { Typography } from '@components/typography';
import { DatasetJob } from '@generated/UseGraphqlHooks';
import { Stack } from '@mui/material';
import { navigate } from 'gatsby';
import { capitalize, lowerCase } from 'lodash';
import moment from 'moment';
import { memo, useMemo } from 'react';

const getTimeDifferenceInSeconds = (startTime: Date, endTime: Date) =>
  moment(endTime).diff(moment(startTime), 'seconds');

interface RecentJobPreviewProps {
  job: DatasetJob;
}

export const RecentJobPreview = memo(
  ({ job: { status, name, estimatedEndAt, createdAt } }: RecentJobPreviewProps) => {
    const percent = useMemo(() => {
      if (
        status === 'success' ||
        status === 'partially_success' ||
        status === 'failed' ||
        status === 'cancelled' ||
        status === 'timeout'
      ) {
        return 100;
      }
      if (!estimatedEndAt) {
        return 0;
      }
      const elapsedTime = getTimeDifferenceInSeconds(new Date(createdAt), new Date());
      const totalTime = getTimeDifferenceInSeconds(new Date(createdAt), new Date(estimatedEndAt));
      return Math.min(99, Math.round((elapsedTime / totalTime) * 100));
    }, [status, createdAt, estimatedEndAt]);

    return (
      <Stack>
        <Typography
          variant="body2"
          handleOverFlow
          onClick={() => navigate('jobs')}
          sx={{ cursor: 'pointer' }}
        >
          {name || '-'}
        </Typography>
        <ProgressBar
          percent={percent}
          error={status === 'failed'}
          warning={status === 'timeout'}
          label={capitalize(lowerCase(status))}
        />
      </Stack>
    );
  },
);
