import { Typography } from '@components/typography';
import { Analytics } from '@generated/UseGraphqlHooks';
import { Grid, useTheme } from '@mui/material';
import { DatasetsDetailsAnalyticsListItemPreview } from '@subsets/workspaces';

interface DatasetsDetailsAnalyticsListProps {
  analytics: Partial<Analytics>[];
  workspaceId: string;
  refetch: () => void;
}

interface DatasetsDetailsAnalyticsListItemPreviewProps {
  workspaceId: string;
  status: string;
  datasetId: string;
  analyticsId: string;
  type: string;
}

export const DatasetsDetailsAnalyticsList = ({
  analytics,
  workspaceId,
  refetch,
}: DatasetsDetailsAnalyticsListProps) => {
  const { palette } = useTheme();
  if (analytics.length === 0) {
    return (
      <Typography variant="caption2" noWrap>
        This dataset has no analytics.
      </Typography>
    );
  }

  return (
    <>
      <Grid container alignItems="center">
        <Grid item xs={1.5}>
          <Typography color={palette.table.header} variant="caption2">
            Status
          </Typography>
        </Grid>
        <Grid item xs={1.5} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Typography color={palette.table.header} variant="caption2">
            Id
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography color={palette.table.header} variant="caption2">
            Type
          </Typography>
        </Grid>
        <Grid item xs={6} />
      </Grid>
      {analytics.map((analytic) => (
        <DatasetsDetailsAnalyticsListItemPreview
          workspaceId={workspaceId}
          {...(analytic as DatasetsDetailsAnalyticsListItemPreviewProps)}
          key={analytic.analyticsId}
          refetch={refetch}
        />
      ))}
    </>
  );
};
