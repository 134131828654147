import { ChevronDownIcon, InfoIcon } from '@assets/icons';
import { Tooltip } from '@components/tooltip';
import { Typography } from '@components/typography';
import {
  Box,
  ClickAwayListener,
  Collapse,
  Stack,
  SxProps,
  Tab,
  TabProps,
  Tabs,
  useTheme,
} from '@mui/material';
import { useLandingPageProvider } from '@subsets/landing-page';
import { useObservableState } from 'observable-hooks';
import { ChangeEvent, Ref, forwardRef, useState } from 'react';

type SideTabProps = TabProps & {
  isRecent?: boolean;
  organizationId?: string;
  expired?: boolean;
  role?: string;
};

export const LandingPageOrganizationsSideTab = forwardRef(
  (
    { isRecent, role, organizationId, expired, label, ...rest }: SideTabProps,
    ref: Ref<HTMLDivElement>,
  ) => {
    const { palette, typography } = useTheme();
    const { tabValue$ } = useLandingPageProvider();
    const tabValue = useObservableState<string>(tabValue$);
    const [shouldExpand, setShouldExpand] = useState<boolean>(false);
    const handleCollapseClose = () => setShouldExpand(false);
    const handleCollapseChange = () => setShouldExpand(!shouldExpand);

    const handleOrganizationChange = () => {
      if (!tabValue.includes(organizationId)) {
        handleCollapseClose();
      }
    };
    const handleChange = (_: ChangeEvent<unknown>, val: string) => {
      tabValue$.next(val);
    };

    const tabStyles = {
      ...typography.body2,
      lineHeight: '44px',
      height: '44px',
      paddingY: 0,
      width: '100%',
      backgroundColor: palette.background.paper,
      textTransform: 'none',
      paddingLeft: 6,
      maxWidth: '100%',
      display: 'flex',
      color: palette.text.paper,
      '&.MuiButtonBase-root.MuiTab-root.Mui-selected': {
        marginLeft: 2,
        paddingLeft: 4,
        backgroundColor: palette.background.default,
        color: palette.mode === 'dark' ? palette.common.white : palette.text.primary,
        borderTopLeftRadius: '4px',
        borderBottomLeftRadius: '4px',
      },
    } as SxProps;

    if (isRecent) {
      return (
        <Tab
          sx={{ ...tabStyles, alignItems: 'flex-start' }}
          label={label}
          ref={ref}
          disableRipple
          {...rest}
        />
      );
    }

    return (
      <ClickAwayListener onClickAway={handleOrganizationChange}>
        <Stack sx={{ width: '100%' }} data-test-id="landing-org-tab">
          <Box sx={{ position: 'relative' }}>
            <Tab
              ref={ref}
              disableRipple
              sx={{
                ...tabStyles,
                paddingRight: 4,
                justifyContent: 'space-between',
                '&.MuiButtonBase-root.MuiTab-root.Mui-selected': {
                  ...tabStyles['&.MuiButtonBase-root.MuiTab-root.Mui-selected'],
                  paddingRight: 6,
                },
              }}
              label={label}
              icon={
                expired ? (
                  <Tooltip
                    title={
                      <Typography variant="caption2">Your subscription has expired</Typography>
                    }
                    placement="top"
                  >
                    <InfoIcon size={20} />
                  </Tooltip>
                ) : (
                  <ChevronDownIcon
                    size={20}
                    style={{
                      transition: 'all 0.2s',
                      transform: !shouldExpand ? 'rotate(0deg)' : 'rotate(180deg)',
                    }}
                  />
                )
              }
              iconPosition="end"
              {...rest}
              onClick={!expired ? handleCollapseChange : () => {}}
            />
          </Box>
          <Collapse in={shouldExpand}>
            <Tabs
              orientation="vertical"
              value={
                !tabValue.includes(organizationId) || tabValue === organizationId || tabValue === ''
                  ? false
                  : tabValue
              }
              onChange={handleChange}
              sx={{ '& .MuiTabs-indicator': { backgroundColor: 'transparent' } }}
            >
              <Tab
                label="Channels"
                value={`${organizationId}.channels`}
                disableRipple
                sx={{
                  ...tabStyles,
                  paddingLeft: 9,
                  alignItems: 'flex-start',
                  '&.MuiButtonBase-root.MuiTab-root.Mui-selected': {
                    ...tabStyles['&.MuiButtonBase-root.MuiTab-root.Mui-selected'],
                    paddingLeft: 7,
                  },
                }}
              />
              <Tab
                label="Volumes"
                value={`${organizationId}.volumes`}
                disableRipple
                sx={{
                  ...tabStyles,
                  paddingLeft: 9,
                  alignItems: 'flex-start',
                  '&.MuiButtonBase-root.MuiTab-root.Mui-selected': {
                    ...tabStyles['&.MuiButtonBase-root.MuiTab-root.Mui-selected'],
                    paddingLeft: 7,
                  },
                }}
              />
              <Tab
                label="GAN Models"
                value={`${organizationId}.gan`}
                disableRipple
                sx={{
                  ...tabStyles,
                  paddingLeft: 9,
                  alignItems: 'flex-start',
                  '&.MuiButtonBase-root.MuiTab-root.Mui-selected': {
                    ...tabStyles['&.MuiButtonBase-root.MuiTab-root.Mui-selected'],
                    paddingLeft: 7,
                  },
                }}
              />
              <Tab
                label="Annotation Maps"
                value={`${organizationId}.annotation`}
                disableRipple
                sx={{
                  ...tabStyles,
                  paddingLeft: 9,
                  alignItems: 'flex-start',
                  '&.MuiButtonBase-root.MuiTab-root.Mui-selected': {
                    ...tabStyles['&.MuiButtonBase-root.MuiTab-root.Mui-selected'],
                    paddingLeft: 7,
                  },
                }}
              />
            </Tabs>
          </Collapse>
        </Stack>
      </ClickAwayListener>
    );
  },
);
