import { FormGroup, FormGroupProps } from '@mui/material';
import { Formik, FormikConfig, Form as FormikForm, FormikFormProps } from 'formik';

export const Form = <T,>({
  children,
  formProps,
  formGroupProps,
  ...props
}: FormikConfig<T> & { formProps?: FormikFormProps; formGroupProps?: FormGroupProps }) => {
  return (
    <Formik {...props}>
      {(formikProps) => {
        return (
          // @ts-ignore - FormikForm is looking for fields that no longer exist, this shouldn't affect the functionality of the form
          <FormikForm {...formProps}>
            <FormGroup {...formGroupProps}>
              {typeof children === 'function' ? children(formikProps) : children}
            </FormGroup>
          </FormikForm>
        );
      }}
    </Formik>
  );
};
