import { MouseContext, PositionContext, SelectionContext } from '@subsets/workspaces';
import { useContext } from 'react';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Link } from './Link';

export const EditLink = () => {
  const { mousePosition$ } = useContext(MouseContext);
  const { getPortPosition } = useContext(PositionContext);
  const { selectedOutputPort } = useContext(SelectionContext);

  const from$ = selectedOutputPort.pipe(
    switchMap((selected) => {
      if (!selected) {
        return of(undefined);
      }
      const { nodeId, portId } = selected;
      return getPortPosition(nodeId, portId);
    }),
  );

  return <Link from={from$} focus to={mousePosition$} />;
};
