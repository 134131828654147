import { InfiniteScrollTableComponents, useGraphqlPagination } from '@components/infiniteScroll';
import { Typography } from '@components/typography';
import {
  AnnotationMap,
  GetAnnotationMapsQuery,
  useGetAnnotationMapsQuery,
} from '@generated/UseGraphqlHooks';
import { Box, Stack, useTheme } from '@mui/material';
import { TableVirtuoso } from 'react-virtuoso';
import { LandingPageOrganizationResourcesLoading } from '../LandingPageOrganizationResourcesLoading';
import { AnnotationMapHeader } from './AnnotationMapHeader';
import { AnnotationMapTableHeader } from './AnnotationMapTableHeader';
import { AnnotationMapTableRow } from './AnnotationMapTableRow';

type OrganizationAnnotationMapsProps = {
  name: string;
  organizationId: string;
};

export const OrganizationAnnotationMaps = ({
  name,
  organizationId,
}: OrganizationAnnotationMapsProps) => {
  const { palette } = useTheme();
  const {
    data: annotationMaps,
    loading: annotationMapsLoading,
    fetchMore,
  } = useGraphqlPagination<GetAnnotationMapsQuery, AnnotationMap>(
    useGetAnnotationMapsQuery({
      variables: { organizationId, limit: 30 },
    }),
    'getAnnotationMaps',
    'mapId',
  );

  const tableRow = (_index, annotationMap: AnnotationMap) => (
    <AnnotationMapTableRow {...annotationMap} selectedOrganizationId={organizationId} />
  );

  if (!annotationMapsLoading) {
    return (
      <Stack pt={5} pl={10} spacing={5} height="100%" overflow="hidden">
        <AnnotationMapHeader name={name} organizationId={organizationId} />
        <Stack flex={1} overflow="hidden" p={6} sx={{ backgroundColor: palette.background.paper }}>
          {annotationMaps.length === 0 ? (
            <Box
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flex: 1,
              }}
            >
              <Typography>This Organization has no Annotation Maps.</Typography>
            </Box>
          ) : (
            <TableVirtuoso
              data={annotationMaps}
              endReached={fetchMore}
              components={InfiniteScrollTableComponents as any}
              fixedHeaderContent={AnnotationMapTableHeader}
              itemContent={tableRow}
              increaseViewportBy={400}
            />
          )}
        </Stack>
      </Stack>
    );
  }
  return (
    <LandingPageOrganizationResourcesLoading
      name={name}
      resource="Annotation maps"
      buttonLabel="New annotation map"
    />
  );
};
