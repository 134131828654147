import { TextInput } from '@components/form';
import { Group } from '@components/layout';
import { Stack } from '@mui/material';
import { debounce } from 'lodash';
import { FilterChips } from './FilterChips';
import { FilterMenu } from './FilterMenu';
import { DeckardFilter, SearchDetailTypes } from './types';

type SearchAndFilterProps<T> = {
  filter: DeckardFilter<T>;
  searchDetail: SearchDetailTypes;
  variant?: 'primary' | 'secondary';
  setFilter: (filter: DeckardFilter<T>) => void;
};

export const SearchAndFilter = <T,>({
  filter,
  searchDetail,
  variant = 'primary',
  setFilter,
}: SearchAndFilterProps<T>) => {
  const debounceTextInput = debounce((value: string) => {
    setFilter({
      ...filter,
      texts: { ...filter['texts'], value: value ? value.trim().split(' ') : [] },
    });
  }, 400);

  const clearFilterKey = (key: string) => {
    setFilter({
      ...filter,
      [key]: { type: filter[key].type, value: [], display: [] },
    });
  };

  return (
    <Stack gap={2} width="100%">
      <Group>
        <TextInput
          fullWidth
          sx={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
          placeholder="Search by name, description, or tags"
          onChange={(e) => debounceTextInput(e.target.value)}
        />
        <FilterMenu
          filter={filter}
          searchDetail={searchDetail}
          onFilterChange={setFilter}
          variant={variant}
        />
      </Group>
      <FilterChips
        filter={filter}
        variant={variant}
        clearFilterKey={clearFilterKey}
        updateFilter={(key, newValue) => {
          setFilter({ ...filter, [key]: { ...filter[key], value: newValue } });
        }}
      />
    </Stack>
  );
};
