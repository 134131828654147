import { Button } from '@components/button';
import { Form, FormSelect, FormTextInput } from '@components/form';
import { useEditManagedVolumeMutation, useGetVolumesQuery } from '@generated/UseGraphqlHooks';
import { delay } from '@helper-functions/delay';
import { MenuItem, Stack } from '@mui/material';
import { useNotifications } from '@notifications/Notifications';
import { toLower, upperFirst } from 'lodash';
import * as yup from 'yup';

interface EditVolumeModalProps {
  name: string;
  description: string;
  organizationId: string;
  volumeId: string;
  onClose: () => void;
  permission: string;
}
const validationSchema = yup.object({
  name: yup
    .string()
    .trim()
    .max(128, 'Must be less than 128 characters')
    .required('Name is required'),
  description: yup.string(),
});
interface SubmitType {
  name: string;
  description: string;
  permission: string;
}

export const EditVolumeModal = ({
  onClose,
  volumeId,
  organizationId,
  name: volumeName,
  description: volumeDescription,
  permission: volumePermission,
}: EditVolumeModalProps) => {
  const { useAsyncNotification } = useNotifications();
  const [editVolume] = useEditManagedVolumeMutation();
  const { refetch: refetchVolumes } = useGetVolumesQuery({
    variables: {
      organizationId,
    },
  });

  const handleSubmission = useAsyncNotification(
    'Successfully updated the volume',
    async ({ name, description, permission }: SubmitType) => {
      await editVolume({
        variables: { name, volumeId, description, permission: toLower(permission) },
      });
      await delay(3000);
      await refetchVolumes();
      onClose();
    },
  );

  return (
    <Form
      enableReinitialize
      validateOnBlur={false}
      validationSchema={validationSchema}
      initialValues={{
        name: volumeName || '',
        description: volumeDescription || '',
        permission: upperFirst(volumePermission),
      }}
      onSubmit={handleSubmission}
    >
      {({ isValid, handleSubmit, dirty, isSubmitting }) => (
        <Stack>
          <FormTextInput label="Name" name="name" />
          <FormSelect label="Permission" name="permission">
            <MenuItem value="Write">Write</MenuItem>
            <MenuItem value="Read">Read</MenuItem>
            <MenuItem value="View">View</MenuItem>
          </FormSelect>
          <FormTextInput label="Description" name="description" multiline rows={3} />
          <Stack gap={4}>
            <Button
              fullWidth
              loading={isSubmitting}
              disabled={!(isValid && dirty && !isSubmitting)}
              onClick={handleSubmit as any}
            >
              Save
            </Button>
            <Button fullWidth variant="secondary" onClick={onClose}>
              Cancel
            </Button>
          </Stack>
        </Stack>
      )}
    </Form>
  );
};
