import { DeckardIcon } from '@assets/types';
import { Menu, Stack, useTheme } from '@mui/material';
import { MouseEventHandler, PropsWithChildren } from 'react';

type HeaderMenuProps = {
  anchorEl: HTMLElement;
  icon: DeckardIcon;
  setAnchorEl: (anchor: HTMLElement) => void;
};

export const HeaderMenu = ({
  anchorEl,
  children,
  icon: Icon,
  setAnchorEl,
}: PropsWithChildren<HeaderMenuProps>) => {
  const { palette } = useTheme();

  const handleClick: MouseEventHandler<HTMLElement> = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => setAnchorEl(null);

  return (
    <>
      <Stack
        onClick={handleClick}
        height="100%"
        px={3}
        justifyContent="center"
        sx={{
          backgroundColor: Boolean(anchorEl) ? palette.grey[800] : 'transparent',
          '&:hover': {
            cursor: 'pointer',
            '& svg': {
              stroke: palette.grey[200],
            },
          },
        }}
      >
        <Icon size={20} color={palette.grey[500]} />
      </Stack>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        elevation={0}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          '& .MuiMenu-paper': {
            borderTopRightRadius: 0,
          },
        }}
      >
        {children}
      </Menu>
    </>
  );
};
