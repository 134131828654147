import { createContext, PropsWithChildren, useContext, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';

export type SeoContextType = {
  setTitle: (title: string) => void;
};
const SeoContext = createContext<SeoContextType>({} as SeoContextType);
export const SeoProvider = ({ children }: PropsWithChildren) => {
  const [pageTitle, setTitle] = useState<string>('');
  const providerValue = useMemo(() => ({ setTitle }), [setTitle]);

  return (
    <SeoContext.Provider value={providerValue}>
      <Helmet>
        <title>{`Rendered.AI${pageTitle && ` | ${pageTitle}`}`}</title>
      </Helmet>
      {children}
    </SeoContext.Provider>
  );
};
export const useSeo = () => useContext(SeoContext);
