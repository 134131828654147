import Cookies from 'js-cookie';

export const acceptCookies = () => {
  const expireDate = new Date('2200:01:01');
  return Cookies.set('stripe', 'Agreed', {
    path: '/',
    domain: window.location.hostname,
    expires: expireDate,
  });
};
