import { HelpCircleIcon, XCircleIcon } from '@assets/icons';
import { IconButton } from '@components/button';
import { Drawer } from '@components/drawer';
import { Tab, TabPanel, Tabs } from '@components/tabs';
import { Typography } from '@components/typography';
import { Box, Stack, useTheme } from '@mui/material';
import { SelectionContext } from '@subsets/workspaces';
import { useContext, useState } from 'react';
import { NodeInformation } from './NodeInformation';
import { ZoomController } from './ZoomController';

export const Information = () => {
  const { palette } = useTheme();
  const [value, setValue] = useState<string>('');
  const { informationDrawerOpen, toggleInformationDrawer } = useContext(SelectionContext);

  return (
    <Drawer
      hideBackdrop
      open={informationDrawerOpen}
      anchor="right"
      onClose={toggleInformationDrawer}
      sx={{
        '& .MuiDrawer-paper': {
          width: '80%',
          maxWidth: '370px',
          borderTop: `1px solid ${palette.background.default} !important`,
          paddingX: 4,
          overflow: 'visible',
          paddingTop: 6,
        },
      }}
      style={{}}
    >
      <Box
        position="absolute"
        top={16}
        right="100%"
        justifyContent="center"
        bgcolor={palette.background.paper}
        sx={{
          padding: 2,
          borderRadius: '4px 0 0 4px',
          border: `1px solid ${palette.grey[500]}`,
          borderRight: 'none',
        }}
      >
        <IconButton
          size={28}
          data-testid="addNodeButton"
          data-cy="Add-Node-Button"
          onClick={toggleInformationDrawer}
          Icon={informationDrawerOpen ? XCircleIcon : HelpCircleIcon}
        />
      </Box>
      <Box position="absolute" bottom={16} right="100%">
        <ZoomController />
      </Box>
      <Stack
        sx={{
          height: '100%',
        }}
      >
        <Tabs
          value={value}
          variant="fullWidth"
          onChange={(_, tabValue: string) => setValue(tabValue)}
        >
          <Tab value="" label="Node information" />
          <Tab value="shortcuts" label="Shortcuts" />
        </Tabs>

        <TabPanel value={value} index="" width="100%" sx={{ flex: 1, overflow: 'auto', py: 4 }}>
          <NodeInformation />
        </TabPanel>
        <TabPanel value={value} index="shortcuts" width="100%" sx={{ flex: 1, overflow: 'auto' }}>
          <Stack px={4} gap={2} mt={4}>
            <Box display="flex" justifyContent="space-between">
              <Typography>Pan Canvas</Typography>
              <Typography variant="body2">LMB + Drag</Typography>
            </Box>
            <Box display="flex" justifyContent="space-between">
              <Typography>Zoom Canvas</Typography>
              <Typography variant="body2">Mouse Wheel</Typography>
            </Box>
            <Box display="flex" justifyContent="space-between">
              <Typography>Select Intersection</Typography>
              <Typography variant="body2">Shift + Drag</Typography>
            </Box>
            <Box display="flex" justifyContent="space-between">
              <Typography>Select Multiple</Typography>
              <Typography variant="body2">Shift + LMB</Typography>
            </Box>
            <Box display="flex" justifyContent="space-between">
              <Typography>Delete Selection</Typography>
              <Typography variant="body2">Delete</Typography>
            </Box>
          </Stack>
        </TabPanel>
      </Stack>
    </Drawer>
  );
};
