import { Button } from '@components/button';
import { Form, FormTextInput } from '@components/form';
import { useEditWorkspaceMutation, useGetChannelsQuery } from '@generated/UseGraphqlHooks';
import { Stack } from '@mui/material';
import { useNotifications } from '@notifications/Notifications';
import { FormikProps } from 'formik';
import * as yup from 'yup';

interface RenameModalProps {
  onClose: () => void;
  workspaceId: string;
  workspacesRefetch?: () => Promise<void> | void;
  workspaceName: string;
}

const validationSchema = yup.object({
  workspaceName: yup
    .string()
    .trim()
    .max(128, 'Must be less than 128 characters')
    .required('Name is required'),
});

export const WorkspacesMoreDropdownRenameModal = ({
  onClose,
  workspaceId,
  workspacesRefetch,
  workspaceName,
}: RenameModalProps) => {
  const { useAsyncNotification } = useNotifications();

  const { data: workspaceChannels } = useGetChannelsQuery({
    variables: {
      workspaceId,
    },
  });

  const workspaceChannelsIds = workspaceChannels?.getChannels.map(
    (organization) => organization.channelId,
  );

  const [editWorkspaceMutation] = useEditWorkspaceMutation();
  const handleSubmit = useAsyncNotification(
    'Successfully renamed workspace',
    async ({ workspaceName: name }: RenameModalProps) => {
      onClose();
      await editWorkspaceMutation({
        variables: {
          workspaceId,
          name,
          channelIds: workspaceChannelsIds,
        },
      });
      await workspacesRefetch();
    },
  );

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={{ workspaceName }}
      validationSchema={validationSchema}
    >
      {({ errors }: FormikProps<{ workspaceName: string }>) => (
        <Stack>
          <FormTextInput label="Workspace name" name="workspaceName" />
          <Stack gap={4}>
            <Button
              fullWidth
              disabled={!!errors?.workspaceName}
              data-cy="Workspaces-More-Dropdown-Rename-Modal-Save-Button"
              type="submit"
            >
              Save
            </Button>
            <Button
              fullWidth
              variant="secondary"
              onClick={onClose}
              data-cy="Workspaces-More-Dropdown-Rename-Modal-Cancel-Button"
            >
              Cancel
            </Button>
          </Stack>
        </Stack>
      )}
    </Form>
  );
};
