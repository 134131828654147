import { TransitionScreen } from '@components/transition';
import { Amplify } from 'aws-amplify';
import { FetchUserAttributesOutput, fetchUserAttributes } from 'aws-amplify/auth';
import posthog from 'posthog-js';
import { PropsWithChildren, createContext, useContext, useEffect, useMemo, useState } from 'react';

const authConfig = {
  Auth: {
    Cognito: {
      userPoolId: process.env.GATSBY_COGNITO_USER_POOL_ID,
      userPoolClientId: process.env.GATSBY_COGNITO_USER_POOL_WEB_CLIENT_ID,
    },
  },
};

Amplify.configure(authConfig);

interface AuthContextType {
  loading: boolean;
  user?: FetchUserAttributesOutput;
  setUser?: (user: FetchUserAttributesOutput) => void;
}

export const AuthContext = createContext<AuthContextType>({} as AuthContextType);

export const AuthProvider = ({ children }: PropsWithChildren<{ authenticationPage?: boolean }>) => {
  const [user, setUser] = useState<FetchUserAttributesOutput>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const checkAuthState = async () => {
      try {
        setUser(await fetchUserAttributes());
      } catch (error) {
        // User not signed in; no console error logging
      } finally {
        setLoading(false);
      }
    };
    void checkAuthState();
  }, []);

  useEffect(() => {
    if (!user) {
      return;
    }
    posthog.identify(user.preferred_username, { email: user.email });
  }, [user]);

  const value = useMemo(() => ({ loading, user, setUser }), [loading, user, setUser]);

  if (loading) {
    return <TransitionScreen />;
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuthContext = () => useContext(AuthContext);
