import { AsyncButton, Button } from '@components/button';
import { Form, FormTextInput } from '@components/form';
import { useEditManagedGanMutation, useGetGanModelsQuery } from '@generated/UseGraphqlHooks';
import { Stack } from '@mui/material';
import { useNotifications } from '@notifications/Notifications';
import * as yup from 'yup';

interface EditGanModelModalProps {
  onClose: () => void;
  organizationId: string;
  modelId: string;
  name: string;
  description: string;
  flags: string;
}

interface SubmitType {
  name: string;
  description: string;
  flags: string;
}

const validationSchema = yup.object({
  name: yup
    .string()
    .trim()
    .max(128, 'Must be less than 128 characters')
    .required('Name is required'),
  description: yup.string(),
});

export const EditGanModelModal = ({
  onClose,
  name: oldName,
  description: oldDescription,
  flags: oldFlags,
  organizationId,
  modelId,
}: EditGanModelModalProps) => {
  const { useAsyncNotification } = useNotifications();
  const [editGAN] = useEditManagedGanMutation();
  const { refetch: refetchGanModels } = useGetGanModelsQuery({
    variables: { organizationId },
  });

  const handleFormSubmit = useAsyncNotification(
    'Successfully updated the model',
    async ({ name, description, flags }: SubmitType) => {
      const submitObject = { modelId } as Partial<SubmitType> & { modelId: string };
      if (name !== oldName) {
        submitObject.name = name;
      }
      if (description !== oldDescription) {
        submitObject.description = description;
      }
      if (flags !== oldFlags) {
        submitObject.flags = flags;
      }
      await editGAN({
        variables: submitObject,
      });
      await refetchGanModels();
      onClose();
    },
  );

  return (
    <Form
      enableReinitialize
      initialValues={{
        name: oldName || '',
        description: oldDescription || '',
        flags: oldFlags || '',
      }}
      validationSchema={validationSchema}
      validateOnBlur={false}
      onSubmit={handleFormSubmit}
    >
      {({ isValid, handleSubmit, dirty, isSubmitting }) => (
        <Stack>
          <FormTextInput name="name" label="Name" />
          <FormTextInput name="flags" label="Flags" />
          <FormTextInput name="description" label="Description" multiline rows={3} />
          <Stack gap={4}>
            <AsyncButton
              fullWidth
              loading={isSubmitting}
              disabled={!(isValid && dirty && !isSubmitting)}
              onClick={handleSubmit}
            >
              Save
            </AsyncButton>
            <Button fullWidth variant="secondary" onClick={onClose}>
              Cancel
            </Button>
          </Stack>
        </Stack>
      )}
    </Form>
  );
};
