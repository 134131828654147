import { ArrowLeftIcon } from '@assets/icons';
import { Button, IconButton } from '@components/button';
import { Checkbox } from '@components/form';
import { Group } from '@components/layout';
import { Typography } from '@components/typography';
import { SearchDetail } from '@generated/UseGraphqlHooks';
import { Divider, MenuItem, Stack } from '@mui/material';
import { capitalize } from 'lodash';
import { useState } from 'react';

type MultiSelectFilterProps = {
  field: string;
  type: 'string' | 'comparator';
  options: SearchDetail[];
  selected: string[] | { eq: string }[];
  onApply: (selected: unknown[], selectedDisplay: string[]) => void;
  onBack: () => void;
};

export const MultiSelectFilter = ({
  field,
  options,
  selected,
  type,
  onApply,
  onBack,
}: MultiSelectFilterProps) => {
  const [dirtySelected, setDirtySelected] = useState(
    selected?.length > 0 ? (type === 'string' ? selected : selected.map((item) => item.eq)) : [],
  );
  return (
    <Stack maxHeight="500px">
      <Group alignItems="center" gap={1} sx={{ px: 2 }}>
        <IconButton size={16} onClick={onBack} Icon={ArrowLeftIcon} />
        <Typography variant="body2">{capitalize(field)}</Typography>
      </Group>
      <Divider sx={{ m: 2 }} flexItem />
      <Stack sx={{ overflowY: 'auto' }}>
        {options?.map((option) => (
          <MenuItem
            key={option.value}
            sx={{ gap: 2 }}
            onClick={() =>
              setDirtySelected((prev) =>
                prev.includes(option.value)
                  ? prev.filter((item) => item !== option.value)
                  : [...prev, option.value],
              )
            }
          >
            <Checkbox checked={dirtySelected?.includes(option.value)} sx={{ height: 'auto' }} />
            {option.display}
          </MenuItem>
        ))}
      </Stack>
      <Divider sx={{ m: 2 }} flexItem />
      <Group justifyContent="flex-end" sx={{ px: 2, gap: 2 }}>
        <Button size="small" variant="secondary" onClick={() => setDirtySelected([])}>
          Clear all
        </Button>
        <Button
          size="small"
          variant="primary"
          onClick={() => {
            onApply(
              type === 'string'
                ? dirtySelected
                : dirtySelected.map((selected) => ({ eq: selected })),
              dirtySelected.map(
                (selected) =>
                  options.find((option) => option.value === selected).display || selected,
              ),
            );
          }}
        >
          Apply
        </Button>
      </Group>
    </Stack>
  );
};
