import { PropsWithChildren, createContext, useContext } from 'react';

const OrganizationContext = createContext('');

export const OrganizationContextProvider = ({
  organizationId,
  children,
}: PropsWithChildren<{ organizationId: string }>) => (
  <OrganizationContext.Provider value={organizationId}>{children}</OrganizationContext.Provider>
);

export const useCurrentOrganizationId = () => useContext(OrganizationContext);
