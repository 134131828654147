import { XIcon } from '@assets/icons';
import { Chip, ChipProps } from '@mui/material';
import { MouseEvent, forwardRef } from 'react';

export const Tag = forwardRef<HTMLDivElement, ChipProps>(({ onDelete, ...props }, ref) => {
  return (
    <Chip
      component="div"
      {...props}
      ref={ref}
      onDelete={
        onDelete
          ? (e: MouseEvent<HTMLButtonElement>) => {
              e.stopPropagation();
              if (onDelete) {
                onDelete(e);
              }
            }
          : undefined
      }
      deleteIcon={<XIcon size={16} onMouseDown={(e) => e.stopPropagation()} />}
      variant="outlined"
    />
  );
});
