import {
  ArrowLeftIcon,
  EqualsIcon,
  GreaterThanOrEqualIcon,
  LessThanOrEqualIcon,
} from '@assets/icons';
import { Button, IconButton } from '@components/button';
import { RadioIcon, TextInput } from '@components/form';
import { Group } from '@components/layout';
import { Typography } from '@components/typography';
import { IntComparator } from '@generated/UseGraphqlHooks';
import { Divider, RadioGroup, Stack } from '@mui/material';
import { capitalize } from 'lodash';
import { useState } from 'react';

type NumberFilterProps = {
  field: string;
  value: IntComparator;
  onApply: (newValue: IntComparator[]) => void;
  onBack: () => void;
  onClose: () => void;
};

export const NumberFilter = ({ field, value, onApply, onBack, onClose }: NumberFilterProps) => {
  const [dirtyValue, setDirtyValue] = useState(() => (value ? Object.values(value)?.[0] : null));
  const [dirtyOption, setDirtyOption] = useState(() => (value ? Object.keys(value)?.[0] : 'eq'));
  return (
    <Stack maxHeight="500px">
      <Group alignItems="center" gap={1} sx={{ px: 2 }}>
        <IconButton size={16} onClick={onBack} Icon={ArrowLeftIcon} />
        <Typography variant="body2">{capitalize(field)}</Typography>
      </Group>
      <Divider sx={{ m: 2 }} flexItem />
      <Stack sx={{ overflowY: 'auto', px: 6, gap: 2 }}>
        <RadioGroup
          row
          sx={{
            flex: 1,
            gap: 1,
            justifyContent: 'center',
            '& .MuiFormControlLabel-label': { height: '24px', lineHeight: '24px' },
          }}
          value={dirtyOption}
          onChange={(e) => setDirtyOption(e.target.value)}
        >
          <RadioIcon value="lte" icon={LessThanOrEqualIcon}></RadioIcon>
          <RadioIcon value="eq" icon={EqualsIcon}></RadioIcon>
          <RadioIcon value="gte" icon={GreaterThanOrEqualIcon}></RadioIcon>
        </RadioGroup>
        <TextInput
          value={dirtyValue}
          onChange={(e) => setDirtyValue(parseInt(e.target.value))}
          type="number"
          sx={{ minWidth: '50px' }}
        />
      </Stack>
      <Divider sx={{ m: 2 }} flexItem />
      <Group justifyContent="flex-end" sx={{ px: 2, gap: 2 }}>
        <Button
          size="small"
          variant="secondary"
          onClick={() => {
            setDirtyValue('' as any);
          }}
        >
          Clear
        </Button>
        <Button
          size="small"
          variant="primary"
          onClick={() => {
            onApply([{ [dirtyOption]: dirtyValue }]);
          }}
        >
          Apply
        </Button>
      </Group>
    </Stack>
  );
};
