import {
  ArrowLeftIcon,
  EqualsIcon,
  GreaterThanOrEqualIcon,
  LessThanOrEqualIcon,
} from '@assets/icons';
import { Button, IconButton } from '@components/button';
import { RadioIcon, Select, TextInput } from '@components/form';
import { Group } from '@components/layout';
import { Typography } from '@components/typography';
import { FloatComparator } from '@generated/UseGraphqlHooks';
import { Divider, MenuItem, RadioGroup, Stack } from '@mui/material';
import { capitalize } from 'lodash';
import { useState } from 'react';

type SizeFilterProps = {
  display: 'kB' | 'MB' | 'GB';
  field: string;
  value: FloatComparator;
  onApply: (newValue: FloatComparator[], display: 'kB' | 'MB' | 'GB') => void;
  onBack: () => void;
  onClose: () => void;
};

export const SizeFilter = ({
  display,
  field,
  value,
  onApply,
  onBack,
  onClose,
}: SizeFilterProps) => {
  const [dirtyValue, setDirtyValue] = useState(() => {
    const val = value ? Object.values(value)?.[0] : null;
    if (val) {
      return display === 'kB' ? val / 1000 : display === 'MB' ? val / 1000000 : val / 1000000000;
    }
    return null;
  });
  const [dirtyOption, setDirtyOption] = useState(() => (value ? Object.keys(value)?.[0] : 'eq'));
  const [dirtyDisplay, setDirtyDisplay] = useState(display || 'MB');
  return (
    <Stack maxHeight="500px">
      <Group alignItems="center" gap={1} sx={{ px: 2 }}>
        <IconButton size={16} onClick={onBack} Icon={ArrowLeftIcon} />
        <Typography variant="body2">{capitalize(field)}</Typography>
      </Group>
      <Divider sx={{ m: 2 }} flexItem />
      <Stack sx={{ overflowY: 'auto', px: 6, gap: 2 }}>
        <RadioGroup
          row
          sx={{
            flex: 1,
            gap: 1,
            justifyContent: 'center',
            '& .MuiFormControlLabel-label': { height: '24px', lineHeight: '24px' },
          }}
          value={dirtyOption}
          onChange={(e) => setDirtyOption(e.target.value)}
        >
          <RadioIcon value="lte" icon={LessThanOrEqualIcon}></RadioIcon>
          <RadioIcon value="eq" icon={EqualsIcon}></RadioIcon>
          <RadioIcon value="gte" icon={GreaterThanOrEqualIcon}></RadioIcon>
        </RadioGroup>
        <Group sx={{ gap: 2 }}>
          <TextInput
            value={dirtyValue}
            onChange={(e) => setDirtyValue(parseInt(e.target.value))}
            type="number"
            fullWidth
            containerProps={{ sx: { flex: 1, width: '100%' } }}
          />
          <Select
            value={dirtyDisplay}
            onChange={(e) => setDirtyDisplay(e.target.value as 'kB' | 'MB' | 'GB')}
            containerProps={{ sx: { width: '75px' } }}
          >
            <MenuItem value="kB">kB</MenuItem>
            <MenuItem value="MB">MB</MenuItem>
            <MenuItem value="GB">GB</MenuItem>
          </Select>
        </Group>
      </Stack>
      <Divider sx={{ m: 2 }} flexItem />
      <Group justifyContent="flex-end" sx={{ px: 2, gap: 2 }}>
        <Button
          size="small"
          variant="secondary"
          onClick={() => {
            setDirtyValue('' as any);
          }}
        >
          Clear
        </Button>
        <Button
          size="small"
          variant="primary"
          onClick={() => {
            if (!dirtyValue) {
              onClose();
            }
            onApply(
              [
                {
                  [dirtyOption]:
                    dirtyValue *
                    (dirtyDisplay === 'kB' ? 1000 : dirtyDisplay === 'MB' ? 1000000 : 1000000000),
                },
              ],
              dirtyDisplay,
            );
          }}
        >
          Apply
        </Button>
      </Group>
    </Stack>
  );
};
