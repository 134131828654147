import { Tooltip } from '@components/tooltip';
import { useTheme } from '@mui/material';
import { forwardRef, memo, SVGProps } from 'react';
import { NodeState } from './types';

export const PortIcon = memo(
  forwardRef<
    SVGSVGElement,
    SVGProps<SVGSVGElement> & {
      linked?: boolean;
      error?: string;
      state?: NodeState;
      type: 'input' | 'output';
    }
  >(({ linked = false, error = false, state, type, ...props }, ref) => {
    const { palette } = useTheme();
    return (
      <Tooltip title={error}>
        <svg
          viewBox="0 0 10 32"
          width="13"
          height="32"
          xmlns="http://www.w3.org/2000/svg"
          style={{ transform: type === 'output' ? 'rotate(180deg)' : '' }}
          {...props}
        >
          <path
            d="M 0.938 17.036 L 0 16.286 L 0.907 15.5 L 6.907 10.3 L 8.562 8.865 L 8.562 11.055 L 8.562 21.055 L 8.562 23.136 L 6.938 21.836 L 0.938 17.036 Z"
            fill={
              linked
                ? error
                  ? palette.error.main
                  : palette.graph.port.linked
                : palette.mode === 'dark'
                  ? palette.graph.port.unlinked
                  : 'transparent'
            }
            stroke={
              linked
                ? error
                  ? palette.error.main
                  : palette.graph.port.linked
                : palette.graph.port.unlinked
            }
            strokeWidth="2"
            transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, -3.552713678800501e-15, -8.881784197001252e-16)"
          />
          <path
            d="M 9.538 0 L 9.538 32"
            stroke={
              state === 'outdated'
                ? palette.warning.main
                : state === 'error'
                  ? palette.graph.node.border.error
                  : state === 'active' || state === 'focus'
                    ? palette.graph.node.border.active
                    : linked
                      ? error
                        ? palette.error.main
                        : palette.graph.port.linked
                      : palette.graph.port.unlinked
            }
            className="port-icon-bar"
            strokeWidth="1.5"
            transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, -3.552713678800501e-15, -8.881784197001252e-16)"
          />
        </svg>
      </Tooltip>
    );
  }),
);
