import { styled } from '@mui/material';
import { AnchorHTMLAttributes } from 'react';

type ExternalLinkProps = AnchorHTMLAttributes<HTMLAnchorElement> & {
  unstyled?: boolean;
};

const UnstyledLink = ({ unstyled = false, ...props }: ExternalLinkProps) => {
  return <a target="_blank" rel="noopener noreferrer" {...props} />;
};

export const ExternalLink = styled(UnstyledLink, {
  shouldForwardProp: (prop) => prop !== 'unstyled' && prop !== 'sx',
})(({ theme, ...props }) => ({
  cursor: 'pointer',
  color: props.unstyled ? 'inherit' : theme.palette.link.default,
  textDecorationColor: props.unstyled ? 'inherit' : theme.palette.link.default,
  textDecoration: props.unstyled ? 'none' : 'underline',
  '&:hover': {
    color: `${props.unstyled ? 'inherit' : theme.palette.link.hover}`,
    textDecoration: props.unstyled ? 'none' : 'underline',
  },
}));
